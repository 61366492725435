import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {InputForm} from "../../util/HelpersForm";
import {addTool, updateTool} from "../../../redux/actions/maintenance";


const FormTools = ({data, onClose, params}) => {
    const dispatch = useDispatch();


    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            data ? dispatch(updateTool(form, data?.id, params)) : dispatch(addTool(form, params));
            onClose();
        }
    })

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-1 gap-2`}>

                <InputForm values={formik.values.name} errors={formik.errors.name} formik={formik}
                           field={'name'} max_length={50}
                           title={'Nombre'}
                />
                <InputForm values={formik.values.model} errors={formik.errors.model} formik={formik}
                           field={'model'} max_length={50}
                           title={'Modelo'}
                />
                <InputForm values={formik.values.maker} errors={formik.errors.maker} formik={formik}
                           field={'maker'} max_length={50}
                           title={'Fabricante'}
                />


            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {
        name: data?.name || '', model: data?.model || '', maker: data?.maker || '',

    }
}
const newSchema = () => {
    return {

        name: Yup.string().max(50).required(),
        model: Yup.string().max(50).required(),
        maker: Yup.string().max(50).required(),


    }
}

export default FormTools;
