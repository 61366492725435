import React from 'react';
import {Document, Image, Page, PDFViewer, StyleSheet, Text, View} from "@react-pdf/renderer";
import Logo from '../../../assets/logo.png'
import Juan from '../../../assets/juanz.png'
import {bodyDelayDocument} from "../../util/helpers";

const styles = StyleSheet.create({
    image: {
        width: "120", padding: "2px",

    }, section: {
        display: "flex", justifyContent: "space-around", width: "100%", flexDirection: "row", borderBottom: "1px"
    }, textHeader: {
        fontSize: "10px", fontWeight: "extrabold", fontFamily: "Times-Roman", textAlign: "right", color: "#22c55e"
    },textSubject:{
        fontSize: "12px", fontWeight: "normal", fontFamily: "Times-Roman"
    }
});



const DocumentViewer = ({id,user}) => {

    const currentDocument=bodyDelayDocument[id]

    return (<PDFViewer style={{width: "100%", height: "100%"}}>
        <Document>
            <Page size="A4" style={{paddingHorizontal: "48px", paddingVertical: "12px", width: "100%",}}>
                <View style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "6px",
                    gap: "2px",
                    alignItems: "center",
                    borderBottom: "2px",
                    borderColor: "#22c55e"
                }}>
                    <View style={{
                        width: "100%", marginLeft: "150px",
                    }}>
                        <Text
                            style={styles.textHeader}>{"Av. Manuel A. Odría #197 \n" + "Acobamba – Tarma \n" + "Junín – Perú"}</Text>
                    </View>
                    <View style={{
                        width: "100%",
                    }}>
                        <Text
                            style={styles.textHeader}>{"www.greenbox.pe \n" + "info@greenbox.pe\n" + "(51) 064 341058"}</Text>
                    </View>
                    <View style={{
                        width: "100%", marginLeft: "10px",
                    }}>
                        <Image style={styles.image} src={Logo}/>
                    </View>
                </View>
                <View style={{
                    display: "flex", flexDirection: "column",
                    borderBottom: "1px",
                    padding: "6px",
                }}>
                    <View style={{
                        display: "flex", flexDirection: "column", width: "100%", padding: "14px",
                    }}>
                        <Text style={{
                            fontSize: "20px", fontWeight: "extrabold", fontFamily: "Times-Roman", textAlign
                                : "center", textDecoration: "underline"
                        }}>{currentDocument.title}</Text>
                    </View>
                    <View style={{display:"flex",flexDirection:"column",gap:"10px",marginVertical:"10px"}}>
                        <View style={{width:"100%",display:"flex",flexDirection:"row"}}>
                             <Text style={[styles.textSubject,{width:"20%"}]}>PARA:</Text>
                            <Text style={[styles.textSubject,{width:"80%",fontWeight:"extrabold !important"}]}>{user?.user}</Text>
                        </View>
                         <View style={{width:"100%",display:"flex",flexDirection:"row"}}>
                             <Text style={[styles.textSubject,{width:"20%"}]}>ASUNTO:</Text>
                            <Text style={[styles.textSubject,{width:"80%"}]}>{currentDocument.subject}</Text>
                        </View>
                        <View style={{width:"100%",display:"flex",flexDirection:"row"}}>
                             <Text style={[styles.textSubject,{width:"20%"}]}>FECHA:</Text>
                            <Text style={[styles.textSubject,{width:"80%"}]}>{"....../....../.........."}</Text>
                        </View>
                    </View>

                </View>
                 <View style={{
                    display: "flex", flexDirection: "column",
                    padding: "6px",
                     lineHeight:"1"
                }}>
                     <Text style={[styles.textSubject,{width:"100%"}]}>{`Mediante la presente carta, deseamos comunicarle que, tras una revisión de nuestros registros, hemos constatado que durante el período comprendido entre el ${user?.start_date} y el ${user?.end_date}, usted ha acumulado un total de ${user?.total_delay} de tardanza. ${currentDocument.body}`}</Text>
                 </View>
                <View style={{
                    display: "flex", flexDirection: "row",
                    justifyContent: "center",
                    width: "100%",
                    padding: "6px",
                }}>
                    <Image style={styles.image} src={Juan}/>
                </View>
                <View style={{
                    display: "flex", flexDirection: "column",
                    marginTop: "20px",
                    justifyContent: "center",
                    width: "25%",
                    padding: "6px",
                    borderTop: "1px",
                }}>
                    <Text style={[styles.textSubject,{width:"100%",textAlign:"center"}]}>{"Firma"}</Text>
                    <Text style={[styles.textSubject,{width:"100%"}]}>{"Recibido:"}</Text>
                    <Text style={[styles.textSubject,{width:"100%"}]}>{"Fecha:"}</Text>

                </View>
            </Page>
        </Document>
    </PDFViewer>);
};

export default DocumentViewer;