import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import {formatDate} from "../../util/helpers";

const TableRequirements = ({data,handleUpdate,handleDelete}) => {
    const columns = ['', 'Estado', 'Solicitante', 'Fecha','Item','Codigo', 'Producto', 'Descripción', 'Tipo de trabajo', 'Cantidad', 'U.M.']
    return (<TableBase columns={columns} cell={Row(data, handleUpdate, handleDelete)}/>);
};


const Row = (data, update, remove) => (data && data.map((item, index) => {

    return <tr key={index} className="h-max border-gray-300  border-b">

        <Cell text={<div className={"font-bold flex items-center whitespace-nowrap"}>
            <PencilSquareIcon onClick={() => update(item)} title={"Editar"}
                              className={"h-5 w-5 mr-2 text-blue-500 cursor-pointer"}/>
            <TrashIcon onClick={() => remove(item?.id)} title={"Eliminar"}
                       className={"h-5 w-5 mr-2 text-red-500 cursor-pointer"}/>

        </div>}/>
        <Cell text={
            <div className={"bg-gray-200 p-2 rounded-2xl font-bold text-center"}>
                <span>{item?.status}</span>
            </div>
        }/>
        <Cell text={item?.username}/>
        <Cell text={item?.date && formatDate(item?.date, )}/>
        <Cell text={item?.item}/>
        <Cell text={item?.code_item}/>
        <Cell text={item?.product}/>
        <Cell wrap={true} text={item?.description}/>
        <Cell text={item?.work}/>
        <Cell text={item?.quantity}/>
        <Cell text={item?.unit_measurement}/>


    </tr>
}));

export default TableRequirements;
