import {
    GET_DEPARTMENTS_FAIL,
    GET_DEPARTMENTS_SUCCESS,
    GET_POSITIONS_FAIL,
    GET_POSITIONS_SUCCESS
} from "../actions/types";

const initialState = {
    departments: null, positions: null,
}
export default function Users(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case GET_DEPARTMENTS_SUCCESS:
            return {
                ...state, departments: payload.data
            }
        case GET_DEPARTMENTS_FAIL:
            return {
                ...state, departments: null
            }
        case GET_POSITIONS_SUCCESS:
            return {
                ...state, positions: payload.data
            }
        case GET_POSITIONS_FAIL:
            return {
                ...state, positions: null
            }
        default:
            return state;
    }
}
