import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {addTracking, getUsersNotTracking, updateTracking} from "../../../redux/actions/talent_hub";
import {InputForm, SelectForm} from "../../util/HelpersForm";
import {format} from 'date-fns';
import SwitchField from "../../mm/Task/SwitchField";


const Form = ({onClose, data, params}) => {
    const absenteeism = useSelector(state => state.TalentHub.absenteeism);
    const users = useSelector(state => state.TalentHub.staff);
    const dispatch = useDispatch();

    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form) => {
            if (form.date === '') {
                form.date = null
            }
            if (form.check_in === '') {
                form.check_in = null
            }
            if (form.check_out === '') {
                form.check_out = null
            }
            if (form.lunch_start === '') {
                form.lunch_start = null
            }
            if (form.lunch_end === '') {
                form.lunch_end = null
            }
            data ? dispatch(updateTracking(data.id, form, params)) : dispatch(addTracking(form, params))
            onClose()
            dispatch(getUsersNotTracking({date: params.date}))
        }
    })
    return (<form className="bg-white  rounded px-4  pb-8 mb-4">
        <HeaderForm submit={formik.handleSubmit} close={onClose}/>
        <div className={`grid  grid-cols-2 gap-2`}>
            {!data &&
                <SelectForm values={formik.values.staff} errors={formik.errors.staff} formik={formik} field={'staff'}
                            display={'full_name'} class_={'col-span-2'}
                            title={'Empleado'} options={users}/>}
            <InputForm values={formik.values.date} errors={formik.errors.date} formik={formik} field={'date'}
                       type_={'date'} class_={'col-span-2'}
                       title={'Fecha'} max_length={10}/>
            <InputForm values={formik.values.check_in} errors={formik.errors.check_in} formik={formik}
                       field={'check_in'}
                       type_={'datetime-local'}
                       title={'Ingreso'} max_length={5}/>
            <InputForm values={formik.values.lunch_start} errors={formik.errors.lunch_start} formik={formik}
                       field={'lunch_start'} type_={'datetime-local'}
                       title={'Inicio almuerzo'} max_length={5}/>
            <InputForm values={formik.values.lunch_end} errors={formik.errors.lunch_end} formik={formik}
                       field={'lunch_end'} type_={'datetime-local'}
                       title={'Fin almuerzo'} max_length={5}/>
            <InputForm values={formik.values.check_out} errors={formik.errors.check_out} formik={formik}
                       field={'check_out'} type_={'datetime-local'}
                       title={'Salida'} max_length={5}/>
            <SelectForm values={formik.values.absenteeism} errors={formik.errors.absenteeism} formik={formik}
                        field={'absenteeism'} display={'name'}
                        title={'Ausentismo'} options={absenteeism}/>
            <InputForm values={formik.values.absenteeism_hours} errors={formik.errors.absenteeism_hours} formik={formik}
                       field={'absenteeism_hours'} type_={'time'}
                       title={'Horas ausentismo'} max_length={8}/>
            <SelectForm values={formik.values.absenteeism_extra} errors={formik.errors.absenteeism_extra}
                        formik={formik}
                        field={'absenteeism_extra'} display={'name'}
                        title={'Ausentismo extra'} options={absenteeism}/>
            <InputForm values={formik.values.absenteeism_hours_extra} errors={formik.errors.absenteeism_hours_extra}
                       formik={formik}
                       field={'absenteeism_hours_extra'} type_={'time'}
                       title={'Horas ausentismo extra'} max_length={8}/>
            <SwitchField formik={formik} title={"Aprobar H.E."} name={"approved"}/>

        </div>
    </form>);
};

const formatDate = (date) => (date ? format(new Date(date), "yyyy-MM-dd' 'HH:mm") : undefined);

const initialValues = (data) => {
    return {
        staff: data?.staff || '',
        date: data?.date || new Date().toISOString().split('T')[0],
        check_in: formatDate(data?.check_in),
        lunch_start: formatDate(data?.lunch_start),
        lunch_end: formatDate(data?.lunch_end),
        check_out: formatDate(data?.check_out),
        absenteeism: data?.absenteeism || undefined,
        absenteeism_extra: data?.absenteeism_extra || undefined,
        absenteeism_hours: data?.absenteeism_hours || "00:00:00",
        absenteeism_hours_extra: data?.absenteeism_hours_extra || "00:00:00",
        approved: data?.approved || false,
    }
}

const newSchema = () => {
    return {
        staff: Yup.string().min(7).required(),
        date: Yup.date(),
        absenteeism_hours: Yup.string(),
        absenteeism_hours_extra: Yup.string(),
        approved: Yup.boolean(),
    }
}

export default Form;