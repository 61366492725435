import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import Humanize from "humanize-plus";
import {formatDateTZ} from "../../util/helpers";
const TableProjection = ({data, handleUpdate, handleDelete}) => {
    const columns = ['', 'Fecha', 'Kg', 'Producto', 'Planta']
    return (<TableBase columns={columns} cell={Row(data, handleUpdate, handleDelete)}/>);

};

const Row = (data, update, remove) => (data && data.map((item, index) => {
    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={<div className={"font-bold flex items-center whitespace-nowrap"}>
            <PencilSquareIcon onClick={() => update(item)} title={"Editar"}
                              className={"h-5 w-5 mr-2 text-blue-500 cursor-pointer"}/>
            <TrashIcon onClick={() => remove(item?.id)} title={"Eliminar"}
                       className={"h-5 w-5 mr-2 text-red-500 cursor-pointer"}/>

        </div>}/>

        <Cell text={formatDateTZ(item?.date)}/>
        <Cell text={

            <p className={"font-semibold "}>
                {Humanize.formatNumber(item?.quantity, 2)} kg
            </p>
            }/>
        <Cell text={item?.product_name}/>
        <Cell text={item?.company_name}/>

    </tr>)
}));

export default TableProjection;
