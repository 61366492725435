import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {TrashIcon} from "@heroicons/react/20/solid";
import {addResouceOT, deleteResourceOT, getResources} from "../../../redux/actions/maintenance";
import {PlusCircleIcon} from "@heroicons/react/24/solid";


const FormResourcesOrder = ({id, close, params}) => {
    let work = useSelector(state => state.Maintenance.works)
    const resources = useSelector(state => state.Maintenance.resources);

    const [form, setForm] = useState({'article': '', 'quantity': 0});

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getResources(id))
    }, [work]);
    const handleSubmit = () => {
        if (form.article === '' || form.quantity === 0) return;
        dispatch(addResouceOT(form, id, params));
        setForm({'article': '', 'quantity': 0})
    };
    const handleDelete = (resourceId) => {
        dispatch(deleteResourceOT(resourceId, params));
    };

    const columns = ['', 'Artículo', 'Cantidad'];
    return (<div className="bg-white px-2 pb-8 mb-4">
        <HeaderForm close={close}/>

        <div className={"h-40 overflow-y-auto scrollbar-hide"}>
            <div className="mt-2 overflow-y-auto ">
                <div className="flex items-center justify-between p-2 hover:bg-blue-50">
                    <input type="text" placeholder="Artículo"
                            value={form.article}
                           onChange={(e) => setForm({...form, 'article': e.target.value})}
                           className="w-1/2 p-2 border border-gray-200 rounded"/>
                    <input type={"number"} placeholder="Cantidad"
                           value={form.quantity}
                            onChange={(e) => setForm({...form, 'quantity': e.target.value})}
                            className="w-1/4 p-2 border border-gray-200 rounded"/>
                    <PlusCircleIcon onClick={handleSubmit}
                        className="text-green-500 cursor-pointer w-6 h-6"/>
                </div>

            </div>
            <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 bg-white">
                <tr>
                    {columns.map((column, index) => (<th key={index} className="px-6 py-3">{column}</th>))}
                </tr>
                </thead>
                <tbody>
                {resources ? resources.map((row, index) => (
                    <tr key={index} className="bg-white border-b hover:bg-blue-100">
                        <td className="px-6  text-center">
                            <TrashIcon onClick={() => handleDelete(row?.id)}
                                       className="text-red-500 cursor-pointer w-5 h-5"/>
                        </td>
                        <td className="px-6 ">{row.article}</td>
                        <td className="px-6 ">{row.quantity}</td>
                    </tr>)) : (<tr>
                    {columns.map((_, index) => (<td key={index} className=" px-6 text-center mt-4">
                        <div className="h-2 bg-gray-300 rounded animate-pulse mt-4"></div>
                        <div className="h-2 bg-gray-300 rounded animate-pulse mt-4"></div>
                        <div className="h-2 bg-gray-300 rounded animate-pulse mt-4"></div>
                    </td>))}
                </tr>)}
                </tbody>
            </table>

        </div>
    </div>);
};


export default FormResourcesOrder;
