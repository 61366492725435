import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {InputForm} from "../../util/HelpersForm";
import {updateFreight} from "../../../redux/actions/logistic";


const FormFreight = ({data, onClose, params}) => {
    const dispatch = useDispatch();


    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            dispatch(updateFreight(data?.id, form, params));
            onClose();
        }
    })

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-1 gap-2`}>

                <InputForm values={formik.values.cost_unit} errors={formik.errors.cost_unit} formik={formik}
                           field={'cost_unit'}
                           title={'Costo unitario'} type={'number'}
                />
                <InputForm values={formik.values.boxes_not_paid} errors={formik.errors.boxes_not_paid} formik={formik}
                           field={'boxes_not_paid'}
                           title={'Jabas pendientes'} type={'number'}
                />


            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {
        cost_unit: data?.cost_unit || 0, boxes_not_paid: data?.boxes_not_paid || 0,
    }
}
const newSchema = () => {
    return {

        cost_unit: Yup.number().required(), boxes_not_paid: Yup.number().integer().required(),


    }
}

export default FormFreight;
