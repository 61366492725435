import React, {useEffect, useState} from 'react';
import Layout from "../../../../hocs/Layout";
import ButtonHelper from "../../../../components/util/ButtonHelper";
import {useDispatch, useSelector} from "react-redux";
import {deleteProjection, getProjection} from "../../../../redux/actions/agrisupply";
import {MySwal} from "../../../../components/util/helpers";
import ModalHook from "../../../../hooks/ModalHook";
import Modal from "../../../../components/util/Modal";
import {get_categories} from "../../../../redux/actions/category";
import {getManufacturingCompanies} from "../../../../redux/actions/stakeholders";
import {Helmet} from "react-helmet";
import TableProjection from "../../../../components/e2e/Agrisupply/TableProjection";
import FiltersProjection from "../../../../components/e2e/Agrisupply/FiltersProjection";
import FormProjection from "../../../../components/e2e/Agrisupply/FormProjection";

const Projection = () => {
    const [params, setParams] = useState({company: '', product: '', week: ''})
    const dispatch = useDispatch();
    const {content, setContent, isOpen, openModal} = ModalHook();
    const data = useSelector(state => state.Agrisupply.projection);

    useEffect(() => {
        dispatch(get_categories());
        dispatch(getManufacturingCompanies())
    }, [dispatch]);

    const handleSearch = () => {
        if (params.company === '' && params.product === '' && params.week === '') {
            MySwal.fire({
                title: 'Debe seleccionar todos los filtros', icon: 'warning',
            });
        } else dispatch(getProjection(params));
    }

    const handleAdd = () => {
        const formComponent = <FormProjection onClose={openModal} params={params}/>;
        setContent(formComponent);
        openModal();
    };

    const handleUpdate = (data) => {
        const formComponent = <FormProjection onClose={openModal} data={data} params={params}
        />;
        setContent(formComponent);
        openModal();
    };
    const handleDelete = (data) => {
        MySwal.fire({
            title: '¿Desea eliminar este registro?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7DABF5',
            confirmButtonColor: '#F87171',
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteProjection(data, params))
            }
        });
    };

    return (<Layout>
        <Helmet>
            <title>Proyeccion</title>
        </Helmet>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <div
            className={"bg-white rounded-2xl p-4 h-fit"}>
            <ButtonHelper handle={handleAdd}/>
            <FiltersProjection params={params} setParams={setParams} handleSearch={handleSearch}/>
            <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                <TableProjection handleDelete={handleDelete} handleUpdate={handleUpdate} data={data}/>
            </div>
        </div>
    </Layout>);
}


export default Projection;
