import {
    ADD_ARTICLE_FAIL,
    ADD_ARTICLE_SUCCESS,
    ADD_BATCH_OUTPUT_FAIL,
    ADD_BATCH_OUTPUT_SUCCESS,
    ADD_DOWNLOAD_LOT_FAIL,
    ADD_DOWNLOAD_LOT_SUCCESS,
    ADD_EXTERNAL_PERSON_FAIL,
    ADD_EXTERNAL_PERSON_SUCCESS,
    ADD_FILE_FAIL,
    ADD_FILE_SUCCESS,
    ADD_ITEM_LOT_FAIL,
    ADD_ITEM_LOT_SUCCESS,
    ADD_LOT_FAIL,
    ADD_LOT_SUCCESS,
    DELETE_BATCH_OUTPUT_FAIL,
    DELETE_BATCH_OUTPUT_SUCCESS,
    DELETE_DOWNLOAD_LOT_FAIL,
    DELETE_DOWNLOAD_LOT_SUCCESS,
    DELETE_EXTERNAL_PERSON_FAIL,
    DELETE_EXTERNAL_PERSON_SUCCESS,
    DELETE_FILE_FAIL,
    DELETE_FILE_SUCCESS,
    DELETE_ITEM_LOT_FAIL,
    DELETE_ITEM_LOT_SUCCESS,
    GET_ARTICLE_ISSUES_FAIL,
    GET_ARTICLE_ISSUES_SUCCESS,
    GET_ARTICLE_RECEIPTS_FAIL,
    GET_ARTICLE_RECEIPTS_SUCCESS,
    GET_ARTICLES_FAIL,
    GET_ARTICLES_SUCCESS,
    GET_BATCH_OUTPUT_FAIL,
    GET_BATCH_OUTPUT_SUCCESS,
    GET_DOWNLOADS_LOT_FAIL,
    GET_DOWNLOADS_LOT_SUCCESS,
    GET_EXTERNAL_PERSONS_FAIL,
    GET_EXTERNAL_PERSONS_SUCCESS,
    GET_FILES_FAIL,
    GET_FILES_SUCCESS,
    GET_FREIGHTS_FAIL,
    GET_FREIGHTS_SUCCESS,
    GET_GLP_FAIL,
    GET_GLP_SUCCESS,
    GET_ITEMS_LOT_FAIL,
    GET_ITEMS_LOT_SUCCESS,
    GET_LOT_FAIL,
    GET_LOT_SUCCESS,
    GET_LOT_SUMMARY_FAIL,
    GET_LOT_SUMMARY_SUCCESS,
    GET_PALLETS_FAIL,
    GET_PALLETS_SUCCESS,
    GET_RECEIPTS_BY_ARTICLE_FAIL,
    GET_RECEIPTS_BY_ARTICLE_SUCCESS,
    GET_RECORDS_FAIL,
    GET_RECORDS_SUCCESS,
    GET_STOCK_AVAILABLE_FAIL,
    GET_STOCK_AVAILABLE_SUCCESS,
    ISSUE_ARTICLE_FAIL,
    ISSUE_ARTICLE_SUCCESS,
    NOTIFICATION_ARTICLE_FAIL,
    NOTIFICATION_ARTICLE_SUCCESS,
    RECEIPT_ARTICLE_FAIL,
    RECEIPT_ARTICLE_SUCCESS,
    TRANSFER_ARTICLE_FAIL,
    TRANSFER_ARTICLE_SUCCESS,
    UPDATE_BATCH_OUTPUT_FAIL,
    UPDATE_BATCH_OUTPUT_SUCCESS,
    UPDATE_DOWNLOAD_LOT_FAIL,
    UPDATE_DOWNLOAD_LOT_SUCCESS,
    UPDATE_EXTERNAL_PERSON_FAIL,
    UPDATE_EXTERNAL_PERSON_SUCCESS,
    UPDATE_ITEM_LOT_FAIL,
    UPDATE_ITEM_LOT_SUCCESS,
    UPDATE_LOT_FAIL,
    UPDATE_LOT_SUCCESS,
} from "../actions/types";

const initialState = {
    external: null,
    lots: null,
    lot: null,
    signature: null,
    downloads: null,
    pallets: null,
    items: null,
    stock: null,
    batch_output: null,
    article: null,
    receipt: null,
    receipts_stock: null,
    issue: null,
    freight: null,
    glp: null,
    document: null,
    records: null,
    stock_mp: null
}
export default function Logistic(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case GET_EXTERNAL_PERSONS_SUCCESS:
            return {
                ...state, external: payload.data
            }
        case GET_EXTERNAL_PERSONS_FAIL:
            return {
                ...state, external: null
            }
        case GET_LOT_SUMMARY_SUCCESS:
            return {
                ...state, lots: payload.data, stock_mp: payload.stock_by_product
            }
        case GET_LOT_SUMMARY_FAIL:
            return {
                ...state, lots: null, stock_mp: null
            }
        case GET_LOT_SUCCESS:
            return {
                ...state, lot: payload.data, signature: payload.signature
            }
        case GET_LOT_FAIL:
            return {
                ...state, lot: null, signature: null
            }
        case GET_DOWNLOADS_LOT_SUCCESS:
            return {
                ...state, downloads: payload.data
            }
        case GET_DOWNLOADS_LOT_FAIL:
            return {
                ...state, downloads: null
            }
        case GET_PALLETS_SUCCESS:
            return {
                ...state, pallets: payload.data
            }
        case GET_PALLETS_FAIL:
            return {
                ...state, pallets: null
            }
        case GET_ITEMS_LOT_SUCCESS:
            return {
                ...state, items: payload.data
            }
        case GET_ITEMS_LOT_FAIL:
            return {
                ...state, items: null
            }
        case GET_STOCK_AVAILABLE_SUCCESS:
            return {
                ...state, stock: payload.data
            }
        case GET_STOCK_AVAILABLE_FAIL:
            return {
                ...state, stock: null
            }
        case GET_BATCH_OUTPUT_SUCCESS:
            return {
                ...state, batch_output: payload.data
            }
        case GET_BATCH_OUTPUT_FAIL:
            return {
                ...state, batch_output: null
            }
        case GET_ARTICLES_SUCCESS:
            return {
                ...state, article: payload.data
            }
        case GET_ARTICLES_FAIL:
            return {
                ...state, article: null
            }
        case GET_ARTICLE_RECEIPTS_SUCCESS:
            return {
                ...state, receipt: payload.data
            }
        case GET_ARTICLE_RECEIPTS_FAIL:
            return {
                ...state, receipt: null
            }
        case GET_ARTICLE_ISSUES_SUCCESS:
            return {
                ...state, issue: payload.data
            }
        case GET_ARTICLE_ISSUES_FAIL:
            return {
                ...state, issue: null
            }
        case GET_RECEIPTS_BY_ARTICLE_SUCCESS:
            return {
                ...state, receipts_stock: payload.data
            }
        case GET_RECEIPTS_BY_ARTICLE_FAIL:
            return {
                ...state, receipts_stock: null
            }
        case GET_FREIGHTS_SUCCESS:
            return {
                ...state, freight: payload.data
            }
        case GET_FREIGHTS_FAIL:
            return {
                ...state, freight: null
            }
        case GET_GLP_SUCCESS:
            return {
                ...state, glp: payload.data
            }
        case GET_GLP_FAIL:
            return {
                ...state, glp: null
            }
        case GET_FILES_SUCCESS:
            return {
                ...state, document: payload.data
            }
        case GET_FILES_FAIL:
            return {
                ...state, document: null
            }
        case GET_RECORDS_SUCCESS:
            return {
                ...state, records: payload.data
            }
        case GET_RECORDS_FAIL:
            return {
                ...state, records: null
            }
        case ADD_EXTERNAL_PERSON_SUCCESS:
        case UPDATE_EXTERNAL_PERSON_SUCCESS:
        case DELETE_EXTERNAL_PERSON_SUCCESS:
        case ADD_EXTERNAL_PERSON_FAIL:
        case UPDATE_EXTERNAL_PERSON_FAIL:
        case DELETE_EXTERNAL_PERSON_FAIL:
        case ADD_LOT_SUCCESS:
        case ADD_LOT_FAIL:
        case ADD_DOWNLOAD_LOT_SUCCESS:
        case ADD_DOWNLOAD_LOT_FAIL:
        case UPDATE_DOWNLOAD_LOT_SUCCESS:
        case UPDATE_DOWNLOAD_LOT_FAIL:
        case DELETE_DOWNLOAD_LOT_SUCCESS:
        case DELETE_DOWNLOAD_LOT_FAIL:
        case ADD_ITEM_LOT_SUCCESS:
        case ADD_ITEM_LOT_FAIL:
        case UPDATE_ITEM_LOT_SUCCESS:
        case UPDATE_ITEM_LOT_FAIL:
        case DELETE_ITEM_LOT_SUCCESS:
        case DELETE_ITEM_LOT_FAIL:
        case ADD_BATCH_OUTPUT_SUCCESS:
        case ADD_BATCH_OUTPUT_FAIL:
        case UPDATE_BATCH_OUTPUT_SUCCESS:
        case UPDATE_BATCH_OUTPUT_FAIL:
        case DELETE_BATCH_OUTPUT_SUCCESS:
        case DELETE_BATCH_OUTPUT_FAIL:
        case ADD_ARTICLE_SUCCESS:
        case ADD_ARTICLE_FAIL:
        case RECEIPT_ARTICLE_SUCCESS:
        case RECEIPT_ARTICLE_FAIL:
        case ISSUE_ARTICLE_SUCCESS:
        case ISSUE_ARTICLE_FAIL:
        case TRANSFER_ARTICLE_SUCCESS:
        case TRANSFER_ARTICLE_FAIL:
        case NOTIFICATION_ARTICLE_SUCCESS:
        case NOTIFICATION_ARTICLE_FAIL:
        case ADD_FILE_SUCCESS:
        case ADD_FILE_FAIL:
        case DELETE_FILE_SUCCESS:
        case DELETE_FILE_FAIL:
        case UPDATE_LOT_FAIL:
        case UPDATE_LOT_SUCCESS: {
            return {
                ...state
            }
        }
        default:
            return state;
    }
}
