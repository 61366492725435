import axios from "axios";
import {
    ADD_PARCEL_FAIL,
    ADD_PARCEL_SUCCESS, ADD_PROJECTION_FAIL, ADD_PROJECTION_SUCCESS,
    DELETE_PARCEL_FAIL,
    DELETE_PARCEL_SUCCESS, DELETE_PROJECTION_FAIL, DELETE_PROJECTION_SUCCESS,
    GET_PARCELS_FAIL,
    GET_PARCELS_SUCCESS, GET_PROJECTIONS_FAIL, GET_PROJECTIONS_SUCCESS,
    UPDATE_PARCEL_FAIL,
    UPDATE_PARCEL_SUCCESS, UPDATE_PROJECTION_FAIL, UPDATE_PROJECTION_SUCCESS,
} from "./types";
import {setAlert} from "./alert";

/* Materia Prima */
export const getParcels = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
        params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/agrisupply/parcel/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_PARCELS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_PARCELS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_PARCELS_FAIL
        });
    }
}
export const addParcel = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/agrisupply/parcel/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_PARCEL_SUCCESS, payload: res.data
            });
            dispatch(getParcels(params))
            dispatch(setAlert(res.data.message || 'Parcela creada con exito', 'success'));
        } else {
            dispatch({
                type: ADD_PARCEL_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_PARCEL_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_PARCEL_FAIL
            });
        }
    }
};
export const updateParcel = (pk, form, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',  'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/agrisupply/parcel/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_PARCEL_SUCCESS, payload: res.data
            });
            dispatch(getParcels(params))
            dispatch(setAlert(res.data.message || 'Parcela actualizada con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_PARCEL_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_PARCEL_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_PARCEL_FAIL
            });
        }
    }
};
export const deleteParcel = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/agrisupply/parcel/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_PARCEL_SUCCESS, payload: res.data
            });
            dispatch(getParcels(params))
            dispatch(setAlert(res.data.message || 'Parcela eliminada con exito.', 'success'));
        } else {
            dispatch({
                type: DELETE_PARCEL_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_PARCEL_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_PARCEL_FAIL
            });
        }
    }
};



export const getProjection = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
        params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/agrisupply/projection/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_PROJECTIONS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_PROJECTIONS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_PROJECTIONS_FAIL
        });
    }
}
export const addProjection = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/agrisupply/projection/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_PROJECTION_SUCCESS, payload: res.data
            });
            dispatch(getProjection(params))
            dispatch(setAlert(res.data.message || 'Registro creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_PROJECTION_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_PROJECTION_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_PROJECTION_FAIL
            });
        }
    }
};
export const updateProjection = (pk, form, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',  'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/agrisupply/projection/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_PROJECTION_SUCCESS, payload: res.data
            });
            dispatch(getProjection(params))
            dispatch(setAlert(res.data.message || 'Registro actulizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_PROJECTION_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_PROJECTION_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_PROJECTION_FAIL
            });
        }
    }
};
export const deleteProjection = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/agrisupply/projection/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_PROJECTION_SUCCESS, payload: res.data
            });
            dispatch(getProjection(params))
            dispatch(setAlert(res.data.message || 'Registro eliminado con exito.', 'success'));
        } else {
            dispatch({
                type: DELETE_PROJECTION_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_PROJECTION_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_PROJECTION_FAIL
            });
        }
    }
};