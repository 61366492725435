import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {InputForm, SelectForm, TextAreaForm} from "../../util/HelpersForm";
import {addRequirement, updateRequirement} from "../../../redux/actions/maintenance";


const FormRequirements = ({data, onClose, params}) => {
    const dispatch = useDispatch();


    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            data ? dispatch(updateRequirement(form, data?.id, params)) : dispatch(addRequirement(form, params));
            onClose();
        }
    })

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-2 gap-2`}>

                <InputForm values={formik.values.date} errors={formik.errors.date} formik={formik}
                           field={'date'}
                           title={'Fecha'} type_={'date'}/>
                <InputForm values={formik.values.product} errors={formik.errors.product} formik={formik}
                           field={'product'} title={'Producto'} max_length={100}/>
                <TextAreaForm values={formik.values.description} errors={formik.errors.description} formik={formik}
                              class_={'col-span-2'}
                              field={'description'} title={'Descripción'}/>
                <TextAreaForm values={formik.values.item} errors={formik.errors.item} formik={formik}
                              class_={'col-span-2'}
                              field={'item'} title={'Item'}/>
                <TextAreaForm values={formik.values.code_item} errors={formik.errors.code_item} formik={formik}
                              class_={'col-span-2'}
                              field={'code_item'} title={'Codigo de parte'}/>
                <TextAreaForm values={formik.values.work} errors={formik.errors.work} formik={formik}
                              class_={'col-span-2'}
                              field={'work'} title={'Tipo de trabajo'}/>
                <InputForm values={formik.values.quantity} errors={formik.errors.quantity} formik={formik}
                           field={'quantity'} title={'Cantidad'} type_={'number'}/>
                <SelectForm values={formik.values.unit_measurement} errors={formik.errors.unit_measurement}
                            formik={formik}
                            field={'unit_measurement'} title={'U.M.'}
                            options={[{id: 'UN', name: 'und'}, {id: 'KG', name: 'kg'}, {id: 'LT', name: 'Litro'}, {
                                id: 'M2', name: 'm2'
                            }, {id: 'M3', name: 'm3'}, {id: 'M', name: 'metro'}, {id: 'CM', name: 'cm'}, {
                                id: 'RL', name: 'Rollo'
                            },{id:'CJ',name:'caja'},{id:'PQ',name:'paquete'}]} display={'name'}/>
                <SelectForm values={formik.values.status} errors={formik.errors.status} formik={formik}
                            field={'status'} title={'Estado'} options={[{id: 'Pendiente', name: 'Pendiente'}, {
                    id: 'Aprobado', name: 'Aprobado'
                }, {id: 'Rechazado', name: 'Rechazado'}, {id: 'Parcial', name: 'Parcial'}, {
                    id: 'Finalizado', name: 'Finalizado'
                }]} display={'name'}/>
            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {
        date: data?.date || new Date().toISOString().split('T')[0],
        work: data?.work || '',
        product: data?.product || '',
        description: data?.description || '',
        quantity: data?.quantity || 1,
        unit_measurement: data?.unit_measurement || 'UN',
        status: data?.status || 'Pendiente',
        item: data?.item || '',
        code_item: data?.code_item || '',
    }
}
const newSchema = () => {
    return {
        date: Yup.date().required(),
        work: Yup.string().max(100).required(),
        product: Yup.string().max(100).required(),
        description: Yup.string().max(100).required(),
        quantity: Yup.number().min(1).required(),
        unit_measurement: Yup.string().required(),
        status: Yup.string().required(),
        item: Yup.string().required(),
        code_item: Yup.string().required(),
    }
}

export default FormRequirements;
