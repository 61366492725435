import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {InputForm, SelectForm} from "../../util/HelpersForm";
import {addAssetPhysical, updateAssetPhysical} from "../../../redux/actions/maintenance";


const FormPhysical = ({data, onClose, params}) => {
    const dispatch = useDispatch();
    const fixed = useSelector(state => state.Maintenance.fixed);


    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            data ? dispatch(updateAssetPhysical(form, data?.id, params)) : dispatch(addAssetPhysical(form, params));
            onClose();
        }
    })

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-1 gap-2`}>

                <InputForm values={formik.values.name} errors={formik.errors.name} formik={formik}
                           field={'name'} max_length={50}
                           title={'Nombre'}
                />
                <InputForm values={formik.values.buy_date} errors={formik.errors.buy_date} formik={formik}
                           field={'buy_date'}
                           title={'Fecha de compra'}
                           type_={'date'}/>
                <InputForm values={formik.values.model} errors={formik.errors.model} formik={formik}
                           field={'model'} max_length={50}
                           title={'Modelo'}
                />
                <SelectForm values={formik.values.criticality} errors={formik.errors.criticality} formik={formik}
                            field={'criticality'}
                            title={'Criticidad'} display={'name'}
                            options={[{id: 'L', name: 'Bajo'}, {id: 'M', name: 'Medio'}, {
                                id: 'H', name: 'Alto'
                            }]}
                />
                <SelectForm values={formik.values.parent} errors={formik.errors.parent} formik={formik}
                            field={'parent'} display={'name'}
                            title={'Ubicación'}
                            options={fixed.map(i => ({id: i.id, name: i.name}))}
                />
                <InputForm values={formik.values.code} errors={formik.errors.code} formik={formik}
                           field={'code'} max_length={50}
                           title={'Código'}
                />


            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {
        name: data?.name || '',
        buy_date: data?.buy_date || '',
        model: data?.model || '',
        criticality: data?.criticality || 'L',
        parent: data?.parent || '',
        code: data?.code || '',
    }
}
const newSchema = () => {
    return {

        name: Yup.string().required(),
        buy_date: Yup.date().required(),
        model: Yup.string().required(),
        criticality: Yup.string().min(1).max(1).required(),
        parent: Yup.string().min(6).required(),
        code: Yup.string().required(),


    }
}

export default FormPhysical;
