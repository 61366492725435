import React, {Fragment} from 'react';
import {Combobox, Transition} from "@headlessui/react";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/24/outline";

const CBox = ({selected, setSelected, query, setQuery, filtered}) => {
    return (<Combobox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
            <div
                className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                <Combobox.Input
                    className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                    displayValue={(type) => type.name}
                    onChange={(event) => setQuery(event.target.value)}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </Combobox.Button>
            </div>
            <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
            >
                <Combobox.Options
                    className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filtered.length === 0 && query !== '' ? (
                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                            Nothing found.
                        </div>) : (filtered.map((type) => (<Combobox.Option
                        key={type.id}
                        className={({active}) => `bg-white hover:bg-blue-200 z-50 relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-300 text-black' : 'text-gray-900'}`}
                        value={type}
                    >
                        {({selected, active}) => (<>
                        <span
                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                        >
                          {type.name}
                        </span>
                            {selected ? (<span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-black' : 'text-blue-300'}`}
                            >
                            <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                          </span>) : null}
                        </>)}
                    </Combobox.Option>)))}
                </Combobox.Options>
            </Transition>
        </div>
    </Combobox>);
};

export default CBox;