import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {PencilSquareIcon} from "@heroicons/react/24/outline";
import {formatDateTZ} from "../../util/helpers";
import Humanize from "humanize-plus";

const TablePackaging = ({data, handleUpdate, cuts}) => {
    const columns = ['', 'Lote', 'Fecha proceso', 'Fecha envasado', 'Kg procesados', 'Presentación', 'Categoria', 'Kg total', 'Rendimiento', 'Humedad', 'Personas', 'Horas']
    return (<TableBase columns={columns} cell={Row(data, handleUpdate, cuts)}/>);
};

const Row = (data, update, cuts) => (data && data.map((item, index) => {
    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={<div className={"font-bold flex items-center gap-2 whitespace-nowrap"}>
            <PencilSquareIcon onClick={() => update(item)} title={"Editar"}
                              className={"h-5 w-5  text-blue-500 cursor-pointer"}/>
            <div title={"Modificaciones"}
                 className={"w-4 h-4 bg-red-500 bg-opacity-75  text-xs  rounded-2xl text-center text-white font-medium"}>
                {item?.number_changes}</div>

        </div>}/>
        <Cell text={<p className={"font-medium"}>{item?.lot_name}</p>}/>
        <Cell text={item?.date_production && formatDateTZ(item.date_production)}/>
        <Cell text={item?.date_packaging && formatDateTZ(item.date_packaging)}/>
        <Cell text={Humanize.formatNumber(item?.process_kg, 2)}/>
        <Cell text={<div
            className={"w-full bg-cyan-500 bg-opacity-60 px-2 py-1 rounded-2xl text-center text-white font-bold"}>
            {cuts && cuts.find(cut => cut.id === item.cut)?.name}
        </div>}/>
        <Cell text={<p
            className={"text-center"}>{item?.category === 'A' ? 'PT' :  "Laboratorio"}</p>}/>
        <Cell text={Humanize.formatNumber(item?.kg, 2)}/>
        <Cell text={<div
            className={"w-full bg-green-500 bg-opacity-75 px-2 py-1 text-center rounded-2xl text-white font-bold"}>
            {Humanize.formatNumber(item?.kg / item?.process_kg * 100, 2)}%
        </div>}/>
        <Cell text={Humanize.formatNumber(item?.humidity, 2)}/>
        <Cell text={Humanize.formatNumber(item?.people, 0)}/>
        <Cell text={item?.duration}/>

    </tr>)
}));

export default TablePackaging;
