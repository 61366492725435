import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {PencilSquareIcon} from "@heroicons/react/24/outline";
import {formatDate} from "../../util/helpers";
import Humanize from "humanize-plus";

const TableFreight = ({data, handleUpdate}) => {
    const columns = ['', 'Fecha', 'Lote', 'Proveedor', 'Guia', 'C.U.', 'Jabas completar', 'Falso flete', 'Total']
    return (<TableBase columns={columns} cell={Row(data, handleUpdate)}/>);
};

const Row = (data, update) => (data && data.map((item, index) => {
    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={<div className={"font-bold flex items-center whitespace-nowrap"}>
            <PencilSquareIcon onClick={() => update(item)} title={"Editar"}
                              className={"h-5 w-5 mr-2 text-blue-500 cursor-pointer"}/>

        </div>}/>
        <Cell text={item?.date && formatDate(item.date)}/>
        <Cell text={item?.lot_name}/>
        <Cell text={item?.supplier}/>
        <Cell text={item?.transport_guide}/>
        <Cell text={Humanize.formatNumber(item?.cost_unit, 2)}/>
        <Cell text={item?.boxes_not_paid}/>
        <Cell text={item?.cost_false}/>

        <Cell text={<div
            className={`text-white rounded-full font-bold px-2 py-1 text-center ${item?.total_cost < 1 ? 'bg-red-400' : 'bg-green-400'}`}>{item?.total_cost}</div>}/>


    </tr>)
}));

export default TableFreight;
