import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import {formatDate} from "../../util/helpers";

const TableDownload = ({data, handleUpdate, handleDelete}) => {
    const columns = ['', 'Fecha', 'Lote', 'Peso neto','Fecha de pago', 'Estado', 'Costo', 'Operador']
    return (<TableBase columns={columns} cell={Row(data, handleUpdate, handleDelete)}/>);
};

const Row = (data, update, remove) => (data && data.map((item, index) => {
    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={<div className={"font-bold flex items-center whitespace-nowrap"}>
            <PencilSquareIcon onClick={() => update(item)} title={"Editar"}
                              className={"h-5 w-5 mr-2 text-blue-500 cursor-pointer"}/>
            <TrashIcon onClick={() => remove(item?.id)} title={"Eliminar"}
                       className={"h-5 w-5 mr-2 text-red-500 cursor-pointer"}/>

        </div>}/>
        <Cell text={item?.datetime_download && formatDate(item.datetime_download)}/>
        <Cell text={item?.lot_name}/>
        <Cell text={item?.weight_net}/>
                <Cell text={item?.payment_date && formatDate(item?.payment_date)}/>

        <Cell text={
            <div
                className={`text-white rounded-full font-bold px-2 py-1 text-center ${item?.status === 'P' ? 'bg-red-400' : 'bg-green-400'}`}>{item?.status === 'P'?'Pendiente':'Cancelado'}</div>}/>
        <Cell text={item?.cost}/>
        <Cell text={item?.external_name}/>

    </tr>)
}));

export default TableDownload;
