import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {useSelector} from "react-redux";

const TableDaily = ({option}) => {
    const data = useSelector(state => state.TalentHub.calendar)
    const {dates, users_summary} = data || {};
    const columns = data ? ['Nombre', ...dates] : [];
    return (<TableBase columns={columns}
                       cell={data && data.users_summary && Object.keys(data.users_summary).length > 0 && Object.entries(data.users_summary).map(([key, value], index) => (
                           <tr className="bg-white border-b" key={index}>
                               <th scope="row"
                                   className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap cursor-pointer hover:text-green-400 sticky left-0 bg-white">
                                   {key}
                               </th>
                               {value.map((item, idx) => (
                                   <td key={idx} className={`px-6 py-2 text-center whitespace-nowrap`}>
                                       {option === 'worked' && item?.worked_hours}
                                       {option === 'delay' &&
                                           <p className={item?.delay_hours > "00:00" ? "text-red-400" : ""}>{item?.delay_hours}</p>}
                                       {option === 'overtime' && item?.overtime_hours}
                                       {option === 'compensated' && item?.compensation_hours}
                                   </td>))}
                           </tr>))}/>);
};

const Row = (data) => (data && data.map((item, index) => {
    console.log(data)
    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={item}/>
    </tr>)
}));

export default TableDaily;
