import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {addProjection, updateProjection} from "../../../redux/actions/agrisupply";
import {InputForm, SelectForm} from "../../util/HelpersForm";


const FormProjection = ({data, onClose, params}) => {
    const dispatch = useDispatch();
    const manufacturing_companies = useSelector(state => state.Stakeholders.manufacturing_companies);
    const categories = useSelector(state => state.Category.categories);

    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            data ? dispatch(updateProjection(data?.id, form, params)) : dispatch(addProjection(form, params));
            onClose();
        }
    })

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-2 gap-2`}>
                <InputForm values={formik.values.date} errors={formik.errors.date} formik={formik} field={'date'}
                           title={'Fecha'} type_={'date'}/>
                <SelectForm values={formik.values.company} errors={formik.errors.company} formik={formik}
                            field={'company'} title={'Planta'}
                            options={manufacturing_companies ? manufacturing_companies : []} display={'name'}/>
                <SelectForm values={formik.values.product} errors={formik.errors.product} formik={formik}
                            field={'product'} title={'Cultivo'}
                            options={categories ? categories.find(item => item.name === "Materia Prima")?.children : []}
                            display={'name'}/>

                <InputForm values={formik.values.quantity} errors={formik.errors.quantity} formik={formik}
                           field={'quantity'}
                           title={'Kg'} max_length={11}/>

            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {
        date: data?.date || new Date().toISOString().split('T')[0],
        product: data?.product || '',
        quantity: data?.quantity || '0',
        company: data?.company || '',
    }
}
const newSchema = () => {
    return {
        date: Yup.date().required(),
        product: Yup.string().required(),
        quantity: Yup.number().min(0).required(),
        company: Yup.string().required(),
    }
}

export default FormProjection;
