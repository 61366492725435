import axios from "axios";
import {
    ADD_CONDITIONING_PINEAPPLE_FAIL,
    ADD_CONDITIONING_PINEAPPLE_SUCCESS,
    ADD_CONDITIONING_SWEET_POTATO_FAIL,
    ADD_CONDITIONING_SWEET_POTATO_SUCCESS, ADD_PACKING_LOT_FAIL, ADD_PACKING_LOT_SUCCESS,
    ADD_THUMBNAIL_FAIL,
    ADD_THUMBNAIL_SUCCESS,
    DELETE_CONDITIONING_PINEAPPLE_FAIL,
    DELETE_CONDITIONING_PINEAPPLE_SUCCESS,
    DELETE_CONDITIONING_SWEET_POTATO_FAIL,
    DELETE_CONDITIONING_SWEET_POTATO_SUCCESS,
    DELETE_THUMBNAIL_FAIL,
    DELETE_THUMBNAIL_SUCCESS,
    GET_CONDITIONING_PINEAPPLE_FAIL,
    GET_CONDITIONING_PINEAPPLE_LOT_FAIL,
    GET_CONDITIONING_PINEAPPLE_LOT_SUCCESS,
    GET_CONDITIONING_PINEAPPLE_SUCCESS,
    GET_CONDITIONING_SWEET_POTATO_FAIL,
    GET_CONDITIONING_SWEET_POTATO_LOT_FAIL,
    GET_CONDITIONING_SWEET_POTATO_LOT_SUCCESS,
    GET_CONDITIONING_SWEET_POTATO_SUCCESS,
    GET_CUTS_FAIL,
    GET_CUTS_SUCCESS,
    GET_PACKING_LOT_FAIL,
    GET_PACKING_LOT_SUCCESS,
    GET_THUMBNAILS_FAIL,
    GET_THUMBNAILS_SUCCESS,
    UPDATE_CONDITIONING_PINEAPPLE_FAIL,
    UPDATE_CONDITIONING_PINEAPPLE_SUCCESS,
    UPDATE_CONDITIONING_SWEET_POTATO_FAIL,
    UPDATE_CONDITIONING_SWEET_POTATO_SUCCESS, UPDATE_PACKING_LOT_FAIL, UPDATE_PACKING_LOT_SUCCESS,
} from "./types";
import {setAlert} from "./alert";

export const getCPineappleLot = (pk) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },

    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/production/conditioning/pineapple/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_CONDITIONING_PINEAPPLE_LOT_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_CONDITIONING_PINEAPPLE_LOT_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_CONDITIONING_PINEAPPLE_LOT_FAIL
        });
    }
}
export const getCPineapple = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/production/conditioning/pineapple/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_CONDITIONING_PINEAPPLE_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_CONDITIONING_PINEAPPLE_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_CONDITIONING_PINEAPPLE_FAIL
        });
    }
}
export const addCPineapple = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/production/conditioning/pineapple/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_CONDITIONING_PINEAPPLE_SUCCESS, payload: res.data
            });
            dispatch(getCPineapple(params))
            dispatch(setAlert(res.data.message || 'Registro creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_CONDITIONING_PINEAPPLE_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_CONDITIONING_PINEAPPLE_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_CONDITIONING_PINEAPPLE_FAIL
            });
        }
    }
};
export const updateCPineapple = (pk, form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/production/conditioning/pineapple/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_CONDITIONING_PINEAPPLE_SUCCESS, payload: res.data
            });
            dispatch(getCPineapple(params))
            dispatch(setAlert(res.data.message || 'Registro actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_CONDITIONING_PINEAPPLE_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_CONDITIONING_PINEAPPLE_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_CONDITIONING_PINEAPPLE_FAIL
            });
        }
    }
};
export const deleteCPineapple = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/production/conditioning/pineapple/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_CONDITIONING_PINEAPPLE_SUCCESS, payload: res.data
            });
            dispatch(getCPineapple(params))
            dispatch(setAlert(res.data.message || 'Registro eliminado con exito.', 'success'));
        } else {
            dispatch({
                type: DELETE_CONDITIONING_PINEAPPLE_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_CONDITIONING_PINEAPPLE_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_CONDITIONING_PINEAPPLE_FAIL
            });
        }
    }
};

export const getCSweetPotatoLot = (pk) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },

    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/production/conditioning/sweet-potato/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_CONDITIONING_SWEET_POTATO_LOT_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_CONDITIONING_SWEET_POTATO_LOT_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_CONDITIONING_SWEET_POTATO_LOT_FAIL
        });
    }
}


export const getCSweetPotato = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/production/conditioning/sweet-potato/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_CONDITIONING_SWEET_POTATO_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_CONDITIONING_SWEET_POTATO_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_CONDITIONING_SWEET_POTATO_FAIL
        });
    }
}
export const addCSweetPotato = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/production/conditioning/sweet-potato/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_CONDITIONING_SWEET_POTATO_SUCCESS, payload: res.data
            });
            dispatch(getCSweetPotato(params))
            dispatch(setAlert(res.data.message || 'Registro creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_CONDITIONING_SWEET_POTATO_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_CONDITIONING_SWEET_POTATO_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_CONDITIONING_SWEET_POTATO_FAIL
            });
        }
    }
};
export const updateCSweetPotato = (pk, form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/production/conditioning/sweet-potato/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_CONDITIONING_SWEET_POTATO_SUCCESS, payload: res.data
            });
            dispatch(getCSweetPotato(params))
            dispatch(setAlert(res.data.message || 'Registro actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_CONDITIONING_SWEET_POTATO_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_CONDITIONING_SWEET_POTATO_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_CONDITIONING_SWEET_POTATO_FAIL
            });
        }
    }
};
export const deleteCSweetPotato = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/production/conditioning/sweet-potato/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_CONDITIONING_SWEET_POTATO_SUCCESS, payload: res.data
            });
            dispatch(getCSweetPotato(params))
            dispatch(setAlert(res.data.message || 'Registro eliminado con exito.', 'success'));
        } else {
            dispatch({
                type: DELETE_CONDITIONING_SWEET_POTATO_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_CONDITIONING_SWEET_POTATO_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_CONDITIONING_SWEET_POTATO_FAIL
            });
        }
    }
};


export const getThumbnailLot = (lot) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json',

        },
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/production/thumbnail/${lot}/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_THUMBNAILS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_THUMBNAILS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_THUMBNAILS_FAIL
        });
    }
}
export const addThumbnail = (form, lot) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/production/thumbnail/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_THUMBNAIL_SUCCESS, payload: res.data
            });
            dispatch(getThumbnailLot(lot))
            dispatch(setAlert(res.data.message || 'Registro creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_THUMBNAIL_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_THUMBNAIL_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_THUMBNAIL_FAIL
            });
        }
    }
};

export const deleteThumbnail = (pk, lot) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/production/thumbnail/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_THUMBNAIL_SUCCESS, payload: res.data
            });
            dispatch(getThumbnailLot(lot))
            dispatch(setAlert(res.data.message || 'Registro eliminado con exito.', 'success'));
        } else {
            dispatch({
                type: DELETE_THUMBNAIL_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_THUMBNAIL_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_THUMBNAIL_FAIL
            });
        }
    }
};

export const getCuts = () => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },

    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/production/cuts/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_CUTS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_CUTS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_CUTS_FAIL
        });
    }
}

export const getPacking = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
        params: {...params}

    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/production/packing/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_PACKING_LOT_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_PACKING_LOT_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_PACKING_LOT_FAIL
        });
    }
}

export const addPackingLot = (form,params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/production/packing/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_PACKING_LOT_SUCCESS, payload: res.data
            });
            dispatch(setAlert(res.data.message || 'Registro creado con exito', 'success'));
            dispatch(getPacking(params))
        } else {
            dispatch({
                type: ADD_PACKING_LOT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_PACKING_LOT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_PACKING_LOT_FAIL
            });
        }
    }
};

export const updatePacking = (pk, form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/production/packing/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_PACKING_LOT_SUCCESS, payload: res.data
            });
            dispatch(getPacking(params))
            dispatch(setAlert(res.data.message || 'Registro actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_PACKING_LOT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_PACKING_LOT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_PACKING_LOT_FAIL
            });
        }
    }
};