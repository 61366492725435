import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import {useSelector} from "react-redux";
import {formatDate, formatDateTZ} from "../../util/helpers";

const TablePhysical = ({data, handleUpdate, handleDelete}) => {
    const fixed = useSelector(state => state.Maintenance.fixed);


    const columns = ["", "Nombre", "Fecha de compra", "Modelo", "Código", "Ubicación", "Criticidad",];
    return (<TableBase columns={columns} cell={Row(data, handleUpdate, handleDelete, fixed)}/>);
};

const Row = (data, update, remove, fixed) => (data && data.map((item, index) => {

    return <tr key={index} className="h-max border-gray-300  border-b">

        <Cell text={<div className={"font-bold flex items-center whitespace-nowrap"}>
            <PencilSquareIcon onClick={() => update(item)} title={"Editar"}
                              className={"h-5 w-5 mr-2 text-blue-500 cursor-pointer"}/>
            <TrashIcon onClick={() => remove(item?.id)} title={"Eliminar"}
                       className={"h-5 w-5 mr-2 text-red-500 cursor-pointer"}/>

        </div>}/>
        <Cell text={item?.name}/>
        <Cell text={item?.buy_date && formatDateTZ(item?.buy_date, 'long')}/>
        <Cell text={item?.model}/>
        <Cell text={item?.code}/>
        <Cell text={fixed && fixed.find(i => i.id === item?.parent)?.name}/>
        <Cell text={<div
            className={`${item?.criticality === 'L' ? 'bg-green-400' : item?.criticality === 'M' ? 'bg-amber-500' : 'bg-red-500'} px-2 rounded-xl text-white font-bold text-center`}>
            <p>{item?.criticality === 'L' ? 'Bajo' : item?.criticality === 'M' ? 'Medio' : 'Alto'}</p>
        </div>}/>

    </tr>
}));

export default TablePhysical;
