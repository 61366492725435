import React, {useEffect, useState} from 'react';
import Layout from "../../../hocs/Layout";
import {useDispatch} from "react-redux";
import Filters from "../../../components/sct/Delay/Filters";
import {getDelay} from "../../../redux/actions/talent_hub";
import TableDelay from "../../../components/sct/Delay/TableDelay";
import HeaderForm from "../../../components/util/HeaderForm";
import RegisterViewer from "../../../components/e2e/Logistic/Register";
import ModalHook from "../../../hooks/ModalHook";
import {Helmet} from "react-helmet";
import Modal from "../../../components/util/Modal";
import DocumentViewer from "../../../components/sct/Delay/Document";


const Delay = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Los meses en JavaScript van de 0 a 11
    const currentDay = currentDate.getDate();

    let previousYear = currentYear;
    let previousMonth = currentMonth;

    if (currentDay < 24) {
        if (currentMonth === 1) {
            previousMonth = 12;
            previousYear--;
        } else {
            previousMonth--;
        }
    }
    const [params, setParams] = useState({year: previousYear, month: previousMonth, user: '', department: ''});
    const dispatch = useDispatch();
    const {content, setContent, isOpen, openModal} = ModalHook();

    useEffect(() => {
        const payload = {
            start_date: `${params.year}-${params.month}-24`, user: params.user, department: params.department
        }
        dispatch(getDelay(payload));
    }, [dispatch])

    const handleSearch = () => {
        const payload = {
            start_date: `${params.year}-${params.month}-24`, user: params.user, department: params.department
        }
        dispatch(getDelay(payload));
    };

    const handleViewInfo = (id,user) => {
        setContent(<div className={"h-screen"}>
            <HeaderForm close={openModal}/>
            <DocumentViewer id={id} user={user}/></div>);
        openModal();
    }


    return (<Layout>
        <Helmet>
            <title>Tardanzas</title>
        </Helmet>
        <Modal isOpen={isOpen} close={openModal} children={content}/>

        <div
            className={"bg-white rounded-2xl p-4 h-fit"}>
            <Filters params={params} setParams={setParams} handleSearch={handleSearch}/>


            <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                <TableDelay handleView={handleViewInfo}/>
            </div>
        </div>

    </Layout>);
};

export default Delay;
