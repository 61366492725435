import {
    ADD_CONDITIONING_PINEAPPLE_FAIL,
    ADD_CONDITIONING_PINEAPPLE_SUCCESS,
    ADD_CONDITIONING_SWEET_POTATO_FAIL,
    ADD_CONDITIONING_SWEET_POTATO_SUCCESS,
    ADD_PACKING_LOT_FAIL,
    ADD_PACKING_LOT_SUCCESS,
    ADD_THUMBNAIL_FAIL,
    ADD_THUMBNAIL_SUCCESS,
    DELETE_CONDITIONING_PINEAPPLE_FAIL,
    DELETE_CONDITIONING_PINEAPPLE_SUCCESS,
    DELETE_CONDITIONING_SWEET_POTATO_FAIL,
    DELETE_CONDITIONING_SWEET_POTATO_SUCCESS,
    DELETE_THUMBNAIL_FAIL,
    DELETE_THUMBNAIL_SUCCESS,
    GET_CONDITIONING_PINEAPPLE_FAIL,
    GET_CONDITIONING_PINEAPPLE_LOT_FAIL,
    GET_CONDITIONING_PINEAPPLE_LOT_SUCCESS,
    GET_CONDITIONING_PINEAPPLE_SUCCESS,
    GET_CONDITIONING_SWEET_POTATO_FAIL,
    GET_CONDITIONING_SWEET_POTATO_LOT_FAIL,
    GET_CONDITIONING_SWEET_POTATO_LOT_SUCCESS,
    GET_CONDITIONING_SWEET_POTATO_SUCCESS,
    GET_CUTS_FAIL,
    GET_CUTS_SUCCESS,
    GET_PACKING_LOT_FAIL,
    GET_PACKING_LOT_SUCCESS,
    GET_THUMBNAILS_FAIL,
    GET_THUMBNAILS_SUCCESS,
    UPDATE_CONDITIONING_PINEAPPLE_FAIL,
    UPDATE_CONDITIONING_PINEAPPLE_SUCCESS,
    UPDATE_CONDITIONING_SWEET_POTATO_FAIL,
    UPDATE_CONDITIONING_SWEET_POTATO_SUCCESS,
    UPDATE_PACKING_LOT_FAIL,
    UPDATE_PACKING_LOT_SUCCESS
} from "../actions/types";

const initialState = {
    c_pineapple: null,
    c_sweet_potato: null,
    c_pineapple_lot: null,
    c_sweet_potato_lot: null,
    thumbnail: null,
    cuts: null,
    packing: null
}
export default function Production(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case GET_CONDITIONING_PINEAPPLE_SUCCESS:
            return {
                ...state, c_pineapple: payload.data
            }
        case GET_CONDITIONING_PINEAPPLE_FAIL:
            return {
                ...state, c_pineapple: null
            }
        case GET_CONDITIONING_SWEET_POTATO_SUCCESS:
            return {
                ...state, c_sweet_potato: payload.data
            }
        case GET_CONDITIONING_SWEET_POTATO_FAIL:
            return {
                ...state, c_sweet_potato: null
            }
        case GET_THUMBNAILS_SUCCESS:
            return {
                ...state, thumbnail: payload.data
            }
        case GET_THUMBNAILS_FAIL:
            return {
                ...state, thumbnail: null
            }
        case GET_CONDITIONING_PINEAPPLE_LOT_SUCCESS:
            return {
                ...state, c_pineapple_lot: payload.data
            }
        case GET_CONDITIONING_SWEET_POTATO_LOT_SUCCESS:
            return {
                ...state, c_sweet_potato_lot: payload.data
            }
        case GET_CONDITIONING_PINEAPPLE_LOT_FAIL:
            return {
                ...state, c_pineapple_lot: null
            }
        case GET_CONDITIONING_SWEET_POTATO_LOT_FAIL:
            return {
                ...state, c_sweet_potato_lot: null
            }
        case GET_CUTS_SUCCESS:
            return {
                ...state, cuts: payload.data
            }
        case GET_CUTS_FAIL:
            return {
                ...state, cuts: null
            }
        case GET_PACKING_LOT_SUCCESS:
            return {
                ...state, packing: payload.data
            }
        case GET_PACKING_LOT_FAIL:
            return {
                ...state, packing: null
            }

        case ADD_CONDITIONING_PINEAPPLE_SUCCESS:
        case ADD_CONDITIONING_PINEAPPLE_FAIL:
        case UPDATE_CONDITIONING_PINEAPPLE_SUCCESS:
        case UPDATE_CONDITIONING_PINEAPPLE_FAIL:
        case DELETE_CONDITIONING_PINEAPPLE_SUCCESS:
        case DELETE_CONDITIONING_PINEAPPLE_FAIL:
        case ADD_CONDITIONING_SWEET_POTATO_SUCCESS:
        case ADD_CONDITIONING_SWEET_POTATO_FAIL:
        case UPDATE_CONDITIONING_SWEET_POTATO_SUCCESS:
        case UPDATE_CONDITIONING_SWEET_POTATO_FAIL:
        case DELETE_CONDITIONING_SWEET_POTATO_SUCCESS:
        case DELETE_CONDITIONING_SWEET_POTATO_FAIL:
        case ADD_THUMBNAIL_SUCCESS:
        case ADD_THUMBNAIL_FAIL:
        case DELETE_THUMBNAIL_SUCCESS:
        case DELETE_THUMBNAIL_FAIL:
        case ADD_PACKING_LOT_SUCCESS:
        case ADD_PACKING_LOT_FAIL:
        case UPDATE_PACKING_LOT_SUCCESS:
        case UPDATE_PACKING_LOT_FAIL:
            return {
                ...state
            }
        default:
            return state;
    }
}
