import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {InputForm} from "../../util/HelpersForm";
import {updateGLP} from "../../../redux/actions/logistic";


const FormGLP = ({data, onClose, params}) => {
    const dispatch = useDispatch();


    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            dispatch(updateGLP(data?.id, form, params));
            onClose();
        }
    })

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-1 gap-2`}>

                <InputForm values={formik.values.consumption} errors={formik.errors.consumption} formik={formik}
                           field={'consumption'}
                           title={'Consumo'} type={'number'}
                />
                <InputForm values={formik.values.cost} errors={formik.errors.cost} formik={formik}
                           field={'cost'}
                           title={'Costo'} type={'number'}
                />


            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {
        consumption: data?.consumption || 0, cost: data?.cost || 0,
    }
}
const newSchema = () => {
    return {

        consumption: Yup.number().required(), cost: Yup.number().required(),


    }
}

export default FormGLP;
