import React, {useEffect, useState} from 'react';
import Layout from "../../../../hocs/Layout";
import Dropdown from "../../../../components/e2e/Production/Dropdown";
import TablePineappleC from "../../../../components/e2e/Production/TablePineappleC";
import ButtonHelper from "../../../../components/util/ButtonHelper";
import Filters from "../../../../components/e2e/Production/Filter";
import ModalHook from "../../../../hooks/ModalHook";
import Modal from "../../../../components/util/Modal";
import FormPineappleC from "../../../../components/e2e/Production/FormPineappleC";
import FormSweetPotatoC from "../../../../components/e2e/Production/FormSweetPotatoC";
import TableSweetPotatoC from "../../../../components/e2e/Production/TableSweetPotatoC";
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {getCPineapple, getCSweetPotato, getCuts} from "../../../../redux/actions/production";

const Conditioning = () => {
    const [selected, setSelected] = useState('Piña');
    const dispatch = useDispatch();
    const [params, setParams] = useState({
        lot: '',
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        week: ''
    });
    const {content, setContent, isOpen, openModal} = ModalHook();
    const cuts = useSelector(state => state.Production.cuts);

    useEffect(() => {
        dispatch(getCuts())
        switch (selected) {
            case 'Piña':
                dispatch(getCPineapple(params));
                break;
            case 'Camote':
                dispatch(getCSweetPotato(params));
                break;
            default:
                break;
        }
    }, [selected]);

    const handleSearch = () => {
        switch (selected) {
            case 'Piña':
                dispatch(getCPineapple(params));
                break;
            case 'Camote':
                dispatch(getCSweetPotato(params))
                break;
            default:
                break;
        }
    }


    const handleAdd = () => {
        let formComponent = null;
        switch (selected) {
            case 'Piña':
                formComponent = <FormPineappleC cuts={cuts} selected={selected} onClose={openModal} params={params}/>;
                break;
            case 'Camote':
                formComponent = <FormSweetPotatoC cuts={cuts} selected={selected} onClose={openModal} params={params}/>;
                break;
            default:
                break;
        }

        setContent(formComponent);
        openModal();
    };
    const handleUpdate = (data) => {
        let formComponent = null;
        switch (selected) {
            case 'Piña':
                formComponent =
                    <FormPineappleC cuts={cuts} selected={selected} onClose={openModal} data={data} params={params}/>;
                break;
            case 'Camote':
                formComponent =
                    <FormSweetPotatoC cuts={cuts} selected={selected} onClose={openModal} data={data} params={params}/>;
                break;
            default:
                break;
        }
        setContent(formComponent);
        openModal();
    };


    return (<Layout>
        <Helmet>
            <title>Acondicionado</title>
        </Helmet>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <div
            className={"bg-white rounded-2xl p-4 h-fit z-10"}>

            <ButtonHelper handle={handleAdd}/>
            <Dropdown setSelect={setSelected} select={selected}/>
            <div className="flex flex-col md:flex-row gap-6 py-4">
                <Filters params={params} setParams={setParams} handleSearch={handleSearch}/>
            </div>

            <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                {selected === 'Piña' && <TablePineappleC handleUpdate={handleUpdate} cuts={cuts}/>}
                {selected === 'Camote' && <TableSweetPotatoC handleUpdate={handleUpdate} cuts={cuts}/>}
            </div>
        </div>
    </Layout>);
};

export default Conditioning;
