import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {EyeIcon} from "@heroicons/react/24/solid";
import {formatDate} from "../../util/helpers";
import Humanize from "humanize-plus";

const Table = ({data, handleUpdate, handleDelete}) => {

    const columns = ["Lote", "Fecha de descarga", "Producto", "Condición", "Stock", "Peso neto", "Precio planta", "Flete", "Estiba", "Kg pagados"];
    return (<TableBase columns={columns} cell={Row(data, handleUpdate, handleDelete)}/>);
};


const getPrice = (item) => {
    const price_one = parseFloat(item?.discount_price_kg) || 0 * parseFloat(item?.discount_price) || 0;
    const price_two = (parseFloat(item?.weight_usable) || 0 - parseFloat(item?.discount_price_kg) || 0) * parseFloat(item?.supplier_price) || 0;
    try {
        const total = (price_one + price_two) / parseFloat(item?.weight_usable) || 0;
        return [total, total.toFixed(2)]
    } catch (e) {
        return [0, 0];
    }
}


const Row = (data) => (data && data.map((item, index) => {


    return <tr key={index} className="h-max border-gray-300  border-b">

        <Cell text={<div
            className={"font-semibold cursor-pointer hover:text-green-400 flex items-center whitespace-nowrap"}>
            <a href={`/logistic/lot/${item.lot}`} target={'_blank'} rel="noreferrer">
                <EyeIcon title={"Visualizar"}
                         className={"h-5 w-5 mr-2 text-gray-500 cursor-pointer hover:text-green-400"}/>
            </a>

            <p>{item.lot}</p>
        </div>}/>
        <Cell text={item?.datetime_download_started && formatDate(new Date(item.datetime_download_started))}/>
        <Cell text={item?.product}/>
        <Cell text={item?.condition === 'C' ? 'Convencional' : 'Orgánico'}/>
        <Cell text={<div
            className={`flex items-center justify-center whitespace-nowrap ${item?.stock > 0 ? 'bg-green-300' : 'bg-red-300'}   w-full p-2 rounded-2xl`}>
            <p className={"text-white text-center font-bold"}>{Humanize.formatNumber(item?.stock, 2)}</p>
        </div>}/>
        <Cell text={<div className={`flex items-center whitespace-nowrap font-semibold w-full p-2 rounded-2xl`}>
            <p>{Humanize.formatNumber(item?.weight_net, 2)}</p>
        </div>}/>
        <Cell text={<div onClick={()=>alert(getPrice(item)[0])}
            className={`flex items-center whitespace-nowrap ${getPrice(item)[1] > 0 ? 'text-green-500' : 'text-red-300'}  w-full p-2 rounded-2xl`}>
            <p >{getPrice(item)[1]}</p>
        </div>}/>
        <Cell text={<div
            className={`flex items-center whitespace-nowrap ${item?.freight > 0 ? 'text-green-500' : 'text-red-300'}  w-full p-2 rounded-2xl`}>
            <p>{Humanize.formatNumber(parseFloat(item?.freight) + parseFloat(item?.freight_boxes) || 0, 2)}</p>
        </div>}/>
        <Cell text={<div
            className={`flex items-center whitespace-nowrap ${item?.download_price > 0 ? 'text-green-500' : 'text-red-300'}  w-full p-2 rounded-2xl`}>
            <p>{Humanize.formatNumber(item?.download_price, 2)}</p>
        </div>}/>
        <Cell text={<div
            className={`flex items-center font-semibold whitespace-nowrap ${item?.weight_usable > 0 ? 'text-green-500' : 'text-red-300'}  w-full p-2 rounded-2xl`}>
            <p>{Humanize.formatNumber(item?.weight_usable, 2)}</p>
        </div>}/>

    </tr>
}));

export default Table;
