import React from 'react';
import {useSelector} from "react-redux";
import {ClockIcon, ExclamationCircleIcon, EyeIcon, WrenchIcon} from "@heroicons/react/24/solid";
import {UserIcon, UserPlusIcon} from "@heroicons/react/20/solid";
import ReactStars from "react-rating-stars-component/dist/react-stars";
import Humanize from "humanize-plus";
import {TrashIcon} from "@heroicons/react/24/outline";
import {formatDateTime} from "../../util/helpers";


const Card = ({
                  workItem,
                  action_supervisor,
                  action_view,
                  action_delete,
                  action_update_helpers,
                  action_update,
                  action_update_resource
              }) => {
    const user = useSelector(state => state.Auth?.user);
    const technicals = useSelector(state => state.Maintenance.technical);
    const physical = useSelector(state => state.Maintenance.physical);
    const maintenance_type = useSelector(state => state.Maintenance.maintenance_type);
    const role = user?.role;

    return (<div className="bg-white  w-full p-2 rounded-xl border border-gray-200 mt-2 px-4 overflow-hidden">
        <CardHeader
            technicals={technicals}
            item={workItem}
            role={role}
            action_supervisor={action_supervisor}
            action_view={action_view}
            action_delete={action_delete}
        />

        <CardBody item={workItem} role={role} action_update_resource={action_update_resource}
                  physical = {physical}
                  action_update_helpers={action_update_helpers}/>
        <CardFooter item={workItem} role={role} action_update={action_update}
            maintenance_type={maintenance_type}
        />

    </div>)
};

const CardHeader = ({item, role, action_view, action_delete,technicals}) => (

    <div className="flex flex-wrap relative w-full ">
        <div className={"flex flex-row  flex-wrap gap-1 w-full justify-between"}>
            <div className={"flex"}>
                <UserIcon
                    className={`w-8 h-8 cursor-pointer col-span-4 text-gray-400 bg-gray-700 bg-opacity-10  rounded-full`}/>
                <div className="ml-2">
                    <p className="text-gray-400 font-semibold text-xs">{technicals && technicals.find(
                        technical => technical.id === item?.technical)?.name}
                    </p>
                    <div className="flex items-center">
                        <span className="text-gray-400 font-light text-[8px]">{formatDateTime(item?.date_start)}</span>
                    </div>
                </div>
            </div>

            <div className={"flex gap-2 justify-end flex-wrap"}>
                {item?.status && (<EyeIcon onClick={action_view}
                                            className={`w-6 h-6   cursor-pointer  text-green-400 bg-green-700 bg-opacity-10 p-1 rounded-full right-8 md:right-10 top-0`}/>)}
                <TrashIcon onClick={action_delete}
                           className={`w-6 h-6  cursor-pointer  text-red-400 bg-red-700 bg-opacity-10 p-1 rounded-full `}/>
            </div>
        </div>



    </div>);

const CardBody = ({item, role, action_update_resource, action_update_helpers,physical}) => (<>
    <div
        className={`${item?.validated ? 'bg-green-400 text-green-400 w-full' : 'bg-red-300 text-red-300 w-10/12'} h-2 mt-2 rounded-xl text-end`}>
        <p className="p-2 text-xs -mt-0.5">{item?.validated ? "100%" : "80%"}</p>
    </div>
    <div className="text-black text-xs flex flex-wrap gap-2 mt-4 hover:text-gray-600 items-center font-bold">
        <WrenchIcon
            className={`w-4 h-4 cursor-pointer ${!item?.status ? 'hover:text-red-400' : 'hover:text-green-400'} `}
            onClick={action_update_resource}/>

        {item?.helpers?.length > 0 ? item?.helpers.map((helper, index) => (<UserIcon
            key={index}
            title={helper?.helper?.first_name}
            onClick={action_update_helpers}
            className={`w-4 h-4 cursor-pointer ${!item?.status ? 'hover:text-red-400' : 'hover:text-green-400'}`}
        />)) : (<UserPlusIcon
            onClick={action_update_helpers}
            className={`w-4 h-4 cursor-pointer ${!item?.status ? 'hover:text-red-400' : 'hover:text-green-400'}`}
        />)}

        <ReactStars
            value={item?.criticality === 'Baja' ? 1 : item?.criticality === 'Media' ? 2 : 3}
            count={3}
            size={20}
            edit={false}
            activeColor={!item?.status ? '#f77778' : '#52df86'}
        />
        <span>{
            physical && physical.find(
                asset => asset.id === item?.asset)?.name
        }</span>
    </div>
</>);


const CardFooter = ({item, role, action_update,maintenance_type}) => (
    <div className="flex flex-row flex-wrap justify-between w-full mt-2">
        <p className="text-xs text-center text-normal text-black cursor-pointer flex items-center gap-1"
           onClick={action_update}>
            {item?.code_ot}
            {item?.status && (<>
                <ClockIcon className="w-4 h-4 text-gray-400"/>
                <span className="text-xs font-extralight">{item?.time}</span>
            </>)}
        </p>
        <div className="flex items-center justify-center">
            <ExclamationCircleIcon className={`w-6 h-6 cursor-pointer text-gray-300`}/>
            <span className="text-xs font-light text-gray-500 text-end">{
                maintenance_type && maintenance_type.find(
                    type => type.id === item?.type_maintenance)?.name
            }</span>
        </div>
    </div>);

export default Card;