import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {PencilSquareIcon} from "@heroicons/react/24/outline";
import {formatDateTZ} from "../../util/helpers";
import Humanize from "humanize-plus";
import {useSelector} from "react-redux";

const TablePineappleC = ({handleUpdate, cuts}) => {
    const data = useSelector(state => state.Production.c_pineapple)

    const columns = ['', 'Lote', 'Fecha', 'Kg logistica', 'Rechazo', 'Kg proceso', 'Presentación', 'Corona', 'Cáscara y tronco', 'Pulpa', 'Kg habilitado', 'Brix', 'pH', 'Personas', 'Horas']
    return (<TableBase columns={columns} cell={Row(data, handleUpdate, cuts)}/>);
};


const Row = (data, update, cuts) => (data && data.map((item, index) => {
    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={<div className={"font-bold flex items-center gap-2 whitespace-nowrap"}>
            <PencilSquareIcon onClick={() => update(item)} title={"Editar"}
                              className={"h-5 w-5  text-blue-500 cursor-pointer"}/>
            <div title={"Modificaciones"}
                 className={"w-4 h-4 bg-red-500 bg-opacity-75  text-xs  rounded-2xl text-center text-white font-medium"}>
                {item?.number_changes}</div>

        </div>}/>
        <Cell text={<p className={"font-medium"}>{item?.lot_name}</p>}/>
        <Cell text={item?.date && formatDateTZ(item.date)}/>
        <Cell text={Humanize.formatNumber(item?.logistic_kg, 2)}/>
        <Cell text={<div
            className={"w-full bg-red-500 bg-opacity-75 px-2 py-1 rounded-2xl text-center text-white font-bold"}>
            {Humanize.formatNumber(item?.rejected_kg, 2)}
        </div>}/>
        <Cell text={Humanize.formatNumber(item?.process_kg, 2)}/>
        <Cell text={<div
            className={"w-full bg-cyan-500 bg-opacity-60 px-2 py-1 rounded-2xl text-center text-white font-bold"}>
            {cuts && cuts.find(cut => cut.id === item.cut)?.name}
        </div>}/>
        <Cell text={Humanize.formatNumber(item?.crown_kg, 2)}/>
        <Cell text={Humanize.formatNumber(item?.shell_trunk_kg, 2)}/>
        <Cell text={Humanize.formatNumber(item?.pulp_juice_kg, 2)}/>
        <Cell text={<div
            className={"w-full bg-green-500 bg-opacity-75 px-2 py-1 text-center rounded-2xl text-white font-bold"}>
            {Humanize.formatNumber(item?.enabled_kg, 2)}
        </div>}/>
        <Cell text={Humanize.formatNumber(item?.brix, 2)}/>
        <Cell text={Humanize.formatNumber(item?.ph, 2)}/>
        <Cell text={Humanize.formatNumber(item?.people, 0)}/>
        <Cell text={item?.duration}/>

    </tr>)
}));


export default TablePineappleC;
