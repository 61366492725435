import React, {createContext, useContext, useState} from 'react';
import {ArrowsPointingInIcon, ArrowsPointingOutIcon, EllipsisVerticalIcon} from '@heroicons/react/24/outline';
import {Disclosure} from "@headlessui/react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {menu_list} from "../util/helpers";
import Logo from "../../assets/logo-horizontal-greenboxM.png";
import {useDispatch, useSelector} from "react-redux";
import {ArrowRightEndOnRectangleIcon} from "@heroicons/react/24/solid";
import {logout} from "../../redux/actions/auth";

const SidebarContext = createContext();


const Sidebar = () => {
    const [expanded, setExpanded] = useState(false);
    const is_authenticated = useSelector(state => state.Auth.isAuthenticated);
    const me = useSelector(state => state.Auth.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    if (!is_authenticated) return null;

    const logout_session = () => {
        dispatch(logout());
        navigate('/login');
    }

    return (<aside
        className={`transition-width duration-300 overflow-scroll scrollbar-hide ${expanded ? 'w-52' : 'w-20'} h-screen`}>
        <nav className="h-full flex flex-col bg-white  shadow-sm">
            <div className="p-4 pb-2 flex justify-between items-center">
                <img
                    src={Logo}
                    onClick={() => navigate('/')}
                    className={`overflow-hidden transition-all cursor-pointer ${expanded ? 'w-32' : 'w-0'}`}
                    alt=""
                />
                <button
                    onClick={() => setExpanded(curr => !curr)}

                    className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
                >
                    {expanded ? <ArrowsPointingInIcon className="w-6 h-6"/> :
                        <ArrowsPointingOutIcon className="w-6 h-6"/>}
                </button>
            </div>

            <SidebarContext.Provider value={{expanded}}>
                <ul className="flex-1 pl-3">
                    <SidebarItem list={menu_list}/>
                </ul>
            </SidebarContext.Provider>

            <div className="border-t flex p-2">
                <img
                    src={`https://ui-avatars.com/api/?background=random&color=fff&name=${me?.get_full_name}`}
                    alt=""
                    className="w-10 h-10 rounded-md"
                />
                <div
                    className={`flex justify-between items-center overflow-hidden transition-all ${expanded ? 'w-52 ml-3' : 'w-0'}`}>

                    <div className="leading-4">
                        <h4 className="font-semibold text-black text-xs flex items-center gap-2">{me?.get_full_name}
                                        <ArrowRightEndOnRectangleIcon onClick={logout_session} className="w-6 h-6 text-black cursor-pointer"/>
</h4>
                        <span className="text-xs text-gray-400">{me?.email}</span>
                    </div>

                    <EllipsisVerticalIcon className="w-6 h-6 text-white"/>

                </div>
            </div>
        </nav>
    </aside>);
}

function SidebarItem({list}) {
    const {expanded} = useContext(SidebarContext);
    const [isHovered, setIsHovered] = useState(null);
    const location = useLocation()
    const path = location.pathname

    return list.map((item, index) => (<Disclosure key={index}>
        {({open}) => (<NavLink to={item.link && item.link}>
            <Disclosure.Button
                className={`relative w-full flex items-center py-2 px-3 my-1 font-medium rounded-l-3xl cursor-pointer transition-colors group ${open ? 'bg-[#f1f5f9] text-black' : 'hover:bg-[#e5ffe1]'}`}
                onMouseEnter={() => setIsHovered(index)}
                onMouseLeave={() => setIsHovered(null)}
            >
                {item.icon}
                <span
                    className={`ml-3 w-full overflow-hidden text-start transition-all duration-300 text-gray-400 ${expanded ? 'inline' : 'hidden'}`}>
                            {item.text}
                </span>

                {!expanded && isHovered === index && (<div
                    className="absolute -top-1/2 right-0 py-2 px-2  z-40 rounded-md bg-white text-[#67ce69] text-[8px] ">
                    {item.text}
                </div>)}
            </Disclosure.Button>
            {item?.submenus && item.submenus.map((submenu, i) => (<NavLink to={submenu.link} key={i} className="w-full">
                <Disclosure.Panel
                    onMouseEnter={() => setIsHovered(submenu.text)}
                    onMouseLeave={() => setIsHovered(null)}
                    key={i}
                    className={`relative w-full flex items-center justify-between py-2 px-3 my-1 font-medium rounded-l-3xl cursor-pointer transition-colors group ${open && path === submenu.link ? 'bg-[#f1f5f9] text-black' : 'hover:bg-[#e5ffe1]'}`}>
                    {submenu.icon}
                    <span
                        className={`ml-3 w-full overflow-hidden text-start transition-all duration-300 text-gray-400 ${expanded ? 'inline' : 'hidden'}`}
                    >
                                {submenu.text}
                            </span>

                    {!expanded && isHovered === submenu.text && (<div
                        className="absolute -top-1/2 right-0 py-2 px-2  z-40 rounded-md bg-white text-[#67ce69] text-[8px] ">
                        {submenu.text}
                    </div>)}
                </Disclosure.Panel>
            </NavLink>))}
        </NavLink>)}
    </Disclosure>));
}

export default React.memo(Sidebar);


