import React, {useEffect, useState} from 'react';
import Layout from "../../../../hocs/Layout";
import Dropdown from "../../../../components/e2e/Production/Dropdown";
import Filters from "../../../../components/e2e/Production/Filter";
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import TablePineapple from "../../../../components/e2e/Quality/TablePineapple";
import TableSweetPotato from "../../../../components/e2e/Quality/TableSweetPotato";
import {getAnalysis} from "../../../../redux/actions/quality";

const Analysis = () => {
    const [selected, setSelected] = useState('Piña');
    const dispatch = useDispatch();
    const [params, setParams] = useState({
        lot: '', month: new Date().getMonth() + 1, year: new Date().getFullYear(), week: ''
    });
    const data = useSelector(state => state.Quality.analysis);

    useEffect(() => {
        switch (selected) {
            case 'Piña':
                dispatch(getAnalysis('Piña', params));
                break;
            case 'Camote':
                dispatch(getAnalysis('Camote', params));
                break;
            default:
                break;
        }
    }, [dispatch, selected]);

    const handleSearch = () => {
        switch (selected) {
            case 'Piña':
                dispatch(getAnalysis('Piña', params));
                break;
            case 'Camote':
                dispatch(getAnalysis('Camote', params));
                break;
            default:
                break;
        }
    }


    return (<Layout>
        <Helmet>
            <title>Analisis MP</title>
        </Helmet>
        <div
            className={"bg-white rounded-2xl p-4 h-fit z-10"}>
            <Dropdown setSelect={setSelected} select={selected}/>
            <div className="flex flex-col md:flex-row gap-6 py-4">
                <Filters params={params} setParams={setParams} handleSearch={handleSearch}/>
            </div>

            <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                {selected === 'Piña' && <TablePineapple data={data}/>}
                {selected === 'Camote' && <TableSweetPotato data={data}/>}
            </div>
        </div>
    </Layout>);
};

export default Analysis;
