import axios from "axios";
import {
    ADD_CLIENT_FAIL,
    ADD_CLIENT_SUCCESS,
    ADD_MANUFACTURING_COMPANY_FAIL,
    ADD_MANUFACTURING_COMPANY_SUCCESS,
    ADD_MATERIAL_SUPPLIER_FAIL,
    ADD_MATERIAL_SUPPLIER_SUCCESS,
    ADD_RAW_MATERIAL_SUPPLIER_FAIL,
    ADD_RAW_MATERIAL_SUPPLIER_SUCCESS,
    ADD_TRANSPORTATION_COMPANY_FAIL,
    ADD_TRANSPORTATION_COMPANY_SUCCESS,
    DELETE_CLIENT_FAIL,
    DELETE_CLIENT_SUCCESS,
    DELETE_MANUFACTURING_COMPANY_FAIL,
    DELETE_MANUFACTURING_COMPANY_SUCCESS,
    DELETE_MATERIAL_SUPPLIER_FAIL,
    DELETE_MATERIAL_SUPPLIER_SUCCESS,
    DELETE_RAW_MATERIAL_SUPPLIER_FAIL,
    DELETE_RAW_MATERIAL_SUPPLIER_SUCCESS,
    DELETE_TRANSPORTATION_COMPANY_FAIL,
    DELETE_TRANSPORTATION_COMPANY_SUCCESS,
    GET_CLIENTS_FAIL,
    GET_CLIENTS_SUCCESS,
    GET_MANUFACTURING_COMPANIES_FAIL,
    GET_MANUFACTURING_COMPANIES_SUCCESS,
    GET_MATERIAL_SUPPLIERS_FAIL,
    GET_MATERIAL_SUPPLIERS_SUCCESS,
    GET_RAW_MATERIAL_SUPPLIERS_FAIL,
    GET_RAW_MATERIAL_SUPPLIERS_SUCCESS,
    GET_TRANSPORTATION_COMPANIES_FAIL,
    GET_TRANSPORTATION_COMPANIES_SUCCESS,
    UPDATE_CLIENT_FAIL,
    UPDATE_CLIENT_SUCCESS,
    UPDATE_MANUFACTURING_COMPANY_FAIL,
    UPDATE_MANUFACTURING_COMPANY_SUCCESS,
    UPDATE_MATERIAL_SUPPLIER_FAIL,
    UPDATE_MATERIAL_SUPPLIER_SUCCESS,
    UPDATE_RAW_MATERIAL_SUPPLIER_FAIL,
    UPDATE_RAW_MATERIAL_SUPPLIER_SUCCESS,
    UPDATE_TRANSPORTATION_COMPANY_FAIL,
    UPDATE_TRANSPORTATION_COMPANY_SUCCESS,
} from "./types";
import {setAlert} from "./alert";

/* Materia Prima */
export const getRawMaterialSuppliers = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
        params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/stakeholders/raw-material-suppliers/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_RAW_MATERIAL_SUPPLIERS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_RAW_MATERIAL_SUPPLIERS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_RAW_MATERIAL_SUPPLIERS_FAIL
        });
    }
}
export const addRawMaterialSupplier = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/stakeholders/raw-material-suppliers/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_RAW_MATERIAL_SUPPLIER_SUCCESS, payload: res.data
            });
            dispatch(getRawMaterialSuppliers(params))
            dispatch(setAlert(res.data.message || 'Proveedor de Materia Prima creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_RAW_MATERIAL_SUPPLIER_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_RAW_MATERIAL_SUPPLIER_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_RAW_MATERIAL_SUPPLIER_FAIL
            });
        }
    }
};
export const updateRawMaterialSupplier = (pk, form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/stakeholders/raw-material-suppliers/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_RAW_MATERIAL_SUPPLIER_SUCCESS, payload: res.data
            });
            dispatch(getRawMaterialSuppliers(params))
            dispatch(setAlert(res.data.message || 'Proveedor de Materia Prima actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_RAW_MATERIAL_SUPPLIER_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_RAW_MATERIAL_SUPPLIER_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_RAW_MATERIAL_SUPPLIER_FAIL
            });
        }
    }
};
export const deleteRawMaterialSupplier = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/stakeholders/raw-material-suppliers/delete/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_RAW_MATERIAL_SUPPLIER_SUCCESS, payload: res.data
            });
            dispatch(getRawMaterialSuppliers(params))
            dispatch(setAlert(res.data.message || 'Proveedor de Material Prima eliminado con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_RAW_MATERIAL_SUPPLIER_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_RAW_MATERIAL_SUPPLIER_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_RAW_MATERIAL_SUPPLIER_FAIL
            });
        }
    }
};


/* Materiales */
export const getMaterialSuppliers = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
        params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/stakeholders/material-suppliers/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_MATERIAL_SUPPLIERS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_MATERIAL_SUPPLIERS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_MATERIAL_SUPPLIERS_FAIL
        });
    }
}
export const addMaterialSupplier = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/stakeholders/material-suppliers/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_MATERIAL_SUPPLIER_SUCCESS, payload: res.data
            });
            dispatch(getMaterialSuppliers(params))
            dispatch(setAlert(res.data.message || 'Proveedor de E&E creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_MATERIAL_SUPPLIER_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_MATERIAL_SUPPLIER_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_MATERIAL_SUPPLIER_FAIL
            });
        }
    }
};
export const updateMaterialSupplier = (pk, form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/stakeholders/material-suppliers/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_MATERIAL_SUPPLIER_SUCCESS, payload: res.data
            });
            dispatch(getMaterialSuppliers(params))
            dispatch(setAlert(res.data.message || 'Proveedor de E&E actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_MATERIAL_SUPPLIER_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_MATERIAL_SUPPLIER_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_MATERIAL_SUPPLIER_FAIL
            });
        }
    }
};
export const deleteMaterialSupplier = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/stakeholders/material-suppliers/delete/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_MATERIAL_SUPPLIER_SUCCESS, payload: res.data
            });
            dispatch(getMaterialSuppliers(params))
            dispatch(setAlert(res.data.message || 'Proveedor de E&E eliminado con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_MATERIAL_SUPPLIER_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_MATERIAL_SUPPLIER_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_MATERIAL_SUPPLIER_FAIL
            });
        }
    }
};


/* Customers */
export const getCustomers = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
        params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/stakeholders/clients/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_CLIENTS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_CLIENTS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_CLIENTS_FAIL
        });
    }
}
export const addCustomer = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/stakeholders/clients/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_CLIENT_SUCCESS, payload: res.data
            });
            dispatch(getCustomers(params))
            dispatch(setAlert(res.data.message || 'Cliente creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_CLIENT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_CLIENT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_CLIENT_FAIL
            });
        }
    }
};
export const updateCustomer = (pk, form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/stakeholders/clients/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_CLIENT_SUCCESS, payload: res.data
            });
            dispatch(getCustomers(params))
            dispatch(setAlert(res.data.message || 'Cliente actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_CLIENT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));
        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_CLIENT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_CLIENT_FAIL
            });
        }
    }
};
export const deleteCustomer = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/stakeholders/customer/delete/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_CLIENT_SUCCESS, payload: res.data
            });
            dispatch(getCustomers(params))
            dispatch(setAlert(res.data.message || 'Cliente eliminado con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_CLIENT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_CLIENT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_CLIENT_FAIL
            });
        }
    }
};


/* Transportation Company */
export const getTransportationCompanies = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
        params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/stakeholders/transportation-companies/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_TRANSPORTATION_COMPANIES_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_TRANSPORTATION_COMPANIES_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_TRANSPORTATION_COMPANIES_FAIL
        });
    }
}
export const addTransportationCompany = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/stakeholders/transportation-companies/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_TRANSPORTATION_COMPANY_SUCCESS, payload: res.data
            });
            dispatch(getTransportationCompanies(params))
            dispatch(setAlert(res.data.message || 'Empresa de transporte creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_TRANSPORTATION_COMPANY_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_TRANSPORTATION_COMPANY_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_TRANSPORTATION_COMPANY_FAIL
            });
        }
    }
};
export const updateTransportationCompany = (pk, form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/stakeholders/transportation-companies/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_TRANSPORTATION_COMPANY_SUCCESS, payload: res.data
            });
            dispatch(getTransportationCompanies(params))
            dispatch(setAlert(res.data.message || 'Empresa de transporte actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_TRANSPORTATION_COMPANY_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));
        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_TRANSPORTATION_COMPANY_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_TRANSPORTATION_COMPANY_FAIL
            });
        }
    }
};
export const deleteTransportationCompany = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/stakeholders/transportation-companies/delete/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_TRANSPORTATION_COMPANY_SUCCESS, payload: res.data
            });
            dispatch(getTransportationCompanies(params))
            dispatch(setAlert(res.data.message || 'Empresa de transporte eliminado con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_TRANSPORTATION_COMPANY_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_TRANSPORTATION_COMPANY_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_TRANSPORTATION_COMPANY_FAIL
            });
        }
    }
};

/* Manufacturing companies*/
export const getManufacturingCompanies = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
        params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/stakeholders/manufacturing-companies/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_MANUFACTURING_COMPANIES_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_MANUFACTURING_COMPANIES_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_MANUFACTURING_COMPANIES_FAIL
        });
    }
}
export const addManufacturingCompany = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/stakeholders/manufacturing-companies/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_MANUFACTURING_COMPANY_SUCCESS, payload: res.data
            });
            dispatch(getManufacturingCompanies(params))
            dispatch(setAlert(res.data.message || 'Empresa creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_MANUFACTURING_COMPANY_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_MANUFACTURING_COMPANY_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_MANUFACTURING_COMPANY_FAIL
            });
        }
    }
};
export const updateManufacturingCompany = (pk, form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/stakeholders/manufacturing-companies/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_MANUFACTURING_COMPANY_SUCCESS, payload: res.data
            });
            dispatch(getManufacturingCompanies(params))
            dispatch(setAlert(res.data.message || 'Empresa actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_MANUFACTURING_COMPANY_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));
        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_MANUFACTURING_COMPANY_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_MANUFACTURING_COMPANY_FAIL
            });
        }
    }
};
export const deleteManufacturingCompany = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/stakeholders/manufacturing-companies/delete/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_MANUFACTURING_COMPANY_SUCCESS, payload: res.data
            });
            dispatch(getManufacturingCompanies(params))
            dispatch(setAlert(res.data.message || 'Empresa eliminada con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_MANUFACTURING_COMPANY_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));
        }
    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_MANUFACTURING_COMPANY_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_MANUFACTURING_COMPANY_FAIL
            });
        }
    }
};
