import React from 'react';
import TableBase from "../../util/Table";

const TableOven= () => {

    const columns = ['','Fecha','Materia Prima','Lote','Presentación','Maduración','Espesor','Horno','N° Coches','N° Bandejas','Kg habilitado (Reporte)','Peso bruto balanza','Tara','Kg habilitado Balanza','Diferencia','Peso * bandeja']

    return (<TableBase columns={columns}/>);
};

export default TableOven;
