import {combineReducers} from "redux";
import Category from "./category";
import Search from "./search";
import Alert from "./alert";
import Stakeholders from "./stakeholders";
import Users from "./users";
import TalentHub from "./talent_hub";
import Agrisupply from "./agrisupply";
import Logistic from "./logistic";
import {Commercial} from "./commercial";
import {Maintenance} from "./maintenance";
import Production from "./production";
import Auth from "./auth";
import Quality from "./quality";


export default combineReducers({
    Category, Search, Alert, Stakeholders, Users, TalentHub, Agrisupply, Logistic, Commercial, Maintenance,Production,Auth,Quality
});