import React, {useState} from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {InputForm, SelectForm} from "../../util/HelpersForm";
import {getLots} from "../../../redux/actions/logistic";
import {addCSweetPotato, updateCSweetPotato} from "../../../redux/actions/production";


const FormSweetPotatoC = ({data, onClose, params, selected,cuts}) => {
    const dispatch = useDispatch();
    const lots = useSelector(state => state.Logistic.lots);
    const [search, setSearch] = useState({lot: '', product: selected})


    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            data ? dispatch(updateCSweetPotato(data?.id, form, params)) : dispatch(addCSweetPotato(form, params));
            onClose();
        }
    })

    const handleSearch = () => {
        dispatch(getLots(search));
        formik.setFieldValue('lot', null);
    }


    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid grid-cols-2 gap-2`}>
                {!data &&
                <div className={"col-span-2 flex items-center justify-center relative"}>

                    <input
                        value={search.lot}
                        onChange={(e) => setSearch({...search, lot: e.target.value})}
                        onKeyUp={(e) => e.key === 'Enter' && handleSearch()}
                        className={`block w-max p-3 pl-10 border-2 text-sm border-gray-200 rounded-md focus:border-green-300 focus:ring-green-300`}
                        placeholder={`Lote...`}
                    />
                    <div className="absolute top-0 inset-y-0 left-8  md:left-20 flex items-center  pointer-events-none">
                        <svg
                            className="h-3.5 w-3.5 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                        </svg>
                    </div>
                </div>}


                <InputForm values={formik.values.date} errors={formik.errors.date} formik={formik}
                           field={'date'}
                           title={'Fecha'} type_={'date'}
                />
                {!data &&  <SelectForm values={formik.values.lot} errors={formik.errors.lot} formik={formik}
                            field={'lot'}
                            title={'Lote'} options={lots} display={'lot'}
                />}
                <SelectForm values={formik.values.cut} errors={formik.errors.cut} formik={formik}
                                      field={'cut'}
                                      title={'Corte'} options={cuts?cuts:[]} display={'name'}
                />

                <InputForm values={formik.values.logistic_kg} errors={formik.errors.logistic_kg} formik={formik}
                           field={'logistic_kg'} max_length={7}
                           title={'Kg Logística'}
                />
                <InputForm values={formik.values.rejected_kg} errors={formik.errors.rejected_kg} formik={formik}
                           field={'rejected_kg'} max_length={9}
                           title={'Kg Rechazados'}
                />
                <InputForm values={formik.values.brix} errors={formik.errors.brix} formik={formik}
                           field={'brix'}
                           title={'Brix'} max_length={3}
                />
                <InputForm values={formik.values.ph} errors={formik.errors.ph} formik={formik}
                           field={'ph'}
                           title={'Ph'} max_length={3}
                />
                <InputForm values={formik.values.people} errors={formik.errors.people} formik={formik}
                           field={'people'} max_length={2}
                           title={'Personas'}
                />
                <InputForm values={formik.values.duration} errors={formik.errors.duration} formik={formik}
                           field={'duration'}
                           title={'Duración'} type={'number'}
                />


            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {

        date: data?.date || new Date().toISOString().split('T')[0],
        lot: data?.lot || '',
        logistic_kg: data?.logistic_kg || 0,
        rejected_kg: data?.rejected_kg || 0,
        brix: data?.brix || 0,
        ph: data?.ph || 0,
        people: data?.people || 0,
        duration: data?.duration || 0,
        waste_kg: data?.waste_kg || 0,
        cut: data?.cut || '',
    }
}
const newSchema = () => {
    return {
        date: Yup.date().required(),
        lot: Yup.string().min(6).required(),
        logistic_kg: Yup.number().required(),
        rejected_kg: Yup.number().required(),
        brix: Yup.number().required(),
        ph: Yup.number().required(),
        people: Yup.number().integer().required(),
        duration: Yup.number().required(),
        waste_kg: Yup.number().required(),
        cut: Yup.string().required(),


    }
}

export default FormSweetPotatoC;
