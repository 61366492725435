import React, {useEffect, useState} from 'react'
import {Navigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {reset_password_confirm} from '../../redux/actions/auth'
import {TailSpin} from 'react-loader-spinner'
import SignLayout from "../../hocs/SignLayout";
import {Helmet} from "react-helmet";

const ResetPasswordConfirm = () => {
    const params = useParams()
    const loading = useSelector(state => state.Auth.loading)
    const dispatch = useDispatch();

    const [requestSent, setRequestSent] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [formData, setFormData] = useState({
        new_password: '', re_new_password: ''
    })

    const {
        new_password, re_new_password
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = e => {
        e.preventDefault();
        const uid = params.uid
        const token = params.token

        dispatch(reset_password_confirm(uid, token, new_password, re_new_password))
        if (new_password === re_new_password) setRequestSent(true);
    }

    if (requestSent && !loading) return <Navigate to='/'/>;

    return (<SignLayout>
        <Helmet>
            <title>Restablecer contraseña</title>
        </Helmet>

        <form onSubmit={e => onSubmit(e)} className="bg-white  px-20 flex flex-col justify-center items-center gap-4">

            <div className="flex items-center border-2 py-2 px-3 rounded-2xl w-full">
                <input
                    name="new_password"
                    value={new_password}
                    onChange={e => onChange(e)}
                    type="password"
                    placeholder="Contraseña"
                    required
                    className={'pl-2 outline-none border-none'}
                />
            </div>
            <div className="flex items-center border-2 py-2 px-3 rounded-2xl w-full">
                <input
                    name="re_new_password"
                    value={re_new_password}
                    onChange={e => onChange(e)}
                    type="password"
                    placeholder="Repetir contraseña"
                    required
                    className={'pl-2 outline-none border-none'}
                />
            </div>

            <div>
                {loading ? <button
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    <TailSpin
                        type="Oval"
                        color="#fff"
                        width={20}
                        height={20}
                    />
                </button> : <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Restablecer contraseña
                </button>}
            </div>
        </form>

    </SignLayout>)
}
export default ResetPasswordConfirm;