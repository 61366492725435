import React, {useEffect} from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import HeaderForm from "../../../util/HeaderForm";
import {InputForm, SelectForm} from "../../../util/HelpersForm";
import {addIssueArticle, getReceiptsByArticles, transferArticle} from "../../../../redux/actions/logistic";
import {setAlert} from "../../../../redux/actions/alert";

const FormTransfer = ({onClose, item, params}) => {
    const dispatch = useDispatch();
    const articles = useSelector(state => state.Logistic.receipts_stock);
    const manufacturing_companies = useSelector(state => state.Stakeholders.manufacturing_companies);


    useEffect(() => {
        item?.id && dispatch(getReceiptsByArticles(item?.id, params?.manufacturer))
    }, [dispatch])


    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {

            if (formik.values.quantity > articles.find((e) => e.id === formik.values.item)?.stock) {
                dispatch(setAlert('No hay suficiente stock', 'error'))
            } else {
                dispatch(transferArticle(form, params))
                onClose();

            }
        }
    })

    return (<form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
        <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
        <h3 className={"font-light text-xs text-gray-400 mb-1"}>{item?.name}</h3>
        <h3 className={"font-light text-xs text-gray-400 border-b-2 mb-2"}>{manufacturing_companies && manufacturing_companies.find((e) => e.id === params?.manufacturer)?.name}</h3>

        <div className={`grid  grid-cols-1 gap-2`}>

            <SelectForm values={formik.values.item} errors={formik.errors.item} formik={formik}
                        field={'item'} display={'name'}
                        title={'Artículo'} options={articles && articles}/>

            <SelectForm values={formik.values.manufacturing} errors={formik.errors.manufacturing} formik={formik}
                        field={'manufacturing'} display={'name'}
                        title={'Almacén'} options={manufacturing_companies && manufacturing_companies}/>

            {formik.values.item &&
                <InputForm values={formik.values.quantity} errors={formik.errors.quantity} formik={formik}
                           field={'quantity'} type_={'number'}
                    max_length={articles && articles.find((e) => e.id === formik.values.item)?.stock}
                           title={'Cantidad'}/>}

        </div>
    </form>);
};

const initialValues = () => {
    return {
        quantity: 0, manufacturing: '',item: ''

    }
}

const newSchema = () => {
    return {
        quantity: Yup.number().integer().min(1).required(),
        manufacturing: Yup.string().min(2).required(),
        item: Yup.string().min(2).required()
    }
}

export default FormTransfer;