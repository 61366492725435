import React from 'react';
import Card from "./Card";

const TaskSection = ({title, icon, color, work, handleModalAction, handleDeleteWork}) => {
    return (<div className="mt-0">
        <div className={`flex items-center bg-${color}-400 bg-opacity-95 w-full p-1 rounded-xl  `}>
            {icon}
            <span className={`text-white font-bold text-sm p-2`}>{title}</span>
        </div>

        <div className={"h-56 md:h-screen overflow-scroll scrollbar-hide"}>
            {work.map((item, index) => (<Card
                key={index}
                workItem={item}
                action_view={() => handleModalAction('view', item)}
                action_update={() => handleModalAction('update', item)}
                action_update_resource={() => handleModalAction('updateResource', item)}
                action_update_helpers={() => handleModalAction('updateHelpers', item)}
                action_delete={() => handleDeleteWork(item)}
                action_supervisor={() => handleModalAction('supervisor', item)}
            />))}
        </div>

    </div>);
};
export default TaskSection;