import {Switch} from "@headlessui/react";
import React from "react";

const SwitchField = ({id, name, formik,class_,title }) => (
    <div className={class_}>
        <p className={`block  text-xs  font-normal ${!formik.errors[name] ? 'text-green-400' : 'text-red-400'}`}>{title}</p>
        <Switch
            id={id}
            checked={formik.values[name]}
            onChange={(checked) => formik.setFieldValue(name, checked)}
            className={`${formik.values[name] ? 'bg-green-400' : 'bg-gray-200'} mt-2 relative inline-flex h-6 w-11 items-center rounded-full transition-colors`}
        >
            <span
                className={`${formik.values[name] ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
            />
        </Switch>

    </div>
);

export default SwitchField;