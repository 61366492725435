import React from 'react';
import {InputSearch, SelectSearch} from "../../../util/Filters";
import {useSelector} from "react-redux";
import {PaperAirplaneIcon} from "@heroicons/react/24/outline";

const Filters = ({params, setParams, handleSearch, businessFilter, yearFilter, monthFilter}) => {
    const manufacturers = useSelector(state => state.Stakeholders.manufacturing_companies);
    const categories = useSelector(state => state.Category.categories);


    return (<div className="flex flex-col lg:flex-row items-center gap-2 justify-end py-4 bg-white ">
            <InputSearch name={"Buscar"} value={params.name} color={'bg-gray-100'} handleSearch={handleSearch}
                         handle={(value) => setParams({...params, 'name': value.target.value})}/>

            <SelectSearch name={"Grupo"} value={params.group} color={'bg-gray-100'}
                          handleSearch={handleSearch}
                          field={'name'}
                          handle={(e) => {
                              setParams({...params, 'group': e.target.value})
                          }}
                          items={categories && categories.map((item) => {
                                  return {id: item.name, name: item.name}
                              }
                          )}/>

            {businessFilter && <SelectSearch name={"Planta"} value={params.manufacturer} color={'bg-gray-100'}
                                             handleSearch={handleSearch}
                                             field={'name'}
                                             handle={(e) => setParams({...params, 'manufacturer': e.target.value})}
                                             items={manufacturers && manufacturers.map((item) => {
                                                 return {id: item.id, name: item.name}
                                             })}/>}
            {yearFilter && <SelectSearch name={"Año"} value={params.year} color={'bg-gray-100'}
                                         handleSearch={handleSearch}
                                         field={'name'} handle={(e) => setParams({...params, 'year': e.target.value})}
                                         items={[{id: '2021', name: '2021'}, {
                                             id: '2022', name: '2022'
                                         }, {id: '2023', name: '2023'}, {id: '2024', name: '2024'}, {
                                             id: '2025', name: '2025'
                                         }, {id: '2026', name: '2026'}, {id: '2027', name: '2027'}, {
                                             id: '2028', name: '2028'
                                         }, {id: '2029', name: '2029'}, {id: '2030', name: '2030'},]}/>}

            {monthFilter && <SelectSearch name={"Mes"} value={params.month} color={'bg-gray-100'}
                                          handle={(e) => setParams({...params, 'month': e.target.value})}
                                          handleSearch={handleSearch}
                                          field={'name'}
                                          items={[{id: 1, name: 'Enero'}, {
                                              id: 2, name: 'Febrero'
                                          }, {
                                              id: 3, name: 'Marzo'
                                          }, {
                                              id: 4, name: 'Abril'
                                          }, {id: 5, name: 'Mayo'}, {id: 6, name: 'Junio'}, {id: 7, name: 'Julio'}, {
                                              id: 8, name: 'Agosto'
                                          }, {id: 9, name: 'Septiembre'}, {id: 10, name: 'Octubre'}, {
                                              id: 11, name: 'Noviembre'
                                          }, {id: 12, name: 'Diciembre'}]}/>}
            <button onClick={handleSearch}
                    className="bg-gray-100 flex gap-2 items-center  hover:bg-gray-300 w-full md:w-max text-gray-500 hover:text-white self-end mb-1 font-medium py-2 px-4 rounded-md justify-center">
                Buscar <PaperAirplaneIcon className="h-5 w-5"/>
            </button>


        </div>

    );
};

export default Filters;