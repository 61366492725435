import React from 'react';
import Humanize from 'humanize-plus'
const Badge = ({data}) => {
    return (
        <div className={"w-full grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-6 py-4 gap-2"}>
            <Items text={`Total de jabas: ${data?.boxes ||0}`}/>
            <Items text={`Peso  Bruto: ${Humanize.formatNumber(data?.weight_gross ||0, 2)}`}/>
            <Items text={`Peso  Neto: ${Humanize.formatNumber(data?.weight_net || 0, 2)}`}/>
            <Items text={`Tara: ${Humanize.formatNumber(parseFloat(data?.weight_boxes) + parseFloat(data?.weight_pallet)||0, 2)}`}/>
            <Items text={`Muestra de calidad: ${Humanize.formatNumber(data?.sample_weight || 0, 2)}`}/>
            <Items text={`Stock: ${Humanize.formatNumber(data?.stock ||0 , 2)}`}/>
        </div>
    );
};

const Items = ({text})=>{
    return (
        <div className="bg-white p-2 h-8 w-full mb-4 md:mb-0 rounded-md flex items-center justify-center">
            <div className="flex items-center cursor-grab">
                <div className="h-1 w-1 rounded-full bg-green-500 mr-1"/>
                <span className="text-xs text-black font-normal text-center">{text}</span>
            </div>
        </div>
    )
}

export default Badge;
