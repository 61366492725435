import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import Humanize from "humanize-plus";

const TablePineapple = ({data}) => {
    const columns = ['Lote', 'M0', 'M1', 'M2', 'M3', 'M4', 'M5', 'Daño Mecánico', 'Daño', 'Quemadura', 'Calidad Nutricional', 'Rancha', 'C6', 'C8', 'C10', 'C12', 'C14', 'Visual', 'Muestreo', 'Destructivo', 'Brix M0', 'PH M0', 'Brix M1', 'PH M1', 'Brix M2', 'PH M2', 'Brix M3', 'PH M3', 'Brix M4', 'PH M4', 'Brix M5', 'PH M5', 'Corona C6', 'Fruta C6', 'Corona C8', 'Fruta C8', 'Corona C10', 'Fruta C10', 'Corona C12', 'Fruta C12', 'Corona C14', 'Fruta C14'];
    return (<TableBase columns={columns} cell={Row(data)}/>);
};


const Row = (data) => (data && data.map((item, index) => {
    return (<tr key={index} className="h-max border-gray-300  border-b">

        <Cell text={<p className={"font-medium hover:text-green-500 cursor-pointer"}>{item?.lot_name}</p>}/>
        <Cell text={Humanize.formatNumber(item?.m0, 2)}/>
        <Cell text={Humanize.formatNumber(item?.m1, 2)}/>
        <Cell text={Humanize.formatNumber(item?.m2, 2)}/>
        <Cell text={Humanize.formatNumber(item?.m3, 2)}/>
        <Cell text={Humanize.formatNumber(item?.m4, 2)}/>
        <Cell text={Humanize.formatNumber(item?.m5, 2)}/>
        <Cell text={Humanize.formatNumber(item?.mechanical_damage, 2)}/>
        <Cell text={Humanize.formatNumber(item?.injury, 2)}/>
        <Cell text={Humanize.formatNumber(item?.sunburn, 2)}/>
        <Cell text={Humanize.formatNumber(item?.nutritional_quality, 2)}/>
        <Cell text={Humanize.formatNumber(item?.spot, 2)}/>
        <Cell text={Humanize.formatNumber(item?.c6, 2)}/>
        <Cell text={Humanize.formatNumber(item?.c8, 2)}/>
        <Cell text={Humanize.formatNumber(item?.c10, 2)}/>
        <Cell text={Humanize.formatNumber(item?.c12, 2)}/>
        <Cell text={Humanize.formatNumber(item?.c14, 2)}/>
        <Cell text={Humanize.formatNumber(item?.visual_quality, 2)}/>
        <Cell text={Humanize.formatNumber(item?.sampling, 2)}/>
        <Cell text={Humanize.formatNumber(item?.destructive_analysis, 2)}/>
        <Cell text={Humanize.formatNumber(item?.brix_m0, 2)}/>
        <Cell text={Humanize.formatNumber(item?.ph_m0, 2)}/>
        <Cell text={Humanize.formatNumber(item?.brix_m1, 2)}/>
        <Cell text={Humanize.formatNumber(item?.ph_m1, 2)}/>
        <Cell text={Humanize.formatNumber(item?.brix_m2, 2)}/>
        <Cell text={Humanize.formatNumber(item?.ph_m2, 2)}/>
        <Cell text={Humanize.formatNumber(item?.brix_m3, 2)}/>
        <Cell text={Humanize.formatNumber(item?.ph_m3, 2)}/>
        <Cell text={Humanize.formatNumber(item?.brix_m4, 2)}/>
        <Cell text={Humanize.formatNumber(item?.ph_m4, 2)}/>
        <Cell text={Humanize.formatNumber(item?.brix_m5, 2)}/>
        <Cell text={Humanize.formatNumber(item?.ph_m5, 2)}/>
        <Cell text={Humanize.formatNumber(item?.crown_c6, 2)}/>
        <Cell text={Humanize.formatNumber(item?.fruit_c6, 2)}/>
        <Cell text={Humanize.formatNumber(item?.crown_c8, 2)}/>
        <Cell text={Humanize.formatNumber(item?.fruit_c8, 2)}/>
        <Cell text={Humanize.formatNumber(item?.crown_c10, 2)}/>
        <Cell text={Humanize.formatNumber(item?.fruit_c10, 2)}/>
        <Cell text={Humanize.formatNumber(item?.crown_c12, 2)}/>
        <Cell text={Humanize.formatNumber(item?.fruit_c12, 2)}/>
        <Cell text={Humanize.formatNumber(item?.crown_c14, 2)}/>
        <Cell text={Humanize.formatNumber(item?.fruit_c14, 2)}/>


    </tr>)
}));


export default TablePineapple;
