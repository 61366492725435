import React from 'react';
import Layout from "../../../../hocs/Layout";
import TableOven from "../../../../components/e2e/Production/TableOvens";
import {InputSearch, SelectSearch} from "../../../../components/util/Filters";
import ButtonHelper from "../../../../components/util/ButtonHelper";
import {Helmet} from "react-helmet";

const Ovens = () => {
    const [params, setParams] = React.useState({date: '', status: '', month: '', year: ''})

    return (<Layout>
        <Helmet>
            <title>Hornos</title>
        </Helmet>
        <div className={"bg-white rounded-2xl p-4 h-fit"}>
            <ButtonHelper/>
            <div className="flex flex-col md:flex-row items-center gap-2 justify-end py-4 bg-white ">
                <InputSearch name={"Buscar"} value={params.date} color={'bg-gray-100'} type={'date'}
                             handle={(value) => setParams({...params, 'date': value.target.value})}/>
                <SelectSearch name={"Estado"} value={params.status} color={'bg-gray-100'}
                              handle={(value) => setParams({
                                  ...params, 'status': value.target.value
                              })}
                              items={[{value: 'Activo', label: 'Greenbox.S.A.C.'}, {
                                  value: 'Inactivo', label: 'Campovida E.I.R.L.'
                              },]}/>
                <SelectSearch value={params.month} color={'bg-gray-100'}
                              handle={(value) => setParams({...params, 'month': value.target.value})}
                              items={[{value: 'Activo', label: 'Enero'}, {
                                  value: 'Inactivo', label: 'Febrero'
                              },]}/>
                <SelectSearch value={params.year} color={'bg-gray-100'}
                              handle={(value) => setParams({...params, 'year': value.target.value})}
                              items={[{value: 'Activo', label: '2021'}, {
                                  value: 'Inactivo', label: '2022'
                              },]}/>
            </div>

            <div className="w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                <TableOven/>
            </div>
        </div>
    </Layout>);
};

export default Ovens;
