import React, {useEffect, useState} from 'react';
import Layout from "../../../../hocs/Layout";
import CardsRecord from "../../../../components/e2e/Logistic/CardsRecord";
import {useParams} from "react-router-dom";
import TableRecords from "../../../../components/e2e/Logistic/TableRecords";
import Filters from "../../../../components/e2e/Production/Filter";
import {useDispatch, useSelector} from "react-redux";
import {getRecords} from "../../../../redux/actions/logistic";
import {Helmet} from "react-helmet";

const Records = () => {
    const {product} = useParams()
    const [params, setParams] = useState({
        lot: '', month: new Date().getMonth() + 1, year: new Date().getFullYear(), week: ''
    });
    const dispatch = useDispatch();
    const data = useSelector(state => state.Logistic.records);

    useEffect(() => {
        dispatch(getRecords({...params, type: product}));
    }, [dispatch]);

    const handleSearch = () => {
        dispatch(getRecords({...params, type: product}));
    }

    return (<Layout>
        <Helmet>
            <title>Registros de {product}</title>
        </Helmet>
        <div className={"bg-white rounded-2xl p-4 h-fit"}>
            <h1 className={"font-bold text-gray-800 text-2xl uppercase p-2"}>{product}</h1>

            <CardsRecord data={data}/>
            <div className="flex flex-col md:flex-row gap-6 py-4">
                <Filters params={params} setParams={setParams} handleSearch={handleSearch}/>
            </div>

            <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                <TableRecords data={data}/>
            </div>

        </div>

    </Layout>);
};

export default Records;
