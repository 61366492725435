import React from 'react';
import {InputSearch, SelectSearch} from "../../util/Filters";
import {useSelector} from "react-redux";
import {PaperAirplaneIcon} from "@heroicons/react/24/outline";

const Filters = ({params, setParams, handleSearch}) => {
    const departments = useSelector(state => state.Users.departments);
    const departmentsWithNull = departments ? [{id: '', name: "Todos los Departamentos"}, ...departments] : [{
        id: '',
        name: "Todos los Departamentos"
    }];


    return (<div className="flex flex-col md:flex-row items-center gap-2 justify-end py-4 bg-white ">
            <InputSearch name={"Usuario"} value={params.user} color={'bg-gray-100'}
                         handleSearch={handleSearch}
                         handle={(value) => setParams({...params, 'user': value.target.value})}/>

            <InputSearch name={"Fecha"} value={params.date} color={'bg-gray-100'} type={"date"} icon={false}
                         handleSearch={handleSearch}
                         handle={(value) => setParams({...params, 'date': value.target.value})}/>
            <SelectSearch name={"Estado"} value={params.status} color={'bg-gray-100'}
                          items={departments ? departmentsWithNull : []}
                          handleSearch={handleSearch}
                          field={'name'}
                          handle={(value) => setParams({...params, 'department': value.target.value})}/>
            <button onClick={handleSearch}
                    className="bg-gray-100 flex gap-2 items-center  hover:bg-gray-300 w-full md:w-max text-gray-500 hover:text-white self-end mb-1 font-medium py-2 px-4 rounded-md justify-center">
                Buscar <PaperAirplaneIcon className="h-5 w-5"/>
            </button>

        </div>

    );
};

export default Filters;
