import React from 'react';
import {InputSearch, SelectSearch} from "../../util/Filters";
import {PaperAirplaneIcon} from "@heroicons/react/24/outline";

const FilterDownloadFreightLot = ({setParams, handleSearch, params}) => {
    return (<div className={"flex gap-4 w-full justify-end flex-col md:flex-row"}>
        <InputSearch name={"Lote"} color={"bg-gray-100"} value={params.lot}
                     handleSearch={handleSearch}
                     handle={(e) => setParams({...params, lot: e.target.value})}/>
        <SelectSearch name={"Año"} color={"bg-gray-100"} value={params.year}
                      handleSearch={handleSearch}
                      field={'name'} handle={(e) => setParams({...params, year: e.target.value})}
                      items={[{id: '2021', name: '2021'}, {
                          id: '2022', name: '2022'
                      }, {id: '2023', name: '2023'}, {id: '2024', name: '2024'}, {
                          id: '2025', name: '2025'
                      }, {id: '2026', name: '2026'}, {id: '2027', name: '2027'}, {
                          id: '2028', name: '2028'
                      }, {id: '2029', name: '2029'}, {id: '2030', name: '2030'},]}/>

        <SelectSearch name={"Mes"} color={"bg-gray-100"} value={params.month}
                      handle={(e) => setParams({...params, month: e.target.value})}
                      handleSearch={handleSearch}
                      field={'name'}
                      items={[{id: 1, name: 'Enero'}, {id: 2, name: 'Febrero'}, {id: 3, name: 'Marzo'}, {
                          id: 4, name: 'Abril'
                      }, {id: 5, name: 'Mayo'}, {id: 6, name: 'Junio'}, {id: 7, name: 'Julio'}, {
                          id: 8, name: 'Agosto'
                      }, {id: 9, name: 'Septiembre'}, {id: 10, name: 'Octubre'}, {
                          id: 11, name: 'Noviembre'
                      }, {id: 12, name: 'Diciembre'}]}/>
        <button onClick={handleSearch}
                className="bg-gray-100 flex gap-2 items-center  hover:bg-gray-300 w-full md:w-max text-gray-500 hover:text-white self-end mb-1 font-medium py-2 px-4 rounded-md justify-center">
            Buscar <PaperAirplaneIcon className="h-5 w-5"/>
        </button>


    </div>);
};

export default FilterDownloadFreightLot;
