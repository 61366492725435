import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {useSelector} from "react-redux";
import {formatDateTZ, formatTime} from "../../util/helpers";

const TableReal = () => {
    const data = useSelector(state => state.TalentHub.real)

    const columns = ['Nombre', 'Fecha', 'Entrada', 'Entrada Break', 'Salida Break', 'Salida', 'Horas Trabajadas']
    return (<TableBase columns={columns} cell={Row(data)}/>);
};

const Row = (data) => (data && data.map((item, index) => {
    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={<p className={"font-medium"}>{item?.staff_name}</p>}/>
        <Cell text={item?.date && formatDateTZ(item.date)}/>
        <Cell text={item?.real_check_in && formatTime(item.real_check_in)}/>
        <Cell text={item?.lunch_start && formatTime(item.lunch_start)}/>
        <Cell text={item?.real_lunch_end && formatTime(item.real_lunch_end)}/>
        <Cell text={item?.check_out && formatTime(item.check_out)}/>
        <Cell text={item?.real_time}/>
    </tr>)

}));

export default TableReal;
