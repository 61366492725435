import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import HeaderForm from "../../../util/HeaderForm";
import {InputForm, SelectForm} from "../../../util/HelpersForm";
import {addReceiptArticle} from "../../../../redux/actions/logistic";
import {setAlert} from "../../../../redux/actions/alert";

const FormReceipt = ({data, onClose, item, params}) => {
    const dispatch = useDispatch();
    const material_suppliers = useSelector(state => state.Stakeholders.material_suppliers);
    const manufacturing_companies = useSelector(state => state.Stakeholders.manufacturing_companies);

    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            if (params.manufacturer !== '' && item?.id !== '') {
                form['item'] = item.id;
                form['manufacturing'] = params.manufacturer;
                form['stock']=form.quantity;

                dispatch(addReceiptArticle(form,params));
                onClose();
            } else {
                console.log(params.manufacturer, item?.id)
                dispatch(setAlert('Seleccione un almacén y un artículo', 'error'));
            }

        }
    })
    return (<form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
        <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
        <h3 className={"font-light text-xs text-gray-400 mb-1"}>{item?.name}</h3>
        <h3 className={"font-light text-xs text-gray-400 border-b-2 mb-2"}>{manufacturing_companies && manufacturing_companies.find((e)=>e.id===params?.manufacturer)?.name}</h3>
        <div className={`grid  grid-cols-1 gap-2`}>
            <InputForm values={formik.values.po_number} errors={formik.errors.po_number} formik={formik}
                       field={'po_number'}
                       title={'OC'} max_length={50}/>
            <InputForm values={formik.values.po_date} errors={formik.errors.po_date} formik={formik}
                       field={'po_date'}
                       title={'Fecha OC'} type_={'date'}/>
            <InputForm values={formik.values.arrival_date} errors={formik.errors.arrival_date} formik={formik}
                       field={'arrival_date'}
                       title={'Fecha de llegada'} type_={'date'}/>
            <SelectForm values={formik.values.supplier} errors={formik.errors.supplier} formik={formik}
                        field={'supplier'} display={'name'}
                        title={'Proveedor'} options={material_suppliers && material_suppliers}/>
            <InputForm values={formik.values.invoice} errors={formik.errors.invoice} formik={formik}
                       field={'invoice'}
                       title={'Factura'} max_length={30}/>
            <InputForm values={formik.values.quantity} errors={formik.errors.quantity} formik={formik}
                       field={'quantity'} max_length={6}
                       title={'Cantidad'}/>
            <InputForm values={formik.values.price_per_unit} errors={formik.errors.price_per_unit} formik={formik}
                       field={'price_per_unit'} max_length={6}
                       title={'Precio unitario'}/>
        </div>
    </form>);
};

const initialValues = (data) => {
    return {
        po_number: data?.po_number || '',
        po_date: data?.po_date || '',
        arrival_date: data?.arrival_date || '',
        supplier: data?.supplier || '',
        invoice: data?.invoice || '',
        quantity: data?.quantity || 1,
        price_per_unit: data?.price_per_unit || 0,
    }
}

const newSchema = () => {
    return {
        po_number: Yup.string().required(),
        po_date: Yup.date().required(),
        arrival_date: Yup.date().required(),
        supplier: Yup.string().min(6).required(),
        invoice: Yup.string().min(2).required(),
        quantity: Yup.number().integer().positive().required(),
        price_per_unit: Yup.number().min(0).required(),
    }
}

export default FormReceipt;