import React, {useEffect, useState} from 'react';
import Layout from "../../../../hocs/Layout";
import TableExternal from "../../../../components/e2e/Logistic/TableExternal";
import {InputSearch} from "../../../../components/util/Filters";
import ButtonHelper from "../../../../components/util/ButtonHelper";
import {useDispatch, useSelector} from "react-redux";
import {deleteExternalPerson, getExternalPersons} from "../../../../redux/actions/logistic";
import ModalHook from "../../../../hooks/ModalHook";
import Modal from "../../../../components/util/Modal";
import {MySwal} from "../../../../components/util/helpers";
import FormExternal from "../../../../components/e2e/Logistic/FormExternal";
import {Helmet} from "react-helmet";

const External = () => {
    const [params, setParams] = useState({full_name: ''})
    const data = useSelector(state => state.Logistic.external);
    const {content, setContent, isOpen, openModal} = ModalHook();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getExternalPersons(params));
    }, [dispatch]);

    const handleSearch = () => {
        dispatch(getExternalPersons(params));
    }

    const handleAdd = () => {
        const formComponent = <FormExternal onClose={openModal} params={params} action={'material_suppliers'}/>;
        setContent(formComponent);
        openModal();
    };

    const handleUpdate = (data) => {
        const formComponent = <FormExternal onClose={openModal} data={data} params={params}
                                            action={'material_suppliers'}/>;
        setContent(formComponent);
        openModal();
    };
    const handleDelete = (data) => {
        MySwal.fire({
            title: '¿Desea eliminar este registro?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7DABF5',
            confirmButtonColor: '#F87171',
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteExternalPerson(data, params))
            }
        });
    };


    return (<Layout>
        <Helmet>
            <title>Externos</title>
        </Helmet>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <div className={"bg-white rounded-2xl p-4 h-fit"}>
            <ButtonHelper handle={handleAdd}/>
            <div className={"w-full flex justify-end"}>
                <InputSearch name={"Nombre"} color={"bg-gray-100"} value={params.full_name} handleSearch={handleSearch}
                         handle={(e) => setParams({full_name: e.target.value})}/>
            </div>

            <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                <TableExternal handleDelete={handleDelete} handleUpdate={handleUpdate} data={data}/>
            </div>
        </div>

    </Layout>);
};


export default External;
