import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {PencilSquareIcon} from "@heroicons/react/24/outline";
import {formatDateTZ} from "../../util/helpers";
import Humanize from "humanize-plus";

const TableGLP = ({data, handleUpdate}) => {
    const columns = ['', 'Fecha', 'Consumo', 'Precio']
    return (<TableBase columns={columns} cell={Row(data, handleUpdate)}/>);
};


const Row = (data, update) => (data && data.map((item, index) => {
    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={<div className={"font-bold flex items-center whitespace-nowrap"}>
            <PencilSquareIcon onClick={() => update(item)} title={"Editar"}
                              className={"h-5 w-5 mr-2 text-blue-500 cursor-pointer"}/>

        </div>}/>
        <Cell text={item?.date && formatDateTZ(item.date)}/>
        <Cell text={Humanize.formatNumber(item?.consumption, 2)}/>
        <Cell text={Humanize.formatNumber(item?.cost, 2)}/>

    </tr>)
}));
export default TableGLP;
