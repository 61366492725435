import {GET_PARCELS_FAIL, GET_PARCELS_SUCCESS, GET_PROJECTIONS_FAIL, GET_PROJECTIONS_SUCCESS} from "../actions/types";

const initialState = {
    parcel: null, projection: null
}
export default function Agrisupply(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case GET_PARCELS_SUCCESS:
            return {
                ...state, parcel: payload.data,
            }
        case GET_PARCELS_FAIL:
            return {
                ...state, parcel: null,
            }
        case GET_PROJECTIONS_SUCCESS:
            return {
                ...state, projection: payload.data,
            }
        case GET_PROJECTIONS_FAIL:
            return {
                ...state, projection: null,
            }
        default:
            return state;
    }
}
