import {
    GET_ANALYSIS_FAIL,
    GET_ANALYSIS_LOT_FAIL,
    GET_ANALYSIS_LOT_SUCCESS,
    GET_ANALYSIS_SUCCESS
} from "../actions/types";

const initialState = {
    analysis: null, analysis_lot: null,
}
export default function Quality(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case GET_ANALYSIS_SUCCESS:
            return {
                ...state, analysis: payload.data
            }
        case GET_ANALYSIS_FAIL:
            return {
                ...state, analysis: null
            }
        case GET_ANALYSIS_LOT_SUCCESS:
            return {
                ...state, analysis_lot: payload.data
            }
        case GET_ANALYSIS_LOT_FAIL:
            return {
                ...state, analysis_lot: null
            }
        default:
            return state;
    }
}
