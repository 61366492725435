import React, {useEffect, useState} from 'react';
import Layout from "../../../hocs/Layout";
import TableOutsourcing from "../../../components/sct/Outsourcing/TableOutsourcing";
import {useDispatch} from "react-redux";
import {getOutsourcing, getSummary} from "../../../redux/actions/talent_hub";
import Filters from "../../../components/sct/Outsourcing/Filters";


const Outsourcing = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();

    let previousYear = currentYear;
    let previousMonth = currentMonth;


    if (currentDay < 24) {
        if (currentMonth === 1) {
            previousMonth = 12;
            previousYear--;
        } else {
            previousMonth--;
        }
    }
    const [params, setParams] = useState({year: previousYear, month: previousMonth});
    const dispatch = useDispatch();

    useEffect(() => {
        const payload = {
            start_date: `${params.year}-${params.month}-24`,
            end_date: `${parseInt(params.month) === 12 ? parseInt(params.year) + 1 : params.year}-${parseInt(params.month) === 12 ? 1 : parseInt(params.month) + 1}-23`,
        }
        dispatch(getOutsourcing(payload));
    }, [dispatch])

    const handleSearch = () => {
        const payload = {
            start_date: `${params.year}-${params.month}-24`,
            end_date: `${parseInt(params.month) === 12 ? parseInt(params.year) + 1 : params.year}-${parseInt(params.month) === 12 ? 1 : parseInt(params.month) + 1}-23`,
        }
        dispatch(getOutsourcing(payload));
    };
    return (<Layout>

        <div
            className={"bg-white rounded-2xl p-4 h-fit"}>
            <Filters params={params} setParams={setParams} handleSearch={handleSearch}/>
            <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                <TableOutsourcing/>
            </div>
        </div>

    </Layout>);
};

export default Outsourcing;
