import React from 'react';
import {
    EyeIcon,
    RectangleGroupIcon,
    ServerStackIcon,
    Square2StackIcon,
    Square3Stack3DIcon,
    SwatchIcon,
    TicketIcon
} from "@heroicons/react/24/solid";
import Humanize from "humanize-plus";
import {useSelector} from "react-redux";

const CardsLot = ({data, items}) => {
    const pallets = useSelector(state => state.Logistic.pallets);

    const getSum = (type) => {
        return items.reduce((acc, curr) => {
            return acc + parseFloat(curr[type]);
        }, 0);
    };


    const palletTotals = items.reduce((acc, item) => {
        if (pallets && pallets.find(e => e.id === item.pallet)) acc[pallets.find(e => e.id === item.pallet).name] = (acc[pallets.find(e => e.id === item.pallet).name] || 0) + 1; else acc[item.pallet] = (acc[item.pallet] || 0) + 1;
        return acc;
    }, {});


    return (<div className={"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full self-center"}>
        <CardItem icon={<Square2StackIcon className={"h-12 w-12  text-indigo-300  "}/>}
                  item={<>
                      <div className="text-gray-800  text-sm font-extrabold text-center">
                          <hr/>
                          <h2>TOTAL PESO BRUTO: {Humanize.formatNumber(data?.weight_gross, 2)}</h2>
                          <hr/>
                      </div>
                      <div className="text-gray-800  mt-2 text-sm font-semibold ">
                          <h2 className={"font-bold"}>TOTAL DE JABAS: {data?.boxes}</h2>
                          <h2 className={"font-light"}>Costal 0 KG: {getSum('box_0')}</h2>
                          <h2 className={"font-light"}>JABAS 0.25 KG: {getSum('box_0_25')}</h2>
                          <h2 className={"font-light"}>JABAS 0.50 KG: {getSum('box_0_5')}</h2>
                          <h2 className={"font-light"}>JABAS 1.00 KG: {getSum('box_1')}</h2>
                          <h2 className={"font-light"}>JABAS 1.50 KG: {getSum('box_1_5')}</h2>
                          <h2 className={"font-light"}>JABAS 1.60 KG: {getSum('box_1_6')}</h2>
                          <hr/>
                      </div>
                      <div className="text-gray-800  text-sm font-extrabold text-center">
                          <hr/>
                          <h2>TOTAL PESO NETO: {Humanize.formatNumber(data?.weight_net, 2)}</h2>
                          <hr/>
                      </div>
                  </>}
        />
        <CardItem icon={<ServerStackIcon className={"h-12 w-12  text-indigo-300  "}/>}
                  item={<>
                      <div className="text-gray-800  text-sm font-extrabold text-center">
                          <hr/>
                          <h2>TIPO DE PARIHUELA - CANTIDAD</h2>
                          <hr/>
                      </div>
                      <div className="text-gray-800  mt-2 text-sm font-semibold ">
                          {palletTotals && Object.keys(palletTotals).map((key, index) => {
                              return (<h2 key={index}
                                          className={"font-light"}>{key.toUpperCase()}: {palletTotals[key]}</h2>)
                          })}
                          <hr/>
                      </div>
                      <div className="text-gray-800  text-sm font-extrabold text-center">
                          <hr/>
                          <h2>TOTAL DE PALLET
                              : {data?.pallets}</h2>
                          <hr/>
                      </div>
                  </>}
        />
        <CardItem icon={<RectangleGroupIcon className={"h-12 w-12  text-indigo-300  "}/>}
                  item={<>
                      <div className="text-gray-800  text-sm font-extrabold text-center">
                          <hr/>
                          <h2>ITEM - KG</h2>
                          <hr/>
                      </div>
                      <div className="text-gray-800  mt-2 text-sm font-semibold ">
                          <h2 className={"font-light"}>PESO DE
                              JABAS: {Humanize.formatNumber(data?.weight_boxes, 2)}</h2>
                          <h2 className={"font-light"}>PESO DE
                              PALLETS: {Humanize.formatNumber(data?.weight_pallet, 2)}</h2>
                          <hr/>
                      </div>
                      <div className="text-gray-800  text-sm font-extrabold text-center">
                          <hr/>
                          <h2>TOTAL {Humanize.formatNumber(parseFloat(data?.weight_boxes) + parseFloat(data?.weight_pallet) || 0, 2)}</h2>
                          <hr/>
                      </div>
                  </>}
        />
        <CardItem icon={<Square3Stack3DIcon className={"h-12 w-12  text-indigo-300  "}/>}
                  item={<>
                      <div className="text-gray-800  mt-2 text-sm font-semibold ">
                          <h2 className={"font-light"}>PESO PROMEDIO
                              BRUTO: {Humanize.formatNumber(data?.weight_boxes / data?.weight_gross * 100 || 0, 2)}</h2>
                          <h2 className={"font-light"}>PESO PROMEDIO
                              NETO: {Humanize.formatNumber(data?.weight_boxes / data?.weight_net * 100 || 0, 2)}</h2>
                          <hr/>
                      </div>
                  </>}
        />
        <CardItem icon={<SwatchIcon className={"h-12 w-12  text-indigo-300 animate-pulse "}/>}
                  item={<>
                      <div className="text-gray-800  text-sm font-extrabold text-center">
                          <hr/>
                          <h2>CALIBRE</h2>
                          <hr/>
                      </div>
                      <div className="text-gray-800  mt-2 text-sm font-semibold ">
                          <h2 className={"font-light"}>CALIBRE
                              6: {Humanize.formatNumber(data?.weight_net * (getSum('c6') / data?.boxes) || 0, 2)} kg
                              | {Humanize.formatNumber((getSum('c6') / data?.boxes) * 100 || 0, 2)}% </h2>
                          <h2 className={"font-light"}>CALIBRE
                              8: {Humanize.formatNumber(data?.weight_net * (getSum('c8') / data?.boxes) || 0, 2)} kg
                              | {Humanize.formatNumber((getSum('c8') / data?.boxes) * 100 || 0, 2)}%</h2>
                          <h2 className={"font-light"}>CALIBRE
                              10: {Humanize.formatNumber(data?.weight_net * (getSum('c10') / data?.boxes) || 0, 2)} kg
                              | {Humanize.formatNumber((getSum('c10') / data?.boxes) * 100 || 0, 2)}%</h2>
                          <h2 className={"font-light"}>CALIBRE
                              12: {Humanize.formatNumber(data?.weight_net * (getSum('c12') / data?.boxes) || 0, 2)} kg
                              | {Humanize.formatNumber((getSum('c12') / data?.boxes) * 100 || 0, 2)}%</h2>
                          <h2 className={"font-light"}>CALIBRE
                              14: {Humanize.formatNumber(data?.weight_net * (getSum('c14') / data?.boxes) || 0, 2)} kg
                              | {Humanize.formatNumber((getSum('c14') / data?.boxes) * 100 || 0, 2)}%</h2>
                          <hr/>
                          <div className="text-gray-800  text-sm font-extrabold text-center">
                              <hr/>
                              <h2 className={"font-extrabold"}>TOTAL: {Humanize.formatNumber((getSum('c6') / data?.boxes) * 100 + (getSum('c8') / data?.boxes) * 100 + (getSum('c10') / data?.boxes) * 100 + (getSum('c12') / data?.boxes) * 100 + (getSum('c14') / data?.boxes) * 100 || 0, 2)} %</h2>
                              <hr/>
                          </div>
                      </div>
                  </>

                  }/>
        <CardItem icon={<EyeIcon className={"h-12 w-12  text-indigo-300 "}/>}
                  item={<>
                      <div className="text-gray-800  text-sm font-extrabold text-center">
                          <hr/>
                          <h2>OBSERVACIONES</h2>
                          <hr/>
                      </div>


                      <div className="text-gray-800  mt-2 text-sm font-semibold ">
                          <h2 className={"font-light"}>TOTAL PESO
                              NETO: {Humanize.formatNumber(data?.weight_net || 0, 2)} kg</h2>
                          <h2 className={"font-light"}>KG DESCONTADOS
                              ({Humanize.formatNumber(data?.discount_weight_percentage || 0, 2)}%): {Humanize.formatNumber(data?.weight_reject || 0, 2)} kg</h2>
                          <hr/>
                      </div>

                      <div className="text-gray-800  text-sm font-extrabold text-center">
                          <hr/>
                          <h2 className={"font-extrabold"}>TOTAL: {Humanize.formatNumber(data?.weight_usable || 0, 2)}</h2>
                          <hr/>
                      </div>
                  </>}
        />
        <CardItem icon={<TicketIcon className={"h-12 w-12  text-indigo-300 "}/>}
                  item={<>
                      <div className="text-gray-800  text-sm font-extrabold text-center">
                          <hr/>
                          <h2>Precios</h2>
                          <hr/>
                      </div>


                      <div className="text-gray-800  mt-2 text-sm font-semibold ">
                          <h2 className={"font-light"}>
                              Peso pagado: {Humanize.formatNumber(data?.weight_usable || 0, 2)} kg</h2>
                          <h2 className={"font-light"}>
                              Flete:
                              S/ {Humanize.formatNumber(parseFloat(data?.freight) +parseFloat(data?.freight_boxes) || 0, 2)} </h2>
                          <h2 className={"font-light"}>
                              Estiba: S/ {Humanize.formatNumber(data?.download_price || 0, 3)} </h2>
                          <h2 className={"font-light"}>
                              Precio campo: S/ {Humanize.formatNumber(data?.supplier_price || 0, 2)} </h2>
                          <h2 className={"font-light"}>
                              Precio descuento: S/ {Humanize.formatNumber(data?.discount_price || 0, 2)} </h2>
                          <h2 className={"font-light"}>% Descuento
                              ({Humanize.formatNumber(data?.discount_weight_percentage || 0, 2)}%): {Humanize.formatNumber(data?.weight_usable || 0, 2)} kg</h2>
                          <h2 className={"font-light"}>% Descuento S/
                              ({Humanize.formatNumber(data?.discount_price_kg/data?.weight_net*100 || 0, 2)}%): {Humanize.formatNumber(data?.discount_price_kg ||0, 2)} kg</h2>
                          <hr/>
                      </div>
                      <div className="text-gray-800  text-sm font-extrabold text-center">
                          <hr/>
                          <h2 className={"font-extrabold"}>TOTAL: {data?.total_amount} | {Humanize.formatNumber(parseFloat(data?.total_amount) / parseFloat(data?.weight_usable) || 0, 2)}</h2>
                          <hr/>
                      </div>
                  </>}
        />
    </div>);
};

const CardItem = ({icon, item}) => {
    return (<div
        className="cursor-pointer bg-white hover:shadow p-6  rounded  flex flex-col justify-center items-center ">
        <div className="mb-6">
            {icon}
        </div>
        <div className="text-gray-800  text-sm font-extrabold text-center">
            {item}
        </div>
    </div>)
}


export default CardsLot;

