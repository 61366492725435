/* Users */
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';
export const GET_DEPARTMENTS_FAIL = 'GET_DEPARTMENTS_FAIL';
export const GET_POSITIONS_SUCCESS = 'GET_POSITIONS_SUCCESS';
export const GET_POSITIONS_FAIL = 'GET_POSITIONS_FAIL';


// Alerts
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

/*Category*/
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL'
/* Stakeholders */

// Raw Material Suppliers
export const GET_RAW_MATERIAL_SUPPLIERS_SUCCESS = 'GET_RAW_MATERIAL_SUPPLIERS_SUCCESS';
export const GET_RAW_MATERIAL_SUPPLIERS_FAIL = 'GET_RAW_MATERIAL_SUPPLIERS_FAIL';
export const ADD_RAW_MATERIAL_SUPPLIER_SUCCESS = 'ADD_RAW_MATERIAL_SUPPLIER_SUCCESS';
export const ADD_RAW_MATERIAL_SUPPLIER_FAIL = 'ADD_RAW_MATERIAL_SUPPLIER_FAIL';
export const UPDATE_RAW_MATERIAL_SUPPLIER_SUCCESS = 'UPDATE_RAW_MATERIAL_SUPPLIER_SUCCESS';
export const UPDATE_RAW_MATERIAL_SUPPLIER_FAIL = 'UPDATE_RAW_MATERIAL_SUPPLIER_FAIL';
export const DELETE_RAW_MATERIAL_SUPPLIER_SUCCESS = 'DELETE_RAW_MATERIAL_SUPPLIER_SUCCESS';
export const DELETE_RAW_MATERIAL_SUPPLIER_FAIL = 'DELETE_RAW_MATERIAL_SUPPLIER_FAIL';

// Material Suppliers
export const GET_MATERIAL_SUPPLIERS_SUCCESS = 'GET_MATERIAL_SUPPLIERS_SUCCESS';
export const GET_MATERIAL_SUPPLIERS_FAIL = 'GET_MATERIAL_SUPPLIERS_FAIL';
export const ADD_MATERIAL_SUPPLIER_SUCCESS = 'ADD_MATERIAL_SUPPLIER_SUCCESS';
export const ADD_MATERIAL_SUPPLIER_FAIL = 'ADD_MATERIAL_SUPPLIER_FAIL';
export const UPDATE_MATERIAL_SUPPLIER_SUCCESS = 'UPDATE_MATERIAL_SUPPLIER_SUCCESS';
export const UPDATE_MATERIAL_SUPPLIER_FAIL = 'UPDATE_MATERIAL_SUPPLIER_FAIL';
export const DELETE_MATERIAL_SUPPLIER_SUCCESS = 'DELETE_MATERIAL_SUPPLIER_SUCCESS';
export const DELETE_MATERIAL_SUPPLIER_FAIL = 'DELETE_MATERIAL_SUPPLIER_FAIL';

// Clients
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_FAIL = 'GET_CLIENTS_FAIL';
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_FAIL = 'ADD_CLIENT_FAIL';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAIL = 'UPDATE_CLIENT_FAIL';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAIL = 'DELETE_CLIENT_FAIL';

// Transportation Companies
export const GET_TRANSPORTATION_COMPANIES_SUCCESS = 'GET_TRANSPORTATION_COMPANIES_SUCCESS';
export const GET_TRANSPORTATION_COMPANIES_FAIL = 'GET_TRANSPORTATION_COMPANIES_FAIL';
export const ADD_TRANSPORTATION_COMPANY_SUCCESS = 'ADD_TRANSPORTATION_COMPANY_SUCCESS';
export const ADD_TRANSPORTATION_COMPANY_FAIL = 'ADD_TRANSPORTATION_COMPANY_FAIL';
export const UPDATE_TRANSPORTATION_COMPANY_SUCCESS = 'UPDATE_TRANSPORTATION_COMPANY_SUCCESS';
export const UPDATE_TRANSPORTATION_COMPANY_FAIL = 'UPDATE_TRANSPORTATION_COMPANY_FAIL';
export const DELETE_TRANSPORTATION_COMPANY_SUCCESS = 'DELETE_TRANSPORTATION_COMPANY_SUCCESS';
export const DELETE_TRANSPORTATION_COMPANY_FAIL = 'DELETE_TRANSPORTATION_COMPANY_FAIL';

// Manufacturing Companies
export const GET_MANUFACTURING_COMPANIES_SUCCESS = 'GET_MANUFACTURING_COMPANIES_SUCCESS';
export const GET_MANUFACTURING_COMPANIES_FAIL = 'GET_MANUFACTURING_COMPANIES_FAIL';
export const ADD_MANUFACTURING_COMPANY_SUCCESS = 'ADD_MANUFACTURING_COMPANY_SUCCESS';
export const ADD_MANUFACTURING_COMPANY_FAIL = 'ADD_MANUFACTURING_COMPANY_FAIL';
export const UPDATE_MANUFACTURING_COMPANY_SUCCESS = 'UPDATE_MANUFACTURING_COMPANY_SUCCESS';
export const UPDATE_MANUFACTURING_COMPANY_FAIL = 'UPDATE_MANUFACTURING_COMPANY_FAIL';
export const DELETE_MANUFACTURING_COMPANY_SUCCESS = 'DELETE_MANUFACTURING_COMPANY_SUCCESS';
export const DELETE_MANUFACTURING_COMPANY_FAIL = 'DELETE_MANUFACTURING_COMPANY_FAIL';


/* SEARCH */
export const SEARCH_DNI_SUCCESS = 'SEARCH_DNI_SUCCESS';
export const SEARCH_DNI_FAIL = 'SEARCH_DNI_FAIL';

export const SEARCH_RUC_SUCCESS = 'SEARCH_RUC_SUCCESS';
export const SEARCH_RUC_FAIL = 'SEARCH_RUC_FAIL';

export const SEARCH_DOLLAR_SUCCESS = 'SEARCH_DOLLAR_SUCCESS';
export const SEARCH_DOLLAR_FAIL = 'SEARCH_DOLLAR_FAIL';


/* TALENT_HUB */
export const GET_STAFF_SUCCESS = 'GET_STAFF_SUCCESS';
export const GET_STAFF_FAIL = 'GET_STAFF_FAIL';

export const GET_DEPARTMENTS_STAFF_SUCCESS = 'GET_DEPARTMENTS_STAFF_SUCCESS';
export const GET_DEPARTMENTS_STAFF_FAIL = 'GET_DEPARTMENTS_STAFF_FAIL';

export const GET_ABSENTEEISM_SUCCESS = 'GET_ABSENTEEISM_SUCCESS';
export const GET_ABSENTEEISM_FAIL = 'GET_ABSENTEEISM_FAIL';

export const ADD_STAFF_SUCCESS = 'ADD_STAFF_SUCCESS';
export const ADD_STAFF_FAIL = 'ADD_STAFF_FAIL';

export const UPDATE_STAFF_SUCCESS = 'UPDATE_STAFF_SUCCESS';
export const UPDATE_STAFF_FAIL = 'UPDATE_STAFF_FAIL';

export const DELETE_STAFF_SUCCESS = 'DELETE_STAFF_SUCCESS';
export const DELETE_STAFF_FAIL = 'DELETE_STAFF_FAIL';


export const GET_TRACKING_SUCCESS = 'GET_TRACKING_SUCCESS';
export const GET_TRACKING_FAIL = 'GET_TRACKING_FAIL';
export const ADD_TRACKING_SUCCESS = 'ADD_TRACKING_SUCCESS';
export const ADD_TRACKING_FAIL = 'ADD_TRACKING_FAIL';
export const UPDATE_TRACKING_SUCCESS = 'UPDATE_TRACKING_SUCCESS';
export const UPDATE_TRACKING_FAIL = 'UPDATE_TRACKING_FAIL';
export const DELETE_TRACKING_SUCCESS = 'DELETE_TRACKING_SUCCESS';
export const DELETE_TRACKING_FAIL = 'DELETE_TRACKING_FAIL';

export const GET_TRACKING_REAL_SUCCESS = 'GET_TRACKING_REAL_SUCCESS';
export const GET_TRACKING_REAL_FAIL = 'GET_TRACKING_REAL_FAIL';

export const GET_DELAY_HOURS_SUCCESS = 'GET_DELAY_HOURS_SUCCESS';
export const GET_DELAY_HOURS_FAIL = 'GET_DELAY_HOURS_FAIL';
export const GET_CALENDAR_DAYS_SUCCESS = 'GET_CALENDAR_DAYS_SUCCESS';
export const GET_CALENDAR_DAYS_FAIL = 'GET_CALENDAR_DAYS_FAIL';

export const GET_SUMMARY_TRACKING_SUCCESS = 'GET_SUMMARY_TRACKING_SUCCESS';
export const GET_SUMMARY_TRACKING_FAIL = 'GET_SUMMARY_TRACKING_FAIL';

export const GET_OUTSOURCING_SUCCESS = 'GET_OUTSOURCING_SUCCESS';
export const GET_OUTSOURCING_FAIL = 'GET_OUTSOURCING_FAIL';


export const GET_STAFF_NOT_TRACKING_SUCCESS = 'GET_STAFF_NOT_TRACKING_SUCCESS';
export const GET_STAFF_NOT_TRACKING_FAIL = 'GET_STAFF_NOT_TRACKING_FAIL';

export const FIND_USER_SUCCESS = 'FIND_USER_SUCCESS';
export const FIND_USER_FAIL = 'FIND_USER_FAIL';

export const SEND_TRACKING_SUCCESS = 'SEND_TRACKING_SUCCESS';
export const SEND_TRACKING_FAIL = 'SEND_TRACKING_FAIL';




/* AGRISUPPLY */
export const GET_PARCELS_SUCCESS = 'GET_PARCELS_SUCCESS';
export const GET_PARCELS_FAIL = 'GET_PARCELS_FAIL';
export const ADD_PARCEL_SUCCESS = 'ADD_PARCEL_SUCCESS';
export const ADD_PARCEL_FAIL = 'ADD_PARCEL_FAIL';
export const UPDATE_PARCEL_SUCCESS = 'UPDATE_PARCEL_SUCCESS';
export const UPDATE_PARCEL_FAIL = 'UPDATE_PARCEL_FAIL';
export const DELETE_PARCEL_SUCCESS = 'DELETE_PARCEL_SUCCESS';
export const DELETE_PARCEL_FAIL = 'DELETE_PARCEL_FAIL';

export const GET_PROJECTIONS_SUCCESS = 'GET_PROJECTIONS_SUCCESS';
export const GET_PROJECTIONS_FAIL = 'GET_PROJECTIONS_FAIL';
export const ADD_PROJECTION_SUCCESS = 'ADD_PROJECTION_SUCCESS';
export const ADD_PROJECTION_FAIL = 'ADD_PROJECTION_FAIL';
export const UPDATE_PROJECTION_SUCCESS = 'UPDATE_PROJECTION_SUCCESS';
export const UPDATE_PROJECTION_FAIL = 'UPDATE_PROJECTION_FAIL';
export const DELETE_PROJECTION_SUCCESS = 'DELETE_PROJECTION_SUCCESS';
export const DELETE_PROJECTION_FAIL = 'DELETE_PROJECTION_FAIL';


/* LOGISTIC */
export const GET_RECORDS_SUCCESS = 'GET_RECORDS_SUCCESS';
export const GET_RECORDS_FAIL = 'GET_RECORDS_FAIL';
export const GET_EXTERNAL_PERSONS_SUCCESS = 'GET_EXTERNAL_PERSONS_SUCCESS';
export const GET_EXTERNAL_PERSONS_FAIL = 'GET_EXTERNAL_PERSONS_FAIL';
export const ADD_EXTERNAL_PERSON_SUCCESS = 'ADD_EXTERNAL_PERSON_SUCCESS';
export const ADD_EXTERNAL_PERSON_FAIL = 'ADD_EXTERNAL_PERSON_FAIL';
export const UPDATE_EXTERNAL_PERSON_SUCCESS = 'UPDATE_EXTERNAL_PERSON_SUCCESS';
export const UPDATE_EXTERNAL_PERSON_FAIL = 'UPDATE_EXTERNAL_PERSON_FAIL';
export const DELETE_EXTERNAL_PERSON_SUCCESS = 'DELETE_EXTERNAL_PERSON_SUCCESS';
export const DELETE_EXTERNAL_PERSON_FAIL = 'DELETE_EXTERNAL_PERSON_FAIL';


export const GET_LOT_SUMMARY_SUCCESS = 'GET_LOT_SUMMARY_SUCCESS';
export const GET_LOT_SUMMARY_FAIL = 'GET_LOT_SUMMARY_FAIL';
export const ADD_LOT_SUCCESS = 'ADD_LOT_SUCCESS';
export const ADD_LOT_FAIL = 'ADD_LOT_FAIL';
export const UPDATE_LOT_SUCCESS = 'UPDATE_LOT_SUCCESS';
export const UPDATE_LOT_FAIL = 'UPDATE_LOT_FAIL';
export const GET_LOT_SUCCESS = 'GET_LOT_SUCCESS';
export const GET_LOT_FAIL = 'GET_LOT_FAIL';

export const GET_DOWNLOADS_LOT_SUCCESS = 'GET_DOWNLOADS_LOT_SUCCESS';
export const GET_DOWNLOADS_LOT_FAIL = 'GET_DOWNLOADS_LOT_FAIL';
export const ADD_DOWNLOAD_LOT_SUCCESS = 'ADD_DOWNLOAD_LOT_SUCCESS';
export const ADD_DOWNLOAD_LOT_FAIL = 'ADD_DOWNLOAD_LOT_FAIL';
export const UPDATE_DOWNLOAD_LOT_SUCCESS = 'UPDATE_DOWNLOAD_LOT_SUCCESS';
export const UPDATE_DOWNLOAD_LOT_FAIL = 'UPDATE_DOWNLOAD_LOT_FAIL';
export const DELETE_DOWNLOAD_LOT_SUCCESS = 'DELETE_DOWNLOAD_LOT_SUCCESS';
export const DELETE_DOWNLOAD_LOT_FAIL = 'DELETE_DOWNLOAD_LOT_FAIL';


export const GET_FREIGHTS_SUCCESS = 'GET_FREIGHTS_SUCCESS';
export const GET_FREIGHTS_FAIL = 'GET_FREIGHTS_FAIL';

export const UPDATE_FREIGHT_SUCCESS = 'UPDATE_FREIGHT_SUCCESS';
export const UPDATE_FREIGHT_FAIL = 'UPDATE_FREIGHT_FAIL';

export const GET_GLP_SUCCESS = 'GET_GLP_SUCCESS'
export const GET_GLP_FAIL = 'GET_GLP_FAIL'

export const UPDATE_GLP_SUCCESS = 'UPDATE_GLP_SUCCESS'
export const UPDATE_GLP_FAIL = 'UPDATE_GLP_FAIL'

export const GET_PALLETS_SUCCESS = 'GET_PALLETS_SUCCESS';
export const GET_PALLETS_FAIL = 'GET_PALLETS_FAIL';


export const GET_ITEMS_LOT_SUCCESS = 'GET_ITEMS_LOT_SUCCESS';
export const GET_ITEMS_LOT_FAIL = 'GET_ITEMS_LOT_FAIL';
export const ADD_ITEM_LOT_SUCCESS = 'ADD_ITEM_LOT_SUCCESS';
export const ADD_ITEM_LOT_FAIL = 'ADD_ITEM_LOT_FAIL';
export const UPDATE_ITEM_LOT_SUCCESS = 'UPDATE_ITEM_LOT_SUCCESS';
export const UPDATE_ITEM_LOT_FAIL = 'UPDATE_ITEM_LOT_FAIL';
export const DELETE_ITEM_LOT_SUCCESS = 'DELETE_ITEM_LOT_SUCCESS';
export const DELETE_ITEM_LOT_FAIL = 'DELETE_ITEM_LOT_FAIL';

export const GET_STOCK_AVAILABLE_SUCCESS = 'GET_STOCK_AVAILABLE_SUCCESS';
export const GET_STOCK_AVAILABLE_FAIL = 'GET_STOCK_AVAILABLE_FAIL';
export const GET_BATCH_OUTPUT_SUCCESS = 'GET_BATCH_OUTPUT_SUCCESS';
export const GET_BATCH_OUTPUT_FAIL = 'GET_BATCH_OUTPUT_FAIL';
export const ADD_BATCH_OUTPUT_SUCCESS = 'ADD_BATCH_OUTPUT_SUCCESS';
export const ADD_BATCH_OUTPUT_FAIL = 'ADD_BATCH_OUTPUT_FAIL';
export const UPDATE_BATCH_OUTPUT_SUCCESS = 'UPDATE_BATCH_OUTPUT_SUCCESS';
export const UPDATE_BATCH_OUTPUT_FAIL = 'UPDATE_BATCH_OUTPUT_FAIL';
export const DELETE_BATCH_OUTPUT_SUCCESS = 'DELETE_BATCH_OUTPUT_SUCCESS';
export const DELETE_BATCH_OUTPUT_FAIL = 'DELETE_BATCH_OUTPUT_FAIL';


export const GET_ARTICLES_SUCCESS = 'GET_ARTICLES_SUCCESS';
export const GET_ARTICLES_FAIL = 'GET_ARTICLES_FAIL';
export const ADD_ARTICLE_SUCCESS = 'ADD_ARTICLE_SUCCESS';
export const ADD_ARTICLE_FAIL = 'ADD_ARTICLE_FAIL';

export const GET_ARTICLE_RECEIPTS_SUCCESS = 'GET_ARTICLE_RECEIPTS_SUCCESS';
export const GET_ARTICLE_RECEIPTS_FAIL = 'GET_ARTICLE_RECEIPTS_FAIL';
export const RECEIPT_ARTICLE_SUCCESS = 'RECEIPT_ARTICLE_SUCCESS';
export const RECEIPT_ARTICLE_FAIL = 'RECEIPT_ARTICLE_FAIL';

export const GET_RECEIPTS_BY_ARTICLE_SUCCESS = 'GET_RECEIPTS_BY_ARTICLE_SUCCESS'
export const GET_RECEIPTS_BY_ARTICLE_FAIL = 'GET_RECEIPTS_BY_ARTICLE_FAIL'
export const GET_ARTICLE_ISSUES_SUCCESS = 'GET_ARTICLE_ISSUES_SUCCESS';
export const GET_ARTICLE_ISSUES_FAIL = 'GET_ARTICLE_ISSUES_FAIL';
export const ISSUE_ARTICLE_SUCCESS = 'ISSUE_ARTICLE_SUCCESS';
export const ISSUE_ARTICLE_FAIL = 'ISSUE_ARTICLE_FAIL';

export const TRANSFER_ARTICLE_SUCCESS = 'TRANSFER_ARTICLE_SUCCESS';
export const TRANSFER_ARTICLE_FAIL = 'TRANSFER_ARTICLE_FAIL';

export const NOTIFICATION_ARTICLE_SUCCESS = 'NOTIFICATION_ARTICLE_SUCCESS';
export const NOTIFICATION_ARTICLE_FAIL = 'NOTIFICATION_ARTICLE_FAIL';

export const GET_FILES_SUCCESS = 'GET_FILES_SUCCESS';
export const GET_FILES_FAIL = 'GET_FILES_FAIL';
export const ADD_FILE_SUCCESS = 'ADD_FILE_SUCCESS';
export const ADD_FILE_FAIL = 'ADD_FILE_FAIL';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAIL = 'DELETE_FILE_FAIL';


/* Commercial */
export const GET_SAMPLES_SUCCESS = 'GET_SAMPLES_SUCCESS';
export const GET_SAMPLES_FAIL = 'GET_SAMPLES_FAIL';
export const ADD_SAMPLE_SUCCESS = 'ADD_SAMPLE_SUCCESS';
export const ADD_SAMPLE_FAIL = 'ADD_SAMPLE_FAIL';
export const UPDATE_SAMPLE_SUCCESS = 'UPDATE_SAMPLE_SUCCESS';
export const UPDATE_SAMPLE_FAIL = 'UPDATE_SAMPLE_FAIL';


/* Maintenance*/

export const GET_TECHNICALS_SUCCESS = 'GET_TECHNICALS_SUCCESS';
export const GET_TECHNICALS_FAIL = 'GET_TECHNICALS_FAIL';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const GET_RESOURCES_SUCCESS = 'GET_RESOURCES_SUCCESS';
export const GET_RESOURCES_FAIL = 'GET_RESOURCES_FAIL';
export const GET_HELPERS_SUCCESS = 'GET_HELPERS_SUCCESS';
export const GET_HELPERS_FAIL = 'GET_HELPERS_FAIL';
export const GET_ASSETS_TREE_SUCCESS = 'GET_ASSETS_TREE_SUCCESS';
export const GET_ASSETS_TREE_FAIL = 'GET_ASSETS_TREE_FAIL';

export const GET_ASSETS_FIXED_SUCCESS = 'GET_ASSETS_FIXED_SUCCESS';
export const GET_ASSETS_FIXED_FAIL = 'GET_ASSETS_FIXED_FAIL';
export const ADD_ASSET_FIXED_SUCCESS = 'ADD_ASSET_FIXED_SUCCESS';
export const ADD_ASSET_FIXED_FAIL = 'ADD_ASSET_FIXED_FAIL';
export const UPDATE_ASSET_FIXED_SUCCESS = 'UPDATE_ASSET_FIXED_SUCCESS';
export const UPDATE_ASSET_FIXED_FAIL = 'UPDATE_ASSET_FIXED_FAIL';
export const DELETE_ASSET_FIXED_SUCCESS = 'DELETE_ASSET_FIXED_SUCCESS';
export const DELETE_ASSET_FIXED_FAIL = 'DELETE_ASSET_FIXED_FAIL';

export const GET_ASSETS_PHYSICAL_SUCCESS = 'GET_ASSETS_PHYSICAL_SUCCESS';
export const GET_ASSETS_PHYSICAL_FAIL = 'GET_ASSETS_PHYSICAL_FAIL';
export const ADD_ASSET_PHYSICAL_SUCCESS = 'ADD_ASSET_PHYSICAL_SUCCESS';
export const ADD_ASSET_PHYSICAL_FAIL = 'ADD_ASSET_PHYSICAL_FAIL';
export const UPDATE_ASSET_PHYSICAL_SUCCESS = 'UPDATE_ASSET_PHYSICAL_SUCCESS';
export const UPDATE_ASSET_PHYSICAL_FAIL = 'UPDATE_ASSET_PHYSICAL_FAIL';
export const DELETE_ASSET_PHYSICAL_SUCCESS = 'DELETE_ASSET_PHYSICAL_SUCCESS';
export const DELETE_ASSET_PHYSICAL_FAIL = 'DELETE_ASSET_PHYSICAL_FAIL';

export const GET_ASSETS_TOOLS_SUCCESS = 'GET_ASSETS_TOOLS_SUCCESS';
export const GET_ASSETS_TOOLS_FAIL = 'GET_ASSETS_TOOLS_FAIL';
export const ADD_ASSET_TOOL_SUCCESS = 'ADD_ASSET_TOOL_SUCCESS';
export const ADD_ASSET_TOOL_FAIL = 'ADD_ASSET_TOOL_FAIL';
export const UPDATE_ASSET_TOOL_SUCCESS = 'UPDATE_ASSET_TOOL_SUCCESS';
export const UPDATE_ASSET_TOOL_FAIL = 'UPDATE_ASSET_TOOL_FAIL';
export const DELETE_ASSET_TOOL_SUCCESS = 'DELETE_ASSET_TOOL_SUCCESS';
export const DELETE_ASSET_TOOL_FAIL = 'DELETE_ASSET_TOOL_FAIL';


export const GET_FAILURES_SUCCESS = 'GET_FAILURES_SUCCESS';
export const GET_FAILURES_FAIL = 'GET_FAILURES_FAIL';

export const GET_MAINTENANCE_TYPES_SUCCESS = 'GET_MAINTENANCE_TYPES_SUCCESS';
export const GET_MAINTENANCE_TYPES_FAIL = 'GET_MAINTENANCE_TYPES_FAIL';

export const GET_REQUIREMENTS_SUCCESS = 'GET_REQUIREMENTS_SUCCESS';
export const GET_REQUIREMENTS_FAIL = 'GET_REQUIREMENTS_FAIL';
export const ADD_REQUIREMENT_SUCCESS = 'ADD_REQUIREMENT_SUCCESS';
export const ADD_REQUIREMENT_FAIL = 'ADD_REQUIREMENT_FAIL';
export const UPDATE_REQUIREMENT_SUCCESS = 'UPDATE_REQUIREMENT_SUCCESS';
export const UPDATE_REQUIREMENT_FAIL = 'UPDATE_REQUIREMENT_FAIL';
export const DELETE_REQUIREMENT_SUCCESS = 'DELETE_REQUIREMENT_SUCCESS';
export const DELETE_REQUIREMENT_FAIL = 'DELETE_REQUIREMENT_FAIL';


export const GET_OT_SUCCESS = 'GET_OT_SUCCESS';
export const GET_OT_FAIL = 'GET_OT_FAIL';
export const ADD_OT_SUCCESS = 'ADD_OT_SUCCESS';
export const ADD_OT_FAIL = 'ADD_OT_FAIL';
export const UPDATE_OT_SUCCESS = 'UPDATE_OT_SUCCESS';
export const UPDATE_OT_FAIL = 'UPDATE_OT_FAIL';
export const DELETE_OT_SUCCESS = 'DELETE_OT_SUCCESS';
export const DELETE_OT_FAIL = 'DELETE_OT_FAIL';
export const UPDATE_WORK_CLEAN_SUCCESS = 'UPDATE_WORK_CLEAN_SUCCESS';
export const UPDATE_WORK_CLEAN_FAIL = 'UPDATE_WORK_CLEAN_FAIL';
export const UPDATE_WORK_VALIDATE_SUCCESS = 'UPDATE_WORK_VALIDATE_SUCCESS';
export const UPDATE_WORK_VALIDATE_FAIL = 'UPDATE_WORK_VALIDATE_FAIL';
export const ADD_RESOURCE_WORK_SUCCESS = 'ADD_RESOURCE_WORK_SUCCESS';
export const ADD_RESOURCE_WORK_FAIL = 'ADD_RESOURCE_WORK_FAIL';
export const DELETE_RESOURCE_WORK_SUCCESS = 'DELETE_RESOURCE_WORK_SUCCESS';
export const DELETE_RESOURCE_WORK_FAIL = 'DELETE_RESOURCE_WORK_FAIL';
export const ADD_HELPER_WORK_SUCCESS = 'ADD_HELPER_WORK_SUCCESS';
export const ADD_HELPER_WORK_FAIL = 'ADD_HELPER_WORK_FAIL';
export const DELETE_HELPER_WORK_SUCCESS = 'DELETE_HELPER_WORK_SUCCESS';
export const DELETE_HELPER_WORK_FAIL = 'DELETE_HELPER_WORK_FAIL';

export const GET_H2O_SUCCESS = 'GET_H2O_SUCCESS';
export const GET_H2O_FAIL = 'GET_H2O_FAIL';
export const UPDATE_H2O_SUCCESS = 'UPDATE_H2O_SUCCESS';
export const UPDATE_H2O_FAIL = 'UPDATE_H2O_FAIL';

export const GET_CHLORINE_SUCCESS = 'GET_CHLORINE_SUCCESS';
export const GET_CHLORINE_FAIL = 'GET_CHLORINE_FAIL';
export const UPDATE_CHLORINE_SUCCESS = 'UPDATE_CHLORINE_SUCCESS';
export const UPDATE_CHLORINE_FAIL = 'UPDATE_CHLORINE_FAIL';



// Production

export const GET_CONDITIONING_PINEAPPLE_LOT_SUCCESS = 'GET_CONDITIONING_PINEAPPLE_LOT_SUCCESS';
export const GET_CONDITIONING_PINEAPPLE_LOT_FAIL = 'GET_CONDITIONING_PINEAPPLE_LOT_FAIL';
export const GET_CONDITIONING_PINEAPPLE_SUCCESS = 'GET_CONDITIONING_PINEAPPLE_SUCCESS';
export const GET_CONDITIONING_PINEAPPLE_FAIL = 'GET_CONDITIONING_PINEAPPLE_FAIL';
export const ADD_CONDITIONING_PINEAPPLE_SUCCESS = 'ADD_CONDITIONING_PINEAPPLE_SUCCESS';
export const ADD_CONDITIONING_PINEAPPLE_FAIL = 'ADD_CONDITIONING_PINEAPPLE_FAIL';
export const UPDATE_CONDITIONING_PINEAPPLE_SUCCESS = 'UPDATE_CONDITIONING_PINEAPPLE_SUCCESS';
export const UPDATE_CONDITIONING_PINEAPPLE_FAIL = 'UPDATE_CONDITIONING_PINEAPPLE_FAIL';
export const DELETE_CONDITIONING_PINEAPPLE_SUCCESS = 'DELETE_CONDITIONING_PINEAPPLE_SUCCESS';
export const DELETE_CONDITIONING_PINEAPPLE_FAIL = 'DELETE_CONDITIONING_PINEAPPLE_FAIL';


export const GET_CONDITIONING_SWEET_POTATO_LOT_SUCCESS = 'GET_CONDITIONING_SWEET_POTATO_LOT_SUCCESS';
export const GET_CONDITIONING_SWEET_POTATO_LOT_FAIL = 'GET_CONDITIONING_SWEET_POTATO_LOT_FAIL';
export const GET_CONDITIONING_SWEET_POTATO_SUCCESS = 'GET_CONDITIONING_SWEET_POTATO_SUCCESS';
export const GET_CONDITIONING_SWEET_POTATO_FAIL = 'GET_CONDITIONING_SWEET_POTATO_FAIL';
export const ADD_CONDITIONING_SWEET_POTATO_SUCCESS = 'ADD_CONDITIONING_SWEET_POTATO_SUCCESS';
export const ADD_CONDITIONING_SWEET_POTATO_FAIL = 'ADD_CONDITIONING_SWEET_POTATO_FAIL';
export const UPDATE_CONDITIONING_SWEET_POTATO_SUCCESS = 'UPDATE_CONDITIONING_SWEET_POTATO_SUCCESS';
export const UPDATE_CONDITIONING_SWEET_POTATO_FAIL = 'UPDATE_CONDITIONING_SWEET_POTATO_FAIL';
export const DELETE_CONDITIONING_SWEET_POTATO_SUCCESS = 'DELETE_CONDITIONING_SWEET_POTATO_SUCCESS';
export const DELETE_CONDITIONING_SWEET_POTATO_FAIL = 'DELETE_CONDITIONING_SWEET_POTATO_FAIL';


export const GET_THUMBNAILS_SUCCESS = 'GET_THUMBNAILS_SUCCESS';
export const GET_THUMBNAILS_FAIL = 'GET_THUMBNAILS_FAIL';
export const ADD_THUMBNAIL_SUCCESS = 'ADD_THUMBNAIL_SUCCESS';
export const ADD_THUMBNAIL_FAIL = 'ADD_THUMBNAIL_FAIL';
export const DELETE_THUMBNAIL_SUCCESS = 'DELETE_THUMBNAIL_SUCCESS';
export const DELETE_THUMBNAIL_FAIL = 'DELETE_THUMBNAIL_FAIL';

export const GET_CUTS_SUCCESS = 'GET_CUTS_SUCCESS';
export const GET_CUTS_FAIL = 'GET_CUTS_FAIL';

export const GET_PACKING_LOT_SUCCESS = 'GET_PACKING_LOT_SUCCESS';
export const GET_PACKING_LOT_FAIL = 'GET_PACKING_LOT_FAIL';
export const ADD_PACKING_LOT_SUCCESS = 'ADD_PACKING_LOT_SUCCESS';
export const ADD_PACKING_LOT_FAIL = 'ADD_PACKING_LOT_FAIL';
export const UPDATE_PACKING_LOT_SUCCESS = 'UPDATE_PACKING_LOT_SUCCESS';
export const UPDATE_PACKING_LOT_FAIL = 'UPDATE_PACKING_LOT_FAIL';


/*AUTH*/
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const REMOVE_AUTH_LOADING = 'REMOVE_AUTH_LOADING';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
export const REFRESH_FAIL = 'REFRESH_FAIL';
export const LOGOUT = 'LOGOUT';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_CONFIRM_SUCCESS = 'RESET_PASSWORD_CONFIRM_SUCCESS';
export const RESET_PASSWORD_CONFIRM_FAIL = 'RESET_PASSWORD_CONFIRM_FAIL';

// QUALITY
export const GET_ANALYSIS_SUCCESS = 'GET_ANALYSIS_SUCCESS';
export const GET_ANALYSIS_FAIL = 'GET_ANALYSIS_FAIL';

export const GET_ANALYSIS_LOT_SUCCESS = 'GET_ANALYSIS_LOT_SUCCESS';
export const GET_ANALYSIS_LOT_FAIL = 'GET_ANALYSIS_LOT_FAIL';
