import React from 'react';
import Cards from "./Card";

const Board = ({view, data,handleUpdate,params}) => {
    return (<div className="flex justify-center ">
        <div className="w-full   max-h-screen overflow-y-scroll scrollbar-hide">
            <div
                className={"flex md:justify-between flex-col lg:flex-row py-2 justify-center items-center bg-white p-4 rounded-lg  "}>
                <h1 className="text-2xl font-bold text-gray-800 "> Tablero de
                    muestras ({data.length})</h1>

            </div>

            <div className="grid lg:grid-cols-4  gap-4 h-96 overflow-y-auto scrollbar-hide">
                <div className="p-4 w-full lg:border-r-2">
                    <h2 className="text-sm font-semibold mb-4 text-black">Tareas pendientes</h2>
                    <div className={"max-h-40 lg:max-h-96 overflow-y-scroll scrollbar-hide w-full"}>

                        {data && data.filter(item => item.status === 'A').map(item => (<Cards
                            view={view}
                            key={item.id}
                            update={handleUpdate}
                            data={item}
                            params={params}
                        />))}
                    </div>

                </div>
                <div className="p-4 w-full lg:border-r-2">
                    <h2 className="text-sm font-semibold mb-4 text-black">En progreso</h2>
                    <div className={"max-h-40 lg:max-h-96 overflow-y-scroll scrollbar-hide w-full"}>
                        {data && data
                            .filter(item => item.status === 'DP' || item.status === 'WD')
                            .map(item => (<Cards
                                view={view}
                                key={item.id}
                                update={handleUpdate}
                                data={item}
                                params={params}
                            />))}

                    </div>
                </div>
                <div className="p-4 w-full lg:border-r-2">
                    <h2 className="text-sm font-semibold mb-4 text-black">En ruta</h2>
                    <div className={"max-h-40 lg:max-h-96 overflow-y-scroll scrollbar-hide w-full"}>
                        {data && data
                            .filter(item => item.status === 'ST' || item.status === 'RL' || item.status === 'SC')
                            .map(item => (<Cards
                                update={handleUpdate}
                                view={view}
                                key={item.id}
                                params={params}
                                data={item}
                            />))}
                    </div>
                </div>
                <div className="p-4 w-full ">
                    <h2 className="text-sm font-semibold mb-4 text-black">Finalizado</h2>
                    <div className={"max-h-40 lg:max-h-96 overflow-y-scroll scrollbar-hide w-full"}>
                        {data && data
                            .filter(item => item.status === 'STC' || item.status === 'ND' || item.status === 'R' || item.status === 'C')
                            .map(item => (<Cards
                                    key={item.id}
                                    update={handleUpdate}
                                    view={view}
                                    data={item}
                                    params={params}
                                />))}
                    </div>
                </div>
            </div>
        </div>
    </div>);
};

export default Board;