import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";

const TableParcels = ({data, handleUpdate, handleDelete}) => {
    const columns = ['', 'Status', 'Parcela', 'Cultivo', 'Variedad', 'Proveedor', 'Sector y zona', 'Coordenadas', 'Fosetil', 'Pesticidad']
    return (<TableBase columns={columns} cell={Row(data, handleUpdate, handleDelete)}/>);

};

const Row = (data, update, remove) => (data && data.map((item, index) => {
    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={<div className={"font-bold flex items-center whitespace-nowrap"}>
            <PencilSquareIcon onClick={() => update(item)} title={"Editar"}
                              className={"h-5 w-5 mr-2 text-blue-500 cursor-pointer"}/>
            <TrashIcon onClick={() => remove(item?.id)} title={"Eliminar"}
                       className={"h-5 w-5 mr-2 text-red-500 cursor-pointer"}/>

        </div>}/>
        <Cell text={<div className={"flex items-center"}>
            <div className={`w-full p-2 rounded-2xl  ${item?.status === 'P' ? 'bg-red-300' : 'bg-green-300'}`}>
                <p className={"text-white font-bold text-center"}>{item?.status === 'P' ? 'Presencia' : 'Limpio'}</p>
            </div>
        </div>}/>
        <Cell text={item?.name}/>
        <Cell text={item?.product_name}/>
        <Cell text={item?.variety}/>
        <Cell text={item?.provider_name}/>
        <Cell text={item?.sector}/>
        <Cell text={item?.latitude + " " + item?.longitude}/>
        <Cell text={item?.fosetil}/>
        <Cell text={item?.pesticide}/>

    </tr>)
}));

export default TableParcels;
