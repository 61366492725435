import axios from "axios";
import {
    ADD_ARTICLE_FAIL,
    ADD_ARTICLE_SUCCESS,
    ADD_BATCH_OUTPUT_FAIL,
    ADD_BATCH_OUTPUT_SUCCESS,
    ADD_DOWNLOAD_LOT_FAIL,
    ADD_DOWNLOAD_LOT_SUCCESS,
    ADD_EXTERNAL_PERSON_FAIL,
    ADD_EXTERNAL_PERSON_SUCCESS,
    ADD_FILE_FAIL,
    ADD_FILE_SUCCESS,
    ADD_ITEM_LOT_FAIL,
    ADD_ITEM_LOT_SUCCESS,
    ADD_LOT_FAIL,
    ADD_LOT_SUCCESS,
    DELETE_BATCH_OUTPUT_FAIL,
    DELETE_BATCH_OUTPUT_SUCCESS,
    DELETE_DOWNLOAD_LOT_FAIL,
    DELETE_DOWNLOAD_LOT_SUCCESS,
    DELETE_EXTERNAL_PERSON_FAIL,
    DELETE_EXTERNAL_PERSON_SUCCESS,
    DELETE_FILE_FAIL,
    DELETE_FILE_SUCCESS,
    DELETE_ITEM_LOT_FAIL,
    DELETE_ITEM_LOT_SUCCESS,
    GET_ARTICLE_ISSUES_FAIL,
    GET_ARTICLE_ISSUES_SUCCESS,
    GET_ARTICLE_RECEIPTS_FAIL,
    GET_ARTICLE_RECEIPTS_SUCCESS,
    GET_ARTICLES_FAIL,
    GET_ARTICLES_SUCCESS,
    GET_BATCH_OUTPUT_FAIL,
    GET_BATCH_OUTPUT_SUCCESS,
    GET_DOWNLOADS_LOT_FAIL,
    GET_DOWNLOADS_LOT_SUCCESS,
    GET_EXTERNAL_PERSONS_FAIL,
    GET_EXTERNAL_PERSONS_SUCCESS,
    GET_FILES_FAIL,
    GET_FILES_SUCCESS,
    GET_FREIGHTS_FAIL,
    GET_FREIGHTS_SUCCESS,
    GET_GLP_FAIL,
    GET_GLP_SUCCESS,
    GET_ITEMS_LOT_FAIL,
    GET_ITEMS_LOT_SUCCESS,
    GET_LOT_FAIL,
    GET_LOT_SUCCESS,
    GET_LOT_SUMMARY_FAIL,
    GET_LOT_SUMMARY_SUCCESS,
    GET_PALLETS_FAIL,
    GET_PALLETS_SUCCESS,
    GET_RECEIPTS_BY_ARTICLE_FAIL,
    GET_RECEIPTS_BY_ARTICLE_SUCCESS,
    GET_RECORDS_FAIL,
    GET_RECORDS_SUCCESS,
    GET_STOCK_AVAILABLE_FAIL,
    GET_STOCK_AVAILABLE_SUCCESS,
    ISSUE_ARTICLE_FAIL,
    ISSUE_ARTICLE_SUCCESS,
    NOTIFICATION_ARTICLE_FAIL,
    NOTIFICATION_ARTICLE_SUCCESS,
    RECEIPT_ARTICLE_FAIL,
    RECEIPT_ARTICLE_SUCCESS,
    TRANSFER_ARTICLE_FAIL,
    TRANSFER_ARTICLE_SUCCESS,
    UPDATE_BATCH_OUTPUT_FAIL,
    UPDATE_BATCH_OUTPUT_SUCCESS,
    UPDATE_DOWNLOAD_LOT_FAIL,
    UPDATE_DOWNLOAD_LOT_SUCCESS,
    UPDATE_EXTERNAL_PERSON_SUCCESS,
    UPDATE_FREIGHT_FAIL,
    UPDATE_FREIGHT_SUCCESS,
    UPDATE_GLP_FAIL,
    UPDATE_GLP_SUCCESS,
    UPDATE_ITEM_LOT_FAIL,
    UPDATE_ITEM_LOT_SUCCESS,
    UPDATE_LOT_FAIL,
    UPDATE_LOT_SUCCESS
} from "./types";
import {setAlert} from "./alert";

export const getExternalPersons = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/logistic/external-person/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_EXTERNAL_PERSONS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_EXTERNAL_PERSONS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_EXTERNAL_PERSONS_FAIL
        });
    }
}
export const addExternalPerson = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/logistic/external-person/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_EXTERNAL_PERSON_SUCCESS, payload: res.data
            });
            dispatch(getExternalPersons(params))
            dispatch(setAlert(res.data.message || 'Externo creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_EXTERNAL_PERSON_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_EXTERNAL_PERSON_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_EXTERNAL_PERSON_FAIL
            });
        }
    }
};
export const updateExternalPerson = (pk, form, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/logistic/external-person/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_EXTERNAL_PERSON_SUCCESS, payload: res.data
            });
            dispatch(getExternalPersons(params))
            dispatch(setAlert(res.data.message || 'Externo actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_EXTERNAL_PERSON_SUCCESS
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_EXTERNAL_PERSON_SUCCESS
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_EXTERNAL_PERSON_SUCCESS
            });
        }
    }
};
export const deleteExternalPerson = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/logistic/external-person/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_EXTERNAL_PERSON_SUCCESS, payload: res.data
            });
            dispatch(getExternalPersons(params))
            dispatch(setAlert(res.data.message || 'Externo eliminado con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_EXTERNAL_PERSON_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_EXTERNAL_PERSON_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_EXTERNAL_PERSON_FAIL
            });
        }
    }
};


/* LOT */
export const getLots = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/logistic/lot/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_LOT_SUMMARY_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_LOT_SUMMARY_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_LOT_SUMMARY_FAIL
        });
    }
}
export const addLot = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/logistic/lot/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_LOT_SUCCESS, payload: res.data
            });
            dispatch(getLots(params))
            dispatch(setAlert(res.data.message || 'Lote creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_LOT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_LOT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_LOT_FAIL
            });
        }
    }
};

export const getLot = (lot) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/logistic/lot/${lot}/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_LOT_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_LOT_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_LOT_FAIL
        });
    }
}

export const updateLot = (pk, form, lot) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/logistic/lot/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_LOT_SUCCESS, payload: res.data
            });
            dispatch(getLot(lot))
            dispatch(setAlert(res.data.message || 'Lote actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_LOT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al actualizar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_LOT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_LOT_FAIL
            });
        }
    }
};


/* DOWNLOAD LOT */
export const getDownloadsLot = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/logistic/download-lot/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_DOWNLOADS_LOT_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_DOWNLOADS_LOT_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_DOWNLOADS_LOT_FAIL
        });
    }
}
export const addDownloadLot = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/logistic/download-lot/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_DOWNLOAD_LOT_SUCCESS, payload: res.data
            });
            dispatch(getDownloadsLot(params))
            dispatch(setAlert(res.data.message || 'Registro creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_DOWNLOAD_LOT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_DOWNLOAD_LOT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_DOWNLOAD_LOT_FAIL
            });
        }
    }
};
export const updateDownloadLot = (pk, form, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }

    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/logistic/download-lot/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_DOWNLOAD_LOT_SUCCESS, payload: res.data
            });
            dispatch(getDownloadsLot(params))
            dispatch(setAlert(res.data.message || 'Registro actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_DOWNLOAD_LOT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_DOWNLOAD_LOT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_DOWNLOAD_LOT_FAIL
            });
        }
    }
};
export const deleteDownloadLot = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/logistic/download-lot/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_DOWNLOAD_LOT_SUCCESS, payload: res.data
            });
            dispatch(getDownloadsLot(params))
            dispatch(setAlert(res.data.message || 'Registro eliminado con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_DOWNLOAD_LOT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_DOWNLOAD_LOT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_DOWNLOAD_LOT_FAIL
            });
        }
    }
};

/*Freight*/
export const getFreights = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/logistic/freight/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_FREIGHTS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_FREIGHTS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_FREIGHTS_FAIL
        });
    }
}


export const updateFreight = (pk, form, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/logistic/freight/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_FREIGHT_SUCCESS, payload: res.data
            });
            dispatch(getFreights(params))
            dispatch(setAlert(res.data.message || 'Registro actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_FREIGHT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_FREIGHT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_FREIGHT_FAIL
            });
        }
    }
};


/*GLP*/
export const getGLP = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/logistic/glp/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_GLP_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_GLP_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_GLP_FAIL
        });
    }
}


export const updateGLP = (pk, form, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/logistic/glp/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_GLP_SUCCESS, payload: res.data
            });
            dispatch(getGLP(params))
            dispatch(setAlert(res.data.message || 'Registro actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_GLP_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al actualizar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_GLP_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_GLP_FAIL
            });
        }
    }
};
export const getPallets = () => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/logistic/pallet/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_PALLETS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_PALLETS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_PALLETS_FAIL
        });
    }
}


export const getItemsLot = (lot) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {lot: lot}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/logistic/items-lot/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_ITEMS_LOT_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_ITEMS_LOT_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_ITEMS_LOT_FAIL
        });
    }
}
export const addItemLot = (form, lot) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/logistic/items-lot/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_ITEM_LOT_SUCCESS, payload: res.data
            });
            dispatch(getLot(lot))
            dispatch(getItemsLot(lot))
            dispatch(setAlert(res.data.message || 'Item creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_ITEM_LOT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_ITEM_LOT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_ITEM_LOT_FAIL
            });
        }
    }
};
export const updateItemLot = (pk, form, lot) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/logistic/items-lot/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_ITEM_LOT_SUCCESS, payload: res.data
            });
            dispatch(getItemsLot(lot))
            dispatch(getLot(lot))
            dispatch(setAlert(res.data.message || 'Item actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_ITEM_LOT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_ITEM_LOT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_ITEM_LOT_FAIL
            });
        }
    }
};
export const deleteItemLot = (pk, lot) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/logistic/items-lot/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_ITEM_LOT_SUCCESS, payload: res.data
            });
            dispatch(getItemsLot(lot))
            dispatch(getLot(lot))
            dispatch(setAlert(res.data.message || 'Item eliminado con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_ITEM_LOT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_ITEM_LOT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_ITEM_LOT_FAIL
            });
        }
    }
};


export const getStockAvailable = () => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/logistic/stock-available/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_STOCK_AVAILABLE_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_STOCK_AVAILABLE_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_STOCK_AVAILABLE_FAIL
        });
    }
}
export const getBatchLot = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/logistic/output/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_BATCH_OUTPUT_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_BATCH_OUTPUT_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_BATCH_OUTPUT_FAIL
        });
    }
}

export const addBatchOutput = (form, lot) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/logistic/output/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_BATCH_OUTPUT_SUCCESS, payload: res.data
            });
            dispatch(getBatchLot())
            dispatch(getStockAvailable())
            dispatch(setAlert(res.data.message || 'Salida creada con exito', 'success'));
        } else {
            dispatch({
                type: ADD_BATCH_OUTPUT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_BATCH_OUTPUT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_BATCH_OUTPUT_FAIL
            });
        }
    }
};
export const updateBatchOutput = (pk, form, lot) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/logistic/output/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_BATCH_OUTPUT_SUCCESS, payload: res.data
            });
            dispatch(getBatchLot())
            dispatch(getStockAvailable())
            dispatch(setAlert(res.data.message || 'Salida actualizada con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_BATCH_OUTPUT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_BATCH_OUTPUT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_BATCH_OUTPUT_FAIL
            });
        }
    }
};
export const deleteBatchOutput = (pk, lot) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/logistic/output/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_BATCH_OUTPUT_SUCCESS, payload: res.data
            });
            dispatch(getBatchLot())
            dispatch(getStockAvailable())
            dispatch(setAlert(res.data.message || 'Salida eliminada con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_BATCH_OUTPUT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_BATCH_OUTPUT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_BATCH_OUTPUT_FAIL
            });
        }
    }
};


export const getArticles = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/logistic/store/article/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_ARTICLES_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_ARTICLES_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_ARTICLES_FAIL
        });
    }
}

export const addArticle = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/logistic/store/article/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_ARTICLE_SUCCESS, payload: res.data
            });
            dispatch(getArticles(params))
            dispatch(setAlert(res.data.message || 'Artículo creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_ARTICLE_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_ARTICLE_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_ARTICLE_FAIL
            });
        }
    }
};


export const getReceipts = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/logistic/store/article/receipt/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_ARTICLE_RECEIPTS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_ARTICLE_RECEIPTS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_ARTICLE_RECEIPTS_FAIL
        });
    }
}

export const addReceiptArticle = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/logistic/store/article/receipt/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: RECEIPT_ARTICLE_SUCCESS, payload: res.data
            });
            dispatch(getArticles(params))
            dispatch(setAlert(res.data.message || 'Ingreso creado con exito', 'success'));
        } else {
            dispatch({
                type: RECEIPT_ARTICLE_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: RECEIPT_ARTICLE_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: RECEIPT_ARTICLE_FAIL
            });
        }
    }
};
export const getReceiptsByArticles = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {'manufacturing': params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/logistic/store/article/${pk}/receipt/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_RECEIPTS_BY_ARTICLE_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_RECEIPTS_BY_ARTICLE_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_RECEIPTS_BY_ARTICLE_FAIL
        });
    }
}

export const getIssues = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/logistic/store/article/issue/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_ARTICLE_ISSUES_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_ARTICLE_ISSUES_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_ARTICLE_ISSUES_FAIL
        });
    }
}

export const addIssueArticle = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/logistic/store/article/issue/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ISSUE_ARTICLE_SUCCESS, payload: res.data
            });
            dispatch(getArticles(params))
            dispatch(setAlert(res.data.message || 'Salida creada con exito', 'success'));
        } else {
            dispatch({
                type: ISSUE_ARTICLE_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ISSUE_ARTICLE_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ISSUE_ARTICLE_FAIL
            });
        }
    }
};

export const transferArticle = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/logistic/store/article/transfer/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: TRANSFER_ARTICLE_SUCCESS, payload: res.data
            });
            dispatch(getArticles(params))
            dispatch(setAlert(res.data.message || 'Transferencia creada con exito', 'success'));
        } else {
            dispatch({
                type: TRANSFER_ARTICLE_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: TRANSFER_ARTICLE_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: TRANSFER_ARTICLE_FAIL
            });
        }
    }
};

export const notifyArticle = (form) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/logistic/store/notify/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: NOTIFICATION_ARTICLE_SUCCESS, payload: res.data
            });
            dispatch(setAlert(res.data.message || 'Solicitud enviada', 'success'));
        } else {
            dispatch({
                type: NOTIFICATION_ARTICLE_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la solicitud.', 'error'));

        }
    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al enviar solicitud.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: NOTIFICATION_ARTICLE_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: NOTIFICATION_ARTICLE_FAIL
            });
        }
    }
}


export const getDocumentLot = (lot) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/logistic/document/${lot}/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_FILES_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_FILES_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_FILES_FAIL
        });
    }
}
export const addDocument = (form, lot) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/logistic/document/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_FILE_SUCCESS, payload: res.data
            });
            dispatch(getDocumentLot(lot))
            dispatch(setAlert(res.data.message || 'Documento añadido con exito', 'success'));
        } else {
            dispatch({
                type: ADD_FILE_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_FILE_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_FILE_FAIL
            });
        }
    }
};

export const deleteDocument = (pk, lot) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/logistic/document/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_FILE_SUCCESS, payload: res.data
            });
            dispatch(getDocumentLot(lot))
            dispatch(setAlert(res.data.message || 'Documento eliminado con exito.', 'success'));
        } else {
            dispatch({
                type: DELETE_FILE_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_FILE_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_FILE_FAIL
            });
        }
    }
};

export const getRecords = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/logistic/records/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_RECORDS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_RECORDS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_RECORDS_FAIL
        });
    }
}
