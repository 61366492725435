import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {useSelector} from "react-redux";

const TableSummary = () => {
    const data = useSelector(state => state.TalentHub.summary);
    const columns = ['Nombre', 'Horas Extras 25%', 'Horas Extras 35%', 'Horas Extras', 'Horas Noche', 'Días Trabajados', 'Vacaciones', 'Descanso Médico', 'Inasistencia', 'Licencia Sin Gose de Haber', 'Descanso', 'Total Días']
    return (<TableBase columns={columns} cell={Row(data)}/>);
};

const Row = (data) => (data && data.map((item, index) => {

    function calculateTotalTime(timeStrings) {
        const totalSeconds = timeStrings.reduce((total, timeString) => {
            if (timeString) {
                const [hours, minutes, seconds] = timeString.split(':').map(Number);
                return total + hours * 3600 + minutes * 60 + seconds;
            }
            return total;
        }, 0);

        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
    }

    return (<tr key={index} className=" border-gray-300  border-b">
        <Cell classes={"py-0"} text={<p className={"font-medium"}>{item?.user}</p>}/>
        <Cell classes={"py-0"}  text={<p className={"font-normal"}>{item?.overtime_25}</p>}/>
        <Cell classes={"py-0"}  text={<p className={"font-normal"}>{item?.overtime_35}</p>}/>
        <Cell classes={"py-0"}  text={<p className={"font-bold"}>{calculateTotalTime([item?.overtime_25,item?.overtime_35])}</p>}/>
        <Cell classes={"py-0"}  text={<p className={"font-normal"}>{item?.total_worked_night}</p>}/>
        <Cell classes={"py-0"}  text={<p className={"font-normal text-center"}>{item?.total_days_worked}</p>}/>
        <Cell classes={"py-0 px-0"}  text={<div className={`w-full ${item?.vacaciones !== 0 && 'bg-green-900 bg-opacity-50 text-white'}  p-2 text-center font-bold `}>
            <p>{item?.vacaciones}</p>
        </div>}/>
        <Cell classes={"py-0 px-0"}  text={<div className={`w-full ${item?.descanso_medico !== 0 && 'bg-[#a6bdc5] text-white'}  p-2 text-center font-bold `}>
            <p>{item?.descanso_medico}</p>
        </div>}/>
        <Cell classes={"py-0 px-0"}  text={<div className={`w-full ${item?.inasistencia !== 0 && 'bg-[#f9b4b4] text-white'}  p-2 text-center font-bold `}>
            <p>{item?.inasistencia}</p>
        </div>}/><Cell classes={"py-0 px-0"}  text={<div className={`w-full ${item?.licencia_sin_gose_de_haber !== 0 && 'bg-[#c7c8c7] text-white'}  p-2 text-center font-bold `}>
            <p>{item?.licencia_sin_gose_de_haber}</p>
        </div>}/>
        <Cell classes={"py-0"}  text={<p className={"font-bold text-center"}>{item?.descanso_semanal}</p>}/>
        <Cell classes={"py-0"}  text={<p className={"font-bold text-center"}>{item?.total_days_worked}</p>}/>
    </tr>)
}));

export default TableSummary;
