import React, {useEffect, useState} from 'react';
import Layout from "../../../../hocs/Layout";
import Modal from "../../../../components/util/Modal";
import ButtonHelper from "../../../../components/util/ButtonHelper";
import {useDispatch, useSelector} from "react-redux";
import ModalHook from "../../../../hooks/ModalHook";
import {getSamples} from "../../../../redux/actions/commercial";
import Form from "../../../../components/e2e/Commercial/Form";
import Board from "../../../../components/e2e/Commercial/Boards";
import FilterSample from "../../../../components/e2e/Commercial/FilterSample";
import DocumentRequest from "../../../../components/e2e/Commercial/Document";
import HeaderForm from "../../../../components/util/HeaderForm";
import {Helmet} from "react-helmet";

const Sample = () => {
    const [params, setParams] = useState({client: '', month: '', year: ''})
    const dispatch = useDispatch();
    const {content, setContent, isOpen, openModal} = ModalHook();
    const data = useSelector(state => state.Commercial.samples);

    useEffect(() => {
        setParams({...params, year: new Date().getFullYear()})
        dispatch(getSamples({year: new Date().getFullYear()}));
    }, [dispatch]);

    const handleSearch = () => {
        dispatch(getSamples(params));
    }

    const handleAdd = () => {
        const formComponent = <Form onClose={openModal} params={params}/>;
        setContent(formComponent);
        openModal();
    };

    const handleView = (data) => {
        const formComponent = <div className={"w-full h-screen"}>
            <HeaderForm close={openModal}/>
            <DocumentRequest data={data}/>
        </div>
        setContent(formComponent);
        openModal();
    };

    const handleUpdate = (data) => {
        const formComponent = <Form onClose={openModal} data={data} params={params}/>;
        setContent(formComponent);
        openModal();
    };


    return (<Layout>
        <Helmet>
            <title>Muestras</title>
        </Helmet>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <div className={"bg-white rounded-2xl p-4 h-fit"}>
            <ButtonHelper handle={handleAdd}/>
            <div className={"flex justify-end "}>
                <FilterSample setParams={setParams} handleSearch={handleSearch} params={params}/>
            </div>

        </div>
        <Board view={handleView} params={params} handleUpdate={handleUpdate} handleFilter={handleSearch}
               data={data ? data : []}/>


    </Layout>);
};

export default Sample;