import React, {useEffect, useState} from 'react';
import Layout from "../../../hocs/Layout";
import Summary from "../../../components/e2e/Home/Summary";
import {InputSearch, SelectSearch} from "../../../components/util/Filters";
import ButtonHelper from "../../../components/util/ButtonHelper";
import {getManufacturingCompanies, getRawMaterialSuppliers} from "../../../redux/actions/stakeholders";
import {useDispatch, useSelector} from "react-redux";
import ModalHook from "../../../hooks/ModalHook";
import Modal from "../../../components/util/Modal";
import Table from "../../../components/e2e/Home/Table";
import FormSummaryLot from "../../../components/e2e/Logistic/FormLotSummary";
import {get_categories} from "../../../redux/actions/category";
import {getLots} from "../../../redux/actions/logistic";
import {Helmet} from "react-helmet";

const Home = () => {
    const {content, setContent, isOpen, openModal} = ModalHook();
    const [params, setParams] = useState({lot: '',})
    const data = useSelector(state => state.Logistic.lots);
    const manufacturingCompanies = useSelector(state => state.Stakeholders.manufacturing_companies);
    const dispatch = useDispatch();

    const handleAdd = () => {
        const formComponent = <FormSummaryLot onClose={openModal} params={params}/>;
        setContent(formComponent);
        openModal();
    };


    useEffect(() => {
        dispatch(getLots(params))
        dispatch(get_categories())
        dispatch(getRawMaterialSuppliers())
        dispatch(getManufacturingCompanies())
    }, [dispatch]);

    const handleSearch = () => {
        dispatch(getLots(params))
    }

    return (<Layout>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <Helmet>
            <title>Dashboard</title>
        </Helmet>

        <div className={"flex gap-4 w-full p-4 flex-col h-full  items-center overflow-scroll scrollbar-hide "}>
            <ButtonHelper handle={handleAdd}/>
            <Summary/>
            <div className={"flex flex-col md:flex-row gap-4 w-full justify-start"}>
                <InputSearch name={"Lote"} value={params.lot} handle={(e) => setParams({'lot': e.target.value})}
                         handleSearch={handleSearch}/>
                <SelectSearch handleSearch={handleSearch} name={"Empresa"} value={params.company} handle={(e) => setParams({'company': e.target.value})}
                          items={manufacturingCompanies?[{id:'',name:'Todo'},...manufacturingCompanies]:[]} field={'name'} />
            </div>

            <div className="h-96 overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                <Table data={data}/>
            </div>

        </div>

    </Layout>);
};


export default Home;
