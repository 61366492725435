import axios from "axios";
import {
    ADD_SAMPLE_FAIL,
    ADD_SAMPLE_SUCCESS,
    GET_SAMPLES_FAIL,
    GET_SAMPLES_SUCCESS,
    UPDATE_SAMPLE_FAIL,
    UPDATE_SAMPLE_SUCCESS,
} from "./types";
import {setAlert} from "./alert";

/* Samples */
export const getSamples = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
        params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/commercial/samples/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_SAMPLES_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_SAMPLES_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_SAMPLES_FAIL
        });
    }
}
export const addSample = (form,params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',  'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/commercial/samples/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_SAMPLE_SUCCESS, payload: res.data
            });
            dispatch(getSamples(params))
            dispatch(setAlert(res.data.message || 'Muestra creada con exito', 'success'));
        } else {
            dispatch({
                type: ADD_SAMPLE_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_SAMPLE_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_SAMPLE_FAIL
            });
        }
    }
};
export const updateSample = (form,pk, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',  'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/commercial/samples/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_SAMPLE_SUCCESS, payload: res.data
            });
            dispatch(getSamples(params))
            dispatch(setAlert(res.data.message || 'Muestra actualizada con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_SAMPLE_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_SAMPLE_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_SAMPLE_FAIL
            });
        }
    }
};