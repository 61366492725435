import React from 'react';
import {Document, Image, Page, PDFViewer, StyleSheet, Text, View} from "@react-pdf/renderer";
import Logo from '../../../assets/logo.png'
import Humanize from "humanize-plus";
import {formatDate} from "../../util/helpers";
import {useSelector} from "react-redux";

const styles = StyleSheet.create({
    image: {
        width: 70, borderRadius: 10, padding: "2px",

    }, section: {
        display: "flex", justifyContent: "space-around", width: "100%",

        flexDirection: "row",

        borderBottom: "1px"
    }
});
const RegisterViewer = ({info, data, category}) => {
    const raw_material_suppliers = useSelector(state => state.Stakeholders.raw_material_suppliers);
    const pallets = useSelector(state => state.Logistic.pallets);

    return (<PDFViewer style={{width: "100%", height: "100%"}}>
        <Document>
            <Page size="A4" style={{padding: "20px", width: "100%"}}>
                <View style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "6px",
                    gap: "24px",
                }}>
                    <Image style={styles.image} src={Logo}/>
                    <Text style={{
                        fontSize: "12px", fontWeight: "extrabold", textAlign: "center",
                    }}>ALMACÉN DE MATERIA PRIMA</Text>
                </View>
                <View style={{
                    display: "flex",
                    gridTemplateColumns: "1fr 1fr",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "6px",
                    gap: "16px",

                }}>
                    <View style={{display: "flex", flexDirection: "column", gap: "1px"}}>
                        <Text style={{
                            fontSize: "8px", color: "gray", fontWeight: "light"
                        }}>Proveedor: {raw_material_suppliers && raw_material_suppliers.find(e => e.id === info?.supplier)?.name}</Text>
                        <Text style={{fontSize: "8px", color: "gray", fontWeight: "light"}}>Guía de
                            transportista: {info?.transport_guide}</Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "column", gap: "1px"}}>
                        <Text style={{fontSize: "8px", color: "gray", fontWeight: "light"}}>Fecha de
                            ingreso: {formatDate(info?.datetime_arrival)}</Text>
                        <Text style={{fontSize: "8px", color: "gray", fontWeight: "light"}}>Guía de
                            proveedor: {info?.supplier_guide}</Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "column", gap: "1px"}}>
                        <Text style={{fontSize: "8px", color: "gray", fontWeight: "light"}}>Fecha de
                            descarga:{formatDate(info?.datetime_download_started)}</Text>
                        <Text style={{fontSize: "8px", color: "gray", fontWeight: "light"}}>Lote: {info?.lot}</Text>
                    </View>

                </View>
                <View style={{
                    display: "flex",
                    flexDirection: "row",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                    alignItems: "center",
                    padding: "4px",
                }}>
                    <Text style={{
                        fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "10%", textAlign: "center"
                    }}>N°</Text>
                    <Text style={{
                        fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "20%", textAlign: "center"
                    }}>Peso de ingreso</Text>
                    <Text style={{
                        fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "40%", textAlign: "center"
                    }}>Tipo de jabas</Text>
                    <Text style={{
                        fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "20%", textAlign: "center"
                    }}>Parihuela</Text>
                    <Text style={{
                        fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "10%", textAlign: "center"
                    }}>Tara</Text>
                    {category === "Piña" && <>
                        <Text style={{
                            fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "10%", textAlign: "center"
                        }}>C6</Text>
                        <Text style={{
                            fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "10%", textAlign: "center"
                        }}>C8</Text>
                        <Text style={{
                            fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "10%", textAlign: "center"
                        }}>C10</Text>
                        <Text style={{
                            fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "10%", textAlign: "center"
                        }}>C12</Text>
                        <Text style={{
                            fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "10%", textAlign: "center"
                        }}>C14</Text>
                    </>}
                </View>

                {data && data.map((item, index) => {
                    return (<View key={index} style={{
                        display: "flex",
                        flexDirection: "row",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                        alignItems: "center",
                        padding: "4px",

                    }}>
                        <Text style={{
                            fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "10%", textAlign: "center"
                        }}>{item?.number}</Text>
                        <Text style={{
                            fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "20%", textAlign: "center"
                        }}>{Humanize.formatNumber(item?.weight, 2)}</Text>
                        <Text style={{
                            fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "40%", textAlign: "center"
                        }}>
                            {item?.box_0 > 0 && "[0kg]: " + item?.box_0 + " "}
                            {item?.box_0_25 > 0 && "[0.25kg]: " + item?.box_0_25 + " "}
                            {item?.box_0_5 > 0 && "[0.5kg]: " + item?.box_0_5 + " "}
                            {item?.box_1 > 0 && "[1kg]: " + item?.box_1 + " "}
                            {item?.box_1_5 > 0 && "[1.5kg]: " + item?.box_1_5 + " "}
                            {item?.box_1_6 > 0 && "[1.6kg]: " + item?.box_1_6 + " "}
                        </Text>
                        <Text style={{
                            fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "20%", textAlign: "center"
                        }}>{pallets && pallets.find((e) => e.id === item?.pallet)?.name}</Text>
                        <Text style={{
                            fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "20%", textAlign: "center"
                        }}>{Humanize.formatNumber(item?.tare, 2)}</Text>
                        {category === "Piña" && <Text style={{
                            fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "10%", textAlign: "center"
                        }}>{item?.c6}</Text>}
                        {category === "Piña" && <Text style={{
                            fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "10%", textAlign: "center"
                        }}>{item?.c8}</Text>}
                        {category === "Piña" && <Text style={{
                            fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "10%", textAlign: "center"
                        }}>{item?.c10}</Text>}
                        {category === "Piña" && <Text style={{
                            fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "10%", textAlign: "center"
                        }}>{item?.c12}</Text>}
                        {category === "Piña" && <Text style={{
                            fontSize: "8px", color: "#4c4848", fontWeight: "light", width: "10%", textAlign: "center"
                        }}>{item?.c14}</Text>}

                    </View>)
                })}
                <View style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    gap: "12px",
                    marginTop: "12px",
                    justifyContent: "center"
                }}>
                    <Text style={{
                        fontSize: "8px",
                        color: "black",
                        fontWeight: "light",
                        padding: "4px",
                        backgroundColor: "#eaeaea",
                        borderRadius: "4px"
                    }}>Total de jabas: {Humanize.formatNumber(info?.boxes, 0)}</Text>
                    <Text style={{
                        fontSize: "8px",
                        color: "black",
                        fontWeight: "light",
                        padding: "4px",
                        backgroundColor: "#eaeaea",
                        borderRadius: "4px"
                    }}>Peso bruto: {Humanize.formatNumber(info?.weight_gross, 2)}</Text>
                    <Text style={{
                        fontSize: "8px",
                        color: "black",
                        fontWeight: "light",
                        padding: "4px",
                        backgroundColor: "#eaeaea",
                        borderRadius: "4px"
                    }}>Peso neto: {Humanize.formatNumber(info?.weight_net, 2)}</Text>
                    <Text style={{
                        fontSize: "8px",
                        color: "black",
                        fontWeight: "light",
                        padding: "4px",
                        backgroundColor: "#eaeaea",
                        borderRadius: "4px"
                    }}>Tara: {Humanize.formatNumber(parseFloat(info?.weight_boxes) + parseFloat(info?.weight_pallet), 2)}</Text>
                    <Text style={{
                        fontSize: "8px",
                        color: "black",
                        fontWeight: "light",
                        padding: "4px",
                        backgroundColor: "#eaeaea",
                        borderRadius: "4px"
                    }}>Muestra de calidad: {Humanize.formatNumber(info?.sample_weight, 2)}</Text>
                </View>


            </Page>


        </Document>
    </PDFViewer>);
};

export default RegisterViewer;