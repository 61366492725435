import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {InputForm} from "../../util/HelpersForm";
import {updateH2O} from "../../../redux/actions/maintenance";


const Form = ({data, onClose, params}) => {
    const dispatch = useDispatch();


    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            dispatch(updateH2O(data?.id, form, params))
            onClose();
        }
    })

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-2 gap-2`}>

                <InputForm values={formik.values.m1_day} errors={formik.errors.m1_day} formik={formik}
                            field={'m1_day'}
                           title={'M1 Dia'} max_length={7}/>
                <InputForm values={formik.values.m1_night} errors={formik.errors.m1_night} formik={formik}
                           field={'m1_night'}
                           title={'M1 Noche'} max_length={7}/>
                <InputForm values={formik.values.m2_day} class_={'col-span-2'} errors={formik.errors.m2_day}
                           formik={formik} field={'m2_day'}
                           title={'M2 Dia'} max_length={7}/>
                <InputForm values={formik.values.m3_day} errors={formik.errors.m3_day} formik={formik}
                            field={'m3_day'}
                           title={'M3 Dia'} max_length={7}/>
                <InputForm values={formik.values.m3_night} errors={formik.errors.m3_night} formik={formik}
                            field={'m3_night'}
                           title={'M3 Noche'} max_length={7}/>
                <InputForm values={formik.values.m4_day} errors={formik.errors.m4_day} formik={formik}
                            field={'m4_day'}
                           title={'M4 Dia'} max_length={7}/>
                <InputForm values={formik.values.m4_night} errors={formik.errors.m4_night} formik={formik}
                            field={'m4_night'}
                           title={'M4 Noche'} max_length={7}/>
                <InputForm values={formik.values.m5_day} errors={formik.errors.m5_day} formik={formik}
                            field={'m5_day'}
                           title={'M5 Dia'} max_length={7}/>
                <InputForm values={formik.values.m5_night} errors={formik.errors.m5_night} formik={formik}
                            field={'m5_night'}
                           title={'M5 Noche'} max_length={7}/>
                <InputForm values={formik.values.m6_day} errors={formik.errors.m6_day} formik={formik}
                            field={'m6_day'}
                           title={'M6 Dia'} max_length={7}/>
                <InputForm values={formik.values.m6_night} errors={formik.errors.m6_night} formik={formik}
                            field={'m6_night'}
                           title={'M6 Noche'} max_length={7}/>


            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {

        m1_day: data?.m1_day || 0,
        m1_night: data?.m1_night || 0,
        m2_day: data?.m2_day || 0,
        m3_day: data?.m3_day || 0,
        m3_night: data?.m3_night || 0,
        m4_day: data?.m4_day || 0,
        m4_night: data?.m4_night || 0,
        m5_day: data?.m5_day || 0,
        m5_night: data?.m5_night || 0,
        m6_day: data?.m6_day || 0,
        m6_night: data?.m6_night || 0,

    }
}
const newSchema = () => {
    return {
        m1_day: Yup.number().required(),
        m1_night: Yup.number().required(),
        m2_day: Yup.number().required(),
        m3_day: Yup.number().required(),
        m3_night: Yup.number().required(),
        m4_day: Yup.number().required(),
        m4_night: Yup.number().required(),
        m5_day: Yup.number().required(),
        m5_night: Yup.number().required(),
        m6_day: Yup.number().required(),
        m6_night: Yup.number().required(),
    }
}

export default Form;
