import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {formatDateTime} from "../../util/helpers";
import Humanize from "humanize-plus";
import {useSelector} from "react-redux";

const TableRecords = ({data}) => {
    const raw_material_suppliers = useSelector(state => state.Stakeholders.raw_material_suppliers);
    const transportation_companies = useSelector(state => state.Stakeholders.transportation_companies);
    const manufacturing_companies = useSelector(state => state.Stakeholders.manufacturing_companies);
    const columns = ['Mes', 'Año', 'Lote', 'Planta', 'Fecha de salida Campo', 'Fecha de entrada', 'Fecha de descarga', 'Fecha de descarga(fin)', 'Variedad', 'Condicion', 'Transporte', 'Guia de transportista', 'Guia de proveedor', 'Factura', 'Proveedor', 'Procedencia', 'Parcela', 'Promedio jabas', 'Cantidad de jabas', 'Peso bruto', 'Tara', 'Peso neto', 'Peso guia', 'Diferencia netos con guia', '% Rechazo', 'Kg rechazados', 'Kg aprovechables', 'KG Descuento S/', 'Descuento S/', 'Precio Campo', 'Precio planta', 'Flete','Estiba','Total a pagar campo', 'Total a pagar planta']
    return (<TableBase columns={columns}
                       cell={Row(data, raw_material_suppliers, transportation_companies, manufacturing_companies)}/>);
};

const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
const Row = (data, supplier, transport, manufacturing) => (data && data.map((item, index) => {
    console.log(manufacturing.find(i => i.id === item.manufacturing))
    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={monthNames[new Date(item?.datetime_download_started).getMonth()]}/>
        <Cell text={new Date(item?.datetime_download_started).getFullYear()}/>
        <Cell text={<a href={`/logistic/lot/${item?.lot}`} target={'_blank'} rel={'noreferrer'}
                       className={"font-bold"}>{item?.lot}</a>}/>
        <Cell
            text={item?.manufacturing && manufacturing && manufacturing.find(i => i.id === item.manufacturing)?.name}/>
        <Cell text={item?.datetime_departure && formatDateTime(item.datetime_departure)}/>
        <Cell text={item?.datetime_arrival && formatDateTime(item.datetime_arrival)}/>
        <Cell text={item?.datetime_download_started && formatDateTime(item.datetime_download_started)}/>
        <Cell text={item?.datetime_download_finished && formatDateTime(item.datetime_download_finished)}/>
        <Cell text={item?.variety}/>
        <Cell text={item?.condition === 'O' ? 'Orgánico' : 'Convencional'}/>
        <Cell text={item?.transport && transport && transport.find(i => i.id === item.transport)?.name}/>
        <Cell text={item?.transport_guide}/>
        <Cell text={item?.supplier_guide}/>
        <Cell text={item?.invoice}/>
        <Cell text={item?.supplier && supplier && supplier.find(i => i.id === item.supplier)?.name}/>
        <Cell text={item?.origin}/>
        <Cell text={item?.parcels_string}/>
        <Cell text={Humanize.formatNumber(item?.weight_net/item?.boxes,2)}/>
        <Cell text={item?.boxes}/>
        <Cell text={Humanize.formatNumber(item?.weight_gross,2)}/>
        <Cell text={Humanize.formatNumber(item?.weight_gross-item?.weight_net,2)}/>
        <Cell text={Humanize.formatNumber(item?.weight_net,2)}/>
        <Cell text={Humanize.formatNumber(item?.guide_weight,2)}/>
        <Cell text={Humanize.formatNumber(item?.guide_weight-item?.weight_net,2)}/>
        <Cell text={Humanize.formatNumber(item?.discount_weight_percentage,2)}/>
        <Cell text={Humanize.formatNumber(item?.weight_reject,2)}/>
        <Cell text={Humanize.formatNumber(item?.weight_usable,2)}/>
        <Cell text={Humanize.formatNumber(item?.discount_price_kg,2)}/>
        <Cell text={Humanize.formatNumber(item?.discount_price,2)}/>
        <Cell text={Humanize.formatNumber(item?.supplier_price||0,2)}/>
        <Cell text={Humanize.formatNumber(item?.plant_price||0,2)}/>
        <Cell text={Humanize.formatNumber(item?.freight||0,2)}/>
        <Cell text={Humanize.formatNumber(item?.download_price||0,2)}/>
        <Cell text={Humanize.formatNumber(item?.weight_usable*item?.plant_price,2)}/>
        <Cell text={Humanize.formatNumber(item?.total_amount||0,2)}/>



    </tr>)
}));
export default TableRecords;
