import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import HeaderForm from "../../../util/HeaderForm";
import {InputForm} from "../../../util/HelpersForm";
import {notifyArticle} from "../../../../redux/actions/logistic";
import {setAlert} from "../../../../redux/actions/alert";

const FormRequest = ({onClose, item, params}) => {
    const dispatch = useDispatch();
    const manufacturing_companies = useSelector(state => state.Stakeholders.manufacturing_companies);

    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            if (params.manufacturer !== '' && item?.id !== '') {
                form['name'] = item.name;
                form['location'] = manufacturing_companies && manufacturing_companies.find((e) => e.id === params?.manufacturer)?.name
                dispatch(notifyArticle(form));
                onClose();
            } else {
                dispatch(setAlert('Seleccione un almacén y un artículo', 'error'));
            }

        }
    })
    return (<form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
        <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
        <h3 className={"font-light text-xs text-gray-400 mb-1"}>{item?.name}</h3>
        <h3 className={"font-light text-xs text-gray-400 border-b-2 mb-2"}>{manufacturing_companies && manufacturing_companies.find((e) => e.id === params?.manufacturer)?.name}</h3>
        <div className={`grid  grid-cols-1 gap-2`}>
            <InputForm values={formik.values.quantity} errors={formik.errors.quantity} formik={formik}
                       field={'quantity'} max_length={6}
                       title={'Cantidad'}/>
        </div>
    </form>);
};

const initialValues = () => {
    return {
        quantity: 1,
    }
}

const newSchema = () => {
    return {
        quantity: Yup.number().integer().required(),
    }
}

export default FormRequest;