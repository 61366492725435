import React from 'react';
import {SelectSearch} from "../../util/Filters";
import {useSelector} from "react-redux";
import {PaperAirplaneIcon} from "@heroicons/react/24/outline";

const FiltersProjection = ({params, setParams, handleSearch}) => {
    const manufacturing_companies = useSelector(state => state.Stakeholders.manufacturing_companies);
    const categories = useSelector(state => state.Category.categories);

    const generateWeekItems = () => {
        const allWeeksOption = [{id: 'all', name: 'Todos'}];
        const weekItems = Array.from({length: 52}, (_, index) => ({
            id: index + 1, name: `Semana ${index + 1}`,
        }));

        return allWeeksOption.concat(weekItems);
    };

    return (<div className="flex flex-col md:flex-row items-center gap-2 justify-end py-4 bg-white ">
            <SelectSearch name={"Semana"} value={params.week} color={'bg-gray-100'}
                          handle={(e) => setParams({...params, 'week': e.target.value})}
                          handleSearch={handleSearch}
                          field={'name'}
                          items={generateWeekItems()}/>

            <SelectSearch handleSearch={handleSearch} value={params.manufacturing_companies} color={'bg-gray-100'}
                          items={[{value: null, name: null}, ...manufacturing_companies ? manufacturing_companies : []]}
                          name={"Planta"}
                          field={'name'}
                          handle={(value) => setParams({...params, 'company': value.target.value})}/>
            <SelectSearch handleSearch={handleSearch} value={params.product} color={'bg-gray-100'}
                          name={"Cultivo"}
                          field={'name'}
                          items={categories ? [{
                              id: null, name: null
                          }, ...categories.find(item => item.name === "Materia Prima")?.children] : []}
                          handle={(value) => setParams({...params, 'product': value.target.value})}/>

            <button onClick={handleSearch}
                    className="bg-gray-100 flex gap-2 items-center  hover:bg-gray-300 w-full md:w-max text-gray-500 hover:text-white self-end mb-1 font-medium py-2 px-4 rounded-md justify-center">
                Buscar <PaperAirplaneIcon className="h-5 w-5"/>
            </button>

        </div>

    );
};

export default FiltersProjection;
