import React from 'react';

import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Error404 from "./container/error/Error404";
import Lot from "./container/pages/E2E/Logistic/Lot";
import Records from "./container/pages/E2E/Logistic/Records";
import {Home as HomeSCT} from "./container/pages/SCT/Home";
import Departments from "./container/pages/SCT/Departments";
import Employees from "./container/pages/SCT/Employees";
import Home from "./container/pages/E2E/Home";
import Hours from "./container/pages/SCT/Hours";
import Real from "./container/pages/SCT/Real";
import Daily from "./container/pages/SCT/Daily";
import {Summary as SummarySCT} from "./container/pages/SCT/Summary";
import Outsourcing from "./container/pages/SCT/Outsourcing";
import {Tasks} from "./container/pages/MM/Tasks";
import Register from "./container/pages/MM/Register";
import Requirements from "./container/pages/MM/Requirements";
import Tree from "./container/pages/MM/Tree";
import Facilities from "./container/pages/MM/Facilities";
import Equipments from "./container/pages/MM/Equipments";
import Tools from "./container/pages/MM/Tools";
import Sidebar from "./components/navigation/Sidebard";
import Parcels from "./container/pages/E2E/Agrisupply/Parcels";
import Projection from "./container/pages/E2E/Agrisupply/Projection";
import Stock from "./container/pages/E2E/Logistic/Stock";
import External from "./container/pages/E2E/Logistic/External";
import Download from "./container/pages/E2E/Logistic/Download";
import Materials from "./container/pages/E2E/Logistic/Materials";
import Ovens from "./container/pages/E2E/Production/Ovens";
import Packaging from "./container/pages/E2E/Production/Packaging";
import Conditioning from "./container/pages/E2E/Production/Conditioning";
import GLP from "./container/pages/E2E/Logistic/GLP";
import Sample from "./container/pages/E2E/Commercial/Sample";
import Raw from "./container/pages/E2E/Stakeholders/Raw";
import Customer from "./container/pages/E2E/Stakeholders/Customer";
import Transports from "./container/pages/E2E/Stakeholders/Transports";
import {Materials as MaterialSupplier} from "./container/pages/E2E/Stakeholders/Materials";
import Plants from "./container/pages/E2E/Stakeholders/Plants";
import BI from "./container/pages/BI";
import Scanner from "./container/pages/SCT/Scanner";
import Recipe from "./container/pages/E2E/Planning/Recipe";
import Simulator from "./container/pages/E2E/Planning/Simulator";
import store, {Persistor} from "./store";
import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import Freight from "./container/pages/E2E/Logistic/Freight";
import Login from "./container/auth/Login";
import ResetPassword from "./container/auth/ResetPassword";
import ResetPasswordConfirm from "./container/auth/ResetPasswordConfirm";
import Analysis from "./container/pages/E2E/Quality/Analysis";
import H2O from "./container/pages/MM/H2O";
import Chlorine from "./container/pages/MM/Chlorine";
import Delay from "./container/pages/SCT/Delay";


const App = () => {


    return (<Provider store={store}>
        <PersistGate loading={null} persistor={Persistor}>
            <Router>
                <section className="flex h-screen bg-white max-w-full">
                    <Sidebar/>
                    <Routes>
                        {/*Error Display*/}
                        <Route path="*" element={<Error404/>}/>
                        <Route exact path="/login" element={<Login/>}/>
                        <Route exact path="/reset_password" element={<ResetPassword/>}/>
                        <Route exact path='/password/reset/confirm/:uid/:token' element={<ResetPasswordConfirm/>}/>
                        <Route exact path="/" element={<Home/>}/>
                        <Route exact path="/agrisupply/parcels" element={<Parcels/>}/>
                        <Route exact path="/agrisupply/projection" element={<Projection/>}/>

                        <Route exact path="/logistic/lot/:lot" element={<Lot/>}/>

                        <Route exact path="/records/:product" element={<Records/>}/>

                        <Route exact path="/logistic/stock" element={<Stock/>}/>
                        <Route exact path="/logistic/external" element={<External/>}/>
                        <Route exact path="/logistic/download" element={<Download/>}/>
                        <Route exact path="/logistic/freight" element={<Freight/>}/>
                        <Route exact path="/logistic/warehouse" element={<Materials/>}/>
                        <Route exact path="/logistic/glp" element={<GLP/>}/>

                        <Route exact path="/quality_control/analysis" element={<Analysis/>}/>

                        <Route exact path="/stakeholders/products/raw" element={<Raw/>}/>
                        <Route exact path="/stakeholders/products/materials" element={<MaterialSupplier/>}/>
                        <Route exact path="/stakeholders/customer" element={<Customer/>}/>
                        <Route exact path="/stakeholders/transports" element={<Transports/>}/>
                        <Route exact path="/stakeholders/plants" element={<Plants/>}/>

                        <Route exact path="/production/conditioning" element={<Conditioning/>}/>
                        <Route exact path="/production/ovens" element={<Ovens/>}/>
                        <Route exact path="/production/packaging" element={<Packaging/>}/>

                        <Route exact path="/commercial/samples" element={<Sample/>}/>
                        <Route exact path="/planning/recipe" element={<Recipe/>}/>
                        <Route exact path="/planning/simulator" element={<Simulator/>}/>

                        {/*SCT*/}
                        <Route exact path="/sct" element={<HomeSCT/>}/>
                        <Route exact path="/sct/scanner" element={<Scanner/>}/>
                        <Route exact path="/sct/employees" element={<Employees/>}/>
                        <Route exact path="/sct/departments" element={<Departments/>}/>
                        <Route exact path="/sct/hours" element={<Hours/>}/>
                        <Route exact path="/sct/real" element={<Real/>}/>
                        <Route exact path="/sct/daily" element={<Daily/>}/>
                        <Route exact path="/sct/delay" element={<Delay/>}/>
                        <Route exact path="/sct/summary" element={<SummarySCT/>}/>
                        <Route exact path="/sct/outsourcing" element={<Outsourcing/>}/>

                        {/*MM*/}
                        <Route exact path="/mm/inventories/tree" element={<Tree/>}/>
                        <Route exact path="/mm/inventories/facilities" element={<Facilities/>}/>
                        <Route exact path="/mm/inventories/equipments" element={<Equipments/>}/>
                        <Route exact path="/mm/inventories/tools" element={<Tools/>}/>
                        <Route exact path="/mm/tasks" element={<Tasks/>}/>
                        <Route exact path="/mm/register" element={<Register/>}/>
                        <Route exact path="/mm/requirements" element={<Requirements/>}/>
                        <Route exact path="/mm/h2o" element={<H2O/>}/>
                        <Route exact path="/mm/chlorine" element={<Chlorine/>}/>

                        {/*BI*/}
                        <Route exact path="/business-intelligence" element={<BI/>}/>

                    </Routes>
                </section>
            </Router>
        </PersistGate>
    </Provider>);
}

export default App;