import React, {useEffect} from 'react';
import TableBase, {Cell} from "../../../util/Table";
import {useDispatch, useSelector} from "react-redux";
import {ArrowRightCircleIcon, TicketIcon} from "@heroicons/react/24/solid";
import {BellAlertIcon} from "@heroicons/react/20/solid";
import {getArticles} from "../../../../redux/actions/logistic";
import {ArrowsRightLeftIcon} from "@heroicons/react/24/outline";
import Humanize from "humanize-plus";

const TableStock = ({handleReceipt, handleIssue, handleTransfer,handleRequest, params}) => {
    const data = useSelector(state => state.Logistic.article);
    const groups = useSelector(state => state.Category.categories);

    const dispatch = useDispatch();

    useEffect(() => {
        if (params) dispatch(getArticles(params))
    }, [dispatch]);


    const columns = ['', 'SAP', 'Nombre', 'Categoría', 'Stock', 'U.M.', 'Costo Und', 'Costo Total', 'Lead Time', 'Consumo', 'S.S.', 'R.P.']
    return (<TableBase columns={columns} cell={Row(data, handleReceipt, handleIssue, handleTransfer,handleRequest, groups)}/>);
};

const Row = (data, receipt, issue, transfer,request, groups) => (data && data.map((item, index) => {
    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={<div className={"font-bold flex items-center whitespace-nowrap"}>

            <TicketIcon title={"Ingreso"} onClick={() => receipt(item)}
                        className={"h-5 w-5 mr-2 text-blue-400 cursor-pointer"}/>

            <ArrowRightCircleIcon title={"Salida"} onClick={() => issue(item)}
                                  className={"h-5 w-5 mr-2 text-red-400 cursor-pointer"}/>
            <ArrowsRightLeftIcon title={"Transferir"} onClick={() => transfer(item)}
                                 className={"h-5 w-5 mr-2 text-green-400 cursor-pointer"}/>

            <BellAlertIcon title={"Solicitar"} onClick={() => request(item)}
                           className={"h-5 w-5 mr-2 text-black  cursor-pointer"}/>

        </div>}/>
        <Cell text={item?.sap_code}/>
        <Cell text={item?.name}/>

        <Cell text={groups && groups.find((e) => e.id = item?.group)?.name}/>
         <Cell text={<div
            className={`${item?.stock < 1 ? 'bg-red-400' : 'bg-green-400'} text-white font-bold w-full p-2 rounded-full text-center`}>
            <p>{item?.stock}</p>
        </div>}/>
        <Cell text={item?.unit_of_measurement}/>
        <Cell text={Humanize.formatNumber(item?.weighted_avg_price,2)}/>
        <Cell text={Humanize.formatNumber(item?.weighted_avg_price*item?.stock,2)}/>
        <Cell text={item?.lead_time_avg ? item?.lead_time_avg : 0}/>
        <Cell text={item?.consumption_day ? item?.consumption_day : 0}/>
        <Cell text={item?.safety_stock ? item?.safety_stock : 0}/>
        <Cell text={item?.reorder_point ? item?.reorder_point : 0}/>
    </tr>)
}));


export default TableStock;
