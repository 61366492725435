import axios from "axios";
import {
    ADD_ASSET_FIXED_FAIL,
    ADD_ASSET_FIXED_SUCCESS,
    ADD_ASSET_PHYSICAL_FAIL,
    ADD_ASSET_PHYSICAL_SUCCESS,
    ADD_ASSET_TOOL_FAIL,
    ADD_ASSET_TOOL_SUCCESS,
    ADD_HELPER_WORK_FAIL,
    ADD_HELPER_WORK_SUCCESS,
    ADD_OT_FAIL,
    ADD_OT_SUCCESS,
    ADD_REQUIREMENT_FAIL,
    ADD_REQUIREMENT_SUCCESS,
    ADD_RESOURCE_WORK_FAIL,
    ADD_RESOURCE_WORK_SUCCESS,
    DELETE_ASSET_FIXED_FAIL,
    DELETE_ASSET_FIXED_SUCCESS,
    DELETE_ASSET_PHYSICAL_FAIL,
    DELETE_ASSET_PHYSICAL_SUCCESS,
    DELETE_ASSET_TOOL_FAIL,
    DELETE_ASSET_TOOL_SUCCESS,
    DELETE_HELPER_WORK_FAIL,
    DELETE_HELPER_WORK_SUCCESS,
    DELETE_OT_FAIL,
    DELETE_OT_SUCCESS,
    DELETE_REQUIREMENT_FAIL,
    DELETE_REQUIREMENT_SUCCESS,
    DELETE_RESOURCE_WORK_FAIL,
    DELETE_RESOURCE_WORK_SUCCESS,
    GET_ASSETS_FIXED_FAIL,
    GET_ASSETS_FIXED_SUCCESS,
    GET_ASSETS_PHYSICAL_FAIL,
    GET_ASSETS_PHYSICAL_SUCCESS,
    GET_ASSETS_TOOLS_FAIL,
    GET_ASSETS_TOOLS_SUCCESS,
    GET_ASSETS_TREE_FAIL,
    GET_ASSETS_TREE_SUCCESS,
    GET_CHLORINE_FAIL,
    GET_CHLORINE_SUCCESS,
    GET_FAILURES_FAIL,
    GET_FAILURES_SUCCESS,
    GET_H2O_FAIL,
    GET_H2O_SUCCESS, GET_HELPERS_FAIL, GET_HELPERS_SUCCESS,
    GET_MAINTENANCE_TYPES_FAIL,
    GET_MAINTENANCE_TYPES_SUCCESS,
    GET_OT_FAIL,
    GET_OT_SUCCESS,
    GET_REQUIREMENTS_FAIL,
    GET_REQUIREMENTS_SUCCESS, GET_RESOURCES_FAIL,
    GET_RESOURCES_SUCCESS,
    GET_TECHNICALS_FAIL,
    GET_TECHNICALS_SUCCESS,
    GET_USERS_FAIL,
    GET_USERS_SUCCESS,
    UPDATE_ASSET_FIXED_FAIL,
    UPDATE_ASSET_FIXED_SUCCESS,
    UPDATE_ASSET_PHYSICAL_FAIL,
    UPDATE_ASSET_PHYSICAL_SUCCESS,
    UPDATE_ASSET_TOOL_FAIL,
    UPDATE_ASSET_TOOL_SUCCESS,
    UPDATE_CHLORINE_FAIL,
    UPDATE_CHLORINE_SUCCESS,
    UPDATE_H2O_FAIL,
    UPDATE_H2O_SUCCESS,
    UPDATE_OT_FAIL,
    UPDATE_OT_SUCCESS,
    UPDATE_REQUIREMENT_FAIL,
    UPDATE_REQUIREMENT_SUCCESS,
    UPDATE_WORK_CLEAN_FAIL,
    UPDATE_WORK_CLEAN_SUCCESS,
    UPDATE_WORK_VALIDATE_FAIL,
    UPDATE_WORK_VALIDATE_SUCCESS,
} from "./types";
import {setAlert} from "./alert";

/* Samples */
export const getTree = () => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/maintenance/tree/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_ASSETS_TREE_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_ASSETS_TREE_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_ASSETS_TREE_FAIL
        });
    }
}

export const getAssetsFixed = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
        params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/maintenance/fixed-assets/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_ASSETS_FIXED_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_ASSETS_FIXED_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_ASSETS_FIXED_FAIL
        });
    }
}

export const addAssetFixed = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json', 'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/maintenance/fixed-assets/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_ASSET_FIXED_SUCCESS, payload: res.data
            });
            dispatch(getAssetsFixed(params))
            dispatch(setAlert(res.data.message || 'Activo fijo creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_ASSET_FIXED_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_ASSET_FIXED_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_ASSET_FIXED_FAIL
            });
        }
    }
};
export const updateAssetFixed = (form, pk, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',  'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/maintenance/fixed-assets/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_ASSET_FIXED_SUCCESS, payload: res.data
            });
            dispatch(getAssetsFixed(params))
            dispatch(setAlert(res.data.message || 'Activo fijo actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_ASSET_FIXED_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_ASSET_FIXED_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_ASSET_FIXED_FAIL
            });
        }
    }
};

export const deleteAssetFixed = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',  'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/maintenance/fixed-assets/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_ASSET_FIXED_SUCCESS, payload: res.data
            });
            dispatch(getAssetsFixed(params))
            dispatch(setAlert(res.data.message || 'Activo fijo eliminado con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_ASSET_FIXED_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));
        }
    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_ASSET_FIXED_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_ASSET_FIXED_FAIL
            });
        }
    }
};


export const getAssetsPhysical = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
        params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/maintenance/physical-assets/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_ASSETS_PHYSICAL_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_ASSETS_PHYSICAL_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_ASSETS_PHYSICAL_FAIL
        });
    }
}

export const addAssetPhysical = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',  'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/maintenance/physical-assets/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_ASSET_PHYSICAL_SUCCESS, payload: res.data
            });
            dispatch(getAssetsPhysical(params))
            dispatch(setAlert(res.data.message || 'Activo fijo creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_ASSET_PHYSICAL_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_ASSET_PHYSICAL_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_ASSET_PHYSICAL_FAIL
            });
        }
    }
};
export const updateAssetPhysical = (form, pk, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',  'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/maintenance/physical-assets/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_ASSET_PHYSICAL_SUCCESS, payload: res.data
            });
            dispatch(getAssetsPhysical(params))
            dispatch(setAlert(res.data.message || 'Activo fijo actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_ASSET_PHYSICAL_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_ASSET_PHYSICAL_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_ASSET_PHYSICAL_FAIL
            });
        }
    }
};

export const deleteAssetPhysical = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json',  'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/maintenance/physical-assets/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_ASSET_PHYSICAL_SUCCESS, payload: res.data
            });
            dispatch(getAssetsPhysical(params))
            dispatch(setAlert(res.data.message || 'Activo fijo eliminado con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_ASSET_PHYSICAL_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));
        }
    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_ASSET_PHYSICAL_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_ASSET_PHYSICAL_FAIL
            });
        }
    }
};


export const getTools = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
        params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/maintenance/tools/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_ASSETS_TOOLS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_ASSETS_TOOLS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_ASSETS_TOOLS_FAIL
        });
    }
}

export const addTool = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/maintenance/tools/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_ASSET_TOOL_SUCCESS, payload: res.data
            });
            dispatch(getTools(params))
            dispatch(setAlert(res.data.message || 'Herramienta creada con exito', 'success'));
        } else {
            dispatch({
                type: ADD_ASSET_TOOL_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_ASSET_TOOL_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_ASSET_TOOL_FAIL
            });
        }
    }
};
export const updateTool = (form, pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/maintenance/tools/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_ASSET_TOOL_SUCCESS, payload: res.data
            });
            dispatch(getTools(params))
            dispatch(setAlert(res.data.message || 'Herramienta actualizada con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_ASSET_TOOL_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_ASSET_TOOL_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_ASSET_TOOL_FAIL
            });
        }
    }
};

export const deleteTool = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/maintenance/tools/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_ASSET_TOOL_SUCCESS, payload: res.data
            });
            dispatch(getTools(params))
            dispatch(setAlert(res.data.message || 'Herramienta eliminada con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_ASSET_TOOL_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));
        }
    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_ASSET_TOOL_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_ASSET_TOOL_FAIL
            });
        }
    }
};


export const getFailures = () => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },

    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/maintenance/failures/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_FAILURES_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_FAILURES_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_FAILURES_FAIL
        });
    }
}

export const getMaintenanceTypes = () => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },

    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/maintenance/types/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_MAINTENANCE_TYPES_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_MAINTENANCE_TYPES_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_MAINTENANCE_TYPES_FAIL
        });
    }
}

export const getRequirements = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
        params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/maintenance/requirements/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_REQUIREMENTS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_REQUIREMENTS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_REQUIREMENTS_FAIL
        });
    }
}

export const addRequirement = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/maintenance/requirements/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_REQUIREMENT_SUCCESS, payload: res.data
            });
            dispatch(getRequirements(params))
            dispatch(setAlert(res.data.message || 'Solicitud creada con exito', 'success'));
        } else {
            dispatch({
                type: ADD_REQUIREMENT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_REQUIREMENT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_REQUIREMENT_FAIL
            });
        }
    }
};
export const updateRequirement = (form, pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/maintenance/requirements/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_REQUIREMENT_SUCCESS, payload: res.data
            });
            dispatch(getRequirements(params))
            dispatch(setAlert(res.data.message || 'Solicitud actualizada con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_REQUIREMENT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_REQUIREMENT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_REQUIREMENT_FAIL
            });
        }
    }
};

export const deleteRequirement = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/maintenance/tools/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_REQUIREMENT_SUCCESS, payload: res.data
            });
            dispatch(getRequirements(params))
            dispatch(setAlert(res.data.message || 'Solicitud eliminada con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_REQUIREMENT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));
        }
    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_REQUIREMENT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_REQUIREMENT_FAIL
            });
        }
    }
};

export const getOT = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
        params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/maintenance/work-orders/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_OT_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_OT_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_OT_FAIL
        });
    }
}

export const addOT = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/maintenance/work-orders/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_OT_SUCCESS, payload: res.data
            });
            dispatch(getOT(params))
            dispatch(setAlert(res.data.message || 'OT creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_OT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_OT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_OT_FAIL
            });
        }
    }
};
export const updateOT = (form, pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/maintenance/work-orders/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_OT_SUCCESS, payload: res.data
            });
            dispatch(getOT(params))
            dispatch(setAlert(res.data.message || 'OT actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_OT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_OT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_OT_FAIL
            });
        }
    }
};

export const deleteOT = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/maintenance/work-orders/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_OT_SUCCESS, payload: res.data
            });
            dispatch(getOT(params))
            dispatch(setAlert(res.data.message || 'OT eliminado con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_OT_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));
        }
    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_OT_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_OT_FAIL
            });
        }
    }
};


export const updateOTClean = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/maintenance/work-orders/${pk}/update-supervisor/`,null, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_WORK_CLEAN_SUCCESS
            });
            dispatch(getOT(params))
            dispatch(setAlert(res.data.message || 'OT actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_WORK_CLEAN_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_WORK_CLEAN_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_WORK_CLEAN_FAIL
            });
        }
    }
};

export const updateOTValidate = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };
    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/maintenance/work-orders/${pk}/update-requester/`,null,config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_WORK_VALIDATE_SUCCESS, payload: res.data
            });
            dispatch(getOT(params))
            dispatch(setAlert(res.data.message || 'OT actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_WORK_VALIDATE_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));
        }
    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_WORK_VALIDATE_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_WORK_CLEAN_FAIL
            });
        }
    }
};

export const addResouceOT = (form, pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/maintenance/work-orders/${pk}/add-resources/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_RESOURCE_WORK_SUCCESS, payload: res.data
            });
            dispatch(getOT(params))
            dispatch(setAlert(res.data.message || 'Recurso añadido con exito', 'success'));
        } else {
            dispatch({
                type: ADD_RESOURCE_WORK_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));
        }
    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_RESOURCE_WORK_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_RESOURCE_WORK_FAIL
            });
        }
    }
};

export const deleteResourceOT = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/maintenance/work-orders/delete-resources/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_RESOURCE_WORK_SUCCESS, payload: res.data
            });
            dispatch(getOT(params))
            dispatch(setAlert(res.data.message || 'Recurso eliminado con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_RESOURCE_WORK_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));
        }
    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_RESOURCE_WORK_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_RESOURCE_WORK_FAIL
            });
        }
    }
};


export const addHelperOT = (form, pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/maintenance/work-orders/${pk}/add-helpers/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_HELPER_WORK_SUCCESS, payload: res.data
            });
            dispatch(getOT(params))
            dispatch(setAlert(res.data.message || 'Recurso añadido con exito', 'success'));
        } else {
            dispatch({
                type: ADD_HELPER_WORK_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));
        }
    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_HELPER_WORK_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_HELPER_WORK_FAIL
            });
        }
    }
};

export const deleteHelperOT = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/maintenance/work-orders/delete-helpers/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_HELPER_WORK_SUCCESS, payload: res.data
            });
            dispatch(getOT(params))
            dispatch(setAlert(res.data.message || 'Recurso eliminado con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_HELPER_WORK_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));
        }
    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_HELPER_WORK_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_HELPER_WORK_FAIL
            });
        }
    }
};


export const getTechnicals = () => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/maintenance/technical/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_TECHNICALS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_TECHNICALS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_TECHNICALS_FAIL
        });
    }
}
export const getUsers = () => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/maintenance/users/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_USERS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_USERS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_USERS_FAIL
        });
    }
}

export const getResources = (pk) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/maintenance/work-orders/${pk}/resources/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_RESOURCES_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_RESOURCES_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_RESOURCES_FAIL
        });
    }
}

export const getHelpers = (pk) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/maintenance/work-orders/${pk}/helpers/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_HELPERS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_HELPERS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_HELPERS_FAIL
        });
    }
}

export const getH2O = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
        params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/maintenance/h2o/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_H2O_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_H2O_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_H2O_FAIL
        });
    }
}
export const updateH2O = (pk,form, params) => async dispatch => {
    console.log(localStorage.getItem('access'))
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };
    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/maintenance/h2o/${pk}/`,form,config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_H2O_SUCCESS, payload: res.data
            });
            dispatch(getH2O(params))
            dispatch(setAlert(res.data.message || 'Registro actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_H2O_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));
        }
    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_H2O_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_H2O_FAIL
            });
        }
    }
};


export const getChlorine = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
        params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/maintenance/chlorine/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_CHLORINE_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_CHLORINE_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_CHLORINE_FAIL
        });
    }
}
export const updateChlorine = (pk,form, params) => async dispatch => {
    console.log(localStorage.getItem('access'))
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };
    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/maintenance/chlorine/${pk}/`,form,config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_CHLORINE_SUCCESS, payload: res.data
            });
            dispatch(getChlorine(params))
            dispatch(setAlert(res.data.message || 'Registro actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_CHLORINE_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));
        }
    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_CHLORINE_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_CHLORINE_FAIL
            });
        }
    }
};