import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import Layout from "../../../hocs/Layout";
import ButtonHelper from "../../../components/util/ButtonHelper";
import TaskSection from "../../../components/mm/Task/TaskSection";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteOT,
    getAssetsFixed,
    getAssetsPhysical,
    getFailures,
    getMaintenanceTypes,
    getOT,
    getTechnicals,
    getTools, getUsers
} from "../../../redux/actions/maintenance";
import {BellIcon, CheckBadgeIcon} from "@heroicons/react/24/solid";
import {MySwal} from "../../../components/util/helpers";
import Modal from "../../../components/util/Modal";
import TaskModalContent from "../../../components/mm/Task/TaskModalContent";
import ModalHook from "../../../hooks/ModalHook";
import Filters from "../../../components/mm/Task/Filters";


const Tasks = () => {
    const work = useSelector(state => state.Maintenance.works);
    const [params, setParams] = useState({
        year: new Date().getFullYear(), month: new Date().getMonth()+1, week: 'all',
        user: '',
    })
    const {content, setContent, isOpen, openModal} = ModalHook();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOT({year: new Date().getFullYear()}));
        dispatch(getAssetsPhysical())
        dispatch(getAssetsFixed())
        dispatch(getTechnicals())
        dispatch(getUsers())
        dispatch(getTools())
        dispatch(getFailures())
        dispatch(getMaintenanceTypes())
    }, [dispatch]);

    const handleSearch = () => {
        dispatch(getOT(params));
    }

    const handleModalAction = (actionType, data) => {
        setContent(<TaskModalContent actionType={actionType} data={data} params={params} close={openModal}/>);
        openModal()
    };

    const handleDeleteWork = (data) => {
        MySwal.fire({
            title: '¿Desea eliminar esta OT?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7DABF5',
            confirmButtonColor: '#F87171',
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteOT(data.id, params));
            }
        });
    };

    return (<Layout>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <Helmet>
            <title>Ordenes de trabajo</title>
        </Helmet>
        <ButtonHelper handle={() => handleModalAction('add')}/>
        <div className={"h-screen overflow-y-auto scrollbar-hide w-full"}>
            <Filters params={params} setParams={setParams} handleSearch={handleSearch}/>
            {work && work.length > 0 ? <TaskSections work={work} handleModalAction={handleModalAction}
                                                     handleDeleteWork={handleDeleteWork}/> :
                <div className="flex w-full justify-center items-center">
                    <p className="text-gray-400 font-semibold text-lg">No hay ordenes de trabajo</p>
                </div>}
        </div>


    </Layout>);
};

const TaskSections = ({work, handleModalAction, handleDeleteWork}) => (<div
    className="grid grid-cols-1 md:grid-cols-2 lg:space-x-12  w-full gap-2 ">
    <TaskSection title="Tareas pendientes" color="red" icon={<BellIcon className="w-6 h-6 text-white"/>}
                 work={work.filter(item => !item.status)} handleModalAction={handleModalAction}
                 handleDeleteWork={handleDeleteWork}/>
    <TaskSection title="Tareas finalizadas" color="green" icon={<CheckBadgeIcon className="w-6 h-6 text-white"/>}
                 work={work.filter(item => item.status)} handleModalAction={handleModalAction}
                 handleDeleteWork={handleDeleteWork}/>
</div>);


export {Tasks};