import React from 'react';

const InputForm = ({values, errors, formik, field, title, class_, max_length,type_='text'}) => {
    return (<div className={class_}>
        <p className={`block  text-xs  font-normal ${!errors ? 'text-green-400' : 'text-red-400'}`}>{title}</p>
        <input type={type_} maxLength={max_length} max={max_length}
               className={`${errors && "border-red-300"} text-black w-full focus:border-blue-300 p-3 mt-1 border border-gray-300 rounded outline-none focus:bg-gray-50 font-light text-xs`}
               value={`${values}`}
               onChange={text => formik.setFieldValue(field, text.target.value)}/>
    </div>);
};


const TextAreaForm = ({values, errors, formik, field, title, class_, }) => {
    return (<div className={class_}>
        <p className={`block  text-xs  font-normal ${!errors ? 'text-green-400' : 'text-red-400'}`}>{title}</p>
        <textarea
               className={`${errors && "border-red-300"} text-black w-full focus:border-blue-300 p-3 mt-1 border border-gray-300 rounded outline-none focus:bg-gray-50 font-light text-xs`}
               value={`${values}`} rows={10}
               onChange={text => formik.setFieldValue(field, text.target.value)}/>
    </div>);
};


const SelectForm = ({values, errors, formik, field, title, class_, options, display}) => (<div className={class_}>
    <label
        className={`block text-xs font-normal ${!errors ? 'text-green-400' : 'text-red-400'}`}>{title}</label>
    <select onChange={(value) => formik.setFieldValue(field, value.target.value)}
            defaultValue={values}
            className={`${errors && "border-red-300"} text-black w-full focus:border-blue-300 p-3 mt-1 border border-gray-300 rounded outline-none focus:bg-gray-50 font-light text-xs`}>
        <option value={''}>---</option>
        {options !== null && options.map(item => (<option key={item.id} value={item.id}>{item[display]}</option>))}
    </select>
</div>)

export {InputForm, SelectForm,TextAreaForm};
