import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {InputForm} from "../../util/HelpersForm";
import {addAssetFixed, updateAssetFixed} from "../../../redux/actions/maintenance";


const FormFixed = ({data, onClose, params}) => {
    const dispatch = useDispatch();


    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            data ? dispatch(updateAssetFixed(form, data?.id, params)) : dispatch(addAssetFixed(form, params));
            onClose();
        }
    })

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-1 gap-2`}>

                <InputForm values={formik.values.name} errors={formik.errors.name} formik={formik}
                           field={'name'}
                           title={'Nombre'}
                />


            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {
        name: data?.name || ''
    }
}
const newSchema = () => {
    return {

        name: Yup.string().required(),


    }
}

export default FormFixed;
