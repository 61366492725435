import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import Humanize from "humanize-plus";
import {useDispatch, useSelector} from "react-redux";
import {get_categories} from "../../../redux/actions/category";

const Summary = ({products}) => {

    const dispatch = useDispatch();
    const data = useSelector(state => state.Category.categories);
    const stock_mp = useSelector(state => state.Logistic.stock_mp);

    function getDayOfYear() {
        const now = new Date();
        const start = new Date(now.getFullYear(), 0, 0);
        const diff = now - start;
        const oneDay = 1000 * 60 * 60 * 24;
        return Math.floor(diff / oneDay);
    }


    useEffect(() => {
        dispatch(get_categories())
    }, []);

    return (<div className={"w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 items-center"}>
            <Card title={'Día del año'} value={getDayOfYear()}/>

            {data && data.length > 0 && data.find(item => item.name === "Materia Prima")?.children?.map((item, index) => {
                const stock = stock_mp && stock_mp.find(stock => stock.product__name === item.name)?.total_stock || 0;
                return (<Link to={`/records/${item.name}`} className="w-full" key={index}>
                    <Card title={item.name} value={Humanize.formatNumber(stock, 2) + " kg"}/>
                </Link>);
            })}
        </div>

    );
};


const Card = ({title, value}) => {
    return (<div
        className="group flex flex-col p-4  text-center overflow-hidden bg-white rounded-xl shadow-lg duration-300 hover:bg-gradient-to-br hover:from-purple-400 hover:via-blue-400 hover:to-blue-500 hover:shadow-2xl">
        <h1 className="text-2xl sm:text-xl xl:text-2xl font-bold text-gray-800 group-hover:text-white">{value}</h1>
        <div className="flex flex-row justify-center">
            <h1 className="text-center group-hover:text-white">{title}</h1>
        </div>
    </div>);
};
export default Summary;