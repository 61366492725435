import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {useSelector} from "react-redux";
import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import {formatDateTZ, formatTime} from "../../util/helpers";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/24/solid";


const TableHours = ({handleUpdate, handleDelete}) => {
    const absenteeism = useSelector(state => state.TalentHub.absenteeism);
    const data = useSelector(state => state.TalentHub.tracking);
    const columns = ['', 'Nombre', 'Fecha', 'Entrada', 'Entrada Break', 'Salida Break', 'Salida', 'Ausentismo', 'Horas Ausentismo', 'Horas Laborales', 'Horas 25%', 'Horas 35%', 'Tardanza']
    return (<TableBase columns={columns} cell={Row(data, handleUpdate, handleDelete, absenteeism)}/>);
};

const Row = (data, update, remove, absenteeism) => (data && data.map((item, index) => {
    function calculateTotalTime(timeStrings) {
        const totalSeconds = timeStrings.reduce((total, timeString) => {
            if (timeString) {
                const [hours, minutes, seconds] = timeString.split(':').map(Number);
                return total + hours * 3600 + minutes * 60 + seconds;
            }
            return total;
        }, 0);

        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
    }

    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={<div className={"font-bold flex items-center whitespace-nowrap"}>
            <PencilSquareIcon onClick={() => update(item)} title={"Editar"}
                              className={"h-5 w-5 mr-2 text-blue-500 cursor-pointer"}/>
            <TrashIcon onClick={() => remove(item?.id)} title={"Eliminar"}
                       className={"h-5 w-5 mr-2 text-red-500 cursor-pointer"}/>
        </div>}/>
        <Cell text={item?.staff_name}/>
        <Cell text={item?.date && formatDateTZ(item.date)}/>
        <Cell text={item?.check_in && formatTime(item.check_in)}/>
        <Cell text={item?.lunch_start && formatTime(item.lunch_start)}/>
        <Cell text={item?.lunch_end && formatTime(item.lunch_end)}/>
        <Cell text={item?.check_out && formatTime(item.check_out)}/>
        <Cell text={<div className={"text-xs"}>
            {absenteeism && absenteeism.find((e) => e.id === item?.absenteeism)?.name} <br/>
            {absenteeism && absenteeism.find((e) => e.id === item?.absenteeism_extra)?.name}
        </div>}/>
        <Cell text={calculateTotalTime([item?.absenteeism_hours, item?.absenteeism_hours_extra])}/>
        <Cell text={<div
            className={`p-2 text-center font-bold text-gray-600 ${item?.worked_hours > "07:55:00" ? "bg-green-400 bg-opacity-20" : "bg-red-400 bg-opacity-20"}`}>
            {item?.worked_hours}
        </div>}/>
        <Cell text={<p className={"flex"}>{item?.approved ? <CheckCircleIcon className={"w-4 mr-2 text-green-400"}/> :
            <XCircleIcon className={"w-4 mr-2 text-red-400"}/>} {item?.overtime_25_hours}</p>}/>
        <Cell text={item?.overtime_35_hours}/>

        <Cell text={item?.delay_hours}/>
        <Cell text={<div className={"hidden"}>{item?.is_day_shift ? "Dia" : "Noche"}</div>}/>
        <Cell text={<div className={"hidden"}>{item?.approved ? "SI" : "NO"}</div>}/>


    </tr>)
}));

export default TableHours;
