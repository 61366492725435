import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {addStaff, updateStaff} from "../../../redux/actions/talent_hub";
import {InputForm, SelectForm} from "../../util/HelpersForm";
import SwitchField from "../../mm/Task/SwitchField";

const Form = ({onClose, data, params}) => {
    const departments = useSelector(state => state.Users.departments);
    const positions = useSelector(state => state.Users.positions);
    const dispatch = useDispatch();

    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form) => {
            if (form.date_of_farewell === "" || form.date_of_farewell === undefined || form.date_of_farewell === null) {
                delete form.date_of_farewell;
            }

            data ? dispatch(updateStaff(data?.id, form, params)) : dispatch(addStaff(form, params));
            // onClose()
        }
    })
    return (<form className="bg-white  rounded px-4  pb-8 mb-4">
        <HeaderForm submit={formik.handleSubmit} close={onClose}/>
        <div className={`grid  grid-cols-2 gap-2`}>
            <InputForm values={formik.values.name} errors={formik.errors.name} formik={formik} field={'name'}
                       title={'Nombre'} max_length={50}/>
            <InputForm values={formik.values.last_name} errors={formik.errors.last_name} formik={formik}
                       field={'last_name'}
                       title={'Apellido'} max_length={50}/>
            <InputForm values={formik.values.dni} errors={formik.errors.dni} formik={formik} field={'dni'}
                       title={'DNI'} max_length={8}/>
            <InputForm values={formik.values.email} errors={formik.errors.email} formik={formik} field={'email'}
                       title={'Correo'} type_={'email'}/>
            <InputForm values={formik.values.phone} errors={formik.errors.phone} formik={formik} field={'phone'}
                       title={'Celular'} max_length={10}/>
            <InputForm values={formik.values.birthday} errors={formik.errors.birthday} formik={formik}
                       field={'birthday'}
                       title={'Fecha de nacimiento'} type_={'date'}/>
            <SelectForm values={formik.values.position} errors={formik.errors.position} formik={formik}
                        field={'position'}
                        title={'Cargo'}
                        options={positions ? positions : []}
                        display={'name'}/>
            <SelectForm values={formik.values.area} errors={formik.errors.area} formik={formik} field={'area'}
                        title={'Departamento'}
                        options={departments ? departments : []}
                        display={'name'}/>

            <InputForm values={formik.values.date_of_admission} errors={formik.errors.date_of_admission} formik={formik}
                       field={'date_of_admission'}
                       title={'Fecha de ingreso'} type_={'date'}/>
            <InputForm values={formik.values.date_of_farewell} errors={formik.errors.date_of_farewell} formik={formik}
                       field={'date_of_farewell'}
                       title={'Fecha de salida'} type_={'date'}/>
            <InputForm values={formik.values.overtime_hours} errors={formik.errors.overtime_hours} formik={formik}
                       field={'overtime_hours'}
                       title={'Horas extras'}/>
            <InputForm values={formik.values.hours_per_day} errors={formik.errors.hours_per_day} formik={formik}
                       type_={'time'}
                       field={'hours_per_day'}
                       title={'Horas por día'}/>
            <InputForm values={formik.values.hours_saturday} errors={formik.errors.hours_saturday} formik={formik}
                       type_={'time'}
                       field={'hours_saturday'}
                       title={'Horas sábado'}/>
            <InputForm values={formik.values.hours_sunday} errors={formik.errors.hours_sunday} formik={formik}
                       type_={'time'}
                       field={'hours_sunday'}
                       title={'Horas domingo'}/>
            <SwitchField formik={formik} name={'status'}
                         title={'Estado'}/>
            <SwitchField formik={formik} name={'trusted'}
                         title={'Personal de confianza'}/>
        </div>
    </form>);
};

const initialValues = (data) => {
    return {
        name: data?.name || '',
        last_name: data?.last_name || '',
        dni: data?.dni || '',
        email: data?.email || '',
        status: data?.status || false,
        phone: data?.phone || '',
        position: data?.position || '',
        date_of_admission: data?.date_of_admission || '',
        date_of_farewell: data?.date_of_farewell || undefined,
        birthday: data?.birthday || undefined,
        area: data?.area || '',
        overtime_hours: data?.overtime_hours || '00:00:00',
        hours_per_day: data?.hours_per_day || '08:00:00',
        hours_saturday: data?.hours_saturday || '08:00:00',
        hours_sunday: data?.hours_sunday || '08:00:00',
        trusted: data?.trusted || false,

    }
}

const newSchema = () => {
    return {
        name: Yup.string(),
        last_name: Yup.string(),
        dni: Yup.string().min(8).required(),
        email: Yup.string().email(),
        status: Yup.bool().required(),
        phone: Yup.string().required(),
        position: Yup.number().required(),
        date_of_admission: Yup.date().required(),
        birthday: Yup.date(),
        area: Yup.number().required(),
        overtime_hours: Yup.string().required(),
        hours_per_day: Yup.string().required(),
        hours_saturday: Yup.string().required(),
        hours_sunday: Yup.string().required(),
        trusted: Yup.bool().required(),
    }
}

export default Form;