import React, {useEffect, useRef, useState} from 'react';
import {ClockIcon} from "@heroicons/react/24/outline";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import BackgroundGB from "../../../assets/back.png";
import {Helmet} from "react-helmet";
import Layout from "../../../hocs/Layout";
import {sendTracking} from "../../../redux/actions/talent_hub";
import {useDispatch, useSelector} from "react-redux";


const OptionButton = ({option, attendance, handleAttendanceChange}) => (<li
    key={option.id}
    className={`w-full ${option.id === 'check_out' ? '' : 'border-b border-gray-200 sm:border-b-0 sm:border-r'}`}
>
    <button
        id={option.id}
        type="button"
        value={option.id}
        className={`w-full py-3 pl-3 pr-6 flex items-center justify-between focus:outline-none ${attendance === option.id ? 'bg-blue-50' : ''}`}
        onClick={handleAttendanceChange}
    >

        <div className="flex items-center justify-center">
            {option.icon}
            <span className="ml-2 text-start">{option.label}</span>
        </div>

        {attendance === option.id && <CheckCircleIcon className="h-5 w-5 text-blue-600"/>}
    </button>
</li>);

const Home = () => {
    const [code, setCode] = useState('');
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const [attendance, setAttendance] = useState('check_in');
    const info = useSelector(state => state.TalentHub.info);


    useEffect(() => {
        inputRef?.current?.focus();
        const handleClickOutside = (e) => {
            if (!inputRef.current?.contains(e.target)) {
                inputRef.current?.focus();
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (code.length === 8 && attendance !== '') {
            dispatch(sendTracking({code, attendance}));
            setCode('');
        }
    };

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= 8) {
            setCode(inputValue.replace(/'/g, '-'));
        }
    };


    const options = [{id: 'check_in', label: 'Ingreso', icon: <ClockIcon className="h-5 w-5"/>}, {
        id: 'lunch_start', label: 'Inicio receso', icon: <ClockIcon className="h-5 w-5"/>
    }, {id: 'lunch_end', label: 'Fin receso', icon: <ClockIcon className="h-5 w-5"/>}, {
        id: 'check_out', label: 'Salida', icon: <ClockIcon className="h-5 w-5"/>
    },];

    // if (user && user.permissions === 'FIND') return <Navigate to='/scanner'/>;

    return (<Layout>
        <Helmet>
            <title>Sistema de Control de Tareos (SCT)</title>
        </Helmet>
        <div
            className="w-full h-screen relative flex items-center justify-center "
            style={{
                backgroundImage: "url(" + BackgroundGB + ")", backgroundSize: "cover", backgroundRepeat: "repeat",
            }}
        >
            <div className="w-full lg:w-8/12 px-4 -mt-24">
                <div className="relative  w-full  shadow-xl rounded-lg bg-white border-1">
                    <div className="mb-0 px-6 bg-green-400 bg-opacity-60 py-2">
                        <h6 className="text-white text-md text-center font-bold">
                            {!info?.staff_name && 'ASISTENCIA'} <span
                            className={"uppercase"}>{info?.staff_name}</span>
                            INFORMACION
                        </h6>
                    </div>

                    <div className="flex flex-col px-4 overflow-hidden">
                        <div className="text-gray-500 text-center font-bold p-4">
                            <ul className="items-center w-full text-sm font-medium text-gray-900  border border-gray-200 rounded-lg sm:flex ">
                                {options.map((option) => (<OptionButton
                                    key={option.id}
                                    option={option}
                                    attendance={attendance}
                                    handleAttendanceChange={() => setAttendance(option.id)}
                                />))}
                            </ul>
                        </div>

                        <form onSubmit={handleSubmit}>
                            <input
                                ref={inputRef}
                                value={code}
                                onChange={handleInputChange}
                                type="text"
                                tabIndex={0}
                                className="text-sm w-2 absolute -top-3 bg-transparent px-4 py-2 border border-solid border-gray-300 rounded mt-4"
                                maxLength={8}
                                minLength={8}
                            />
                        </form>

                        <p className={"text-black text-center"}></p>
                        <div className={"grid md:grid-cols-2 text-gray-400 text-sm font-light w-full mb-4"}>
                            <p className={"text-center"}>Ingreso:
                                <span>{info?.check_in && info?.check_in !== null && new Date(info?.check_in).toLocaleTimeString('es-PE', {
                                    hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'America/Lima'
                                })}</span>
                            </p>

                            <p className={"text-center"}>Ingreso receso:
                                <span>{info?.lunch_start && info?.lunch_start !== null && new Date(info?.lunch_start).toLocaleTimeString('es-PE', {
                                    hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'America/Lima'
                                })}</span>
                            </p>
                            <p className={"text-center"}>
                                Fin receso:
                                <span>{info?.lunch_end && info?.lunch_end !== null && new Date(info?.lunch_end).toLocaleTimeString('es-PE', {
                                    hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'America/Lima'
                                })}</span>
                            </p>
                            <p className={"text-center"}>Salida:
                                <span>{info?.check_out && info?.check_out !== null && new Date(info?.check_out).toLocaleTimeString('es-PE', {
                                    hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'America/Lima'
                                })}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </Layout>);
};
export {Home};