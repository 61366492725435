import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {InputForm, SelectForm} from "../../util/HelpersForm";
import {addDownloadLot, updateDownloadLot} from "../../../redux/actions/logistic";


const FormDownload = ({data, onClose, params}) => {
    const dispatch = useDispatch();
    const external = useSelector(state => state.Logistic.external);

    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            if (form.payment_date === "") {
                form.payment_date = null;
            }
            data ? dispatch(updateDownloadLot(data?.id, form, params)) : dispatch(addDownloadLot(form, params));
            onClose();
        }
    })

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-1 gap-2`}>

                <InputForm values={formik.values.payment_date} errors={formik.errors.payment_date} formik={formik}
                           field={'payment_date'}
                           title={'Fecha de pago'} type_={'date'}/>

                <InputForm values={formik.values.cost} errors={formik.errors.cost} formik={formik} field={'cost'}
                           title={'Costo'} type={'number'}
                           max_length={7}/>

                <SelectForm values={formik.values.status} errors={formik.errors.status} formik={formik} field={'status'}
                            display={'name'}
                            title={'Estado'}
                            options={[{id: 'P', name: 'Pendiente'}, {id: 'C', name: 'Cancelado'}]}/>

                <SelectForm values={formik.values.external} errors={formik.errors.external} formik={formik}
                            field={'external'}
                            display={'full_name'}
                            title={'Operador Externo'}
                            options={external ? external : []}
                />


            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {
        payment_date: data.payment_date ? data.payment_date.split('T')[0] : '',
        status: data?.status || 'P',
        cost: data?.cost || 0.00,
        external: data?.external || '',
    }
}
const newSchema = () => {
    return {

        payment_date: Yup.date(),
        status: Yup.string().min(1).max(1).required(),
        cost: Yup.number().required(),
        external: Yup.string().min(3).required(),

    }
}

export default FormDownload;
