import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {addParcel, updateParcel} from "../../../redux/actions/agrisupply";
import {InputForm, SelectForm} from "../../util/HelpersForm";


const FormParcel = ({data, onClose, params}) => {
    const dispatch = useDispatch();
    const raw_material_suppliers = useSelector(state => state.Stakeholders.raw_material_suppliers);
    const categories = useSelector(state => state.Category.categories);

    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            data ? dispatch(updateParcel(data?.id, form, params)) : dispatch(addParcel(form, params));
            onClose();
        }
    })

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-2 gap-2`}>
                <InputForm values={formik.values.name} errors={formik.errors.name} formik={formik} field={'name'}
                           title={'Nombre'} max_length={100}/>
                <SelectForm values={formik.values.provider} errors={formik.errors.provider} formik={formik}
                            field={'provider'} title={'Proveedor'}
                            options={raw_material_suppliers ? raw_material_suppliers : []} display={'name'}/>
                <SelectForm values={formik.values.product} errors={formik.errors.product} formik={formik}
                            field={'product'} title={'Producto'}
                            options={categories ? categories.find(item => item.name === "Materia Prima")?.children : []}
                            display={'name'}/>
                <InputForm values={formik.values.sector} errors={formik.errors.sector} formik={formik} field={'sector'}
                           title={'Sector'} max_length={25}/>
                <InputForm values={formik.values.latitude} errors={formik.errors.latitude} formik={formik}
                           field={'latitude'}
                           title={'Latitud'} max_length={25}/>
                <InputForm values={formik.values.longitude} errors={formik.errors.longitude} formik={formik}
                           field={'longitude'}
                           title={'Longitud'} max_length={25}/>
                <InputForm values={formik.values.variety} errors={formik.errors.variety} formik={formik}
                           field={'variety'}
                           title={'Variedad'} max_length={25}/>
                <InputForm values={formik.values.pesticide} errors={formik.errors.pesticide} formik={formik}
                           field={'pesticide'}
                           title={'Fosetil'} max_length={50}/>
                <InputForm values={formik.values.pesticide} errors={formik.errors.pesticide} formik={formik}
                           field={'pesticide'}
                           title={'Pesticida'} max_length={50}/>
                <SelectForm values={formik.values.status} errors={formik.errors.status} formik={formik}
                            field={'status'} title={'Estado'}
                            options={[{id: 'C', name: 'Limpio'}, {id: 'P', name: 'Presencia'}]} display={'name'}/>


            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {
        name: data?.name || '',
        provider: data?.provider || '',
        product: data?.product || '',
        sector: data?.sector || '',
        latitude: data?.latitude || '',
        longitude: data?.longitude || '',
        variety: data?.variety || '',
        fosetil: data?.fosetil || '',
        pesticide: data?.pesticide || '',
        status: data?.status || 'C',
    }
}
const newSchema = () => {
    return {
        name: Yup.string().required(),
        provider: Yup.string().min(3).required(),
        product: Yup.string().min(1).required(),
        sector: Yup.string().required(),
        latitude: Yup.string().required(),
        longitude: Yup.string().required(),
        variety: Yup.string(),
        fosetil: Yup.boolean(),
        pesticide: Yup.string(),
        status: Yup.string().required(),
    }
}

export default FormParcel;
