import React, {useEffect} from 'react';
import TableBase, {Cell} from "../../../util/Table";
import {useDispatch, useSelector} from "react-redux";
import {formatDate} from "../../../util/helpers";
import Humanize from "humanize-plus";
import {getReceipts} from "../../../../redux/actions/logistic";

const TableIncoming = ({params}) => {
    const articles = useSelector(state => state.Logistic.article);
    const material_suppliers = useSelector(state => state.Stakeholders.material_suppliers);

    const data = useSelector(state => state.Logistic.receipt);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getReceipts(params))
    }, [dispatch]);


    const columns = ['Nombre', 'OC', 'Factura', 'Proveedor', 'Fecha de OC', 'Fecha de Ingreso', 'Precio', 'Cantidad', 'Precio Total', 'Stock']
    return (<TableBase columns={columns} cell={Row(data, articles, material_suppliers)}/>);
};

const Row = (data, articles, suppliers) => (data && data.map((item, index) => {
    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={<p className={"font-medium"}>{articles && articles.find((e) => e.id === item?.item)?.name}</p>}/>
        <Cell text={suppliers && suppliers.find((e) => e.id === item?.supplier)?.name}/>
        <Cell text={item?.po_number}/>
        <Cell text={item?.invoice}/>

        <Cell text={item?.po_date && formatDate(item?.po_date)}/>
        <Cell text={item?.arrival_date && formatDate(item?.arrival_date)}/>
        <Cell text={Humanize.formatNumber(item?.price_per_unit, 2)}/>
        <Cell text={item?.quantity}/>
        <Cell text={Humanize.formatNumber(item?.quantity ? parseFloat(item?.quantity) * parseFloat(item?.price_per_unit) : 0,2)}/>
        <Cell text={<div
            className={`${item?.stock < 0 ? 'bg-red-400' : 'bg-green-400'} text-white font-bold w-full p-2 rounded-full text-center`}>
            <p>{item?.stock}</p>
        </div>

        }/>
    </tr>)
}));


export default TableIncoming;
