import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {InputForm, TextAreaForm} from "../../util/HelpersForm";
import {updateLot} from "../../../redux/actions/logistic";


const FormProductionDiscount = ({data, onClose, lot_id, lot}) => {
    const dispatch = useDispatch();

    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            dispatch(updateLot(lot_id, form, lot));
            onClose();
        }
    })

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-2 gap-2`}>

                <TextAreaForm class_={"col-span-2"} values={formik.values.discount_description} errors={formik.errors.discount_description}
                           formik={formik} field={'discount_description'}
                           title={'Descripción de descuento'}/>
                <InputForm values={formik.values.discount_price_kg} errors={formik.errors.discount_price_kg}
                           formik={formik} field={'discount_price_kg'}
                           title={'Kg a descontar/precio'} max_length={8}/>
                <InputForm values={formik.values.discount_price} errors={formik.errors.discount_price} formik={formik}
                           field={'discount_price'}
                           title={'Precio de descuento'} max_length={4}/>

                <InputForm values={formik.values.discount_caliber_kg} errors={formik.errors.discount_caliber_kg}
                           formik={formik} field={'discount_caliber_kg'}
                           title={'Kg a descontar/calibre'} max_length={8}/>
                <InputForm values={formik.values.discount_caliber_price} errors={formik.errors.discount_caliber_price} formik={formik}
                           field={'discount_caliber_price'}
                           title={'Precio de descuento(calibre)'} max_length={4}/>

                <InputForm values={formik.values.supplier_price} errors={formik.errors.supplier_price} formik={formik}
                           field={'supplier_price'}
                           title={'Precio campo'} max_length={8}/>
                <InputForm values={formik.values.invoice} errors={formik.errors.invoice} formik={formik}
                           field={'invoice'}
                           title={'Factura'} max_length={15}/>


            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {
        discount_description: data?.discount_description || '',
        discount_price_kg: data?.discount_price_kg || 0,
        discount_price: data?.discount_price || 0,
        discount_caliber_kg: data?.discount_caliber_kg || 0,
        discount_caliber_price: data?.discount_caliber_price || 0,
        supplier_price: data?.supplier_price || '',
        invoice: data?.invoice || ''
    }
}
const newSchema = () => {
    return {
        discount_description: Yup.string().required(),
        discount_price_kg: Yup.number().required(),
        discount_price: Yup.number().min(0).required(),
        discount_caliber_kg: Yup.number().required(),
        discount_caliber_price: Yup.number().min(0).required(),
        supplier_price: Yup.number().min(0).required(),
        invoice : Yup.string()
    }
}

export default FormProductionDiscount;
