import React, {useEffect} from 'react';
import {Disclosure, Transition} from "@headlessui/react";
import {MinusIcon, PlusIcon} from "@heroicons/react/20/solid";
import {MapPinIcon, WrenchScrewdriverIcon} from "@heroicons/react/24/outline";
import Layout from "../../../hocs/Layout";
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {getTree} from "../../../redux/actions/maintenance";
import ChangePage from "../../../components/mm/Assets/ChangePage";


const Tree = () => {
    const dispatch = useDispatch();
    const tree = useSelector(state => state.Maintenance.tree);

    useEffect(() => {
        dispatch(getTree());
    }, [dispatch]);


    return (<Layout>
        <Helmet>
            <title>Activos</title>
        </Helmet>
        <ChangePage/>
        <div className="h-screen overflow-y-auto scrollbar-hide w-full bg-white rounded-l-2xl">

            {tree && tree.map((i, index) => (<Disclosure key={index} as="div">
                {({open}) => (<>
                    <DisclosureButton open={open} item={i}/>
                    <DisclosurePanel open={open} children={i?.children} parentName={i?.name}/>
                </>)}
            </Disclosure>))}
        </div>


    </Layout>);
};


const DisclosureButton = ({open, item}) => (<Disclosure.Button
    className="flex w-full  justify-start gap-2 border-b-2 items-center bg-white px-4 py-2 text-left text-sm font-medium text-black hover:bg-green-600 hover:bg-opacity-10 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
    {item?.children?.length > 0 && (open ?
        <MinusIcon className="h-6 w-6 bg-gray-600 text-white bg-opacity-20 rounded-full hover:bg-white"/> :
        <PlusIcon className="h-6 w-6 bg-gray-600 text-white bg-opacity-20 rounded-full hover:bg-white"/>)}
    <MapPinIcon className="h-5 w-5 text-green-500"/>
    <p>
        <span className="font-normal font-sans text-xs">{item?.name}</span>
        <br/>
        <span className="font-normal text-gray-400 font-sans text-[10px]">{"// GREENBOX/"}</span>
    </p>
</Disclosure.Button>);

const DisclosurePanel = ({open, children, parentName}) => (<Transition
    show={open}
    enter="transition duration-100 ease-out"
    enterFrom="transform scale-95 opacity-0"
    enterTo="transform scale-100 opacity-100"
    leave="transition duration-75 ease-out"
    leaveFrom="transform scale-100 opacity-100"
    leaveTo="transform scale-95 opacity-0"
>
    {children.map((j, index) => (<Disclosure.Panel key={index}
                                                   className="flex w-full justify-start gap-2 border-b-2 items-center bg-white pl-16 py-2 text-left text-sm font-medium text-black hover:bg-green-600 hover:bg-opacity-10 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
        <WrenchScrewdriverIcon className="h-5 w-5 text-green-500"/>
        <p>
            <span className="font-normal font-sans text-xs">{j.name}</span>
            <br/>
            <span className="font-normal text-gray-400 font-sans text-[10px]">// GREENBOX/ {parentName}/</span>
        </p>
    </Disclosure.Panel>))}
</Transition>);


export default Tree;