import React, {useEffect} from 'react';
import Header from "../components/navigation/Header";
import {Footer} from "../components/navigation/Footer";
import {useDispatch, useSelector} from "react-redux";
import {check_authenticated, load_user, refresh} from "../redux/actions/auth";
import {Navigate} from "react-router-dom";

const Layout = ({children}) => {
    const isAuthenticated = useSelector(state => state.Auth.isAuthenticated);
    const me = useSelector(state => state.Auth.user);
    const accessUrls = me?.access_url?.map(url => url.name) || []; // Asume que esto devuelve algo como ["/", "/logistic/freight", "/logistic/lot/"]
    const path = window.location.pathname;


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(refresh())
        dispatch(check_authenticated())
        dispatch(load_user())
    }, []);

    if (!isAuthenticated) return <Navigate to='/login/'/>;
    const checkAccess = (currentPath, accessUrls) => {
        if (accessUrls.includes("/") && currentPath === "/") {
            return true;
        }

        return accessUrls.some(accessUrl => {
            if (accessUrl === "/") {
                return false;
            }

            let regexPattern = "^" + accessUrl.replace(/:\w+/g, "[^/]+") + "$";
            if (accessUrl.endsWith("/")) {
                regexPattern = "^" + accessUrl;
            }

            const regex = new RegExp(regexPattern);
            return regex.test(currentPath);
        });
    };

    if (!checkAccess(path, accessUrls)) {
        // Si no hay acceso, redirige al primer path de la lista de acceso o a una ruta por defecto
        const redirectTo = accessUrls.length > 0 ? accessUrls[0] : '/';
        return <Navigate to={redirectTo}/>;
    }


    return (<div className="flex-1 bg-[#f1f5f9] rounded-3xl shadow-lg p-3 w-full scrollbar-hide overflow-y-scroll">
        <Header/>
        <div
            className="flex flex-col justify-start p-4 gap-4 overflow-scroll scrollbar-hide  w-full  overflow-y-scroll">
            {children}
        </div>
        <Footer/>
    </div>);
};

export default Layout;

