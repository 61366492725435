import React, {useEffect} from 'react';
import TableBase, {Cell} from "../../../util/Table";
import {useDispatch, useSelector} from "react-redux";
import {getIssues} from "../../../../redux/actions/logistic";
import {formatDate} from "../../../util/helpers";
import Humanize from "humanize-plus";

const TableOutput = ({params}) => {
    const data = useSelector(state => state.Logistic.issue);
    const departments = useSelector(state => state.Users.departments);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getIssues(params))
    }, []);

    const columns = ['Nombre', 'OC', 'Área', 'Lote', 'Fecha de Salida', 'Cantidad', 'Precio Unitario', 'Precio Total']
    return (<TableBase columns={columns} cell={Row(data, departments)}/>);
};

const Row = (data, departments) => (data && data.map((item, index) => {
    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={<p className={"font-medium"}>{item?.item_name}</p>}/>
        <Cell text={item?.oc}/>
        <Cell text={departments && departments.find((e)=>e.id===item?.area)?.name}/>
        <Cell text={item?.lot_id}/>

        <Cell text={item?.date && formatDate(item?.date)}/>
        <Cell text={item?.quantity}/>
        <Cell text={item?.price}/>
        <Cell text={Humanize.formatNumber(item?.price * item?.quantity,2)}/>

    </tr>)
}));

export default TableOutput;
