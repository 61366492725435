import React from 'react';
import {InputSearch, SelectSearch} from "../../util/Filters";
import {PaperAirplaneIcon} from "@heroicons/react/24/outline";

const Filters = ({params, setParams, handleSearch}) => {
    return (<div className="flex flex-col md:flex-row items-center gap-2 justify-end py-4 bg-white ">
            <InputSearch name={"Buscar"} value={params.full_name} color={'bg-gray-100'}
                         handleSearch={handleSearch}
                         handle={(value) => setParams({...params, 'full_name': value.target.value})}/>
            <SelectSearch name={"Activo"} value={params.status} color={'bg-gray-100'}
                          handleSearch={handleSearch} field={'name'}
                          items={[{id: 'true', name: 'Activo'}, {id: 'false', name: 'Inactivo'}]}
                          handle={(value) => setParams({...params, 'status': value.target.value})}/>
            <button onClick={handleSearch}
                    className="bg-gray-100 flex gap-2 items-center  hover:bg-gray-300 w-full md:w-max text-gray-500 hover:text-white self-end mb-1 font-medium py-2 px-4 rounded-md justify-center">
                Buscar <PaperAirplaneIcon className="h-5 w-5"/>
            </button>

        </div>

    );
};

export default Filters;
