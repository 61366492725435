import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {InputForm, SelectForm} from "../../util/HelpersForm";
import {addBatchOutput, updateBatchOutput} from "../../../redux/actions/logistic";


const FormOutput = ({data, onClose, params}) => {
    const dispatch = useDispatch();
    const stock = useSelector(state => state.Logistic.stock);

    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            data ? dispatch(updateBatchOutput(data?.id, form, params)) : dispatch(addBatchOutput(form, params));
            onClose();
        }
    })



    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4" onKeyDown={handleKeyDown}>
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-2 gap-2`}>
                <InputForm values={formik.values.date} errors={formik.errors.date} formik={formik}
                           field={'date'} type_={'date'}
                           title={'Fecha'} max_length={10}/>
                {!data&&
                <SelectForm values={formik.values.lot} errors={formik.errors.lot} formik={formik} field={'lot'}
                            display={'lot'}
                            title={'Lote'} options={stock}/>}
                <InputForm values={formik.values.kg} errors={formik.errors.kg} formik={formik}
                           field={'kg'}
                           title={'Kg'} max_length={7}/>
                <SelectForm values={formik.values.destine} errors={formik.errors.destine} formik={formik}
                            field={'destine'} display={'name'}
                            title={'Destino'}
                            options={[{id: 'P', name: 'Producción'}, {id: 'M', name: 'Merma'}, {
                                id: 'I',
                                name: 'I+D'
                            }]}/>

            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {
        // el date pero en formato dd/mm/yyyy
        date: data?.date || new Date().toISOString().split('T')[0],
        lot: data?.lot || '',
        kg: data?.kg || 0,
        destine: data?.destine || 'P',

    }
}
const newSchema = () => {
    return {
        date: Yup.date().required(),
        lot: Yup.string().min(8).required(),
        kg: Yup.number().positive().required(),
        destine: Yup.string().min(1).max(1).required(),
    }
}

export default FormOutput;
