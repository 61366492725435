import React, {useEffect, useState} from 'react';
import Layout from "../../../../hocs/Layout";
import {Tab} from "@headlessui/react";
import TableStock from "../../../../components/e2e/Logistic/Materials/TableStock";
import TableIncoming from "../../../../components/e2e/Logistic/Materials/TableIncoming";
import TableOutput from "../../../../components/e2e/Logistic/Materials/TableOutput";
import Filters from "../../../../components/e2e/Logistic/Materials/Filters";
import {useDispatch, useSelector} from "react-redux";
import {getManufacturingCompanies, getMaterialSuppliers} from "../../../../redux/actions/stakeholders";
import {get_categories} from "../../../../redux/actions/category";
import ModalHook from "../../../../hooks/ModalHook";
import Modal from "../../../../components/util/Modal";
import FormArticle from "../../../../components/e2e/Logistic/Materials/FormArticle";
import ButtonHelper from "../../../../components/util/ButtonHelper";
import FormReceipt from "../../../../components/e2e/Logistic/Materials/FormReceipt";
import {getArticles, getIssues, getReceipts} from "../../../../redux/actions/logistic";
import {getDepartments} from "../../../../redux/actions/users";
import FormIssue from "../../../../components/e2e/Logistic/Materials/FormIssue";
import FormTransfer from "../../../../components/e2e/Logistic/Materials/FormTransfer";
import FormRequest from "../../../../components/e2e/Logistic/Materials/FormRequest";
import {Helmet} from "react-helmet";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Materials = () => {
    const [params, setParams] = useState({name: '', group: '', manufacturer: '', year: '', month: ''})
    const dispatch = useDispatch();
    const {content, setContent, isOpen, openModal} = ModalHook();
    const manufacturers = useSelector(state => state.Stakeholders.manufacturing_companies);
    const categories = useSelector(state => state.Category.categories);


    useEffect(() => {
        setParams({
            ...params, month: new Date().getMonth() + 1, year: new Date().getFullYear(),
        })
        dispatch(getManufacturingCompanies())
        dispatch(getMaterialSuppliers())
        dispatch(get_categories())
        dispatch(getDepartments())
    }, []);


    useEffect(() => {
        const updates = {};
        if (manufacturers && manufacturers.length > 0) {
            updates.manufacturer = manufacturers[0].id;
        }
        if (categories && categories.length > 0) {
            updates.group = categories[0].id;
        }
        if (Object.keys(updates).length > 0) {
            setParams(prevParams => ({
                ...prevParams, ...updates,
            }));
        }
    }, [manufacturers, categories]);


    const handleAddMaterial = () => {
        const formComponent = <FormArticle onClose={openModal} params={params}/>;
        setContent(formComponent);
        openModal();
    };

    const handleAddReceipt = (item) => {
        const formComponent = <FormReceipt onClose={openModal} params={params} item={item}/>;
        setContent(formComponent);
        openModal();
    };

    const handleAddIssue = (item) => {
        const formComponent = <FormIssue onClose={openModal} params={params} item={item}/>;
        setContent(formComponent);
        openModal();
    }

    const handleTransfer = (item) => {
        const formComponent = <FormTransfer onClose={openModal} params={params} item={item}/>;
        setContent(formComponent);
        openModal();
    };
    const handleRequest = (item) => {
        const formComponent = <FormRequest onClose={openModal} params={params} item={item}/>;
        setContent(formComponent);
        openModal();
    }

    return (<Layout>
        <Helmet>
            <title>Almacén</title>
        </Helmet>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <div className="mx-auto container bg-white mt-4 p-4 ">
            <Tab.Group>
                <Tab.List className="flex space-x-1  border-b-4 p-1 overflow-scroll scrollbar-hide">
                    <Tab
                        className={({selected}) => classNames('w-max px-8  py-2.5 text-sm font-medium leading-5 border-r-2 ', selected ? 'bg-white text-green-400 font-bold' : 'text-gray-500 hover:bg-green-200 hover:bg-opacity-10 hover:text-green-600')}>
                        Stock
                    </Tab>
                    <Tab
                        className={({selected}) => classNames('w-max px-8  py-2.5 text-sm font-medium leading-5 border-r-2 ', selected ? 'bg-white text-green-400 font-bold' : 'text-gray-500 hover:bg-green-200 hover:bg-opacity-10 hover:text-green-600')}>
                        Ingresos
                    </Tab>
                    <Tab
                        className={({selected}) => classNames('w-max px-8  py-2.5 text-sm font-medium leading-5 border-r-2 ', selected ? 'bg-white text-green-400 font-bold' : 'text-gray-500 hover:bg-green-200 hover:bg-opacity-10 hover:text-green-600')}>
                        Salidas
                    </Tab>
                </Tab.List>
                <Tab.Panels className="mt-2 w-full">


                    <Tab.Panel>
                        <ButtonHelper handle={handleAddMaterial}/>
                        <Filters params={params} setParams={setParams}
                                 handleSearch={() => dispatch(getArticles(params))} businessFilter={true}
                                 yearFilter={false}
                                 monthFilter={false}/>
                        <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                            <TableStock handleReceipt={handleAddReceipt} handleIssue={handleAddIssue}
                                        handleTransfer={handleTransfer} handleRequest={handleRequest} params={params}/>
                        </div>
                    </Tab.Panel>

                    <Tab.Panel>
                        <Filters params={params} setParams={setParams}
                                 handleSearch={() => dispatch(getReceipts(params))} businessFilter={true}
                                 yearFilter={true}
                                 monthFilter={true}/>
                        <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                            <TableIncoming params={params}/>
                        </div>
                    </Tab.Panel>

                    <Tab.Panel>
                        <Filters params={params} setParams={setParams}
                                 handleSearch={() => dispatch(getIssues(params))}
                                 businessFilter={true}
                                 yearFilter={true}
                                 monthFilter={true}/>
                        <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                            <TableOutput params={params}/>
                        </div>
                    </Tab.Panel>

                </Tab.Panels>
            </Tab.Group>
        </div>

    </Layout>);
};

export default Materials;
