import React, {useEffect, useState} from 'react';
import Layout from "../../../../hocs/Layout";
import TableGLP from "../../../../components/e2e/Logistic/TableGLP";
import {useDispatch, useSelector} from "react-redux";
import {getGLP} from "../../../../redux/actions/logistic";
import FilterGLP from "../../../../components/e2e/Logistic/FilterGLP";
import FormGLP from "../../../../components/e2e/Logistic/FormGLP";
import ModalHook from "../../../../hooks/ModalHook";
import Modal from "../../../../components/util/Modal";
import {Helmet} from "react-helmet";

const GLP = () => {
    const [params, setParams] = useState({month: '', year: ''})
    const dispatch = useDispatch();
    const {content, setContent, isOpen, openModal} = ModalHook();

    const data = useSelector(state => state.Logistic.glp)

    useEffect(() => {
        setParams({...params, month: new Date().getMonth() + 1, year: new Date().getFullYear()})
        dispatch(getGLP({month: new Date().getMonth() + 1, year: new Date().getFullYear()}))
    }, []);

    const handleSearch = () => {
        dispatch(getGLP(params))
    }
    const handleUpdate = (data) => {
        const formComponent = <FormGLP onClose={openModal} data={data} params={params}/>;
        setContent(formComponent);
        openModal();
    };


    return (<Layout>
        <Helmet>
            <title>GLP</title>
        </Helmet>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <div className={"bg-white rounded-2xl p-4 h-fit"}>
            <div className={"flex justify-end w-full"}>
                <FilterGLP handleSearch={handleSearch} setParams={setParams} params={params}/>
            </div>
            <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                <TableGLP data={data} handleUpdate={handleUpdate}/>
            </div>
        </div>

    </Layout>);
};

export default GLP;
