import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {PencilSquareIcon} from "@heroicons/react/24/outline";
import Humanize from "humanize-plus";
import {formatDateTZ} from "../../util/helpers";
import {useSelector} from "react-redux";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/24/solid";

const Table = ({data, handleUpdate}) => {
    const technical = useSelector(state => state.Maintenance.technical);
    const columns = ['', 'Fecha', 'Hora', 'Dosificación 01', 'Dosificación 02', 'Dureza', 'PPM', 'Limpieza', 'Nivel 01', 'Nivel 02', 'Técnico']
    return (<TableBase columns={columns} cell={Row(data, handleUpdate, technical)}/>);
};


const Row = (data, update, technical) => (data && data.map((item, index) => {

    return <tr key={index} className="h-max border-gray-300  border-b">

        <Cell text={<div className={"font-bold flex items-center whitespace-nowrap"}>
            <PencilSquareIcon onClick={() => update(item)} title={"Editar"}
                              className={"h-5 w-5 mr-2 text-blue-500 cursor-pointer"}/>
        </div>}/>
        <Cell text={formatDateTZ(item?.date)}/>
        <Cell text={item?.hour}/>
        <Cell text={Humanize.formatNumber(item?.dosage_1, 2)}/>
        <Cell text={Humanize.formatNumber(item?.dosage_2, 2)}/>
        <Cell text={Humanize.formatNumber(item?.hardness, 2)}/>
        <Cell text={Humanize.formatNumber(item?.ppm, 2)}/>
        <Cell text={<div className={"flex justify-center"}>
            {item?.clean ? <CheckCircleIcon className={"h-5 w-5 text-green-500"}/> :
                <XCircleIcon className={"h-5 w-5 text-red-500"}/>}
        </div>}/>
        <Cell text={Humanize.formatNumber(item?.level_1, 2)}/>
        <Cell text={Humanize.formatNumber(item?.level_2, 2)}/>
        <Cell text={technical && technical.find(tech => tech.id === item?.technical)?.name}/>


    </tr>
}));

export default Table;
