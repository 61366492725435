import React, {useEffect, useState} from 'react';
import Layout from "../../../hocs/Layout";
import TableDaily from "../../../components/sct/Daily/TableDaily";
import {useDispatch} from "react-redux";
import Filters from "../../../components/sct/Daily/Filters";
import {getCalendar} from "../../../redux/actions/talent_hub";


const Daily = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Los meses en JavaScript van de 0 a 11
    const currentDay = currentDate.getDate();

    let previousYear = currentYear;
    let previousMonth = currentMonth;

    const [selectedOption, setSelectedOption] = useState('worked');


    const radioOptions = [{value: 'worked', label: 'Horas trabajadas'}, {
        value: 'delay', label: 'Horas de tardanza'
    }, {value: 'overtime', label: 'Horas extras'}, {value: 'compensated', label: 'Horas compensadas'},];


    if (currentDay < 24) {
        if (currentMonth === 1) {
            previousMonth = 12;
            previousYear--;
        } else {
            previousMonth--;
        }
    }
    const [params, setParams] = useState({year: previousYear, month: previousMonth, user: '', department: ''});
    const dispatch = useDispatch();

    useEffect(() => {
        const payload = {
            start_date: `${params.year}-${params.month}-24`,
            end_date: `${parseInt(params.month) === 12 ? parseInt(params.year) + 1 : params.year}-${parseInt(params.month) === 12 ? 1 : parseInt(params.month) + 1}-23`,
            user: params.user,
            department: params.department
        }
        dispatch(getCalendar(payload));
    }, [dispatch])

    const handleSearch = () => {
        const payload = {
            start_date: `${params.year}-${params.month}-24`,
            end_date: `${parseInt(params.month) === 12 ? parseInt(params.year) + 1 : params.year}-${parseInt(params.month) === 12 ? 1 : parseInt(params.month) + 1}-23`,
            user: params.user,
            department: params.department
        }
        dispatch(getCalendar(payload));
    };

    return (<Layout>

        <div
            className={"bg-white rounded-2xl p-4 h-fit"}>
            <Filters params={params} setParams={setParams} handleSearch={handleSearch}/>
            <div className="flex sm:flex-row gap-2 flex-col justify-center items-center sm:justify-end">
                {radioOptions.map((option) => (
                    <label key={option.value} className="flex items-center mb-2 text-black text-xs w-full  md:w-max ">
                        <input
                            type="radio"
                            value={option.value}
                            checked={selectedOption === option.value}
                            onChange={() => setSelectedOption(option.value)}
                            className="mr-2 items-start"
                        />
                        {option.label}
                    </label>))}
            </div>

            <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                <TableDaily option={selectedOption}/>
            </div>
        </div>

    </Layout>);
};

export default Daily;
