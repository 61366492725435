import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {useSelector} from "react-redux";

function rgbToHex(r, g, b) {
    return "#" + [r, g, b].map(x => {
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    }).join('').toUpperCase();
}

function processDelay(delay) {
    const parts = delay.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const seconds = parseInt(parts[2], 10);

    const totalMinutes = hours * 60 + minutes + (seconds >= 30 ? 1 : 0);

    let elements;
    if (totalMinutes < 10) {
        elements = 0;
    } else {
        elements = Math.ceil((totalMinutes - 10) / 10);
    }

    const arraySize = Math.min(elements, 5);

    return Array.from({length: arraySize}, (_, index) => {
        const id = index + 1;
        const intensity = 255 - 40 * (id);
        const color = rgbToHex(255, intensity, intensity);
        return {id, color};
    });
}

const TableDelay = ({handleView}) => {
    const data = useSelector(state => state.TalentHub.delay_hours);
    const columns = ['Apellidos y nombre', 'Horas de tardanza', 'Documentos']
    return (<TableBase columns={columns} cell={Row(data, handleView)}/>);
};

const Row = (data, handleView) => (data && data.map((item, index) => {


    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={item?.user}/>
        <Cell text={<div
            className={`w-max text-start rounded-2xl py-2 px-4 ${item?.total_delay !== "0:00:00" ? "bg-red-400 text-white font-bold" : ""}`}>
            <p>{item?.total_delay}</p>
        </div>}/>
        <Cell text={<div className="flex gap-2">
            {processDelay(item?.total_delay).map((i, index) => <div onClick={() => handleView(index,item)}
                                                                       key={index}
                                                                       style={{backgroundColor: i.color}}
                                                                       className={`w-4 h-4 rounded-full `}/>)}

        </div>

        }/>
    </tr>)
}));

export default TableDelay;
