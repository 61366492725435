import React, {useEffect, useRef} from 'react';

const Fotocheck = ({template_url, photo_url, barcode_url,name,last_name,position}) => {
    const canvasRef = useRef(null);
    const barcodeRef = useRef(null);
    const photoRef = useRef(null); //

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const loadImage = (url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'anonymous';
                img.src = url;
                img.onload = () => resolve(img);
                img.onerror = reject;
            });
        };

        const drawImage = async () => {
            const template = await loadImage(template_url);

            canvas.width = template.width;
            canvas.height = template.height;


            ctx.drawImage(template, 0, 0);




            // // Texto
            ctx.font = 'bold 28px Cascadia';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            const centerX = canvas.width / 4;
            ctx.fillText(last_name, centerX, 600);
            ctx.fillText(name, centerX, 640);
            ctx.font = 'bold 20px Cascadia';
            ctx.fillStyle = '#2cb674';
            ctx.fillText(position, centerX, 680);
            ctx.drawImage(barcodeRef.current, 635, 575, 500, 310);
            ctx.drawImage(photoRef.current,169, 170, 250, 322);
        };

        drawImage();


    }, [template_url, photo_url, barcode_url]);


    return (<div>
            <img ref={barcodeRef} src={barcode_url} alt="Barcode" style={{display: 'none'}}/>
            <img ref={photoRef} src={photo_url} alt="Photo" style={{display: 'none'}}/>
            <canvas ref={canvasRef}></canvas>
        </div>);
};

export default Fotocheck;
