import {
    ADD_STAFF_FAIL,
    ADD_STAFF_SUCCESS,
    ADD_TRACKING_FAIL,
    ADD_TRACKING_SUCCESS,
    DELETE_STAFF_FAIL,
    DELETE_STAFF_SUCCESS,
    DELETE_TRACKING_FAIL,
    DELETE_TRACKING_SUCCESS,
    FIND_USER_FAIL,
    FIND_USER_SUCCESS,
    GET_ABSENTEEISM_FAIL,
    GET_ABSENTEEISM_SUCCESS,
    GET_CALENDAR_DAYS_FAIL,
    GET_CALENDAR_DAYS_SUCCESS,
    GET_DEPARTMENTS_STAFF_FAIL,
    GET_DEPARTMENTS_STAFF_SUCCESS,
    GET_OUTSOURCING_FAIL,
    GET_OUTSOURCING_SUCCESS,
    GET_STAFF_FAIL,
    GET_STAFF_NOT_TRACKING_FAIL,
    GET_STAFF_NOT_TRACKING_SUCCESS,
    GET_STAFF_SUCCESS,
    GET_SUMMARY_TRACKING_FAIL,
    GET_SUMMARY_TRACKING_SUCCESS,
    GET_TRACKING_FAIL,
    GET_TRACKING_REAL_FAIL,
    GET_TRACKING_REAL_SUCCESS,
    GET_TRACKING_SUCCESS,
    SEND_TRACKING_FAIL,
    SEND_TRACKING_SUCCESS,
    UPDATE_STAFF_FAIL,
    UPDATE_STAFF_SUCCESS,
    UPDATE_TRACKING_FAIL,
    UPDATE_TRACKING_SUCCESS,
    GET_DELAY_HOURS_SUCCESS,
    GET_DELAY_HOURS_FAIL
} from "../actions/types";

const initialState = {
    staff: null,
    department: null,
    absenteeism: null,
    tracking: null,
    real: null,
    calendar: null,
    summary: null,
    outsourcing: null,
    staff_not_tracking: null,
    find: null,
    info: null,
    delay_hours: null
}

export default function TalentHub(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case FIND_USER_SUCCESS:
            return {
                ...state, find: payload.data
            }

        case FIND_USER_FAIL:
            return {
                ...state, find: null
            }
        case GET_STAFF_SUCCESS:
            return {
                ...state, staff: payload.data
            }
        case GET_STAFF_FAIL:
            return {
                ...state, staff: null
            }
        case GET_ABSENTEEISM_SUCCESS:
            return {
                ...state, absenteeism: payload.data
            }
        case GET_ABSENTEEISM_FAIL:
            return {
                ...state, absenteeism: null
            }
        case GET_TRACKING_SUCCESS:
            return {
                ...state, tracking: payload.data
            }
        case GET_TRACKING_FAIL:
            return {
                ...state, tracking: null
            }
        case GET_TRACKING_REAL_SUCCESS:
            return {
                ...state, real: payload.data
            }
        case GET_TRACKING_REAL_FAIL:
            return {
                ...state, real: null
            }
        case GET_CALENDAR_DAYS_SUCCESS:
            return {
                ...state, calendar: payload.data
            }
        case GET_CALENDAR_DAYS_FAIL:
            return {
                ...state, calendar: null
            }
        case GET_SUMMARY_TRACKING_SUCCESS:
            return {
                ...state, summary: payload.data
            }
        case GET_SUMMARY_TRACKING_FAIL:
            return {
                ...state, summary: null
            }
        case GET_OUTSOURCING_SUCCESS:
            return {
                ...state, outsourcing: payload.data
            }
        case GET_OUTSOURCING_FAIL:
            return {
                ...state, outsourcing: null
            }
        case GET_STAFF_NOT_TRACKING_SUCCESS:
            return {
                ...state, staff_not_tracking: payload.data
            }
        case GET_STAFF_NOT_TRACKING_FAIL:
            return {
                ...state, staff_not_tracking: null
            }
        case SEND_TRACKING_SUCCESS:
            return {
                ...state, info: payload.data
            }

        case SEND_TRACKING_FAIL:
            return {
                ...state, info: null
            }
        case GET_DEPARTMENTS_STAFF_SUCCESS:
            return {
                ...state, department: payload.data
            }
        case GET_DEPARTMENTS_STAFF_FAIL:
            return {
                ...state, department: null
            }
        case GET_DELAY_HOURS_SUCCESS:
            return {
                ...state, delay_hours: payload.data
            }
        case GET_DELAY_HOURS_FAIL:
            return {
                ...state, delay_hours: null
            }
        case ADD_STAFF_SUCCESS:
        case ADD_STAFF_FAIL:
        case UPDATE_STAFF_SUCCESS:
        case UPDATE_STAFF_FAIL:
        case DELETE_STAFF_SUCCESS:
        case DELETE_STAFF_FAIL:
        case ADD_TRACKING_SUCCESS:
        case ADD_TRACKING_FAIL:
        case UPDATE_TRACKING_SUCCESS:
        case UPDATE_TRACKING_FAIL:
        case DELETE_TRACKING_SUCCESS:
        case DELETE_TRACKING_FAIL:

            return state;
        default:
            return state;
    }
}