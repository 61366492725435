import {
    SEARCH_DNI_FAIL,
    SEARCH_DNI_SUCCESS,
    SEARCH_DOLLAR_FAIL,
    SEARCH_DOLLAR_SUCCESS,
    SEARCH_RUC_FAIL,
    SEARCH_RUC_SUCCESS
} from "../actions/types";

const initialState = {
    dni: null, ruc: null, dollar: null,
}
export default function Search(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case SEARCH_DNI_SUCCESS:
            return {...state, dni: payload};
        case SEARCH_DNI_FAIL:
            return {...state, dni: null};
        case SEARCH_RUC_SUCCESS:
            return {...state, ruc: payload};
        case SEARCH_RUC_FAIL:
            return {...state, ruc: null};
        case SEARCH_DOLLAR_SUCCESS:
            return {...state, dollar: payload};
        case SEARCH_DOLLAR_FAIL:
            return {...state, dollar: null};
        default:
            return state;
    }
}
