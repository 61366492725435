import {Popover, Transition} from '@headlessui/react'
import {Fragment, useEffect, useState} from 'react'

import {NavLink} from "react-router-dom";

import Tree from "../../../container/pages/MM/Tree";
import Tools from "../../../container/pages/MM/Tools";
import Equipments from "../../../container/pages/MM/Equipments";
import Facilities from "../../../container/pages/MM/Facilities";
import {ListBulletIcon, WrenchIcon} from "@heroicons/react/24/outline";
import {BuildingOffice2Icon, EyeIcon, PuzzlePieceIcon, TagIcon} from "@heroicons/react/24/solid";


export default function ChangePage() {
    const page = window.location.pathname.split('/')[3]
    const [title, setTitle] = useState({name: 'Todos los activos', page: <Tree/>})

    const switchPage = (page) => {
        switch (page) {
            case 'tree':
                setTitle({name: 'Todos los activos', page: <Tree/>})
                break;
            case 'facilities':
                setTitle({name: 'Ubicaciones', page: <Facilities/>})
                break;
            case 'equipments':
                setTitle({name: 'Equipos', page: <Equipments/>})
                break;
            case 'tools':
                setTitle({name: 'Herramientas', page: <Tools/>})
                break;
            default:
                setTitle({name: 'Todos los activos', page: <Tree/>})

        }
    }
    useEffect(() => {
        switchPage(page)
    }, []);


    const list = [{
        name: 'Todos los activos', description: '', href: '/mm/inventories/tree', icon: <TagIcon className={"h-4 w-4 text-green-400"}/>
    }, {
        name: 'Ubicaciones', href: '/mm/inventories/facilities', icon: <BuildingOffice2Icon className={"h-4 w-4 text-green-400"}/>
    }, {
        name: 'Equipos', href: '/mm/inventories/equipments', icon: <PuzzlePieceIcon className={"h-4 w-4 text-green-400"}/>
    }, {
        name: 'Herramientas', href: '/mm/inventories/tools', icon: <WrenchIcon className={"h-4 w-4 text-green-400"}/>
    }]

    return (<div className="relative w-full max-w-sm px-4">
        <Popover className="relative">
            {({open}) => (<>
                <Popover.Button
                    className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center space-x-2  rounded-xl bg-green-400  px-3  text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                >
                    <TagIcon className={"h-4 w-4 text-white"}/>
                    <p className={"font-normal text-[10px] uppercase"}>{title.name} ▼</p>
                </Popover.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <Popover.Panel
                        className="absolute z-10 w-max  transform  sm:px-0 lg:max-w-3xl">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid  bg-white   ">
                                {list.map((item) => (

                                    <NavLink
                                        key={item.name}
                                        to={item.href}
                                        className="px-2 text-xs flex items-center py-2  justify-start font-light hover:bg-opacity-10 hover:rounded-lg hover:bg-green-300"
                                    >
                                        {item.icon}
                                        <div className={"flex flex-col justify-start"}>
                                            <p className={"text-black font-normal"}> {item.name}</p>
                                        </div>

                                    </NavLink>))}
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </>)}
        </Popover>
    </div>)


}
