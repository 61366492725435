import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";

import HeaderForm from "../../../util/HeaderForm";
import {InputForm, SelectForm} from "../../../util/HelpersForm";
import {addArticle} from "../../../../redux/actions/logistic";


const FormArticle = ({data, onClose, params}) => {
    const dispatch = useDispatch();
    const categories = useSelector(state => state.Category.categories);

    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            dispatch(addArticle(form, params));
            onClose();
        }
    })

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-1 gap-2`}>
                <InputForm values={formik.values.name} errors={formik.errors.name} formik={formik}
                           field={'name'}
                           title={'Nombre'} max_length={100}/>
                <InputForm values={formik.values.sap} errors={formik.errors.sap} formik={formik}
                           field={'sap'}
                           title={'SAP'} max_length={10}/>
                <SelectForm values={formik.values.group} errors={formik.errors.group} formik={formik}
                            field={'group'}
                            title={'Grupo'} options={categories && categories} display={'name'}/>

                <SelectForm values={formik.values.unit_of_measurement} errors={formik.errors.unit_of_measurement}
                            formik={formik}
                            field={'unit_of_measurement'}
                            title={'Unidad de Medida'}
                            options={[{id: 'UN', name: 'UN'}, {id: 'KG', name: 'KG'}, {id: 'LT', name: 'LT'}, {
                                id: 'M2', name: 'M2'
                            }, {id: 'M3', name: 'M3'}, {id: 'M', name: 'M'}, {id: 'CM', name: 'CM'}, {
                                id: 'RL', name: 'RL'
                            },]} display={'name'}/>


            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {
        name: data?.name || '',
        sap: data?.sap || '',
        group: data?.group || '',
        unit_of_measurement: data?.unit_of_measurement || '',

    }
}
const newSchema = () => {
    return {
        name: Yup.string().required(),
        sap: Yup.string().min(4).required(),
        group: Yup.string().required(),
        unit_of_measurement: Yup.string().min(2).required(),

    }
}

export default FormArticle;
