import React from 'react';
import {
    BuildingOffice2Icon,
    CalendarDaysIcon, ClipboardDocumentCheckIcon,
    CloudArrowUpIcon,
    Cog8ToothIcon,
    DocumentTextIcon,
    GlobeAltIcon,
    InformationCircleIcon,
    PhotoIcon,
    TruckIcon,
    UserIcon,
    UsersIcon
} from "@heroicons/react/24/solid";
import {FilmIcon} from "@heroicons/react/24/outline";
import {formatDateTime} from "../../util/helpers";
import {useSelector} from "react-redux";
import 'react-slideshow-image/dist/styles.css'
import {Slide} from "react-slideshow-image";
import {TrashIcon} from "@heroicons/react/20/solid";

const AsideLot = ({data, thumbnail, deleteThumbnail, deleteDocument, files, handleUploadFile, handleUploadPhoto,handleUpdateProduction}) => {


    const raw_material_suppliers = useSelector(state => state.Stakeholders.raw_material_suppliers);
    const transportation_companies = useSelector(state => state.Stakeholders.transportation_companies);
    const manufacturing_companies = useSelector(state => state.Stakeholders.manufacturing_companies);

    return (<div className={"w-full bg-white p-4 rounded-2xl"}>
        <div className={"font-bold flex justify-start gap-2 items-center border-b-2 p-2 overflow-hidden"}>
            <Cog8ToothIcon className={"h-8 w-8 text-gray-400  cursor-pointer"}/>
            <p className={"md:text-xl text-sm"}>INFORMACIÓN</p>
            <CloudArrowUpIcon className={"h-8 w-8 text-gray-400 cursor-pointer"} onClick={handleUploadFile}/>
            <PhotoIcon className={"h-8 w-8 text-gray-400 cursor-pointer"} onClick={handleUploadPhoto}/>
            <ClipboardDocumentCheckIcon onClick={handleUpdateProduction} className={"h-8 w-8 text-gray-400 cursor-pointer"}/>
        </div>
        <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4 overflow-hidden lg:pl-6 lg:py-2"}>
            <Item color={"bg-gray-300"} icon={<InformationCircleIcon className={"h-8 w-8 text-white"}/>} title={"Lote"}
                  item={data?.lot}/>
            <Item color={"bg-green-300"} icon={<BuildingOffice2Icon className={"h-8 w-8 text-white"}/>}
                  title={"Planta de proceso"}
                  item={manufacturing_companies ? manufacturing_companies.find(e => e.id === data?.manufacturing)?.name : ""}/>
            <Item color={"bg-orange-500"} icon={<CalendarDaysIcon className={"h-8 w-8 text-white"}/>} title={"Fechas"}
                  item={<div>
                      <p className={"font-semibold"}>Salida: <span
                          className={"font-light"}>{data?.datetime_departure && formatDateTime(data.datetime_departure)}</span>
                      </p>
                      <p className={"font-semibold"}>Ingreso: <span
                          className={"font-light"}>{data?.datetime_arrival && formatDateTime(data.datetime_arrival)}</span>
                      </p>

                      <p className={"font-semibold"}>Inicio descarga: <span
                          className={"font-light"}>{data?.datetime_download_started && formatDateTime(data.datetime_download_started)}</span>
                      </p>

                      <p className={"font-semibold"}>Fin descarga: <span
                          className={"font-light"}>{data?.datetime_download_finished && formatDateTime(data.datetime_download_finished)}</span>
                      </p>


                  </div>}/>
            <Item color={"bg-purple-500"} icon={<GlobeAltIcon className={"h-8 w-8 text-white"}/>} title={"Parcela"}
                  item={data?.parcels_string}/>
            <Item color={"bg-zinc-500"} icon={<DocumentTextIcon className={"h-8 w-8 text-white"}/>} title={"Factura"}
                  item={data?.invoice}/>
            <Item color={"bg-amber-500"} icon={<UsersIcon className={"h-8 w-8 text-white"}/>}
                  title={"Guia del proveedor"} item={data?.supplier_guide}/>
            <Item color={"bg-blue-600"} icon={<TruckIcon className={"h-8 w-8 text-white"}/>}
                  title={"Guia de transportista"} item={<>
                <p>{data?.transport_guide}</p>
                <p className={"text-xs"}>{transportation_companies ? transportation_companies.find(e => e.id === data?.transport)?.name : ""}</p>
            </>}
            />
            <Item color={"bg-amber-700"} icon={<UserIcon className={"h-8 w-8 text-white"}/>} title={"Proveedor"}
                  item={raw_material_suppliers ? raw_material_suppliers.find(e => e.id === data?.supplier)?.name : ""}/>
            <Item color={"bg-emerald-500"} icon={<UserIcon className={"h-8 w-8 text-white"}/>} title={"Variedad"}
                  item={data?.variety}/>
            <Item color={"bg-red-500"} icon={<UserIcon className={"h-8 w-8 text-white"}/>} title={"Condición"}
                  item={data?.condition === 'C' ? 'Convencional' : "Orgánico"}/>

            <Item color={"bg-black"} icon={<FilmIcon className={"h-8 w-8 text-white"}/>} title={"Documentos digitales"}
                  item={<>
                      {files && files.map((e, i) => <div key={i} className={"flex items-center gap-2"}>
                          <TrashIcon className={"h-4 w-4 text-red-500 cursor-pointer"}
                                     onClick={() => deleteDocument(e?.id)}/>
                          <a href={e?.file} target={'_blank'} rel={'noreferrer'}  className={"hover:font-medium hover:text-black cursor-pointer"}>{e?.file.split("/").pop()}</a>
                      </div>)}
                  </>}/>
        </div>

        <div className={"mt-4"}>
            <Slide>
                {thumbnail && thumbnail.map((e, i) => <div key={i} className="each-slide-effect relative">
                    <div
                        style={{'backgroundImage': `url(${e?.photo})`}}>
                        <span>{i + 1}</span>
                        <TrashIcon onClick={() => deleteThumbnail(e?.id)}
                                   className={"h-8 w-8 text-white hover:text-red-400 absolute top-2 right-2 cursor-pointer"}/>
                    </div>
                </div>)}
            </Slide>
        </div>
    </div>);
};

const Item = ({icon, title, item, color}) => {
    return (<div className="flex items-center mt-1 w-full">
        <div className={` text-white rounded-full ${color} p-2`}>
            {icon}
        </div>
        <div className="pl-3">
            <div className="flex items-center text-sm leading-none">
                <p className="font-semibold text-gray-800 dark:text-white ">{title} </p>
            </div>
            <span className="text-sm text-gray-400">{item}</span>
            <div
                className="flex animate-pulse items-center justify-center px-2 py-1 mt-2 bg-green-100 rounded-full"></div>
        </div>
    </div>)
}

export default AsideLot;