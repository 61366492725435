import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";
import {formatDateTZ} from "../../util/helpers";
import {PhotoIcon, QrCodeIcon} from "@heroicons/react/24/solid";

const TableEmployees = ({data, handleUpdate, handleDelete, handleView, handlePhoto, handleRemovePhoto}) => {
    const columns = ['', 'Nombre', 'DNI', 'Estado', 'Teléfono', 'Fecha de Ingreso/Retiro', 'Departamento', 'Cargo']
    return (<TableBase columns={columns}
                       cell={Row(data, handleUpdate, handleDelete, handleView, handlePhoto, handleRemovePhoto)}/>);
};


const Row = (data, update, remove, view, photo, removePhoto) => (data && data.map((item, index) => {
    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={<div className={"font-bold flex  gap-1 justify-center   items-center whitespace-nowrap"}>
            <PencilSquareIcon onClick={() => update(item)} title={"Editar"}
                              className={"h-5 w-5 mr-2 text-blue-500 cursor-pointer"}/>
            <TrashIcon onClick={() => remove(item?.id)} title={"Eliminar"}
                       className={"h-5 w-5 mr-2 text-red-500 cursor-pointer"}/>
            <QrCodeIcon className={"h-5 w-5 text-gray-800 mr-2 cursor-pointer"} onClick={() => view(item)}/>
            {item?.photo ? <img onDoubleClick={() => removePhoto(item?.id)} src={item?.photo} alt={item?.name}
                                className={"w-5 h-5 rounded-full ml-2 "}/>

                : <PhotoIcon onClick={() => photo(item?.id)} className={"h-5 w-5 text-gray-800 cursor-pointer"}/>}

        </div>}/>
        <Cell text={item?.full_name}/>
        <Cell text={item?.dni}/>
        <Cell text={<div className={"flex items-center"}>
            <div className={`w-3 h-3 rounded-full mr-2 ${item?.status ? 'bg-green-500' : 'bg-red-500'}`}/>
            {item?.status ? 'Activo' : 'Inactivo'}
        </div>}/>
        <Cell text={item?.phone}/>
        <Cell text={item?.date_of_admission ? formatDateTZ(item.date_of_admission) : ''}/>
        <Cell text={item?.area_name}/>
        <Cell text={item?.position_name}/>
    </tr>)
}));

export default TableEmployees;
