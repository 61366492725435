import React from 'react';
import {Switch} from "@headlessui/react";
import {ChevronUpDownIcon} from "@heroicons/react/24/outline";

const InputSearch = ({name = 'Buscar', value, handle, handleSearch, color, type = "text", icon = true}) => {
    return (<div className="relative lg:w-max w-full self-start">
        <p className="text-xs text-gray-400">
            {name}
        </p>
        <input
            type={type}
            name={name}
            value={value}
            id={name}
            onChange={handle}
            onKeyUp={(e) => e.key === 'Enter' && handleSearch()}
            className={`block w-full p-3 pl-10 ${color} text-sm border-gray-200 rounded-md focus:border-green-300 focus:ring-green-300`}
            placeholder={`${name}...`}
        />
        {icon && <div className="absolute top-4 inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
            <svg
                className="h-3.5 w-3.5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
            >
                <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
            </svg>
        </div>}
    </div>);
};
const SwitchFilter = ({value, handle, handleSearch}) => {
    return (<Switch
        checked={value}
        onChange={()=>{
            handle();
            handleSearch()}}
        className={`${value ? 'bg-green-400' : 'bg-green-200'}
          relative inline-flex h-[24px] w-[60px] self-center shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
    >
                                <span
                                    aria-hidden="true"
                                    className={`${value ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                                />
    </Switch>)

}
const SelectSearch = ({name = 'Buscar', value, handle, color, items, field, icon = false, handleSearch}) => {
    return (<div className="relative lg:w-max w-full  self-start">
        <p className="text-xs text-gray-400">
            {name}
        </p>
        <select onChange={handle} value={value}
                onKeyDown={() => handleSearch()}
                className={`block w-full p-3  ${color} text-sm border-gray-200 text-gray-400 rounded-md focus:border-green-300 focus:ring-green-300`}
        >
            {items && items.length > 0 && items.map((item, index) => {
                return <option key={index} value={item.id} className={"h-12 text-black"}>
                    {item[field]}
                </option>
            })}
        </select>
        {icon && <div className="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
            <ChevronUpDownIcon className={"w-6 h-6 text-gray-400"}/>
        </div>}


    </div>);
};

export {InputSearch, SwitchFilter, SelectSearch};
