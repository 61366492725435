import React, {useEffect, useState} from 'react';
import Layout from "../../../hocs/Layout";
import {Tab} from "@headlessui/react";
import Departments from "./Departments";
import {Helmet} from "react-helmet";
import Filters from "../../../components/sct/Employees/Filters";
import TableEmployees from "../../../components/sct/Employees/TableEmployees";
import {deleteStaff, getDepartmentsWithChildren, getStaff, updateStaff} from "../../../redux/actions/talent_hub";
import {useDispatch, useSelector} from "react-redux";
import ModalHook from "../../../hooks/ModalHook";
import {MySwal} from "../../../components/util/helpers";
import Form from "../../../components/sct/Employees/Form";
import ButtonHelper from "../../../components/util/ButtonHelper";
import Modal from "../../../components/util/Modal";
import {getDepartments, getPositions} from "../../../redux/actions/users";
import Fotocheck from "../../../components/sct/Employees/Fotocheck";
import TemplateFoto from "../../../assets/fotocheck.jpg";
import HeaderForm from "../../../components/util/HeaderForm";
import PhotoUploadForm from "../../../components/sct/Employees/FormUploadPhoto";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Employees = () => {
    const data = useSelector(state => state.TalentHub.staff);

    const {content, setContent, isOpen, openModal} = ModalHook();
    const [params, setParams] = useState({'full_name': '', 'status': true})
    const dispatch = useDispatch();

    // const user = useSelector(state => state.Auth?.user);


    useEffect(() => {
        dispatch(getStaff(params));
        dispatch(getDepartmentsWithChildren());
        dispatch(getDepartments());
        dispatch(getPositions());
    }, [dispatch]);

    const handleSearch = () => {
        dispatch(getStaff(params));
    }

    const handleAdd = () => {
        const formComponent = <Form onClose={openModal} params={params}/>;
        setContent(formComponent);
        openModal();
    }
    const handleUploadPhoto = (pk) => {
        const formComponent = <PhotoUploadForm pk={pk} onClose={openModal} params={params}/>;
        setContent(formComponent);
        openModal();
    }
    const handleView = (item) => {
        setContent(<>
            <HeaderForm title={'Fotocheck'} close={openModal}/>
            <Fotocheck template_url={TemplateFoto}
                       name={item?.name}
                       last_name={item?.last_name}
                       position={item?.position_name}
                       photo_url={item?.photo}
                       barcode_url={`https://barcode.tec-it.com/barcode.ashx?data=${item?.dni}&code=Code128&translate-esc=on`}/>
        </>)
        openModal();
    }

    const handleUpdate = (data) => {
        const formComponent = <Form onClose={openModal} params={params} data={data}/>;
        setContent(formComponent);
        openModal();
    }

    const handleDelete = (data) => {
        MySwal.fire({
            title: '¿Desea eliminar este registro?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7DABF5',
            confirmButtonColor: '#F87171',
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteStaff(data, params))
            }
        });
    };
    const handleRemovePhoto = (id) => {
        MySwal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(updateStaff(id, {photo: null}, params));
            }
        })
    }


    // if (user && user.permissions !== 'EDITOR') return <Navigate to='/home'/>;

    return (<Layout>
        <Helmet>
            <title>Colaboradores</title>
        </Helmet>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <ButtonHelper handle={handleAdd}/>

        <div className="mx-auto container bg-white mt-4 p-4 ">
            <Tab.Group>
                <Tab.List className="flex space-x-1  border-b-4 p-1 overflow-scroll scrollbar-hide">
                    <Tab
                        className={({selected}) => classNames('w-max px-8  py-2.5 text-sm font-medium leading-5 border-r-2 ', selected ? 'bg-white text-green-400 font-bold' : 'text-gray-500 hover:bg-green-200 hover:bg-opacity-10 hover:text-green-600')}>
                        Staff
                    </Tab>

                    <Tab
                        className={({selected}) => classNames('w-max px-8  py-2.5 text-sm font-medium leading-5  ', selected ? 'bg-white text-green-400 font-bold' : 'text-gray-500 hover:bg-green-200 hover:bg-opacity-10 hover:text-green-600')}>
                        Departamentos
                    </Tab>
                </Tab.List>
                <Tab.Panels className="mt-2">
                    <Tab.Panel>
                        <Filters handleSearch={handleSearch} params={params} setParams={setParams}/>
                        <div className="w-full min-h-96 max-h-96 overflow-scroll scrollbar-hide gap-2 flex flex-col">
                            <TableEmployees data={data} handleDelete={handleDelete} handleUpdate={handleUpdate}
                                            handleView={handleView} handlePhoto={handleUploadPhoto}
                                            handleRemovePhoto={handleRemovePhoto}
                            />


                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <Departments/>
                    </Tab.Panel>

                </Tab.Panels>
            </Tab.Group>
        </div>
    </Layout>);


};

export default Employees;