import React, {useEffect, useState} from 'react';
import Layout from "../../../../hocs/Layout";
import TableParcels from "../../../../components/e2e/Agrisupply/TableParcels";
import Filters from "../../../../components/e2e/Agrisupply/Filters";
import ButtonHelper from "../../../../components/util/ButtonHelper";
import {useDispatch, useSelector} from "react-redux";
import {deleteParcel, getParcels} from "../../../../redux/actions/agrisupply";
import {MySwal} from "../../../../components/util/helpers";
import ModalHook from "../../../../hooks/ModalHook";
import Modal from "../../../../components/util/Modal";
import FormParcel from "../../../../components/e2e/Agrisupply/Form";
import {get_categories} from "../../../../redux/actions/category";
import {getRawMaterialSuppliers} from "../../../../redux/actions/stakeholders";
import {Helmet} from "react-helmet";

const Parcels = () => {
    const [params, setParams] = useState({sts: '', name: '', provider: '', product: ''})
    const dispatch = useDispatch();
    const {content, setContent, isOpen, openModal} = ModalHook();
    const data = useSelector(state => state.Agrisupply.parcel);

    useEffect(() => {
        dispatch(getParcels(params));
        dispatch(get_categories());
        dispatch(getRawMaterialSuppliers())
    }, [dispatch]);

    const handleSearch = () => {
        dispatch(getParcels(params));
    }

    const handleAdd = () => {
        const formComponent = <FormParcel onClose={openModal} params={params} />;
        setContent(formComponent);
        openModal();
    };

    const handleUpdate = (data) => {
        const formComponent = <FormParcel onClose={openModal} data={data} params={params}
                                          />;
        setContent(formComponent);
        openModal();
    };
    const handleDelete = (data) => {
        MySwal.fire({
            title: '¿Desea eliminar este registro?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7DABF5',
            confirmButtonColor: '#F87171',
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteParcel(data, params))
            }
        });
    };


    return (<Layout>
        <Helmet>
            <title>Parcelas</title>
        </Helmet>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <div
            className={"bg-white rounded-2xl p-4 h-fit"}>
            <ButtonHelper handle={handleAdd}/>
            <Filters params={params} setParams={setParams} handleSearch={handleSearch}/>
            <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                <TableParcels handleDelete={handleDelete} handleUpdate={handleUpdate} data={data}/>
            </div>
        </div>
    </Layout>);
};

export default Parcels;
