import {
    ADD_ASSET_FIXED_FAIL,
    ADD_ASSET_FIXED_SUCCESS,
    ADD_ASSET_PHYSICAL_FAIL,
    ADD_ASSET_PHYSICAL_SUCCESS,
    ADD_ASSET_TOOL_FAIL,
    ADD_ASSET_TOOL_SUCCESS,
    ADD_OT_FAIL,
    ADD_OT_SUCCESS,
    ADD_REQUIREMENT_FAIL,
    ADD_REQUIREMENT_SUCCESS,
    DELETE_ASSET_FIXED_FAIL,
    DELETE_ASSET_FIXED_SUCCESS,
    DELETE_ASSET_PHYSICAL_FAIL,
    DELETE_ASSET_PHYSICAL_SUCCESS,
    DELETE_ASSET_TOOL_FAIL,
    DELETE_ASSET_TOOL_SUCCESS,
    DELETE_OT_FAIL,
    DELETE_OT_SUCCESS,
    DELETE_REQUIREMENT_FAIL,
    DELETE_REQUIREMENT_SUCCESS,
    GET_ASSETS_FIXED_FAIL,
    GET_ASSETS_FIXED_SUCCESS,
    GET_ASSETS_PHYSICAL_FAIL,
    GET_ASSETS_PHYSICAL_SUCCESS,
    GET_ASSETS_TOOLS_FAIL,
    GET_ASSETS_TOOLS_SUCCESS,
    GET_ASSETS_TREE_FAIL,
    GET_ASSETS_TREE_SUCCESS,
    GET_CHLORINE_FAIL,
    GET_CHLORINE_SUCCESS,
    GET_FAILURES_FAIL,
    GET_FAILURES_SUCCESS,
    GET_H2O_FAIL,
    GET_H2O_SUCCESS, GET_HELPERS_FAIL, GET_HELPERS_SUCCESS,
    GET_MAINTENANCE_TYPES_FAIL,
    GET_MAINTENANCE_TYPES_SUCCESS,
    GET_OT_FAIL,
    GET_OT_SUCCESS,
    GET_REQUIREMENTS_FAIL,
    GET_REQUIREMENTS_SUCCESS, GET_RESOURCES_FAIL, GET_RESOURCES_SUCCESS,
    GET_TECHNICALS_FAIL,
    GET_TECHNICALS_SUCCESS, GET_USERS_FAIL, GET_USERS_SUCCESS,
    UPDATE_ASSET_FIXED_FAIL,
    UPDATE_ASSET_FIXED_SUCCESS,
    UPDATE_ASSET_PHYSICAL_FAIL,
    UPDATE_ASSET_PHYSICAL_SUCCESS,
    UPDATE_ASSET_TOOL_FAIL,
    UPDATE_ASSET_TOOL_SUCCESS,
    UPDATE_CHLORINE_FAIL,
    UPDATE_CHLORINE_SUCCESS,
    UPDATE_H2O_FAIL,
    UPDATE_H2O_SUCCESS,
    UPDATE_OT_FAIL,
    UPDATE_OT_SUCCESS,
    UPDATE_REQUIREMENT_FAIL,
    UPDATE_REQUIREMENT_SUCCESS
} from "../actions/types";

const initialState = {
    fixed: null,
    physical: null,
    tools: null,
    tree: null,
    maintenance_type: null,
    failures: null,
    requirements: null,
    works: null,
    technical: null,
    h2o: null,
    chlorine: null,
    users: null,
    resources : null,
    helpers: null
}

export const Maintenance = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_ASSETS_TREE_SUCCESS:
            return {...state, tree: payload.data};
        case GET_ASSETS_TREE_FAIL:
            return {...state, tree: null}
        case GET_ASSETS_FIXED_SUCCESS:
            return {...state, fixed: payload.data}
        case GET_ASSETS_FIXED_FAIL:
            return {...state, fixed: null}
        case GET_ASSETS_PHYSICAL_SUCCESS:
            return {...state, physical: payload.data}
        case GET_ASSETS_PHYSICAL_FAIL:
            return {...state, physical: null}
        case GET_ASSETS_TOOLS_SUCCESS:
            return {...state, tools: payload.data}
        case GET_ASSETS_TOOLS_FAIL:
            return {...state, tools: null}
        case GET_FAILURES_SUCCESS:
            return {...state, failures: payload.data}
        case GET_FAILURES_FAIL:
            return {...state, failures: null}
        case GET_MAINTENANCE_TYPES_SUCCESS:
            return {...state, maintenance_type: payload.data}
        case GET_MAINTENANCE_TYPES_FAIL:
            return {...state, maintenance_type: null}
        case GET_REQUIREMENTS_SUCCESS:
            return {...state, requirements: payload.data}
        case GET_REQUIREMENTS_FAIL:
            return {...state, requirements: null}
        case GET_OT_SUCCESS:
            return {...state, works: payload.data}
        case GET_OT_FAIL:
            return {...state, works: null}
        case GET_RESOURCES_SUCCESS:
            return {...state, resources: payload.data}
        case GET_RESOURCES_FAIL:
            return {...state, resources: null}

        case GET_TECHNICALS_SUCCESS:
            return {...state, technical: payload.data}
        case GET_TECHNICALS_FAIL:
            return {...state, technical: null}
        case GET_HELPERS_SUCCESS:
            return {...state, helpers: payload.data}
        case GET_HELPERS_FAIL:
            return {...state, helpers: null}

        case GET_USERS_SUCCESS:
            return {...state, users: payload.data}
        case GET_USERS_FAIL:
            return {...state, users: null}
        case GET_H2O_SUCCESS:
            return {...state, h2o: payload.data}
        case GET_H2O_FAIL:
            return {...state, h2o: null}
        case GET_CHLORINE_SUCCESS:
            return {...state, chlorine: payload.data}
        case GET_CHLORINE_FAIL:
            return {...state, chlorine: null}
        case ADD_ASSET_FIXED_SUCCESS:
        case ADD_ASSET_FIXED_FAIL:
        case UPDATE_ASSET_FIXED_SUCCESS:
        case UPDATE_ASSET_FIXED_FAIL:
        case DELETE_ASSET_FIXED_SUCCESS:
        case DELETE_ASSET_FIXED_FAIL:
        case ADD_ASSET_PHYSICAL_SUCCESS:
        case ADD_ASSET_PHYSICAL_FAIL:
        case UPDATE_ASSET_PHYSICAL_SUCCESS:
        case UPDATE_ASSET_PHYSICAL_FAIL:
        case DELETE_ASSET_PHYSICAL_SUCCESS:
        case DELETE_ASSET_PHYSICAL_FAIL:
        case ADD_ASSET_TOOL_SUCCESS:
        case ADD_ASSET_TOOL_FAIL:
        case UPDATE_ASSET_TOOL_SUCCESS:
        case UPDATE_ASSET_TOOL_FAIL:
        case DELETE_ASSET_TOOL_SUCCESS:
        case DELETE_ASSET_TOOL_FAIL:
        case ADD_REQUIREMENT_SUCCESS:
        case ADD_REQUIREMENT_FAIL:
        case UPDATE_REQUIREMENT_SUCCESS:
        case UPDATE_REQUIREMENT_FAIL:
        case DELETE_REQUIREMENT_SUCCESS:
        case DELETE_REQUIREMENT_FAIL:
        case ADD_OT_SUCCESS:
        case ADD_OT_FAIL:
        case UPDATE_OT_SUCCESS:
        case UPDATE_OT_FAIL:
        case DELETE_OT_SUCCESS:
        case DELETE_OT_FAIL:
        case UPDATE_H2O_SUCCESS:
        case UPDATE_H2O_FAIL:
        case UPDATE_CHLORINE_SUCCESS:
        case UPDATE_CHLORINE_FAIL:
            return {
                ...state
            }
        default:
            return state;
    }
}