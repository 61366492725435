import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import Layout from "../../../hocs/Layout";
import TableRegister from "../../../components/mm/Register/TableRegister";
import {
    deleteOT,
    getAssetsFixed,
    getAssetsPhysical,
    getFailures,
    getMaintenanceTypes,
    getOT,
    getTechnicals,
    getTools,
    getUsers,
    updateOTClean,
    updateOTValidate
} from "../../../redux/actions/maintenance";
import {useDispatch, useSelector} from "react-redux";
import Filters from "../../../components/mm/Register/Filters";
import Modal from "../../../components/util/Modal";
import ModalHook from "../../../hooks/ModalHook";
import DocumentViewer from "../../../components/mm/Task/DocumentViewer";
import {MySwal} from "../../../components/util/helpers";


const Register = () => {
    const [params, setParams] = useState({
        month: '', year: new Date().getFullYear(), week: 'all', equipment: '', type_: '', user: '', planned: 'true'
    })
    const dispatch = useDispatch();
    const {content, setContent, isOpen, openModal} = ModalHook();
    const data = useSelector(state => state.Maintenance.works);

    useEffect(() => {
        dispatch(getAssetsPhysical())
        dispatch(getAssetsFixed())
        dispatch(getTechnicals())
        dispatch(getUsers())
        dispatch(getTools())
        dispatch(getFailures())
        dispatch(getMaintenanceTypes())
    }, [dispatch]);

    const handleView = (data) => {
        const formComponent = <DocumentViewer data={data} close={openModal}/>;
        setContent(formComponent);
        openModal();
    };

    const handleCleanOT = (id) => {
        dispatch(updateOTClean(id, params));
    }
    const handleValidatedOT = (id) => {
        dispatch(updateOTValidate(id, params));
    }
    const handleSearch = () => {
        dispatch(getOT(params));
    }

    const handleDeleteWork = (data) => {
        MySwal.fire({
            title: '¿Desea eliminar esta OT?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7DABF5',
            confirmButtonColor: '#F87171',
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteOT(data.id, params));
            }
        });
    };

    return (<Layout>
        <Helmet>
            <title>Historial de OT</title>
        </Helmet>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <div className={"bg-white rounded-2xl p-4 h-fit"}>
            <Filters params={params} setParams={setParams} handleSearch={handleSearch}/>
            <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                <TableRegister data={data} cleaned={handleCleanOT} remove={handleDeleteWork}
                               validated={handleValidatedOT} view={handleView}/>
            </div>
        </div>


    </Layout>);
};
export default Register;