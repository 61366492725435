import React, {useEffect, useState} from 'react';
import HeaderForm from "../../util/HeaderForm";
import {useDispatch} from "react-redux";
import {addThumbnail} from "../../../redux/actions/production";
import {setAlert} from "../../../redux/actions/alert";

export default function PhotoUploadForm({onClose, lot_id, lot}) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [preview, setPreview] = useState('');
    const dispatch = useDispatch();

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.substr(0, 5) === 'image') {
            setSelectedImage(file);
        } else {
            setSelectedImage(null);
        }
    };

    useEffect(() => {
        if (!selectedImage) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedImage);
        setPreview(objectUrl);

        // Liberar memoria cuando este componente sea desmontado
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedImage]);

    const handleSubmit = () => {
        if (!selectedImage) {
            setAlert('Seleccione una imagen', 'error')
            return;
        }
        const formData = new FormData();
        formData.append('photo', selectedImage);
        formData.append('lot', lot_id);
        dispatch(addThumbnail(formData, lot));
        onClose();
    };

    return (<div>
        <HeaderForm close={onClose} submit={handleSubmit}/>
        <form className="w-full max-w-md">
            <div className="flex flex-col items-center mb-4">
                <label htmlFor="file-upload" className="block text-gray-700 text-sm font-bold mb-2">
                    Selecciona una imagen para subir
                </label>
                <input
                    className="hidden"
                    id="file-upload"
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                />
                <label htmlFor="file-upload"
                       className="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Seleccionar Imagen
                </label>
            </div>
            {preview && (<div className="flex justify-center mb-4">
                <img src={preview} alt="Vista previa" className="max-w-xs max-h-64"/>
            </div>)}

        </form>
    </div>);
}
