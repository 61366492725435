import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import Layout from "../../../hocs/Layout";
import TableRequirements from "../../../components/mm/Requirements/TableRequirements";
import Filters from "../../../components/mm/Requirements/Filters";
import {useDispatch, useSelector} from "react-redux";
import {deleteRequirement, getRequirements} from "../../../redux/actions/maintenance";
import Modal from "../../../components/util/Modal";
import {MySwal} from "../../../components/util/helpers";
import ModalHook from "../../../hooks/ModalHook";
import ButtonHelper from "../../../components/util/ButtonHelper";
import FormRequirements from "../../../components/mm/Requirements/FormRequirements";
import DocumentViewerRequirement from "../../../components/mm/Requirements/Document";
import {DocumentIcon} from "@heroicons/react/24/solid";


const Requirements = () => {
    const [params, setParams] = useState({year: '', month: '', week: ''})
    const dispatch = useDispatch();
    const {content, setContent, isOpen, openModal} = ModalHook();
    const data = useSelector(state => state.Maintenance.requirements);

    useEffect(() => {
        setParams({year: new Date().getFullYear(), month: new Date().getMonth() + 1, week: 'all'})
        dispatch(getRequirements({year: new Date().getFullYear(), month: new Date().getMonth() + 1, week: 'all'}))
    }, []);

    const handleSearch = () => {
        dispatch(getRequirements(params));
    }


    const handleAdd = () => {
        const formComponent = <FormRequirements onClose={openModal} params={params}/>;
        setContent(formComponent);
        openModal();
    };

    const handleUpdate = (data) => {
        const formComponent = <FormRequirements onClose={openModal} data={data} params={params}
        />;
        setContent(formComponent);
        openModal();
    };

    const handleView = () => {
        const formComponent = <DocumentViewerRequirement close={openModal} data={data}/>
        setContent(formComponent);
        openModal();
    }
    const handleDelete = (data) => {
        MySwal.fire({
            title: '¿Desea eliminar este registro?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7DABF5',
            confirmButtonColor: '#F87171',
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteRequirement(data, params))
            }
        });
    };


    return (<Layout>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <Helmet>
            <title>Solicitudes de compra</title>
        </Helmet>
        <div className={"bg-white rounded-2xl p-4 h-fit"}>
            <ButtonHelper handle={handleAdd}/>
            <DocumentIcon onClick={handleView} title={"Ver documentos"} className={"h-6 w-6 text-gray-300 cursor-pointer"}/>
            <Filters  params={params} setParams={setParams} handleSearch={handleSearch}/>
            <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                <TableRequirements data={data} handleUpdate={handleUpdate} handleDelete={handleDelete}/>
            </div>
        </div>


    </Layout>);
};
export default Requirements;