import {
    ADD_CLIENT_FAIL,
    ADD_CLIENT_SUCCESS,
    ADD_MANUFACTURING_COMPANY_FAIL,
    ADD_MANUFACTURING_COMPANY_SUCCESS,
    ADD_MATERIAL_SUPPLIER_FAIL,
    ADD_MATERIAL_SUPPLIER_SUCCESS,
    ADD_RAW_MATERIAL_SUPPLIER_FAIL,
    ADD_RAW_MATERIAL_SUPPLIER_SUCCESS,
    ADD_TRANSPORTATION_COMPANY_FAIL,
    ADD_TRANSPORTATION_COMPANY_SUCCESS,
    DELETE_CLIENT_FAIL,
    DELETE_CLIENT_SUCCESS,
    DELETE_MANUFACTURING_COMPANY_FAIL,
    DELETE_MANUFACTURING_COMPANY_SUCCESS,
    DELETE_MATERIAL_SUPPLIER_FAIL,
    DELETE_MATERIAL_SUPPLIER_SUCCESS,
    DELETE_RAW_MATERIAL_SUPPLIER_FAIL,
    DELETE_RAW_MATERIAL_SUPPLIER_SUCCESS,
    DELETE_TRANSPORTATION_COMPANY_FAIL,
    DELETE_TRANSPORTATION_COMPANY_SUCCESS,
    GET_CLIENTS_SUCCESS,
    GET_MANUFACTURING_COMPANIES_FAIL,
    GET_MANUFACTURING_COMPANIES_SUCCESS,
    GET_MATERIAL_SUPPLIERS_FAIL,
    GET_MATERIAL_SUPPLIERS_SUCCESS,
    GET_RAW_MATERIAL_SUPPLIERS_FAIL,
    GET_RAW_MATERIAL_SUPPLIERS_SUCCESS,
    GET_TRANSPORTATION_COMPANIES_FAIL,
    GET_TRANSPORTATION_COMPANIES_SUCCESS,
    UPDATE_CLIENT_FAIL,
    UPDATE_CLIENT_SUCCESS,
    UPDATE_MANUFACTURING_COMPANY_FAIL,
    UPDATE_MANUFACTURING_COMPANY_SUCCESS,
    UPDATE_MATERIAL_SUPPLIER_FAIL,
    UPDATE_MATERIAL_SUPPLIER_SUCCESS,
    UPDATE_RAW_MATERIAL_SUPPLIER_FAIL,
    UPDATE_RAW_MATERIAL_SUPPLIER_SUCCESS,
    UPDATE_TRANSPORTATION_COMPANY_FAIL,
    UPDATE_TRANSPORTATION_COMPANY_SUCCESS,
} from "../actions/types";

const initialState = {
    raw_material_suppliers: null,
    material_suppliers: null,
    clients: null,
    transportation_companies: null,
    manufacturing_companies: null
}
export default function Stakeholders(state = initialState, action) {
    const {type, payload} = action;
    switch (type) {
        case GET_RAW_MATERIAL_SUPPLIERS_SUCCESS:
            return {
                ...state, raw_material_suppliers: payload.data
            }
        case GET_RAW_MATERIAL_SUPPLIERS_FAIL:
            return {
                ...state, raw_material_suppliers: null
            }
        case GET_MATERIAL_SUPPLIERS_SUCCESS:
            return {
                ...state, material_suppliers: payload.data
            }
        case GET_MATERIAL_SUPPLIERS_FAIL:
            return {
                ...state, material_suppliers: null
            }
        case GET_CLIENTS_SUCCESS:
            return {
                ...state, clients: payload.data
            }
        case GET_TRANSPORTATION_COMPANIES_SUCCESS:
            return {
                ...state, transportation_companies: payload.data
            }
        case GET_TRANSPORTATION_COMPANIES_FAIL:
            return {
                ...state, transportation_companies: null
            }
        case GET_MANUFACTURING_COMPANIES_SUCCESS:
            return {
                ...state, manufacturing_companies: payload.data
            }
        case GET_MANUFACTURING_COMPANIES_FAIL:
            return {
                ...state, manufacturing_companies: null
            }
        case ADD_RAW_MATERIAL_SUPPLIER_SUCCESS:
        case ADD_RAW_MATERIAL_SUPPLIER_FAIL:
        case UPDATE_RAW_MATERIAL_SUPPLIER_SUCCESS:
        case UPDATE_RAW_MATERIAL_SUPPLIER_FAIL:
        case DELETE_RAW_MATERIAL_SUPPLIER_SUCCESS:
        case DELETE_RAW_MATERIAL_SUPPLIER_FAIL:
        case ADD_MATERIAL_SUPPLIER_SUCCESS:
        case ADD_MATERIAL_SUPPLIER_FAIL:
        case UPDATE_MATERIAL_SUPPLIER_SUCCESS:
        case UPDATE_MATERIAL_SUPPLIER_FAIL:
        case DELETE_MATERIAL_SUPPLIER_SUCCESS:
        case DELETE_MATERIAL_SUPPLIER_FAIL:
        case ADD_CLIENT_SUCCESS:
        case ADD_CLIENT_FAIL:
        case UPDATE_CLIENT_SUCCESS:
        case UPDATE_CLIENT_FAIL:
        case DELETE_CLIENT_SUCCESS:
        case DELETE_CLIENT_FAIL:
        case ADD_TRANSPORTATION_COMPANY_SUCCESS:
        case ADD_TRANSPORTATION_COMPANY_FAIL:
        case UPDATE_TRANSPORTATION_COMPANY_SUCCESS:
        case UPDATE_TRANSPORTATION_COMPANY_FAIL:
        case DELETE_TRANSPORTATION_COMPANY_SUCCESS:
        case DELETE_TRANSPORTATION_COMPANY_FAIL:
        case ADD_MANUFACTURING_COMPANY_SUCCESS:
        case ADD_MANUFACTURING_COMPANY_FAIL:
        case UPDATE_MANUFACTURING_COMPANY_SUCCESS:
        case UPDATE_MANUFACTURING_COMPANY_FAIL:
        case DELETE_MANUFACTURING_COMPANY_SUCCESS:
        case DELETE_MANUFACTURING_COMPANY_FAIL:
            return {
                ...state
            }
        default:
            return state;
    }
}
