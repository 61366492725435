import React, {useState} from 'react';
import HeaderForm from "../../util/HeaderForm";
import {useDispatch} from "react-redux";
import {setAlert} from "../../../redux/actions/alert";
import {addDocument} from "../../../redux/actions/logistic";

export default function UploadDocument({lot,lot_id, onClose}) {
    const [file, setFile] = useState(null);
    const dispatch = useDispatch();

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = () => {
        if (!file) {
            dispatch(setAlert('Seleccione un archivo', 'error'));
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('lot', lot_id);
        dispatch(addDocument(formData, lot));
        onClose();
    };

    return (<div className="">
            <HeaderForm close={onClose} submit={()=>handleSubmit()}/>
            <form className="mt-5">
                <div className="flex flex-col items-center">
                    <label className="mb-2 text-lg font-medium text-gray-700">
                        Subir Documento
                    </label>
                    <input
                        className="hidden"
                        id="file-upload"
                        type="file"
                        onChange={handleFileChange}
                        accept={".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.jpg,.jpeg,.png,.gif"}
                    />
                    <label htmlFor="file-upload"
                           className="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Seleccionar Archivo
                    </label>
                    {file && (<span className="mt-2 text-sm text-gray-600">
                            Archivo seleccionado: {file.name}
                        </span>)}
                </div>
            </form>
        </div>);
}
