import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/outline";

const TableExternal = ({data, handleUpdate, handleDelete}) => {
    const columns = ['', 'Nombre', 'DNI', 'Celular', 'Licencia']
    return (<TableBase columns={columns} cell={Row(data, handleUpdate, handleDelete)} />);
};

const Row = (data, update, remove) => (data && data.map((item, index) => {
    return (<tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={<div className={"font-bold flex items-center whitespace-nowrap"}>
            <PencilSquareIcon onClick={() => update(item)} title={"Editar"}
                              className={"h-5 w-5 mr-2 text-blue-500 cursor-pointer"}/>
            <TrashIcon onClick={() => remove(item?.id)} title={"Eliminar"}
                       className={"h-5 w-5 mr-2 text-red-500 cursor-pointer"}/>

        </div>}/>
        <Cell text={item?.full_name}/>
        <Cell text={item?.dni}/>
        <Cell text={item?.phone}/>
        <Cell text={item?.licence}/>

    </tr>)
}));

export default TableExternal;
