import React from 'react';
import {Document, Image, Page, PDFViewer, StyleSheet, Text, View} from "@react-pdf/renderer";
import Humanize from "humanize-plus";
import Logo from '../../../assets/logo.png'
import {formatDate, formatDateTime} from "../../util/helpers";
import {useSelector} from "react-redux";

const styles = StyleSheet.create({
    image: {
        width: 70, borderRadius: 10, padding: "2px",

    }, section: {
        display: "flex", justifyContent: "space-around", width: "100%",

        flexDirection: "row",

        borderBottom: "1px"
    }
});
const ReportViewer = ({data, category}) => {
    const signature = useSelector(state => state.Logistic.signature);
    const raw_material_suppliers = useSelector(state => state.Stakeholders.raw_material_suppliers);

    return (<PDFViewer style={{width: "100%", height: "100%"}}>
        <Document>
            <Page size="A4" style={{padding: "12px", width: "100%"}}>
                <View style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "6px",
                    gap: "24px",
                }}>
                    <Image style={styles.image} src={Logo}/>
                    <Text style={{
                        fontSize: "18px", fontWeight: "extrabold", fontFamily: "Times-Roman",
                    }}>INFORME DE RECEPCIÓN DE MATERIA PRIMA</Text>
                </View>
                <View style={[styles.section, {backgroundColor: "#22c55e"}]}>
                    <Text style={{
                        fontSize: "8px",
                        fontWeight: "bold",
                        textAlign: "center",
                        color: "white",
                        paddingVertical: "2px",
                        textTransform: "uppercase"
                    }}>INFORME {category}/{data?.lot} </Text>
                </View>
                <View style={[styles.section, {flexDirection: "column"}]}>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "20%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px"
                        }}>A:</Text>
                        <View style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "80%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px"
                        }}>
                            <Text style={{fontSize: "12px", fontWeight: "extrabold", fontFamily: "Times-Roman",}}>
                                {data?.is_smart_foods ? 'SMARTFOOD S.A.C.': raw_material_suppliers && raw_material_suppliers.find(e => e.id === data?.supplier)?.name}
                            </Text>
                            <Text style={{fontSize: "12px", fontWeight: "extrabold", fontFamily: "Times-Roman",}}>
                                Proveedor de materia prima
                            </Text>
                        </View>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "20%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px"
                        }}>DE:</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "80%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "6px"
                        }}>
                            GREENBOX S.A.C.
                        </Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "20%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px"
                        }}>ASUNTO:</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "80%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px"
                        }}>
                            Informe de recepción de materia prima
                            - {category} ({data?.condition === 'C' ? 'Convencional' : 'Orgánico'})
                        </Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "20%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px"
                        }}>FECHA:</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "80%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px"
                        }}>
                            {data?.datetime_download_started && formatDate(data.datetime_download_started, 'long')}
                        </Text>
                    </View>

                    <Text style={{
                        fontSize: "10px",
                        fontWeight: "normal",
                        fontFamily: "Times-Roman",
                        width: "100%",
                        paddingHorizontal: "12px",

                        display: "block",
                        borderTopWidth: "1px",
                        paddingVertical: "2px",

                    }}>
                        Estimado(a) {data?.is_smart_foods ? 'SMARTFOOD S.A.C.': raw_material_suppliers && raw_material_suppliers.find(e => e.id === data?.supplier)?.name};
                        por medio del presente reciban un cordial saludo a nombre del
                        departamento de Logística - GREENBOX S.A.C. y a la vez informarles los detalles de la carga
                        recibida. {'\n'}
                        Se recibieron en total <Text style={{fontWeight: "bold"}}>{data?.boxes} JABAS DE
                        PLÁSTICO</Text> con contenido
                        de {category}.
                    </Text>
                    <Text style={{
                        fontSize: "10px",
                        fontWeight: "normal",
                        fontFamily: "Times-Roman",
                        width: "100%",
                        paddingHorizontal: "12px",
                        display: "block",
                        paddingVertical: "2px",
                        color: "red"
                    }}>
                        {data?.observation}
                    </Text>
                    <Text style={{
                        fontSize: "10px",
                        fontWeight: "normal",
                        fontFamily: "Times-Roman",
                        width: "100%",
                        paddingHorizontal: "12px",
                        display: "block",
                        paddingVertical: "2px",

                    }}>
                        {data?.discount_description}
                    </Text>
                    <Text style={{
                        fontSize: "10px",
                        fontWeight: "normal",
                        fontFamily: "Times-Roman",
                        width: "100%",
                        paddingHorizontal: "12px",
                        display: "block",
                        paddingVertical: "2px",

                    }}>
                        El área de Control de Calidad tomó {data?.sample_weight} kg para su análisis y verificación de
                        calidad.
                    </Text>
                </View>
                <View style={[styles.section, {
                    paddingVertical: "2px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderBottomWidth: "0px",
                }]}>
                    <View style={{
                        display: "flex", width: "100%", paddingVertical: "2px"
                    }}>
                        <Text style={{
                            fontSize: "12px", fontWeight: "extrabold", fontFamily: "Times-Roman", textAlign: "start",
                        }}>1. DATOS GENERALES</Text>
                    </View>

                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row"
                    }}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "50%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "1px",
                            borderWidth: "1px",

                        }}>FECHA DEL PUNTO DE PARTIDA:</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "50%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "1px",
                            borderWidth: "1px",
                        }}>{data?.datetime_departure ? formatDateTime(data.datetime_departure, 'long') : ' '}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row"
                    }}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "50%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "1px",
                            borderWidth: "1px",

                        }}>FECHA DE DESCARGA:</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "50%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "1px",
                            borderWidth: "1px",
                        }}>{data?.datetime_download_started ? formatDateTime(data.datetime_download_started, 'long') : ' '}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                    }}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "50%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "1px",
                            borderWidth: "1px",

                        }}>PROVEEDOR:</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "50%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "1px",
                            borderWidth: "1px",
                        }}> {data?.is_smart_foods ? 'SMARTFOOD S.A.C.': raw_material_suppliers && raw_material_suppliers.find(e => e.id === data?.supplier)?.name}</Text>


                    </View>

                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row"
                    }}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "50%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "1px",
                            borderWidth: "1px",

                        }}>GUIA DE REMISIÓN:</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "50%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "1px",
                            borderWidth: "1px",
                        }}>{data?.supplier_guide}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row"
                    }}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "50%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "1px",
                            borderWidth: "1px",

                        }}>PARCELAS:</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "50%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "1px",
                            borderWidth: "1px",
                        }}>{data?.parcels_string ? data.parcels_string : ' '}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row"
                    }}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "50%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "1px",
                            borderWidth: "1px",

                        }}>LOTE GB:</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "50%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "1px",
                            borderWidth: "1px",
                        }}>{data?.lot}</Text>
                    </View>
                </View>
                <View style={[styles.section, {
                    paddingVertical: "2px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderBottomWidth: "0px",
                }]}>
                    <View style={{
                        display: "flex", width: "100%", paddingVertical: "6px"
                    }}>
                        <Text style={{
                            fontSize: "12px", fontWeight: "extrabold", fontFamily: "Times-Roman", textAlign: "start",
                        }}>2. RECEPCIÓN</Text>
                    </View>

                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",

                    }}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "30%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px",
                            borderWidth: "1px",
                            textAlign: "center",
                            backgroundColor: "#4ade80"

                        }}>TOTAL PESO GUÍA:</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "30%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px",
                            borderWidth: "1px",
                            textAlign: "center",
                            backgroundColor: "#4ade80"
                        }}>{Humanize.formatNumber(data?.guide_weight, 2)}</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "30%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px",
                            borderWidth: "1px",
                            textAlign: "center",
                            backgroundColor: "#4ade80"
                        }}>KG</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row"
                    }}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "30%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px",
                            borderWidth: "1px",
                            textAlign: "center",
                            backgroundColor: "#4ade80"

                        }}>TOTAL PESO BRUTO:</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "30%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px",
                            borderWidth: "1px",
                            textAlign: "center",
                            backgroundColor: "#4ade80"
                        }}>{Humanize.formatNumber(data?.weight_gross, 2)}</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "30%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px",
                            borderWidth: "1px",
                            textAlign: "center",
                            backgroundColor: "#4ade80"
                        }}>KG</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row"
                    }}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "30%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px",
                            borderWidth: "1px",
                            textAlign: "center",
                            backgroundColor: "#4ade80"

                        }}>TOTAL PESO NETO:</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "30%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px",
                            borderWidth: "1px",
                            textAlign: "center",
                            backgroundColor: "#4ade80"
                        }}>{Humanize.formatNumber(data?.weight_net, 2)}</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "30%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px",
                            borderWidth: "1px",
                            textAlign: "center",
                            backgroundColor: "#4ade80"
                        }}>KG</Text>
                    </View>

                </View>

                <View style={[styles.section, {
                    paddingVertical: "2px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderBottomWidth: "0px",
                }]}>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        gap: "10px"
                    }}>
                        <View style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "44%",
                            display: "inline-block",

                            textAlign: "center",
                            gap: "4px"

                        }}>
                            <View style={{borderWidth: "1px",}}>

                                <View style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    width: "100%",
                                    borderBottomWidth: "1px",
                                    backgroundColor: "#4ade80",
                                    padding: "4px"
                                }}>
                                    <Text>ITEM</Text>
                                    <Text>Cant.</Text>
                                </View>
                                <View style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    width: "100%",
                                    borderBottomWidth: "1px",
                                    backgroundColor: "white",
                                    padding: "4px"
                                }}>
                                    <Text>TOTAL DE JABAS</Text>
                                    <Text>{data?.boxes}</Text>
                                </View>

                                <View style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    width: "100%",
                                    backgroundColor: "white",
                                    padding: "4px"
                                }}>
                                    <Text>TOTAL DE PARIHUELAS</Text>
                                    <Text>{data?.pallets}</Text>
                                </View>


                            </View>
                            <View style={{borderWidth: "1px",}}>
                                <View style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    width: "100%",
                                    borderBottomWidth: "1px",
                                    backgroundColor: "#4ade80",
                                    padding: "4px"
                                }}>
                                    <Text>OBSERVACIÓN</Text>
                                    <Text>KG</Text>
                                </View>
                                <View style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    width: "100%",
                                    padding: "4px"
                                }}>
                                    <Text>TOTAL PESO NETO</Text>
                                    <Text>{Humanize.formatNumber(data?.weight_net, 2)}</Text>
                                </View>
                                <View style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    width: "100%",
                                    padding: "4px"
                                }}>
                                    <Text>KG DESCONTADOS
                                        ({Humanize.formatNumber(data?.discount_weight_percentage, 2)}%)</Text>
                                    <Text>{Humanize.formatNumber(data?.weight_reject, 2)}</Text>
                                </View>
                                <View style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    width: "100%",
                                    padding: "4px",
                                    borderTopWidth: "1px",
                                    backgroundColor: "#fbbf24"
                                }}>
                                    <Text>TOTAL PESO NETO A PAGAR</Text>
                                    <Text>{Humanize.formatNumber(data?.weight_usable, 2)}</Text>
                                </View>

                            </View>
                        </View>
                        <View style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "44%",
                            display: "inline-block",

                            textAlign: "center",
                            gap: "4px"

                        }}>

                            <View style={{borderWidth: "1px",}}>
                                <View style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    width: "100%",
                                    borderBottomWidth: "1px",
                                    backgroundColor: "#4ade80",
                                    padding: "4px"
                                }}>
                                    <Text>ITEM</Text>
                                    <Text>KG</Text>
                                </View>
                                <View style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    width: "100%",
                                    padding: "4px"
                                }}>
                                    <Text>TOTAL DE JABAS</Text>
                                    <Text>{Humanize.formatNumber(data?.weight_boxes, 2)}</Text>
                                </View>

                                <View style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    width: "100%",
                                    padding: "4px",


                                }}>
                                    <Text>TOTAL DE PARIHUELAS</Text>
                                    <Text>{Humanize.formatNumber(data?.weight_pallet, 2)}</Text>
                                </View>
                                <View style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                    width: "100%",
                                    padding: "4px",
                                    borderTopWidth: "1px",
                                    backgroundColor: "#4ade80"
                                }}>
                                    <Text>TOTAL</Text>
                                    <Text>{Humanize.formatNumber(parseFloat(data?.weight_boxes) + parseFloat(data?.weight_pallet), 2)}</Text>
                                </View>

                            </View>
                        </View>


                    </View>
                    <View style={{
                        marginTop: "20px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-around",
                        flexDirection: "row",
                        alignItems: "center",
                        maxHeight: "80px",
                        backgroundColor: "white"
                    }}>
                        <View style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "4px",
                            width: "40%",
                            padding: "6px",
                        }}>
                            {signature && signature[0]['signature'] &&
                            <Image
                                style={{
                                    width: 120, backgroundColor: "white",
                                }} src={signature && signature[0]['signature']}/>}
                            <Text style={{
                                fontSize: "12px", fontWeight: "extrabold", fontFamily: "Times-Roman",
                            }}>_________________________</Text>
                            <Text style={{
                                fontSize: "12px", fontWeight: "extrabold", fontFamily: "Times-Roman",
                            }}>{signature && signature[0]['name']}</Text>
                            <Text style={{
                                fontSize: "12px", fontWeight: "extrabold", fontFamily: "Times-Roman",
                            }}>{signature && signature[0]['position']}</Text>
                        </View>
                        <View style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "4px",
                            width: "40%",
                            padding: "6px",
                        }}>
                            {signature && signature[1]['signature'] &&
                            <Image
                                style={{
                                    width: 120, backgroundColor: "white",
                                }} src={signature && signature[1]['signature']}/>}
                            <Text style={{
                                fontSize: "12px", fontWeight: "extrabold", fontFamily: "Times-Roman",
                            }}>_________________________</Text>
                            <Text style={{
                                fontSize: "12px", fontWeight: "extrabold", fontFamily: "Times-Roman",
                            }}>{signature && signature[1]['name']}</Text>
                            <Text style={{
                                fontSize: "12px", fontWeight: "extrabold", fontFamily: "Times-Roman",
                            }}>{signature && signature[1]['position']}</Text>
                        </View>


                    </View>
                </View>


            </Page>


        </Document>
    </PDFViewer>);
};

export default ReportViewer;