import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import Layout from "../../../hocs/Layout";
import Filters from "../../../components/mm/Requirements/Filters";
import {useDispatch, useSelector} from "react-redux";
import {getChlorine, getTechnicals} from "../../../redux/actions/maintenance";
import Modal from "../../../components/util/Modal";
import ModalHook from "../../../hooks/ModalHook";
import Form from "../../../components/mm/Chlorine/Form";
import Table from "../../../components/mm/Chlorine/Table";


const Chlorine = () => {
    const [params, setParams] = useState({
        year: new Date().getFullYear(), month: new Date().getMonth() + 1, week: 'all'
    })
    const dispatch = useDispatch();
    const {content, setContent, isOpen, openModal} = ModalHook();
    const data = useSelector(state => state.Maintenance.chlorine);

    useEffect(() => {
        dispatch(getTechnicals())
        dispatch(getChlorine(params));
    }, []);

    const handleSearch = () => {
        dispatch(getChlorine(params));
    }

    const handleUpdate = (data) => {
        const formComponent = <Form onClose={openModal} data={data} params={params}
        />;
        setContent(formComponent);
        openModal();
    };


    return (<Layout>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <Helmet>
            <title>Cloro</title>
        </Helmet>
        <div className={"bg-white rounded-2xl p-4 h-fit"}>
            <Filters params={params} setParams={setParams} handleSearch={handleSearch}/>
            <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                <Table data={data} handleUpdate={handleUpdate}/>
            </div>
        </div>


    </Layout>);
};
export default Chlorine;