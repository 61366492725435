import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {InputForm, SelectForm} from "../../util/HelpersForm";
import {addLot, updateExternalPerson} from "../../../redux/actions/logistic";


const FormSummaryLot = ({data, onClose, params}) => {
    const dispatch = useDispatch();
    const categories = useSelector(state => state.Category.categories);
    const raw_material_suppliers = useSelector(state => state.Stakeholders.raw_material_suppliers);
    const manufacturing_companies = useSelector(state => state.Stakeholders.manufacturing_companies);

    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            console.log(form)
            data ? dispatch(updateExternalPerson(data?.id, form, params)) : dispatch(addLot(form, params));
            onClose();
        }
    })

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-1 gap-2`}>

                <InputForm values={formik.values.lot} errors={formik.errors.lot} formik={formik} field={'lot'}
                           title={'Lote'} max_length={13}/>
                <SelectForm values={formik.values.product} errors={formik.errors.product} formik={formik}
                            field={'product'}
                            title={'Producto'}
                            options={categories ? categories.find(item => item.name === 'Materia Prima')?.children : []}
                            display={'name'}/>
                <SelectForm values={formik.values.supplier} errors={formik.errors.supplier} formik={formik}
                            field={'supplier'}
                            title={'Proveedor'} options={raw_material_suppliers ? raw_material_suppliers : []}
                            display={'name'}/>
                <SelectForm values={formik.values.manufacturing} errors={formik.errors.manufacturing} formik={formik}
                            field={'manufacturing'}
                            title={'Planta de proceso'} options={manufacturing_companies ? manufacturing_companies : []}
                            display={'name'}/>
                <InputForm values={formik.values.datetime_download_started}
                           errors={formik.errors.datetime_download_started}
                           formik={formik}
                           field={'datetime_download_started'}
                           type_={'datetime-local'}
                           title={'Fecha de llegada'} max_length={25}/>
                <InputForm values={formik.values.datetime_download_started}
                           errors={formik.errors.datetime_download_started}
                           formik={formik}
                           field={'datetime_download_started'}
                           type_={'datetime-local'}
                           title={'Fecha de descarga'} max_length={25}/>

                <InputForm values={formik.values.origin} errors={formik.errors.origin} formik={formik} field={'origin'}
                           title={'Origen'} max_length={100}/>
                <InputForm values={formik.values.transport_guide} errors={formik.errors.transport_guide} formik={formik}
                           field={'transport_guide'}
                           title={'Guía de transporte'} max_length={30}/>
                <InputForm values={formik.values.supplier_guide} errors={formik.errors.supplier_guide} formik={formik}
                           field={'supplier_guide'}
                           title={'Guía de proveedor'} max_length={30}/>
                <SelectForm values={formik.values.condition} errors={formik.errors.condition} formik={formik}
                            field={'condition'}
                            title={'Condición'} options={[{id: 'O', name: 'Orgánico'}, {id: 'C', name: 'Convencional'}]}
                            display={'name'}/>
                <InputForm values={formik.values.variety} errors={formik.errors.variety} formik={formik}
                           field={'variety'}
                           title={'Variedad'} max_length={25}/>
            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {
        lot: data?.lot || '',
        product: data?.product || '',
        supplier: data?.supplier || '',
        manufacturing: data?.manufacturing || '',
        origin: data?.origin || '',
        transport_guide: data?.transport_guide || '',
        supplier_guide: data?.supplier_guide || '',
        condition: data?.condition || 'O',
        variety: data?.variety || '',
        datetime_arrival: data?.datetime_arrival || new Date().toISOString().split('T')[0] + 'T00:00',
        datetime_download_started: data?.datetime_download_started || new Date().toISOString().split('T')[0] + 'T00:00',

    }
}
const newSchema = () => {
    return {
        lot: Yup.string().min(12).max(13).required(),
        product: Yup.number().integer().required(),
        supplier: Yup.string().required(),
        manufacturing: Yup.string().required(),
        origin: Yup.string().required(),
        transport_guide: Yup.string().required(),
        supplier_guide: Yup.string().required(),
        condition: Yup.string().min(1).max(1).required(),
        variety: Yup.string().required(),
        datetime_arrival: Yup.string().required(),
        datetime_download_started: Yup.string().required(),

    }
}

export default FormSummaryLot;
