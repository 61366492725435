import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {InputForm} from "../../util/HelpersForm";
import {updateChlorine, updateH2O} from "../../../redux/actions/maintenance";
import SwitchField from "../Task/SwitchField";


const Form = ({data, onClose, params}) => {
    const dispatch = useDispatch();


    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            dispatch(updateChlorine(data?.id, form, params))
            onClose();
        }
    })

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-2 gap-2`}>
                <InputForm values={formik.values.hour} errors={formik.errors.hour} formik={formik}
                            field={'hour'}
                            title={'Hora'} max_length={8} type_={'time'}/>

                <InputForm values={formik.values.dosage_1} errors={formik.errors.dosage_1} formik={formik}
                            field={'dosage_1'}
                           title={'Dosificación 01'} max_length={3}/>
                <InputForm values={formik.values.dosage_2} errors={formik.errors.dosage_2} formik={formik}
                            field={'dosage_2'}
                            title={'Dosificación 02'} max_length={3}/>
                <InputForm values={formik.values.hardness} errors={formik.errors.hardness} formik={formik}
                            field={'hardness'}
                            title={'Dureza'} max_length={3}/>
                <InputForm values={formik.values.ppm} errors={formik.errors.ppm} formik={formik}
                            field={'ppm'}
                            title={'PPM'} max_length={3}/>
                <InputForm values={formik.values.level_1} errors={formik.errors.level_1} formik={formik}
                            field={'level_1'}
                            title={'Nivel 01'} max_length={3}/>
                <InputForm values={formik.values.level_2} errors={formik.errors.level_2} formik={formik}
                            field={'level_2'}
                            title={'Nivel 02'} max_length={3}/>
                <SwitchField formik={formik} title={"Limpieza"} name={"clean"}/>



            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {

        hour: data?.hour || '00:00:00',
        dosage_1: data?.dosage_1 || 0,
        dosage_2: data?.dosage_2 || 0,
        hardness: data?.hardness || 0,
        ppm: data?.ppm || 0,
        clean: data?.clean || false,
        level_1: data?.level_1 || 0,
        level_2: data?.level_2 || 0,


    }
}
const newSchema = () => {
    return {
        hour: Yup.string().required(),
        dosage_1: Yup.number().min(0).max(100).required(),
        dosage_2: Yup.number().min(0).max(100).required(),
        hardness: Yup.number().required(),
        ppm: Yup.number().min(0).max(100).required(),
        clean: Yup.boolean().required(),
        level_1: Yup.number().min(0).max(100).required(),
        level_2: Yup.number().min(0).max(100).required(),
    }
}

export default Form;
