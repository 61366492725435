import React, {useEffect} from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import HeaderForm from "../../../util/HeaderForm";
import {InputForm, SelectForm} from "../../../util/HelpersForm";
import {addIssueArticle, getReceiptsByArticles} from "../../../../redux/actions/logistic";
import {setAlert} from "../../../../redux/actions/alert";

const FormIssue = ({onClose, item, params}) => {

    const dispatch = useDispatch();
    const articles = useSelector(state => state.Logistic.receipts_stock);
    const departments = useSelector(state => state.Users.departments);
    const manufacturing_companies = useSelector(state => state.Stakeholders.manufacturing_companies);


    useEffect(() => {
        item?.id && dispatch(getReceiptsByArticles(item?.id, params?.manufacturer))
    }, [dispatch])

    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            if (formik.values.quantity > articles.find((e) => e.id === formik.values.item)?.stock) {
                dispatch(setAlert('No hay suficiente stock', 'error'))
            } else {
                if (params.manufacturer !== '') {
                    form['manufacturing'] = params.manufacturer;
                    dispatch(addIssueArticle(form, params))
                    onClose();
                } else {
                    dispatch(setAlert('Seleccione un almacén', 'error'));
                }

            }
        }
    })

    return (<form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
        <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
        <h3 className={"font-light text-xs text-gray-400 mb-1"}>{item?.name}</h3>
        <h3 className={"font-light text-xs text-gray-400 border-b-2 mb-2"}>{manufacturing_companies && manufacturing_companies.find((e) => e.id === params?.manufacturer)?.name}</h3>

        <div className={`grid  grid-cols-1 gap-2`}>

            <InputForm values={formik.values.date} errors={formik.errors.date} formik={formik}
                       field={'date'}
                       title={'Fecha'} type_={'date'}/>

            <SelectForm values={formik.values.item} errors={formik.errors.item} formik={formik}
                        field={'item'} display={'name'}
                        title={'Artículo'} options={articles && articles}/>
            <SelectForm values={formik.values.area} errors={formik.errors.area} formik={formik}
                        field={'area'} display={'name'}
                        title={'Area'} options={departments && departments}/>

            {formik.values.item &&
                <InputForm values={formik.values.quantity} errors={formik.errors.quantity} formik={formik}
                           field={'quantity'} type_={'number'}
                           max_length={articles && articles.find((e) => e.id === formik.values.item)?.stock}
                           title={'Cantidad'}/>}

            <InputForm values={formik.values.lot_id} errors={formik.errors.lot_id} formik={formik}
                       field={'lot_id'}
                       title={'Lote'} max_length={13}/>

        </div>
    </form>);
};

const initialValues = () => {
    return {
        item: '', area: '', date: new Date().toISOString()[0], quantity: 0, lot_id: '',

    }
}

const newSchema = () => {
    return {
        item: Yup.string().min(6).required(),
        area: Yup.string().min(1).required(),
        date: Yup.date().required(),
        quantity: Yup.number().integer().min(1).required(),
        lot_id: Yup.string().min(2)
    }
}

export default FormIssue;