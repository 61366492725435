import React from 'react';
import FormTasks from "./FormTask";
import DocumentViewer from "./DocumentViewer";
import FormResourcesOrder from "./FormResource";
import FormHelpersOrder from "./FormHelper";

const TaskModalContent = ({actionType, data, params, close}) => {


    switch (actionType) {
        case 'add':
            return <FormTasks close={close} params={params}/>;
        case 'view':
            return <DocumentViewer data={data} close={close}/>;
        case 'update':
            return <FormTasks params={params} id={data?.id} close={close} data={data}/>;
        case 'updateResource':
            return <FormResourcesOrder close={close} id={data.id} params={params}/>;
        case 'updateHelpers':
            return <FormHelpersOrder close={close} id={data.id} params={params}/>;
        default:
            return <div>No action selected</div>;
    }
};

export default TaskModalContent;