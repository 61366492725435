import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {
    addCustomer,
    addManufacturingCompany,
    addMaterialSupplier,
    addRawMaterialSupplier,
    addTransportationCompany,
    updateCustomer,
    updateManufacturingCompany,
    updateMaterialSupplier,
    updateRawMaterialSupplier,
    updateTransportationCompany
} from "../../../redux/actions/stakeholders";


const FormAbstract = ({data, onClose, params, action}) => {
    const dispatch = useDispatch();

    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            if (action === 'raw_material_suppliers') data ? dispatch(updateRawMaterialSupplier(data?.id, form, params)) : dispatch(addRawMaterialSupplier(form, params));
            if (action === 'material_suppliers') data ? dispatch(updateMaterialSupplier(data?.id, form, params)) : dispatch(addMaterialSupplier(form, params));
            if (action === 'customer') data ? dispatch(updateCustomer(data?.id, form, params)) : dispatch(addCustomer(form, params));
            if (action === 'transportation_company') data ? dispatch(updateTransportationCompany(data?.id, form, params)) : dispatch(addTransportationCompany(form, params));
            if (action === 'manufacturing_company') data ? dispatch(updateManufacturingCompany(data?.id, form, params)) : dispatch(addManufacturingCompany(form, params));
            onClose();
        }
    })

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-1 gap-2`}>
                <div>
                    {formik.values.ruc.length !== 11 &&
                        <p className={`text-[10px]  font-extralight leading-none text-blue-400 `}>NIC O RUC:</p>}
                    <input type={'text'} maxLength={11} placeholder={"NIF"}
                           className={`${formik.errors.ruc && "border-red-300"} text-black w-full focus:border-blue-300 p-3 mt-4 border border-gray-300 rounded outline-none focus:bg-gray-50 font-light text-xs`}
                           value={`${formik.values.ruc}`}
                           onChange={text => formik.setFieldValue('ruc', text.target.value)}/>
                    <p className={`${formik.errors.ruc ? "text-red-400" : "text-gray-800"} text-[10px] mt-1  font-extralight leading-none `}>{formik.errors.ruc}</p>
                </div>
                <div>

                    <p className={`text-[10px]  font-extralight leading-none text-blue-400 `}>Nombre:</p>
                    <input type={'text'} maxLength={100} placeholder={"Apellidos y Nombres"}
                           className={`${formik.errors.name && "border-red-300"} text-black w-full focus:border-blue-300 p-3 mt-4 border border-gray-300 rounded outline-none focus:bg-gray-50 font-light text-xs`}
                           value={formik.values.name}
                           onChange={text => formik.setFieldValue('name', text.target.value)}/>
                    <p className={`${formik.errors.name ? "text-red-400" : "text-gray-800"} text-[10px] mt-1  font-extralight leading-none `}>{formik.errors.name}</p>
                </div>

            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {
        name: data?.name || '', ruc: data?.ruc || '',
    }
}
const newSchema = () => {
    return {
        name: Yup.string(), ruc: Yup.string().min(11).max(11).required("RUC no puede estar en blanco"),
    }
}

export default FormAbstract;
