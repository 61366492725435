import React, {useEffect} from 'react';
import {Document, Image, Page, PDFViewer, StyleSheet, Text, View} from "@react-pdf/renderer";
import Logo from "../../../assets/logo.png";
import {useDispatch, useSelector} from "react-redux";
import Humanize from "humanize-plus";
import HeaderForm from "../../util/HeaderForm";
import {formatTime} from "../../util/helpers";
import {getHelpers, getResources} from "../../../redux/actions/maintenance";

const styles = StyleSheet.create({
    image: {
        width: 70, borderRadius: 10, padding: "2px",

    }, section: {
        display: "flex",
        justifyContent: "space-around",
        width: "100%",
        borderLeft: "1px",
        flexDirection: "row",
        borderRight: "1px",
        borderBottom: "1px"
    }
});
const DocumentViewer = ({data, close}) => {
    const technicals = useSelector(state => state.Maintenance.technical);
    const users = useSelector(state => state.Maintenance.users);
    const physical = useSelector(state => state.Maintenance.physical);
    const maintenance_type = useSelector(state => state.Maintenance.maintenance_type);
    const failures = useSelector(state => state.Maintenance.failures);
    const tools = useSelector(state => state.Maintenance.tools)
    const resources = useSelector(state => state.Maintenance.resources)
    const helpers = useSelector(state => state.Maintenance.helpers)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getResources(data?.id))
        dispatch(getHelpers(data?.id))
    }, []);

    function costTotal(time, salary) {
        const [hours, minutes, seconds] = time.split(':').map(Number);
        const totalMinutes = (hours * 60) + minutes + (seconds / 60);
        return totalMinutes * salary;
    }


    return (<div className={"h-screen"}><HeaderForm close={close}/>


        <PDFViewer style={{width: "100%", height: "100%"}}>
            <Document>
                <Page size="A4" style={{padding: "12px", width: "100%"}}>
                    <View style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "6px",
                        gap: "24px",
                        border: "1px solid black",
                    }}>
                        <Image style={styles.image} src={Logo}/>
                        <Text style={{
                            fontSize: "18px", fontWeight: "extrabold", fontFamily: "Times-Roman",
                        }}>ORDEN DE TRABAJO - {data?.code_ot}</Text>
                    </View>
                    <View style={styles.section}>
                        <Text style={{
                            fontSize: "12px", fontWeight: "extrabold", fontFamily: "Times-Roman", textAlign: "center",
                        }}>PLANTA: GREENBOX S.A.C. -BQ. LA FLORIDA, C. CENTRAL KM 39 ACOBAMBA </Text>
                    </View>
                    <View style={styles.section}> <Text style={{
                        fontSize: "12px",
                        fontWeight: "extrabold",
                        fontFamily: "Times-Roman",
                        textAlign: "center",
                        width: "35%",
                        borderRightWidth: "1px"
                    }}>Tipo de
                        trabajo: {maintenance_type && maintenance_type.find(e => e.id === data?.type_maintenance)?.name}</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            textAlign: "center",
                            width: "35%",
                            borderRightWidth: "1px"
                        }}>Origen de la falla: {failures && failures.find(e => e.id === data?.failure)?.name}</Text>
                        <Text style={{
                            fontSize: "11px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            textAlign: "center",
                            width: "30%"
                        }}>Fecha: {new Date(data?.date_report).toLocaleDateString('es-PE', {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            hour12: true
                        })}</Text>
                    </View>
                    <View style={styles.section}> <Text style={{
                        fontSize: "12px",
                        fontWeight: "extrabold",
                        fontFamily: "Times-Roman",
                        textAlign: "center",
                        width: "50%"
                    }}>Equipo: {physical && physical.find(e => e.id === data?.asset)?.name}</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            textAlign: "center",
                            borderLeftWidth: "1px",
                            width: "50%"
                        }}>Ubicación: {data?.facility}</Text>
                    </View>
                    <View style={[styles.section, {flexDirection: "column"}]}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "100%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "6px"
                        }}>Descripción o actividad: </Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "100%",
                            paddingHorizontal: "12px",
                            paddingBottom: "6px",
                            display: "block"

                        }}>
                            {data?.description}
                        </Text>
                    </View>
                    <View style={[styles.section, {paddingVertical: "2px", width: "100%", justifyContent: "none"}]}>
                        <View style={{display: "flex", width: "30%", alignItems: "center", justifyContent: "center",}}>
                            <Text style={{
                                fontSize: "12px",
                                fontWeight: "extrabold",
                                fontFamily: "Times-Roman",
                                textAlign: "center",
                            }}>TRABAJO REALIZADO</Text>
                        </View>
                        <View style={{display: "flex", width: "35%"}}>
                            <Text style={{
                                fontSize: "12px",
                                fontWeight: "extrabold",
                                fontFamily: "Times-Roman",
                                textAlign: "center",
                                borderLeftWidth: "1px",
                                borderBottomWidth: "1px",
                            }}>FECHA DE INICIO</Text>
                            <Text style={{
                                fontSize: "12px",
                                fontWeight: "extrabold",
                                fontFamily: "Times-Roman",
                                textAlign: "center",
                                borderLeftWidth: "1px",

                            }}>FECHA DE FIN</Text>
                        </View>
                        <View style={{display: "flex", width: "35%",}}>
                            <Text style={{
                                fontSize: "12px",
                                fontWeight: "extrabold",
                                fontFamily: "Times-Roman",
                                textAlign: "center",
                                borderLeftWidth: "1px",
                                borderBottomWidth: "1px",
                            }}>{new Date(data?.date_start).toLocaleDateString('es-PE', {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                second: "numeric",
                                hour12: true
                            })}</Text>
                            <Text style={{
                                fontSize: "12px",
                                fontWeight: "extrabold",
                                fontFamily: "Times-Roman",
                                textAlign: "center",
                                borderLeftWidth: "1px",
                            }}>{new Date(data?.date_finish).toLocaleDateString('es-PE', {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                second: "numeric",

                                hour12: true
                            })}</Text>
                        </View>
                    </View>
                    <View style={[styles.section, {flexDirection: "column"}]}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "100%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "6px"
                        }}>Trabajos realizados: </Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "100%",
                            paddingHorizontal: "12px",
                            paddingBottom: "6px",
                            display: "block"

                        }}>
                            {data?.activities}
                        </Text>
                    </View>
                    <View style={[styles.section, {flexDirection: "col"}]}>
                        <Text style={{
                            paddingVertical: "2px",
                            backgroundColor: "rgba(0,0,0,0.1)",
                            width: "100%",
                            fontSize: "12px",
                            fontWeight: "bold",
                            fontFamily: "Times-Roman",
                            textAlign: "center",
                        }}>PERSONAL DE MANTENIMIENTO</Text>
                        <View style={styles.section}>
                            <Text style={{
                                fontSize: "8px",
                                fontWeight: "extrabold",
                                fontFamily: "Times-Roman",
                                textAlign: "center",
                                width: "60%",
                                paddingVertical: "2px"
                            }}>TÉCNICO RESPONSABLE</Text>
                            <Text style={{
                                fontSize: "8px",
                                fontWeight: "extrabold",
                                fontFamily: "Times-Roman",
                                textAlign: "center",
                                borderLeftWidth: "1px",
                                width: "20%",
                                paddingVertical: "2px"
                            }}>FIRMA</Text>
                            <Text style={{
                                fontSize: "8px",
                                fontWeight: "extrabold",
                                fontFamily: "Times-Roman",
                                textAlign: "center",
                                borderLeftWidth: "1px",
                                width: "20%",
                                paddingVertical: "2px"
                            }}>Costo</Text>
                        </View>
                        <View
                            style={[styles.section, {
                                display: "flex", justifyContent: "center", alignItems: "center"
                            }]}>
                            <Text style={{
                                fontSize: "12px",
                                fontWeight: "extrabold",
                                fontFamily: "Times-Roman",
                                textAlign: "center",
                                width: "60%",
                            }}>
                                {technicals && technicals.find(e => e.id === data?.technical)?.name}
                                {data?.date_start && `(${formatTime(data?.date_start)} - ${formatTime(data?.date_finish)})`}
                            </Text>
                            <View style={{
                                width: "20%", borderRightWidth: "1px", borderLeftWidth: "1px",
                            }}>
                                {technicals && data?.technical && <Image style={{
                                    width: "60%", marginLeft: "20%"
                                }} src={technicals.find(e => e.id === data?.technical)?.signature || Logo}></Image>}
                            </View>
                            <Text style={{
                                fontSize: "12px",
                                fontWeight: "extrabold",
                                fontFamily: "Times-Roman",
                                textAlign: "center",
                                borderLeftWidth: "1px",
                                width: "20%"
                            }}>S/{Humanize.formatNumber(costTotal(data?.time,technicals.find(e => e.id === data?.technical)?.salary), 2)}</Text>


                        </View>
                        {helpers && helpers?.map((item, index) => {
                            return (<View key={index}
                                          style={[styles.section, {
                                              display: 'flex', justifyContent: "center", alignItems: "center"
                                          }]}>
                                <Text style={{
                                    fontSize: "12px",
                                    fontWeight: "extrabold",
                                    fontFamily: "Times-Roman",
                                    textAlign: "center",
                                    width: "60%",
                                }}>
                                    {item.helper.get_full_name} {item?.date_start && `(${formatTime(item?.date_start)} - ${formatTime(item?.date_finish)})`}
                                </Text>
                                <View style={{
                                    width: "20%", borderRightWidth: "1px", borderLeftWidth: "1px",
                                }}>

                                    <Image style={{
                                        width: "60%", marginLeft: "20%"
                                    }} src={item.helper.signature || Logo}></Image>
                                </View>
                                <Text style={{
                                    fontSize: "12px",
                                    fontWeight: "extrabold",
                                    fontFamily: "Times-Roman",
                                    textAlign: "center",
                                    borderLeftWidth: "1px",
                                    width: "20%"
                                }}>S/{Humanize.formatNumber(costTotal(item?.time,item?.helper?.salary), 2)}</Text>


                            </View>)
                        })}

                    </View>

                    <View style={[styles.section, {flexDirection: "col"}]}>
                        <Text style={{
                            paddingVertical: "2px",
                            backgroundColor: "rgba(0,0,0,0.1)",
                            width: "100%",
                            fontSize: "12px",
                            fontWeight: "bold",
                            fontFamily: "Times-Roman",
                            textAlign: "center",
                        }}>RESPUESTOS Y MATERIALES USADOS Y/O REQUERIDOS</Text>
                        <View style={styles.section}>
                            <Text style={{
                                fontSize: "8px",
                                fontWeight: "extrabold",
                                fontFamily: "Times-Roman",
                                textAlign: "center",
                                width: "80%",
                                paddingVertical: "2px"
                            }}>DESCRIPCIÓN</Text>
                            <Text style={{
                                fontSize: "8px",
                                fontWeight: "extrabold",
                                fontFamily: "Times-Roman",
                                textAlign: "center",
                                borderLeftWidth: "1px",
                                width: "20%",
                                paddingVertical: "2px"
                            }}>CANTIDAD</Text>

                        </View>
                        {resources && resources?.map(item => {
                            return (<View style={styles.section}>
                                <Text style={{
                                    fontSize: "12px",
                                    fontWeight: "extrabold",
                                    fontFamily: "Times-Roman",
                                    textAlign: "center",
                                    width: "80%"
                                }}>{item?.article}</Text>
                                <Text style={{
                                    fontSize: "12px",
                                    fontWeight: "extrabold",
                                    fontFamily: "Times-Roman",
                                    textAlign: "center",
                                    borderLeftWidth: "1px",
                                    width: "20%"
                                }}>{item?.quantity}</Text>
                            </View>)
                        })}

                    </View>


                    <View style={[styles.section, {flexDirection: "col"}]}>
                        <Text style={{
                            paddingVertical: "2px",
                            backgroundColor: "rgba(0,0,0,0.1)",
                            width: "100%",
                            fontSize: "12px",
                            fontWeight: "bold",
                            fontFamily: "Times-Roman",
                            textAlign: "center",
                        }}>HERRAMIENTAS Y OTROS USADOS Y/O REQUERIDOS</Text>
                        {tools && data?.tools?.map(t => {


                            return (<Text style={{
                                fontSize: "12px",
                                fontWeight: "extrabold",
                                fontFamily: "Times-Roman",
                                textAlign: "center",
                                width: "100%"
                            }}>• {tools.find(e => e.id === t)?.name}</Text>)
                        })}


                    </View>

                    <View style={styles.section}>
                        <Text style={{
                            paddingVertical: "2px",
                            backgroundColor: "rgba(0,0,0,0.1)",
                            width: "100%",
                            fontSize: "12px",
                            fontWeight: "bold",
                            fontFamily: "Times-Roman",
                            textAlign: "center",
                        }}>OBSERVACIONES</Text>
                    </View>
                    <View style={[styles.section, {flexDirection: "column"}]}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "100%",
                            paddingHorizontal: "12px",
                            paddingVertical: "6px",
                            display: "block"

                        }}>
                            {data?.stop ? " Afectó la producción" : "No afectó la producción"}
                        </Text>
                    </View>
                    <View style={styles.section}> <Text style={{
                        fontSize: "12px",
                        fontWeight: "extrabold",
                        fontFamily: "Times-Roman",
                        textAlign: "center",
                        width: "100%",
                        backgroundColor: "rgba(0,0,0,0.1)",
                    }}>AUTORIZACIONES</Text>
                    </View>
                    <View style={[styles.section]}>
                        {users && data && <View style={{
                            width: "20%", display: "flex", flexDirection: "column"
                        }}>
                            <Image style={{
                                width: "100%"
                            }} src={users.find(e => e.position === 'Jefe de mantenimiento')?.signature || Logo}></Image>
                            <Text style={{
                                fontSize: "7px",
                                fontWeight: "extrabold",
                                fontFamily: "Times-Roman",
                                textAlign: "center",
                                width: "100%",
                                borderTopWidth: "1px"
                            }}>JEFE DE ÁREA - ROLY SILVA</Text>
                        </View>}
                        {users && <View style={{
                            width: "20%", display: "flex", flexDirection: "column"
                        }}>
                            <Image style={{
                                width: "100%",
                            }} alt={"supervisor"}
                                   src={users.find(e => e.position === 'Planner de mantenimiento')?.signature || Logo}></Image>
                            <Text style={{
                                fontSize: "7px",
                                fontWeight: "extrabold",
                                fontFamily: "Times-Roman",
                                textAlign: "center",
                                width: "100%",
                                borderTopWidth: "1px",
                                textTransform: "uppercase"
                            }}>PLANNER
                                - {users.find(e => e.position === 'Planner de mantenimiento')?.name || 'No asignado'}</Text>
                        </View>}

                        {data?.cleaned && users && data?.supervisor && <View style={{
                            width: "20%", display: "flex", flexDirection: "column"
                        }}>
                            <Image style={{
                                width: "100%",
                            }} alt={"supervisor"}
                                   src={users.find(e => e.id === data.supervisor)?.signature || Logo}></Image>


                            <Text style={{
                                fontSize: "7px",
                                fontWeight: "extrabold",
                                fontFamily: "Times-Roman",
                                textAlign: "center",
                                width: "100%",
                                borderTopWidth: "1px",
                                textTransform: "uppercase"
                            }}>SUP. CALIDAD - {users.find(e => e.id === data.supervisor)?.name || 'No asignado'}</Text>

                        </View>}
                        {data?.validated && users && data?.requester && <View style={{
                            width: "20%", display: "flex", flexDirection: "column"
                        }}>
                            <Image style={{
                                width: "100%",
                            }} alt={"solicitante"}
                                   src={users.find(e => e.id === data.requester)?.signature || Logo}></Image>
                            <Text style={{
                                fontSize: "7px",
                                fontWeight: "extrabold",
                                fontFamily: "Times-Roman",
                                textAlign: "center",
                                width: "100%",
                                borderTopWidth: "1px",
                                textTransform: "uppercase"
                            }}>SOLICITANTE - {users.find(e => e.id === data.requester)?.name || 'No asignado'}</Text>
                        </View>}
                    </View>
                    <View style={styles.section}> <Text style={{
                        fontSize: "12px",
                        fontWeight: "extrabold",
                        fontFamily: "Times-Roman",
                        textAlign: "start",
                        width: "100%",
                        backgroundColor: "rgba(0,0,0,0.1)",
                    }}>60–F-01-V5</Text>
                    </View>
                </Page>


            </Document>
        </PDFViewer>
    </div>);
};

export default DocumentViewer;