import React, {Fragment} from 'react';
import {Popover, Transition} from "@headlessui/react";
import {PlusCircleIcon, SquaresPlusIcon} from "@heroicons/react/24/solid";

const ButtonHelper = ({handle}) => {


    return (<div
        className={"absolute z-30 right-4 bottom-4 h-14 w-14 border-4 border-white rounded-full bg-[#0dcd79] text-lg"}>
        <Popover className="relative">
            {({open}) => (<>
                <Popover.Button
                    className={`
                ${open ? '' : 'text-opacity-90'}
                group inline-flex items-center space-x-2 p-2 rounded-md px-2 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none focus-visible:ring-0 focus-visible:ring-inv focus-visible:ring-opacity-75`}
                >
                    <SquaresPlusIcon className="w-8 h-8 font-bold text-white focus:clear-none"/>
                </Popover.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <Popover.Panel
                        className="absolute bottom-1/2 z-10 mt-3 w-max  -translate-x-2/3 transform px-4 sm:px-0 lg:max-w-3xl">
                        <div
                            className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="relative grid  bg-white   ">
                                <button type={"button"} onClick={handle}
                                        className={"text-xs space-x-4 border-b-2  flex items-center p-2 justify-around font-light hover:bg-opacity-10 hover:rounded-lg hover:bg-green-400"}>
                                    <PlusCircleIcon className={"w-5 h-5 text-[#0dcd79] cursor-pointer"}/>
                                    <p className={"text-[#0dcd79] font-semibold"}>Añadir</p>
                                </button>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </>)}
        </Popover>

    </div>);
};

export default ButtonHelper;