import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {PencilSquareIcon} from "@heroicons/react/24/outline";
import Humanize from "humanize-plus";
import {formatDate, formatDateTZ} from "../../util/helpers";
const Table = ({data, handleUpdate}) => {
    const columns = ['','Fecha', 'M1 dia', 'M1 noche', 'M2 dia', 'M3 dia', 'M3 noche', 'M4 dia', 'M4 noche', 'M5 dia', 'M5 noche', 'M6 dia', 'M6 noche']
    return (<TableBase columns={columns} cell={Row(data, handleUpdate)}/>);
};


const Row = (data, update) => (data && data.map((item, index) => {

    return <tr key={index} className="h-max border-gray-300  border-b">

        <Cell text={<div className={"font-bold flex items-center whitespace-nowrap"}>
            <PencilSquareIcon onClick={() => update(item)} title={"Editar"}
                              className={"h-5 w-5 mr-2 text-blue-500 cursor-pointer"}/>
        </div>}/>
        <Cell text={formatDateTZ(item?.date)}/>
        <Cell text={Humanize.formatNumber(item?.m1_day, 2)}/>
        <Cell text={Humanize.formatNumber(item?.m1_night, 2)}/>
        <Cell text={Humanize.formatNumber(item?.m2_day, 2)}/>
        <Cell text={Humanize.formatNumber(item?.m3_day, 2)}/>
        <Cell text={Humanize.formatNumber(item?.m3_night, 2)}/>
        <Cell text={Humanize.formatNumber(item?.m4_day, 2)}/>
        <Cell text={Humanize.formatNumber(item?.m4_night, 2)}/>
        <Cell text={Humanize.formatNumber(item?.m5_day, 2)}/>
        <Cell text={Humanize.formatNumber(item?.m5_night, 2)}/>
        <Cell text={Humanize.formatNumber(item?.m6_day, 2)}/>
        <Cell text={Humanize.formatNumber(item?.m6_night, 2)}/>


    </tr>
}));

export default Table;
