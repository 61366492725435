import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {reset_password} from '../../redux/actions/auth'
import {Navigate} from "react-router-dom";
import SignLayout from "../../hocs/SignLayout";
import {TailSpin} from "react-loader-spinner";
import {Helmet} from "react-helmet";

const ResetPassword = () => {
    const dispatch = useDispatch();
    const loading = useSelector(state => state.Auth.loading);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [requestSent, setRequestSent] = useState(false);

    const [formData, setFormData] = useState({
        email: '',
    })

    const {
        email,
    } = formData;

    const onChange = e => setFormData({...formData, [e.target.name]: e.target.value});

    const onSubmit = e => {
        e.preventDefault();
        dispatch(reset_password(email));
        setRequestSent(true)
    }

    if (requestSent && !loading) return <Navigate to='/'/>;

    return (<SignLayout>
            <Helmet>
            <title>Restablecer contraseña</title>
        </Helmet>
            <form onSubmit={e => onSubmit(e)} className="bg-white  px-20 flex flex-col justify-center items-center gap-4">

                    <div className="flex items-center border-2 py-2 px-3 rounded-2xl w-full"><input
                        name="email"
                        value={email}
                        onChange={e => onChange(e)}
                        type="email"
                        placeholder="Email"
                        required
                        autoComplete={"off"}
                        className="pl-2 outline-none border-none"
                    />
                    </div>

                <div>
                    {loading ? <button
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                        <TailSpin
                            type="Oval"
                            color="#fff"
                            width={20}
                            height={20}
                        />
                    </button> : <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    >
                        Enviar correo
                    </button>}
                </div>
            </form>
        </SignLayout>

    )
}


export default ResetPassword