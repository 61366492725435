import React from 'react';
import Layout from "../../hocs/Layout";


const BI = () => {

    return (<Layout>
            <div
                className="relative w-full h-screen  flex  items-center justify-center animate-pulse">
                <a target={'_blank'}
                   href="https://app.powerbi.com/groups/me/apps/a5dbe2b0-dfc1-4ce8-b01c-167e6432c1fd?ctid=6c52845c-0b26-4ce8-b0aa-c65bd25bee29">
                    <img src="https://1000logos.net/wp-content/uploads/2022/08/Microsoft-Power-BI-Logo.png" alt=""
                         width={300} height={300}/>

                </a>
            </div>
        </Layout>);
};

export default BI;
