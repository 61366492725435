import React, {useState} from 'react';
import Layout from "../../../../hocs/Layout";
import {Helmet} from "react-helmet";

const Simulator = () => {
    const [params, setParams] = useState({lot: '', month: '', year: ''})
    return (<Layout>
        <Helmet>
            <title>Simulador</title>
        </Helmet>
        <div
            className={"bg-white rounded-2xl p-4 h-fit z-10"}>

            <div className="w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
            </div>
        </div>
    </Layout>);
};

export default Simulator;
