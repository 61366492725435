import axios from "axios";
import {GET_DEPARTMENTS_FAIL, GET_DEPARTMENTS_SUCCESS, GET_POSITIONS_FAIL, GET_POSITIONS_SUCCESS} from "./types";

export const getDepartments = () => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/departments/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_DEPARTMENTS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_DEPARTMENTS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_DEPARTMENTS_FAIL
        });
    }
}

export const getPositions = () => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/positions/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_POSITIONS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_POSITIONS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_POSITIONS_FAIL
        });
    }
}