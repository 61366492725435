import React from 'react';
import {InputSearch, SelectSearch} from "../../util/Filters";
import {useSelector} from "react-redux";
import {PaperAirplaneIcon} from "@heroicons/react/24/outline";

const Filters = ({params, setParams, handleSearch}) => {
    const raw_material_suppliers = useSelector(state => state.Stakeholders.raw_material_suppliers);
    const categories = useSelector(state => state.Category.categories);

    return (<div className="flex flex-col md:flex-row items-center gap-2 justify-end py-4 bg-white ">
            <InputSearch handleSearch={handleSearch} name={"Parcela"} value={params.name} color={'bg-gray-100'}
                         handle={(value) => setParams({...params, 'name': value.target.value})}/>
            <SelectSearch handleSearch={handleSearch} value={params.provider} color={'bg-gray-100'}
                          items={[{value: null, name: null}, ...raw_material_suppliers ? raw_material_suppliers : []]}
                          name={"Propietario"}
                          field={'name'}
                          handle={(value) => setParams({...params, 'provider': value.target.value})}/>
            <SelectSearch handleSearch={handleSearch} value={params.product} color={'bg-gray-100'}
                          name={"Cultivo"}
                          field={'name'}
                          items={categories ? [{
                              id: null,
                              name: null
                          }, ...categories.find(item => item.name === "Materia Prima")?.children] : []}
                          handle={(value) => setParams({...params, 'product': value.target.value})}/>
            <SelectSearch handleSearch={handleSearch} value={params.sts} color={'bg-gray-100'}
                          name={"Estado"}
                          field={'name'}
                          items={[{id: null, name: null}, {id: 'P', name: 'Presencia'}, {value: 'C', name: 'Limpio'}]}
                          handle={(value) => setParams({...params, 'sts': value.target.value})}/>
            <button onClick={handleSearch}
                    className="bg-gray-100 flex gap-2 items-center  hover:bg-gray-300 w-full md:w-max text-gray-500 hover:text-white self-end mb-1 font-medium py-2 px-4 rounded-md justify-center">
                Buscar <PaperAirplaneIcon className="h-5 w-5"/>
            </button>

        </div>

    );
};

export default Filters;
