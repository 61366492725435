import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {useSelector} from "react-redux";

const TableOutsourcing = () => {
    const data = useSelector(state => state.TalentHub.outsourcing);
    const columns = ['N°', 'Cargo', 'DNI', 'Apellidos y Nombres', 'Días Laborados', 'Horas Extras 25%', 'Horas Extras 35%', 'Bonificación Nocturna por Horas', 'Compensación Feriado', 'Horas Feriado', 'Feriado', 'Total', 'Vacaciones', 'Total', 'Descanso Médico', 'Total', 'Licencia sin gose', 'Total', 'Inasistencias', 'Total', 'Fecha de cese']
    return (<TableBase columns={columns} cell={Row(data)}/>);
};


const Row = (data) => (data && data.map((item, index) => {


    return (<tr key={index} className=" border-gray-300  border-b">
        <Cell classes={"py-2"} text={<p className={"font-medium"}>{index + 1}</p>}/>
        <Cell classes={"py-2"} text={<p className={"font-normal"}>{item?.position}</p>}/>
        <Cell classes={"py-2"} text={<p className={"font-normal"}>{item?.dni}</p>}/>
        <Cell classes={"py-2"} text={<p className={"font-medium"}>{item?.user}</p>}/>
        <Cell classes={"py-2"} text={<p className={"font-normal text-center"}>{item?.total_days_worked}</p>}/>
        <Cell classes={"py-2"} text={<p className={"font-normal text-center"}>{item?.overtime_25}</p>}/>
        <Cell classes={"py-2"} text={<p className={"font-normal text-center"}>{item?.overtime_35}</p>}/>
        <Cell classes={"py-2"} text={<p className={"font-normal text-center"}>{item?.total_worked_night}</p>}/>
        <Cell classes={"py-2"} text={<p className={"font-normal text-center"}>{item?.compensación_feriados}</p>}/>
        <Cell classes={"py-2"} text={<p className={"font-normal text-center"}>{item?.horas_feriado}</p>}/>
        <Cell classes={"py-2"} text={<p className={"font-normal text-center"}>{item?.dias_feriado.join(", ")}</p>}/>
        <Cell classes={"py-2"} text={<p className={"font-normal text-center"}>{item?.feriado}</p>}/>
        <Cell classes={"py-2"} text={<p className={"font-normal text-center"}>{item?.dias_vacaciones.join(", ")}</p>}/>
        <Cell classes={"py-2"} text={<p className={"font-bold text-center"}>{item?.vacaciones}</p>}/>
        <Cell classes={"py-2"}
              text={<p className={"font-normal text-center"}> {item?.dias_descanso_medico.join(", ")}</p>}/>
        <Cell classes={"py-2"} text={<p className={"font-bold text-center"}> {item?.descanso_medico}</p>}/>
        <Cell classes={"py-2"}
              text={<p className={"font-normal text-center"}> {item?.dias_licencia_sin_gose_de_haber.join(", ")}</p>}/>
        <Cell classes={"py-2"} text={<p className={"font-bold text-center"}> {item?.licencia_sin_gose_de_haber}</p>}/>
        <Cell classes={"py-2"}
              text={<p className={"font-normal text-center"}> {item?.dias_inasistencia.join(", ")}</p>}/>
        <Cell classes={"py-2"} text={<p className={"font-bold text-center"}> {item?.inasistencia}</p>}/>
        <Cell classes={"py-2"} text={<p
            className={"font-normal text-center"}> {item?.out ? new Date(item?.out + "T00:00:00").toLocaleDateString('es-PE', {
            year: 'numeric', month: 'short', day: 'numeric', timeZone: 'America/Lima'
        }) : ''}</p>}/>

    </tr>)
}));
export default TableOutsourcing;
