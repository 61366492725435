import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import Layout from "../../../hocs/Layout";
import ChangePage from "../../../components/mm/Assets/ChangePage";
import TableFixed from "../../../components/mm/Assets/TableFixed";
import {useDispatch, useSelector} from "react-redux";
import ModalHook from "../../../hooks/ModalHook";
import {MySwal} from "../../../components/util/helpers";
import {deleteAssetFixed, getAssetsFixed} from "../../../redux/actions/maintenance";
import Modal from "../../../components/util/Modal";
import ButtonHelper from "../../../components/util/ButtonHelper";
import {InputSearch} from "../../../components/util/Filters";
import FormFixed from "../../../components/mm/Assets/FormFixed";


const Facilities = () => {
    const [params, setParams] = useState({name: ''})
    const dispatch = useDispatch();
    const {content, setContent, isOpen, openModal} = ModalHook();
    const data = useSelector(state => state.Maintenance.fixed);

    useEffect(() => {
        dispatch(getAssetsFixed(params));
    }, [dispatch]);

    const handleSearch = () => {
        dispatch(getAssetsFixed(params));
    }

    const handleAdd = () => {
        const formComponent = <FormFixed onClose={openModal} params={params}/>;
        setContent(formComponent);
        openModal();
    };

    const handleUpdate = (data) => {
        const formComponent = <FormFixed onClose={openModal} data={data} params={params}
        />;
        setContent(formComponent);
        openModal();
    };
    const handleDelete = (data) => {
        MySwal.fire({
            title: '¿Desea eliminar este registro?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7DABF5',
            confirmButtonColor: '#F87171',
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteAssetFixed(data, params))
            }
        });
    };


    return (<Layout>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <Helmet>
            <title>Instalaciones</title>
        </Helmet>
        <ChangePage/>
        <div
            className={"bg-white rounded-2xl p-4 h-fit"}>
            <ButtonHelper handle={handleAdd}/>
            <div className={"w-full flex justify-end"}>
                <InputSearch params={params} setParams={setParams} color={"bg-gray-100"} handleSearch={handleSearch}
                             handle={(e) => setParams({...params, name: e.target.value})} value={params.name}
                             name={"Buscar"}/>
            </div>
            <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                <TableFixed data={data} handleUpdate={handleUpdate} handleDelete={handleDelete}/>
            </div>
        </div>


    </Layout>);
};
export default Facilities;