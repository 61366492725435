import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {InputForm} from "../../util/HelpersForm";
import {addExternalPerson, updateExternalPerson} from "../../../redux/actions/logistic";


const FormExternal = ({data, onClose, params}) => {
    const dispatch = useDispatch();

    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            data ? dispatch(updateExternalPerson(data?.id, form, params)) : dispatch(addExternalPerson(form, params));
            onClose();
        }
    })

    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={() => onClose()}/>
            <div className={`grid  grid-cols-1 gap-2`}>
                <InputForm values={formik.values.full_name} errors={formik.errors.full_name} formik={formik}
                           field={'full_name'}
                           title={'Nombre'} max_length={100}/>
                <InputForm values={formik.values.dni} errors={formik.errors.dni} formik={formik} field={'dni'}
                           title={'DNI'} max_length={8}/>
                <InputForm values={formik.values.phone} errors={formik.errors.phone} formik={formik} field={'phone'}
                           title={'Celular'} max_length={9}/>
                <InputForm values={formik.values.licence} errors={formik.errors.licence} formik={formik}
                           field={'licence'}
                           title={'Licencia'} max_length={25}/>
            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {
        full_name: data?.full_name || '', dni: data?.dni || '', phone: data?.phone || '', licence: data?.licence || '',

    }
}
const newSchema = () => {
    return {
        full_name: Yup.string(), dni: Yup.string().min(8).required(), phone: Yup.string().min(9), licence: Yup.string(),

    }
}

export default FormExternal;
