import React, {useEffect, useState} from 'react';
import Layout from "../../../../hocs/Layout";
import Humanize from "humanize-plus";
import TableStock from "../../../../components/e2e/Logistic/TableStock";
import {generateRandomColor, MySwal} from "../../../../components/util/helpers";
import ButtonHelper from "../../../../components/util/ButtonHelper";
import {useDispatch, useSelector} from "react-redux";
import {deleteBatchOutput, getBatchLot, getStockAvailable} from "../../../../redux/actions/logistic";
import {GET_BATCH_OUTPUT_FAIL, GET_STOCK_AVAILABLE_FAIL} from "../../../../redux/actions/types";
import ModalHook from "../../../../hooks/ModalHook";
import Modal from "../../../../components/util/Modal";
import FormOutput from "../../../../components/e2e/Logistic/FormOutput";
import FilterOutput from "../../../../components/e2e/Logistic/FilterOutput";
import {Helmet} from "react-helmet";

const RandomColoredText = ({text}) => {
    const randomColor = generateRandomColor();
    const style = {
        backgroundColor: randomColor + '1A',
        color: randomColor,
        padding: '0.2rem 0.5rem',
        borderRadius: '0.5rem',
        textColor: 'black'
    };
    return <div style={style}
                className={"w-12 h-12  flex items-center justify-center rounded-full "}>
        <p>{text}</p></div>;
};
const Stock = () => {
    const [params, setParams] = useState({lot: '', date: '', month: '', year: ''})
    const stock = useSelector(state => state.Logistic.stock);
    const {content, setContent, isOpen, openModal} = ModalHook();
    const data = useSelector(state => state.Logistic.batch_output);
    const dispatch = useDispatch();

    const getSum = (type, items) => {
        return items.reduce((acc, curr) => {
            return acc + parseFloat(curr[type]);
        }, 0);
    };


    const handleAdd = () => {
        const formComponent = <FormOutput onClose={openModal}/>;
        setContent(formComponent);
        openModal();
    };

    const handleUpdate = (values) => {
        const formComponent = <FormOutput onClose={openModal}
                                          data={values}/>;
        setContent(formComponent);
        openModal();
    };
    const handleDelete = (value) => {
        MySwal.fire({
            title: '¿Desea eliminar este registro?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7DABF5',
            confirmButtonColor: '#F87171',
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteBatchOutput(value))
            }
        });
    };

    const handleSearch = () => {
        dispatch(getBatchLot(params))

    }


    useEffect(() => {
        dispatch({type: GET_BATCH_OUTPUT_FAIL})
        dispatch({type: GET_STOCK_AVAILABLE_FAIL})
        setParams({
            ...params, month: new Date().getMonth() + 1, year: new Date().getFullYear(),
        })
        setTimeout(() => {
            dispatch(getBatchLot(params))
            dispatch(getStockAvailable())
        }, 1000)
    }, []);

    return (<Layout>
        <Helmet>
            <title>Salidas MP</title>
        </Helmet>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <ButtonHelper handle={handleAdd}/>
        <div className={"grid grid-cols-1 lg:grid-cols-3 gap-4  w-full"}>

            <div className={"col-span-2 grid grid-cols-1 md:grid-cols-2"}>
                <div
                    className={"bg-white bg-opacity-90 h-16 md:h-20 justify-center  gap-6 items-center py-4 px-6 lg:px-8 shadow-md overflow-hidden"}>
                    <h1 className={"text-md font-medium text-gray-400"}>Salida</h1>
                    <p className={"text-sm md:text-lg lg:text-2xl font-bold"}>{Humanize.formatNumber(data && getSum('kg', data), 2)} kg</p>
                </div>
                <div
                    className={"bg-gray-200 bg-opacity-90 h-16  md:h-20 gap-6 items-center justify-center py-4 px-6 lg:px-8  shadow-md overflow-hidden"}>
                    <h1 className={"text-md font-medium text-white"}>Stock</h1>
                    <p className={"text-sm md:text-lg lg:text-2xl font-bold"}>{Humanize.formatNumber(stock && getSum('stock', stock), 2)} kg</p>
                </div>

                <div className={"w-full col-span-2 mt-4"}>
                    <FilterOutput setParams={setParams} handleSearch={handleSearch} params={params}/>
                </div>


                <div
                    className="h-96 overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col mb-2 col-span-2 mt-4">
                    <TableStock data={data} handleUpdate={handleUpdate} handleDelete={handleDelete}/>
                </div>
            </div>
            <div className={"md:col-span-1 h-96 overflow-scroll scrollbar-hide col-span-2 bg-white rounded-2xl p-6"}>
                <h1 className={"text-xl font-medium"}>Lotes</h1>
                {stock ? stock.map((item, index) => {
                        return <div key={index} className="flex items-center border-b border-gray-200 py-6">
                            <RandomColoredText text={item?.product[0]}/>
                            <div className="flex items-start justify-between w-full">
                                <div className="pl-3 w-full">
                                    <p className="lg:text-xl text-sm font-medium leading-5 text-gray-800">{item?.lot}</p>
                                    <p className="text-sm leading-normal pt-2 text-gray-500">{Humanize.formatNumber(item?.stock, 2)}</p>
                                </div>
                            </div>
                        </div>
                    }) : /*Skeleton with animate pulse with tailwind*/
                    <div className="animate-pulse flex items-center border-b border-gray-200 py-6">
                        <div className="bg-gray-200 bg-opacity-90 h-12 w-12 rounded-full"/>
                        <div className="pl-3 w-full">
                            <div className="h-4 bg-gray-200 bg-opacity-90 rounded w-full"/>
                            <div className="h-4 bg-gray-200 bg-opacity-90 rounded w-1/2 mt-2"/>
                        </div>
                    </div>}
            </div>
        </div>
    </Layout>);
};

export default Stock;
