import React, {useEffect, useState} from 'react';
import {Document, Image, Page, PDFViewer, StyleSheet, Text, View} from "@react-pdf/renderer";
import Humanize from "humanize-plus";
import Logo from '../../../assets/logo.png'
import {formatDateTime} from "../../util/helpers";
import {useSelector} from "react-redux";

const styles = StyleSheet.create({
    image: {
        width: 70, borderRadius: 10, padding: "2px",

    }, section: {
        display: "flex", justifyContent: "space-start", width: "100%",

        flexDirection: "row",

        borderBottom: "1px"
    }, text_section_one: {
        fontSize: "12px",
        fontWeight: "extrabold",
        fontFamily: "Times-Roman",
        width: "50%",
        display: "inline-block",
        paddingHorizontal: "12px",
        paddingVertical: "1px",
    }, view_section_one: {
        display: "flex", width: "100%", alignItems: "flex-start", justifyContent: "flex-start", flexDirection: "row"
    }
});
const ReportProcessSweetPotato = ({data, category, photos}) => {
    const c_sweetpotato = useSelector(state => state.Production.c_sweet_potato_lot);
    const quality = useSelector(state => state.Quality.analysis_lot);
    const raw_material_suppliers = useSelector(state => state.Stakeholders.raw_material_suppliers);
    const [process, setProcess] = useState([]);


    const weight_final = data?.weight_net - data?.sample_weight || 0;

    function processData(data) {
        let lastDate = data.reduce((max, item) => item.date > max ? item?.date : max, data[0]?.date);
        lastDate = new Date(lastDate + 'T00:00:00-05:00').toLocaleDateString('es-PE', {
            year: 'numeric', month: 'long', day: 'numeric'
        });

        const sums = data.reduce((acc, item) => {
            acc.waste_kg += parseFloat(item.waste_kg);
            acc.logistic_kg += parseFloat(item.logistic_kg);
            acc.rejected_kg += parseFloat(item.rejected_kg);
            acc.process_kg += parseFloat(item.process_kg);
            acc.enabled_kg += parseFloat(item.enabled_kg);
            acc.brix += parseFloat(item.brix);
            acc.ph += parseFloat(item.ph);
            return acc;
        }, {
            waste_kg: 0,
            logistic_kg: 0,
            rejected_kg: 0,
            process_kg: 0,
            enabled_kg: 0,
            brix: 0,
            ph: 0,
        });

        const averages = {
            brix: sums.brix / data.length, ph: sums.ph / data.length,
        };

        return {lastDate, ...sums, ...averages};
    }

    useEffect(() => {
        if (c_sweetpotato) {
            setProcess(processData(c_sweetpotato));
        }
    }, [c_sweetpotato]);


    return (<PDFViewer style={{width: "100%", height: "100%"}}>
        <Document>
            <Page size="A4" style={{padding: "12px", width: "100%"}}>
                <View style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "6px",
                    gap: "24px",
                    borderBottomWidth: "1px"
                }}>
                    <Image style={styles.image} src={Logo}/>
                    <Text style={{
                        fontSize: "18px", fontWeight: "extrabold", fontFamily: "Times-Roman",
                    }}>INFORME DE PROCESO DE PRODUCCIÓN</Text>
                </View>

                <View style={[styles.section, {flexDirection: "column"}]}>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "20%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px"
                        }}>A:</Text>
                        <View style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "80%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px"
                        }}>
                            <Text style={{fontSize: "12px", fontWeight: "extrabold", fontFamily: "Times-Roman",}}>
                                {raw_material_suppliers && raw_material_suppliers.find(e => e.id === data?.supplier)?.name}
                            </Text>
                            <Text style={{fontSize: "12px", fontWeight: "extrabold", fontFamily: "Times-Roman",}}>
                                Proveedor de materia prima
                            </Text>
                        </View>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "20%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px"
                        }}>DE:</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "80%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "6px"
                        }}>
                            DEPARTAMENTO DE PRODUCCION
                        </Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "20%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px"
                        }}>ASUNTO:</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "80%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px"
                        }}>
                            Informe de recepción de materia prima
                            - {category} ({data?.condition === 'C' ? 'Convencional' : 'Orgánico'})
                        </Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "20%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px"
                        }}>FECHA:</Text>
                        <Text style={{
                            fontSize: "12px",
                            fontWeight: "extrabold",
                            fontFamily: "Times-Roman",
                            width: "80%",
                            display: "inline-block",
                            paddingHorizontal: "12px",
                            paddingVertical: "2px"
                        }}>
                            {process?.lastDate}
                        </Text>
                    </View>

                    <Text style={{
                        fontSize: "10px",
                        fontWeight: "normal",
                        fontFamily: "Times-Roman",
                        width: "100%",
                        paddingHorizontal: "12px",

                        display: "block",
                        borderTopWidth: "1px",
                        paddingVertical: "2px",

                    }}>
                        Es un placer saludarles en nombre del Departamento de Producción de Greenbox S.A.C.
                        Nos dirigimos a ustedes para proporcionarles información detallada sobre el proceso en
                        curso. {'\n'}
                    </Text>

                </View>
                <View style={[styles.section, {backgroundColor: "#22c55e", border: 'none', padding: "2px"}]}>
                    <Text style={{
                        fontSize: "10px",
                        fontWeight: "bold",
                        textAlign: "start",
                        color: "white",
                        fontFamily: "Times-Roman",
                        paddingVertical: "2px",
                        textTransform: "uppercase"
                    }}>1. Datos Generales:</Text>
                </View>

                <View style={[styles.section, {
                    paddingVertical: "2px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderBottomWidth: "0px",
                }]}>

                    <View style={styles.view_section_one}>
                        <Text style={styles.text_section_one}>Lote: {data?.lot}</Text>
                    </View>
                    <View style={styles.view_section_one}>
                        <Text style={styles.text_section_one}>Fecha de
                            recepción: {data?.datetime_download_started ? formatDateTime(data.datetime_download_started, 'long') : ' '}</Text>

                    </View>
                    <View style={styles.view_section_one}>
                        <Text style={styles.text_section_one}>Fecha de proceso: {process.lastDate}</Text>
                    </View>
                    <View style={styles.view_section_one}>
                        <Text style={styles.text_section_one}>Kg
                            recepcionados: {Humanize.formatNumber(data?.weight_net, 2)}</Text>
                        <Text
                            style={styles.text_section_one}>Precio {data?.supplier_price ? Humanize.formatNumber(data.supplier_price, 2) : "0.00"}</Text>
                    </View>
                    <View style={styles.view_section_one}>
                        <Text style={styles.text_section_one}>Muestra de
                            calidad: {Humanize.formatNumber(data?.sample_weight, 2)}</Text>
                        <Text style={styles.text_section_one}>° Brix: {process.brix}</Text>

                    </View>
                    <View style={styles.view_section_one}>
                        <Text style={styles.text_section_one}>Kg
                            ingresados: {Humanize.formatNumber(data?.weight_net - data?.sample_weight, 2)}</Text>
                        <Text style={styles.text_section_one}>pH: {Humanize.formatNumber(process.ph, 2)}</Text>

                    </View>
                </View>
                <View style={[styles.section, {backgroundColor: "#22c55e", border: 'none', padding: "2px"}]}>
                    <Text style={{
                        fontSize: "10px",
                        fontWeight: "bold",
                        textAlign: "start",
                        color: "white",
                        fontFamily: "Times-Roman",
                        paddingVertical: "2px",
                        textTransform: "uppercase"
                    }}>2. Reporte de maduración:</Text>
                </View>


                <View style={[styles.section, {
                    paddingVertical: "2px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderBottomWidth: "0px",
                    marginTop: "10px"
                }]}>

                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        borderBottomWidth: "1px"

                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center", borderRightWidth: "1px",
                        }]}>Maduración</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center", borderRightWidth: "1px",
                        }]}>Kg</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center", borderRightWidth: "1px",
                        }]}>% Maduración</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center", borderRightWidth: "1px",
                        }]}>Limite</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center",
                        }]}>Excedente</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",


                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center",
                        }]}>Mad "1":</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center",
                        }]}>{Humanize.formatNumber(data?.weight_net*quality?.m1 , 2)}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center",
                        }]}>{Humanize.formatNumber(quality?.m1, 2)}%</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center",
                        }]}>{Humanize.formatNumber(10, 2)}%</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center",
                        }]}>{Humanize.formatNumber(10<quality?.m1?quality?.m1-10:0, 2)}%</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",


                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center",
                        }]}>Mad "2":</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center",
                        }]}>{Humanize.formatNumber(data?.weight_net*quality?.m2 , 2, 2)}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center",
                        }]}>{Humanize.formatNumber(quality?.m2, 2)}%</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center",
                        }]}>{Humanize.formatNumber(90, 2)}%</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center",
                        }]}>{Humanize.formatNumber(90<quality?.m2?quality?.m2-90:0, 2, 2)}%</Text>
                    </View>
                </View>
                <View style={[styles.section, {backgroundColor: "#22c55e", border: 'none', padding: "2px"}]}>
                    <Text style={{
                        fontSize: "10px",
                        fontWeight: "bold",
                        textAlign: "start",
                        color: "white",
                        fontFamily: "Times-Roman",
                        paddingVertical: "2px",
                        textTransform: "uppercase"
                    }}>3. Reporte de proceso:</Text>
                </View>
                <View style={[styles.section, {
                    paddingVertical: "2px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderBottomWidth: "0px",
                    marginTop: "10px"
                }]}>

                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        borderBottomWidth: "1px"

                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>Dimensión</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>Parámetro</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>Kg</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>%</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>Limite</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>Excedente</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",

                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}></Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{"< 8 cm:"}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(data?.weight_net*quality?.length_lt_8/100,2)}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(quality?.length_lt_8,2)}%</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>0.0%</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(0<quality?.length_lt_8?quality?.length_lt_8-0:0,2)}%</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",

                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>Largo</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{"8-15 cm:"}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(data?.weight_net*(parseFloat(quality?.length_8_11)+parseFloat(quality?.length_11_15))/100,2)}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(parseFloat(quality?.length_8_11)+parseFloat(quality?.length_11_15),2)}%</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>100.0%</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(100<(parseFloat(quality?.length_8_11)+parseFloat(quality?.length_11_15))?(parseFloat(quality?.length_8_11)+parseFloat(quality?.length_11_15))-100:0,2)}%</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        borderBottomWidth: "1px"

                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}></Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{"> 15 cm:"}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(data?.weight_net*quality?.length_gt_15/100,2)}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(quality?.length_gt_15,2)}%</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>0.0%</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(0<quality?.length_gt_15?quality?.length_gt_15-0:0,2)}%</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",

                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}></Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{"< 7 cm:"}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(data?.weight_net*quality?.width_lt_7/100,2)}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(quality?.width_lt_7,2)}%</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>0.0%</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(0<quality?.width_lt_7?quality?.width_lt_7-0:0,2)}%</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",

                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>Ancho</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{"7-12 cm:"}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(data?.weight_net*(parseFloat(quality?.width_7_9)+parseFloat(quality?.width_9_12))/100,2)}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(parseFloat(quality?.width_7_9)+parseFloat(quality?.width_9_12),2)}%</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>100.0%</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(100<(parseFloat(quality?.width_7_9)+parseFloat(quality?.width_9_12))?(parseFloat(quality?.width_7_9)+parseFloat(quality?.width_9_12))-100:0,2)}%</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}></Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{"> 12 cm:"}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(data?.weight_net*quality?.width_gt_12/100,2)}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(quality?.width_gt_12,2)}%</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>0.0%</Text>
                        <Text style={[styles.text_section_one, {
                            width: "15%", textAlign: "center",
                        }]}>{Humanize.formatNumber(0<quality?.width_gt_12?quality?.width_gt_12-0:0,2)}%</Text>
                    </View>
                </View>

                <View style={[styles.section, {backgroundColor: "#22c55e", border: 'none', padding: "2px"}]}>
                    <Text style={{
                        fontSize: "10px",
                        fontWeight: "bold",
                        textAlign: "start",
                        color: "white",
                        fontFamily: "Times-Roman",
                        paddingVertical: "2px",
                        textTransform: "uppercase"
                    }}>4. Reporte de proceso:</Text>
                </View>

                <View style={[styles.section, {
                    paddingVertical: "2px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderBottomWidth: "0px",
                    marginTop: "10px"
                }]}>

                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",

                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center", borderRightWidth: "1px",
                        }]}>Kg ingresados</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center", borderRightWidth: "1px",
                        }]}>{Humanize.formatNumber(weight_final, 2)}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center",
                        }]}>{Humanize.formatNumber(100, 2)}%</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center", borderRightWidth: "1px",
                        }]}>Kg descarte</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center", borderRightWidth: "1px",
                        }]}>{Humanize.formatNumber(process?.rejected_kg, 2)}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center",
                        }]}>{Humanize.formatNumber(process?.rejected_kg / weight_final * 100, 2)}%</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",

                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center", borderRightWidth: "1px",
                        }]}>Kg procesados</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center", borderRightWidth: "1px",
                        }]}>{Humanize.formatNumber(process?.process_kg, 2)}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center",
                        }]}>{Humanize.formatNumber(100, 2)}%</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",

                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center", borderRightWidth: "1px",
                        }]}>Residuo orgánico</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center", borderRightWidth: "1px",
                        }]}>{Humanize.formatNumber(process?.waste_kg, 2)}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center",
                        }]}>{Humanize.formatNumber(process?.waste_kg / weight_final * 100, 2)}%</Text>
                    </View>


                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",

                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center", borderRightWidth: "1px",
                        }]}>Kg habilitados</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center", borderRightWidth: "1px",
                        }]}>{Humanize.formatNumber(process?.enabled_kg, 2)}</Text>
                        <Text style={[styles.text_section_one, {
                            width: "20%", textAlign: "center",
                        }]}>{Humanize.formatNumber(process?.enabled_kg / weight_final * 100, 2)}%</Text>
                    </View>
                </View>
                <View style={[styles.section, {backgroundColor: "#22c55e", border: 'none', padding: "2px"}]}>
                    <Text style={{
                        fontSize: "10px",
                        fontWeight: "bold",
                        textAlign: "start",
                        color: "white",
                        fontFamily: "Times-Roman",
                        paddingVertical: "2px",
                        textTransform: "uppercase"
                    }}>4. Rechazos y descuentos:</Text>
                </View>
                <View style={[styles.section, {
                    paddingVertical: "2px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderBottomWidth: "0px",
                    marginTop: "10px"
                }]}>

                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",


                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "40%", textAlign: "center", borderBottomWidth: "1px"
                        }]}>Rechazo</Text>
                        <Text style={[styles.text_section_one, {
                            width: "40%", textAlign: "center", borderBottomWidth: "1px"
                        }]}>Descuentos</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "80%", textAlign: "justify",
                        }]}>{data?.discount_description}</Text>

                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "40%", textAlign: "justify", color: "red"
                        }]}>Kg totales: {Humanize.formatNumber(data?.weight_reject, 2)}kg
                            ({Humanize.formatNumber(data?.discount_weight_percentage, 2)}%)</Text>
                        <Text style={[styles.text_section_one, {
                            width: "40%", textAlign: "center", color: "red"
                        }]}>Kg totales {Humanize.formatNumber(data?.discount_price_kg,2)}kg ({Humanize.formatNumber(data?.discount_price_kg/weight_final*100, 2)}%)</Text>
                    </View>
                </View>
                <View style={[styles.section, {backgroundColor: "#22c55e", border: 'none', padding: "2px"}]}>
                    <Text style={{
                        fontSize: "10px",
                        fontWeight: "bold",
                        textAlign: "start",
                        color: "white",
                        fontFamily: "Times-Roman",
                        paddingVertical: "2px",
                        textTransform: "uppercase"
                    }}>5. Evidencias fotográficas:</Text>
                </View>
                <View style={[styles.section, {
                    paddingVertical: "2px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    borderBottomWidth: "0px",
                    marginTop: "10px"
                }]}>

                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        flexWrap: "wrap",

                    }}>
                        {photos && photos.map((item, index) => (<Image key={index}
                                                                       source={{
                                                                           uri: item.photo,
                                                                           headers: {
                                                                               Pragma: 'no-cache',
                                                                               'Cache-Control': 'no-cache'
                                                                           },
                                                                           method: 'GET',
                                                                           body: undefined,
                                                                       }}
                                                                       style={[styles.image, {width: "40%", marginHorizontal: "12px"}]
                                                                       }

                        />))}


                    </View>
                    <View style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                        flexWrap: "wrap",

                    }}>
                        <Text style={[styles.text_section_one, {
                            width: "40%", textAlign: "center", borderTopWidth: "2px", marginTop: "50px"
                        }]}>Jefe de produccion</Text>


                    </View>


                </View>


            </Page>


        </Document>
    </PDFViewer>);
};

export default ReportProcessSweetPotato;