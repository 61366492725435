import React from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {login} from "../../redux/actions/auth";
import {TailSpin} from "react-loader-spinner";
import {Link, Navigate} from "react-router-dom";
import {Footer} from "../../components/navigation/Footer";
import SignLayout from "../../hocs/SignLayout";

export default function Login() {
    const isAuthenticated = useSelector(state => state.Auth.isAuthenticated);
    const loading = useSelector(state => state.Auth.loading);
    const dispatch = useDispatch();
    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form) => {
            dispatch(login(form))
        }
    })

    if (isAuthenticated) return <Navigate to='/' replace/>;

    return (<>
        <Helmet>
            <title>Greenbox</title>
            <meta name="description" content="Site created by Anthonny Gómez"/>
        </Helmet>
        <SignLayout>
            <form className="bg-white  px-20 flex flex-col justify-center items-center">

                <p className={"text-gray-400 text-xs border-b-2 mb-4 pb-2"}>Conexión Integral Industrial</p>

                <div className="flex items-center border-2 py-2 px-3 rounded-2xl mb-4 w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400" fill="none"
                         viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"/>
                    </svg>
                    <input className="pl-2 outline-none border-none" type="email" name="email"
                           value={formik.values.email}
                           onChange={e => formik.setFieldValue('email', e.target.value)}
                           placeholder="Correo electrónico"/>
                </div>
                <div className="flex items-center border-2 py-2 px-3 rounded-2xl w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-400"
                         viewBox="0 0 20 20"
                         fill="currentColor">
                        <path fillRule="evenodd"
                              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                              clipRule="evenodd"/>
                    </svg>
                    <input className="pl-2 outline-none border-none" type="password" name="password"
                           value={formik.values.password}
                           onChange={e => formik.setFieldValue('password', e.target.value)}
                           placeholder="Password"/>
                </div>
                {loading ? <button type={"button"}
                                   className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    <TailSpin ariaLabel="loading-indicator" height={20}/>
                </button> : <button onClick={formik.handleSubmit} type="button"
                                    className="block w-full bg-[#00ce6f] mt-4 py-2 rounded-2xl text-white font-semibold mb-2">Iniciar
                    sesión
                </button>}
                <Link to={'/reset_password'} className="text-center text-xs hover:text-green-500 cursor-pointer">¿Olvidaste
                    tu contraseña?</Link>
            </form>
        </SignLayout>
        <Footer/>
    </>);
}
const initialValues = () => {
    return {
        email: "", password: "",

    }
}
const newSchema = () => {
    return {
        email: Yup.string().email().required(true), password: Yup.string().required(true),
    }
}