import React, {useEffect, useState} from 'react';
import Layout from "../../../hocs/Layout";
import TableHours from "../../../components/sct/Hours/TableHours";
import Filters from "../../../components/sct/Hours/Filters";
import {useDispatch, useSelector} from "react-redux";
import {getDepartments} from "../../../redux/actions/users";
import {
    deleteTracking,
    getAbsenteeism,
    getStaff,
    getTracking,
    getUsersNotTracking
} from "../../../redux/actions/talent_hub";
import ButtonHelper from "../../../components/util/ButtonHelper";
import {MySwal} from "../../../components/util/helpers";
import Modal from "../../../components/util/Modal";
import ModalHook from "../../../hooks/ModalHook";
import Form from "../../../components/sct/Hours/FormHours";
import ListUsers from "../../../components/sct/Hours/ListUsers";


const Hours = () => {
    const [params, setParams] = useState({user: '', date: '', department: ''});
    const staff_not_tracking = useSelector(state => state.TalentHub.staff_not_tracking);
    const {content, setContent, isOpen, openModal} = ModalHook();
    const dispatch = useDispatch();

    const handleSearch = () => {
        dispatch(getTracking(params))
    }
    useEffect(() => {
        dispatch(getDepartments())

        dispatch(getStaff({status: 'true'}))
        dispatch(getAbsenteeism())
        dispatch(getTracking(params))
    }, [dispatch]);

    useEffect(() => {
        dispatch(getUsersNotTracking({date: params.date}))
    }, [params.date]);

    const handleAdd = () => {
        const formComponent = <Form onClose={openModal} params={params}/>;
        setContent(formComponent);
        openModal();
    };

    const handleUpdate = (data) => {
        const formComponent = <Form onClose={openModal} data={data} params={params}/>;
        setContent(formComponent);
        openModal();
    };
    const handleDelete = (data) => {
        MySwal.fire({
            title: '¿Desea eliminar este registro?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: '#7DABF5',
            confirmButtonColor: '#F87171',
            confirmButtonText: 'Eliminar',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteTracking(data, params))
            }
        });
    };

    return (<Layout>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <div className={"bg-white rounded-2xl p-4 h-fit"}>
            <ButtonHelper handle={handleAdd}/>
            <ListUsers users={staff_not_tracking}/>
            <Filters params={params} setParams={setParams} handleSearch={handleSearch}/>
            <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                <TableHours handleUpdate={handleUpdate} handleDelete={handleDelete}/>
            </div>

        </div>

    </Layout>);
};

export default Hours;