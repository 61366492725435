import React from 'react';
import {DocumentTextIcon} from "@heroicons/react/24/solid";

const Plugins = ({handleReport, handleRegister, handleLabel,handleProcess}) => {
    return (<div className={"w-full flex flex-col md:flex-row "}>
        <div onClick={() => handleReport()}
             className="bg-gray-100 cursor-pointer  md:w-max flex justify-between gap-2 items-center hover:bg-gray-400 text-gray-800 hover:text-white font-bold p-2 rounded-r md:border-r-2 border-b-2 border-white">Informe
            <DocumentTextIcon className={'w-6 h-6'}/>
        </div>
        <div onClick={() => handleLabel()}
             className="bg-gray-100 cursor-pointer md:w-max flex justify-between gap-2 items-center hover:bg-gray-400 text-gray-800 hover:text-white font-bold p-2 rounded-r md:border-r-2 border-b-2 border-white">Rotulos
            <DocumentTextIcon className={'w-6 h-6'}/>
        </div>

        <div onClick={() => handleRegister()}
             className="bg-gray-100 cursor-pointer md:w-max flex justify-between gap-2 items-center hover:bg-gray-400 text-gray-800 hover:text-white font-bold p-2 rounded-r md:border-r-2 border-b-2 border-white">Registro
            <DocumentTextIcon className={'w-6 h-6'}/>
        </div>
        <div onClick={() => handleProcess()}
             className="bg-gray-100 cursor-pointer md:w-max flex justify-between gap-2 items-center hover:bg-gray-400 text-gray-800 hover:text-white font-bold p-2 rounded-r md:border-r-2 border-b-2 border-white">Proceso
            <DocumentTextIcon className={'w-6 h-6'}/>
        </div>


    </div>);
};

export default Plugins;
