import React, {useEffect, useState} from 'react';
import Layout from "../../../../hocs/Layout";
import {useDispatch, useSelector} from "react-redux";
import {getFreights} from "../../../../redux/actions/logistic";
import FilterDownloadFreightLot from "../../../../components/e2e/Logistic/FilterDownloadFreightLot";
import ModalHook from "../../../../hooks/ModalHook";
import Modal from "../../../../components/util/Modal";
import TableFreight from "../../../../components/e2e/Logistic/TableFreight";
import FormFreight from "../../../../components/e2e/Logistic/FormFreight";
import {Helmet} from "react-helmet";

const Freight = () => {
    const [params, setParams] = useState({lot: '', month: '', year: ''})
    const dispatch = useDispatch();
    const {content, setContent, isOpen, openModal} = ModalHook();
    const data = useSelector(state => state.Logistic.freight);

    useEffect(() => {
        setParams({...params, month: new Date().getMonth() + 1, year: new Date().getFullYear()})

        dispatch(getFreights({month: new Date().getMonth() + 1, year: new Date().getFullYear()}));
    }, [dispatch]);

    const handleSearch = () => {
        dispatch(getFreights(params));
    }


    const handleUpdate = (data) => {
        const formComponent = <FormFreight onClose={openModal} data={data} params={params}/>;
        setContent(formComponent);
        openModal();
    };

    return (<Layout>
        <Helmet>
            <title>Flete</title>
        </Helmet>
        <Modal isOpen={isOpen} close={openModal} children={content}/>
        <div className={"bg-white rounded-2xl p-4 h-fit"}>
            <div className={"flex justify-end"}>
                <FilterDownloadFreightLot setParams={setParams} handleSearch={handleSearch} params={params}/>
            </div>

            <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                <TableFreight data={data} handleUpdate={handleUpdate}/>
            </div>
        </div>

    </Layout>);
};

export default Freight;
