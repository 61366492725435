import axios from "axios";
import {GET_ANALYSIS_FAIL, GET_ANALYSIS_LOT_FAIL, GET_ANALYSIS_LOT_SUCCESS, GET_ANALYSIS_SUCCESS,} from "./types";

export const getAnalysis = (product, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/quality-control/analysis/${product}/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_ANALYSIS_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_ANALYSIS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_ANALYSIS_FAIL
        });
    }
};

export const getAnalysisLot = (product, lot) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/quality-control/analysis/${product}/${lot}/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_ANALYSIS_LOT_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_ANALYSIS_LOT_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_ANALYSIS_LOT_FAIL
        });
    }
};