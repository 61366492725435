import axios from "axios";
import {
    ADD_STAFF_FAIL,
    ADD_STAFF_SUCCESS,
    ADD_TRACKING_FAIL,
    ADD_TRACKING_SUCCESS,
    DELETE_STAFF_FAIL,
    DELETE_STAFF_SUCCESS,
    DELETE_TRACKING_FAIL,
    DELETE_TRACKING_SUCCESS,
    FIND_USER_FAIL,
    FIND_USER_SUCCESS,
    GET_ABSENTEEISM_FAIL,
    GET_ABSENTEEISM_SUCCESS,
    GET_CALENDAR_DAYS_FAIL,
    GET_CALENDAR_DAYS_SUCCESS, GET_DELAY_HOURS_FAIL, GET_DELAY_HOURS_SUCCESS,
    GET_DEPARTMENTS_STAFF_FAIL,
    GET_DEPARTMENTS_STAFF_SUCCESS,
    GET_OUTSOURCING_FAIL,
    GET_OUTSOURCING_SUCCESS,
    GET_STAFF_FAIL,
    GET_STAFF_NOT_TRACKING_FAIL,
    GET_STAFF_NOT_TRACKING_SUCCESS,
    GET_STAFF_SUCCESS,
    GET_SUMMARY_TRACKING_FAIL,
    GET_SUMMARY_TRACKING_SUCCESS,
    GET_TRACKING_FAIL,
    GET_TRACKING_REAL_FAIL,
    GET_TRACKING_REAL_SUCCESS,
    GET_TRACKING_SUCCESS,
    SEND_TRACKING_FAIL,
    SEND_TRACKING_SUCCESS,
    UPDATE_STAFF_FAIL,
    UPDATE_STAFF_SUCCESS,
    UPDATE_TRACKING_FAIL,
    UPDATE_TRACKING_SUCCESS,
} from "./types";
import {setAlert} from "./alert";


export const getStaff = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/talent-hub/staff/`, config);
        if (res.status === 200) {

            dispatch({
                type: GET_STAFF_SUCCESS, payload: res.data
            });

        } else {
            dispatch({
                type: GET_STAFF_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_STAFF_FAIL
        });
    }
}
export const addStaff = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/talent-hub/staff/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_STAFF_SUCCESS, payload: res.data
            });
            dispatch(getStaff(params))
            dispatch(setAlert(res.data.message || 'Staff creado con exito', 'success'));
        } else {
            dispatch({
                type: ADD_STAFF_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_STAFF_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_STAFF_FAIL
            });
        }
    }
};
export const updateStaff = (pk, form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/talent-hub/staff/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_STAFF_SUCCESS, payload: res.data
            });
            dispatch(getStaff(params))
            dispatch(setAlert(res.data.message || 'Staff actualizado con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_STAFF_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));
        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al actualizar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_STAFF_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_STAFF_FAIL
            });
        }
    }
};
export const deleteStaff = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/talent-hub/staff/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_STAFF_SUCCESS, payload: res.data
            });
            dispatch(getStaff(params))
            dispatch(setAlert(res.data.message || 'Staff eliminado con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_STAFF_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));
        }
    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_STAFF_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_STAFF_FAIL
            });
        }
    }
};

export const getAbsenteeism = () => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/talent-hub/absenteeism/`, config);
        if (res.status === 200) {

            dispatch({
                type: GET_ABSENTEEISM_SUCCESS, payload: res.data
            });

        } else {
            dispatch({
                type: GET_ABSENTEEISM_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_ABSENTEEISM_FAIL
        });
    }
}


export const getTracking = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/talent-hub/tracking/`, config);
        if (res.status === 200) {

            dispatch({
                type: GET_TRACKING_SUCCESS, payload: res.data
            });

        } else {
            dispatch({
                type: GET_TRACKING_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_TRACKING_FAIL
        });
    }
}
export const addTracking = (form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/talent-hub/tracking/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: ADD_TRACKING_SUCCESS, payload: res.data
            });
            dispatch(getTracking(params))
            dispatch(setAlert(res.data.message || 'Marcación creada con exito', 'success'));
        } else {
            dispatch({
                type: ADD_TRACKING_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la creación.', 'error'));

        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al crear.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: ADD_TRACKING_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: ADD_TRACKING_FAIL
            });
        }
    }
};
export const updateTracking = (pk, form, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/talent-hub/tracking/${pk}/`, form, config);
        if (res.status === 200) {
            dispatch({
                type: UPDATE_TRACKING_SUCCESS, payload: res.data
            });
            dispatch(getTracking(params))
            dispatch(setAlert(res.data.message || 'Marcación actualizada con exito', 'success'));
        } else {
            dispatch({
                type: UPDATE_TRACKING_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la actualización.', 'error'));
        }

    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al actualizar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: UPDATE_TRACKING_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: UPDATE_TRACKING_FAIL
            });
        }
    }
};
export const deleteTracking = (pk, params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        },
    };

    try {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}/api/talent-hub/tracking/${pk}/`, config);
        if (res.status === 200) {
            dispatch({
                type: DELETE_TRACKING_SUCCESS, payload: res.data
            });
            dispatch(getTracking(params))
            dispatch(setAlert(res.data.message || 'Marcación eliminada con exito', 'success'));
        } else {
            dispatch({
                type: DELETE_TRACKING_FAIL
            });
            dispatch(setAlert('Respuesta inesperada del servidor durante la eliminación.', 'error'));
        }
    } catch (err) {
        if (err.response) {
            const errorMessage = err.response.data.error || err.response.message || 'Error desconocido al eliminar.';
            dispatch(setAlert(errorMessage, 'error'));
            dispatch({
                type: DELETE_TRACKING_FAIL
            });
        } else {
            dispatch(setAlert('Error al conectar con el servidor.', 'error'));
            dispatch({
                type: DELETE_TRACKING_FAIL
            });
        }
    }
};


export const getTrackingReal = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/talent-hub/tracking-summary/`, config);
        if (res.status === 200) {

            dispatch({
                type: GET_TRACKING_REAL_SUCCESS, payload: res.data
            });

        } else {
            dispatch({
                type: GET_TRACKING_REAL_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_TRACKING_REAL_FAIL
        });
    }
}

export const getCalendar = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/talent-hub/calendar/`, config);
        if (res.status === 200) {

            dispatch({
                type: GET_CALENDAR_DAYS_SUCCESS, payload: res.data
            });

        } else {
            dispatch({
                type: GET_CALENDAR_DAYS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_CALENDAR_DAYS_SUCCESS
        });
    }
}
export const getDelay = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/talent-hub/delay/`, config);
        if (res.status === 200) {

            dispatch({
                type: GET_DELAY_HOURS_SUCCESS, payload: res.data
            });

        } else {
            dispatch({
                type: GET_DELAY_HOURS_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_DELAY_HOURS_SUCCESS
        });
    }
}

export const getSummary = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/talent-hub/summary/`, config);
        if (res.status === 200) {

            dispatch({
                type: GET_SUMMARY_TRACKING_SUCCESS, payload: res.data
            });

        } else {
            dispatch({
                type: GET_SUMMARY_TRACKING_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_SUMMARY_TRACKING_FAIL
        });
    }
}

export const getOutsourcing = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/talent-hub/outsourcing/`, config);
        if (res.status === 200) {

            dispatch({
                type: GET_OUTSOURCING_SUCCESS, payload: res.data
            });

        } else {
            dispatch({
                type: GET_OUTSOURCING_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_OUTSOURCING_FAIL
        });
    }
}

export const getUsersNotTracking = (form) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/talent-hub/staff-not-tracking/`, form, config);
        if (res.status === 200) {

            dispatch({
                type: GET_STAFF_NOT_TRACKING_SUCCESS, payload: res.data
            });

        } else {
            dispatch({
                type: GET_STAFF_NOT_TRACKING_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_STAFF_NOT_TRACKING_FAIL
        });
    }
}

export const findUser = (form) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/talent-hub/find-user/`, form, config);
        if (res.status === 200) {

            dispatch({
                type: FIND_USER_SUCCESS, payload: res.data
            });

        } else {
            dispatch({
                type: FIND_USER_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: FIND_USER_FAIL
        });
    }
}

export const sendTracking = (form) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }
    };
    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/talent-hub/scanner/`, form, config);
        if (res.status === 201) {
            dispatch({
                type: SEND_TRACKING_SUCCESS, payload: res.data
            })
            dispatch(setAlert(res.data.message, 'success'));

        } else {
            dispatch({
                type: SEND_TRACKING_FAIL
            });
        }
    } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
            dispatch(setAlert(err.response.data.message, 'error'));
        } else {
            dispatch(setAlert('Ocurrió un error al registrar la asistencia', 'error'));
        }
        dispatch({type: SEND_TRACKING_FAIL});
    }
}
export const getDepartmentsWithChildren = (params) => async dispatch => {
    const config = {
        headers: {
            'Authorization': `JWT ${localStorage.getItem('access')}`, 'Accept': 'application/json'
        }, params: {...params}
    };
    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/talent-hub/departments/`, config);
        if (res.status === 200) {
            dispatch({
                type: GET_DEPARTMENTS_STAFF_SUCCESS, payload: res.data
            });
        } else {
            dispatch({
                type: GET_DEPARTMENTS_STAFF_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_DEPARTMENTS_STAFF_FAIL
        });
    }
}