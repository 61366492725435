import React, {useEffect, useState} from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import HeaderForm from "../../util/HeaderForm";
import {InputForm, SelectForm} from "../../util/HelpersForm";
import {addPackingLot, updatePacking} from "../../../redux/actions/production";
import {getLots} from "../../../redux/actions/logistic";


const FormPacking = ({data, onClose, params, cuts, selected}) => {
    const dispatch = useDispatch();
    const lots = useSelector(state => state.Logistic.lots);
    const [search, setSearch] = useState({lot: data?.lot_name || ''})

    const handleSearch = () => {
        dispatch(getLots(search));
        formik.setFieldValue('lot', null);
    }
    useEffect(() => {
        dispatch({type: 'GET_LOT_SUMMARY_FAIL'})
        data &&
        dispatch(getLots({lot: data.lot_name}));
    }, []);

    /*Formik*/
    const formik = useFormik({
        initialValues: initialValues(data),
        validationSchema: Yup.object(newSchema()),
        validateOnChange: true,
        onSubmit: (form, onSubmitProps) => {
            // console.log(form)
            data ? dispatch(updatePacking(data?.id, form, {...params, type: selected})) : dispatch(addPackingLot(form, {...params, type: selected}));
            onClose();
        }
    })
    return (

        <form className="bg-white  rounded px-8 pt-6 pb-8 mb-4">
            <HeaderForm submit={formik.handleSubmit} close={onClose}/>
            <div className={`grid grid-cols-2 gap-2`}>
                <div className={"col-span-2 flex items-center justify-center relative"}>
                    <input
                        value={search.lot}
                        onChange={(e) => setSearch({...search, lot: e.target.value})}
                        onKeyUp={(e) => e.key === 'Enter' && handleSearch()}
                        className={`block w-max p-3 pl-10 border-2 text-sm border-gray-200 rounded-md focus:border-green-300 focus:ring-green-300`}
                        placeholder={`Lote...`}
                    />
                    <div className="absolute top-0 inset-y-0 left-8  md:left-20 flex items-center  pointer-events-none">
                        <svg
                            className="h-3.5 w-3.5 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                        >
                            <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                        </svg>
                    </div>
                </div>
                {lots && lots?.[0]?.['id'] &&
                <SelectForm values={formik.values.lot} errors={formik.errors.lot} formik={formik}
                                      field={'lot'}
                                      title={'Lote'} options={lots} display={'lot'}
                />}
                <InputForm values={formik.values.date_production} errors={formik.errors.date_production} formik={formik}
                           field={'date_production'}
                           title={'Fecha de acondicionado'} type_={'date'}
                />
                <InputForm values={formik.values.date_packaging} errors={formik.errors.date_packaging} formik={formik}
                           field={'date_packaging'}
                           title={'Fecha de envasado'} type_={'date'}
                />
                <SelectForm values={formik.values.cut} errors={formik.errors.cut} formik={formik}
                            field={'cut'}
                            title={'Presentación'} options={cuts ? cuts : []} display={'name'}
                />
                <InputForm values={formik.values.kg} errors={formik.errors.kg} formik={formik}
                           field={'kg'}
                           title={'Kg'} max_length={9}
                />
                <SelectForm values={formik.values.category} errors={formik.errors.category} formik={formik}
                            field={'category'}
                            title={'Categoría'}
                            options={[{id: 'A', name: 'A'}, {id: 'Q', name: 'Laboratorio'},]} display={'name'}
                />
                <InputForm values={formik.values.humidity} errors={formik.errors.humidity} formik={formik}
                           field={'humidity'}
                           title={'Humedad'} max_length={5}
                />
                <InputForm values={formik.values.people} errors={formik.errors.people} formik={formik}
                           field={'people'} max_length={2}
                           title={'Personas'}
                />
                <InputForm values={formik.values.duration} errors={formik.errors.duration} formik={formik}
                           field={'duration'}
                           title={'Duración'} type={'number'}
                />
            </div>
        </form>

    );
};

const initialValues = (data) => {
    return {
        lot: data?.lot || '',
        date_production: data?.date_production || new Date().toISOString().split('T')[0],
        date_packaging: data?.date_packaging || new Date().toISOString().split('T')[0],
        cut: data?.cut || '',
        category: data?.category || 'A',
        kg: data?.kg || 0,
        humidity: data?.humidity || 0,
        people: data?.people || 0,
        duration: data?.duration || 0,
    }

}
const newSchema = () => {
    return {
        date_production: Yup.date().required(),
        date_packaging: Yup.date().required(),
        cut: Yup.string().min(6).required(),
        category: Yup.string().min(1).max(1).required(),
        kg: Yup.number().required(),
        humidity: Yup.number().required(),
        people: Yup.number().required(),
        duration: Yup.number().required()


    }
}

export default FormPacking;
