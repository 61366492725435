import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import {formatDateTime} from "../../util/helpers";
import {
    BellSlashIcon,
    DocumentIcon,
    ExclamationTriangleIcon,
    FlagIcon,
    HandThumbDownIcon,
    HandThumbUpIcon
} from "@heroicons/react/24/solid";
import {BellAlertIcon, TrashIcon} from "@heroicons/react/20/solid";
import {useSelector} from "react-redux";

const TableRegister = ({data, cleaned, validated, view, remove}) => {
    const physical = useSelector(state => state.Maintenance.physical);
    const maintenance_type = useSelector(state => state.Maintenance.maintenance_type);
    const failures = useSelector(state => state.Maintenance.failures);

    const columns = ['', 'Fecha', 'Origen de falla', 'Tipo de mantenimiento', 'Equipo', 'Descripción']
    return (<TableBase columns={columns}
                       cell={Row(data, cleaned, validated, view, remove, physical, maintenance_type, failures)}/>);
};

const Row = (data, cleaned, validated, view, remove, physical, maintenance_type, failures) => (data && data.map((item, index) => {


    return <tr key={index} className="h-max border-gray-300  border-b">
        <Cell text={<div className="flex flex-row gap-4">
            <TrashIcon title={"Eliminar"} className={"w-5 h-5 text-red-400 cursor-pointer"}
                       onClick={() => remove(item)}/>
            <DocumentIcon
                onClick={() => view(item)}
                className="w-5 h-5 text-gray-500 hover:text-blue-400 cursor-pointer"
            />
            {item.cleaned ? (<FlagIcon title={"Calidad"}
                                       className="w-5 h-5 text-green-400 hover:text-green-600 cursor-pointer"
            />) : (<ExclamationTriangleIcon title={"Calidad"}
                                            onClick={() => cleaned(item?.id)}
                                            className="w-5 h-5 text-yellow-400 hover:text-yellow-600 cursor-pointer"
            />)}
            {item.validated ? (<HandThumbUpIcon title={"Solicitante"}
                                                className="w-5 h-5 text-green-400 hover:text-green-600 cursor-pointer"
            />) : (<HandThumbDownIcon title={"Solicitante"}
                                      onClick={() => validated(item?.id)}
                                      className="w-5 h-5 text-red-400 hover:text-red-600 cursor-pointer"
            />)}
            {item?.planned ? <BellAlertIcon title={"Programado"} className={"w-5 h-5 text-blue-400"}/> :
                <BellSlashIcon title={"Programado"}
                               className="w-5 h-5 text-gray-400"/>}
        </div>}/>
        <Cell text={<div className={"flex  flex-col"}>
            <span>{item?.date_start && formatDateTime(item.date_start)}</span>
            <span>{item?.date_finish && formatDateTime(item.date_finish)}</span>
        </div>}/>
        <Cell text={failures && failures.find(fail => fail.id === item?.failure)?.name}
        />
        <Cell
            text={maintenance_type && maintenance_type.find(maintenance => maintenance.id === item?.type_maintenance)?.name}/>
        <Cell text={physical && physical.find(asset => asset.id === item?.asset)?.name}/>
        <Cell text={item?.description}/>

    </tr>
}));


export default TableRegister;
