import React, {useEffect, useState} from 'react';
import Layout from "../../../hocs/Layout";
import TableReal from "../../../components/sct/Real/TableReal";
import Filters from "../../../components/sct/Hours/Filters";
import {getAbsenteeism, getStaff, getTrackingReal} from "../../../redux/actions/talent_hub";
import {getDepartments} from "../../../redux/actions/users";
import {useDispatch} from "react-redux";

const Real = () => {
    const [params, setParams] = useState({user: '', date: '', department: ''})
    const dispatch = useDispatch()
    const handleSearch = () => {
        dispatch(getTrackingReal(params))
    }
    useEffect(() => {
        dispatch(getDepartments())
        dispatch(getStaff({status: 'true'}))
        dispatch(getAbsenteeism())
        dispatch(getTrackingReal(params))
    }, [dispatch]);

    return (<Layout>
        <div
            className={"bg-white rounded-2xl p-4 h-fit"}>
            <Filters params={params} setParams={setParams} handleSearch={handleSearch}/>
            <div className="h-screen overflow-y-auto w-full overflow-scroll scrollbar-hide gap-2 flex flex-col">
                <TableReal/>
            </div>
        </div>

    </Layout>);
};

export default Real;
