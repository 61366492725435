import React from 'react';
import TableBase, {Cell} from "../../util/Table";
import Humanize from "humanize-plus";

const TableSweetPotato = ({data, handleUpdate}) => {
    const columns = ['Lote', 'M1', 'M2', 'Daño Mecánico', 'Características Verdes', 'Daño de Gusano', 'Presencia de Hongos', 'Podrido', 'Presencia de Raíz', 'Largo < 8', 'Largo 8-11', 'Largo 11-15', 'Largo > 15', 'Ancho < 7', 'Ancho 7-9', 'Ancho 9-12', 'Ancho > 12', 'Visual', 'Muestreo', 'Destructivo', 'Brix M1', 'PH M1', 'Brix M2', 'PH M2'];
    return (<TableBase columns={columns} cell={Row(data, handleUpdate)}/>);
};


const Row = (data, update) => (data && data.map((item, index) => {
    return (<tr key={index} className="h-max border-gray-300  border-b">

        <Cell text={<p className={"font-medium hover:text-green-500 cursor-pointer"}>{item?.lot_name}</p>}/>
        <Cell text={Humanize.formatNumber(item?.m1, 2)}/>
        <Cell text={Humanize.formatNumber(item?.m2, 2)}/>
        <Cell text={Humanize.formatNumber(item?.mechanical_damage_cracked, 2)}/>
        <Cell text={Humanize.formatNumber(item?.green_traits, 2)}/>
        <Cell text={Humanize.formatNumber(item?.worm_damage, 2)}/>
        <Cell text={Humanize.formatNumber(item?.fungal_presence, 2)}/>
        <Cell text={Humanize.formatNumber(item?.rot, 2)}/>
        <Cell text={Humanize.formatNumber(item?.root_presence, 2)}/>
        <Cell text={Humanize.formatNumber(item?.length_lt_8, 2)}/>
        <Cell text={Humanize.formatNumber(item?.length_8_11, 2)}/>
        <Cell text={Humanize.formatNumber(item?.length_11_15, 2)}/>
        <Cell text={Humanize.formatNumber(item?.length_gt_15, 2)}/>
        <Cell text={Humanize.formatNumber(item?.width_lt_7, 2)}/>
        <Cell text={Humanize.formatNumber(item?.width_7_9, 2)}/>
        <Cell text={Humanize.formatNumber(item?.width_9_12, 2)}/>
        <Cell text={Humanize.formatNumber(item?.width_gt_12, 2)}/>
        <Cell text={Humanize.formatNumber(item?.visual, 2)}/>
        <Cell text={Humanize.formatNumber(item?.sampling, 2)}/>
        <Cell text={Humanize.formatNumber(item?.destructive_analysis, 2)}/>
        <Cell text={Humanize.formatNumber(item?.bm1, 2)}/>
        <Cell text={Humanize.formatNumber(item?.ph_m1, 2)}/>
        <Cell text={Humanize.formatNumber(item?.bm2, 2)}/>
        <Cell text={Humanize.formatNumber(item?.ph_m2, 2)}/>


    </tr>)
}));


export default TableSweetPotato;
